import React from 'react';
import { Stack, Typography } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Button } from 'src/components/shared';

import { useColumns } from './useColumns';
import { data } from './data';

import { useCustomVariables } from '../../utils/customVariableStore';

const CustomVariablesGrid = ({ showAddForm }) => {
  const { variables, paging, setPaging, loading, put } = useCustomVariables();
  const onEdit = (item) => {};
  const columns = useColumns(variables, showAddForm, put);

  const onTableChange = ({ pageNo, perPage }) => {
    try {
      setPaging({
        ...paging,
        pageNo,
        perPage,
      });
    } catch (error) {}
  };

  return (
    <>
      <AppGrid
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        onFilterChange={(key, val) => onFilterChange(key, val)}
        onTableChange={onTableChange}
        data={variables}
        className="lookupsGrid"
        filters={{}}
        loading={loading}
        options={{
          sortOrder: {
            name: 'none',
            direction: 'none',
          },
          rowsSelected: [],
          serverSide: true,
          pagination: true,
          page: paging._from,
          rowsPerPage: paging._size,
          count: paging._total,
          searchPlaceholder: 'Search',
          search: true,
          searchOpen: true,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Stack direction="row" spacing={1.5}>
                <Button variant="contained" color="secondary" size="medium" onClick={showAddForm}>
                  <Typography variant="body2">Add custom variable</Typography>
                </Button>
              </Stack>
            );
          },
        }}
      />
    </>
  );
};

export default CustomVariablesGrid;
