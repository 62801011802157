import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material';
import useRequest from 'src/components/hooks/useRequest';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { AppGrid } from 'src/components/App';
import Icon from 'src/components/lms/Icon';
import FilterBar from 'src/components/lms/Filterbar';

import { fetchAssignedReport } from '../../api/LMSApis';
import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { isEmpty } from 'lodash';
import usePersistState from 'src/components/hooks/usePersistState';

function LearningReport() {
  const { palette } = useTheme();
  // const [filters, setFilters] = useState({});

  const [filters, setFilters] = usePersistState('lmsAssignedReport', {
    createdAt: { id: 'last30Days', name: 'Last 30 days' },
  });

  const [paging, setPaging] = useState({
    pageNo: 0,
    perPage: 10,
  });

  const filtersArr = [
    {
      key: 'createdAt',
      title: 'Date',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'last30Days', name: 'Last 30 days' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      icon: <AccessTimeIcon />,
      isPrimary: true,
    },
  ];

  let payload = {
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...filters,
  };

  if (filters?.createdAt && !isEmpty(filters?.createdAt)) {
    const dateRange = getRelativeDateRange(filters?.createdAt);
    payload = {
      ...payload,
      updatedAt_gte: dateRange.start,
      updatedAt_lte: dateRange.end,
    };
  }
  delete payload.createdAt;
  const [data, { loading, error, refetch }] = useRequest(fetchAssignedReport, payload);
  const usersData = data?.report || [];

  const columns = useMemo(() => {
    return [
      {
        label: 'Assigned Learning Name',
        name: 'title',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: '300px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              minWidth: '300px',
              textTransform: 'capitalize',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            return <Typography color="primary">{row.title}</Typography>;
          },
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="layers" size={20} color={palette.secondary.main} />
            <Typography variant="body2">Type</Typography>
          </Stack>
        ),
        name: 'type',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '100px', minWidth: '100px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '100px',
              minWidth: '100px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="team" size={20} color={palette.secondary.main} />
            <Typography variant="body2">Assigned to</Typography>
          </Stack>
        ),
        name: 'totalUsers',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '120px', minWidth: '120px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '120px',
              minWidth: '120px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            return (
              <Typography color="textSecondary" variant="body2">
                {row.totalUsers} {row.totalUsers > 1 ? 'users' : 'user'}
              </Typography>
            );
          },
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="percent" size={20} color={palette.secondary.main} />
            <Typography variant="body2">Completed</Typography>
          </Stack>
        ),
        name: 'completedPercentage',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '120px', minWidth: '120px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '120px',
              minWidth: '120px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            return (
              <Typography color="textSecondary" variant="body2">
                {row.completedPercentage}%
              </Typography>
            );
          },
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center">
            <Icon name="activity" size={20} color={palette.secondary.main} />
            <Typography variant="body2">Last updated</Typography>
          </Stack>
        ),
        name: 'completedPercentage',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '140px', minWidth: '140px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: { maxWidth: '140px', minWidth: '140px', textTransform: 'capitalize' },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            const date = moment(row.lastUpdatedAt).format(dateTimeFormat);
            return (
              <Typography color="textSecondary" variant="body2">
                {date}
              </Typography>
            );
          },
        },
      },
    ];
  }, [usersData]);

  const handleTableChange = ({ pageNo, perPage }) => {
    setPaging({
      pageNo,
      perPage,
    });
  };

  const onFilterChange = (key, value) => {};

  const handleFilter = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setFilters({
        ...filters,
        [key]: value,
      });
      // setFilters((prev) => ({ ...prev, [key]: value }));
    } else {
      const prev = JSON.parse(JSON.stringify(filters));
      delete prev[key];
      setFilters(prev);
    }
  };

  const total = data?.total?.value || 0;

  return (
    <>
      <Box mt={2} mb={2} zIndex={999999} alignItems="center">
        <FilterBar
          showSearch={true}
          filters={filtersArr}
          key="completed-filters"
          value={filters}
          onChange={handleFilter}
        />
      </Box>
      <AppGrid
        selectableRows="none"
        columns={columns}
        data={usersData}
        filters={[]}
        appliedFilters={filters}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        loading={loading}
        pagingType="links"
        stickyCol={false}
        options={{
          stickyCol: false,
          pagination: true,
          page: paging?.pageNo,
          rowsPerPage: paging?.perPage,
          count: total,
          serverSide: true,
          searchPlaceholder: 'Search invoice',
          searchText: filters?._search ? filters._search : '',
          search: false,
        }}
      />
    </>
  );
}

export default LearningReport;
