import React, { useMemo } from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Icon from 'src/components/lms/Icon';
import { Tabs } from 'src/components/App';
import { teamTabs } from '../../config';
import LearningReport from './LearningReport';
import UsersReport from './UsersReport';

function TeamsContainer({ ...props }) {
  const currentTab = useMemo(() => {
    return props?.match?.params?.slug || 'users';
  }, [props]);

  const onTabChange = (tab) => {
    props.history.push(`/lms/team/${tab.id}`);
  };

  return (
    <LMSCard title="Team" icon={<Icon name="team" style={{ width: 24, height: 24 }} />}>
      <Tabs tabs={teamTabs} onChange={onTabChange} value={currentTab} />
      {/* {currentTab === 'competitions' && <CompetitionContainer />} */}
      {currentTab === 'assigned' && <LearningReport />}
      {currentTab === 'users' && <UsersReport />}
    </LMSCard>
  );
}

export default TeamsContainer;
