import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Switch, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  BlackChip,
  BlackChipText,
  CardMoreOption,
  CustomerCard,
  FieldCardChip,
  FieldChipText,
  ICPCardViewsGrid,
  useStyles,
} from './style';
import images from '../../../../config/images';
import { fieldList, getBackGroundColor } from './utils';
import IcpModal from './IcpModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import toast from '../../../../utils/toast';
import Menu from '../../../../components/shared/Menu';

const ICPCard = ({
  data,
  setIsDetailModal,
  setShowICPForm,
  updateICP,
  deleteICP,
  icpsData,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const classes = useStyles();

  const handleUpdateICP = async () => {
    try {
      const updateValue = icpsData.find((v) => v?.id === data.id);
      updateValue.status = !data.status;
      await updateICP(updateValue);
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
      toast.success(`ICP ${!data.status ? 'activated' : 'deactivated'} successfully`);
      setIsOpenModal(false);
    } catch (error) {
      toast.error(`ICP ${!data.status ? 'activated' : 'deactivated'} failed`);
      setIsOpenModal(false);
      console.error(error);
    }
  };

  const handelDeleteICP = async () => {
    try {
      await deleteICP(data.id);
      props.fetchICP(props.icp.paging, props.icp.filters, props.icp.sort);
      toast.success('ICP deleted successfully');
      setIsOpenDeleteModal(false);
    } catch (error) {
      setIsOpenDeleteModal(false);
      console.error(error);
    }
  };

  const updatedFieldList = fieldList
    .map((field) => {
      if (data?.hasOwnProperty(field.key)) {
        return {
          ...field,
          value: data[field.key],
        };
      }
      return field;
    })
    .filter((field) => field?.value !== undefined && field?.value !== null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        sx={{
          width: 250,
          borderRadius: '16px',
          '& .MuiCardContent-root': { padding: '0px' },
          '& .MuiCardContent-root:last-child': { padding: '0px !important' },
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            sx={{ background: getBackGroundColor(data?.profileType) }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="12px">
              <FormControlLabel
                sx={{ marginLeft: '0px !important' }}
                control={
                  <Switch
                    checked={data.status}
                    onChange={() => setIsOpenModal(true)}
                    color="secondary"
                    size="small"
                  />
                }
                label=""
              />
              <Box sx={{ '& .MuiCardActions-root': { padding: '0px !important' } }}>
                <CardActions disableSpacing>
                  <Box
                    sx={{
                      '& .MuiMenu-paper': {
                        borderRadius: '8px !important',
                      },
                      '& .MuiList-root': {
                        padding: '0px !important',
                      },
                    }}
                  >
                    <Menu
                      removeParentAnchorlEl={setAnchorEl}
                      sx={{
                        '& .MuiMenu-paper': {
                          borderRadius: '8px !important',
                        },
                        '& .MuiList-root': {
                          padding: '0px !important',
                        },
                      }}
                      iconButtonColor={Boolean(anchorEl) ? 'white' : 'rgba(13, 12, 10, 0.5)'}
                      buttonStyles={{
                        padding: '0px !important',
                        background: Boolean(anchorEl) ? 'rgba(13, 12, 10, 0.5)' : '',
                        borderRadius: '4px',
                      }}
                      options={[
                        {
                          label: 'Edit',
                          icon: (
                            <img src={images.icp.editIcon} alt="" width={'24px'} height={'24px'} />
                          ),
                          onClick: () => {
                            setShowICPForm({ isOpen: true, edit: true, clone: false, id: data.id });
                          },
                        },
                        {
                          label: 'Clone',
                          icon: (
                            <img src={images.icp.cloneIcon} alt="" width={'24px'} height={'24px'} />
                          ),
                          onClick: () => {
                            setShowICPForm({ isOpen: true, edit: false, clone: true, id: data.id });
                          },
                        },
                        {
                          label: 'Delete',
                          icon: (
                            <img
                              src={images.icp.deleteIcon}
                              alt=""
                              width={'24px'}
                              height={'24px'}
                            />
                          ),
                          onClick: () => {
                            setIsOpenDeleteModal(true);
                          },
                        },
                      ]}
                    />
                  </Box>
                </CardActions>
              </Box>
            </Box>
            <CustomerCard>
              <BlackChip>
                <BlackChipText style={{ fontSize: '10px' }}>{data?.profileType}</BlackChipText>
              </BlackChip>
              <Typography variant="h2" className={classes.cardLabelTypography}>
                {data.name}
              </Typography>
            </CustomerCard>
          </Box>
          <ICPCardViewsGrid>
            <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
              {updatedFieldList &&
                updatedFieldList?.slice(0, 3)?.map((field) => (
                  <FieldCardChip sx={{ width: 'fit-content !important' }}>
                    <img src={images.icp[field?.icon]} alt={''} />
                    <FieldChipText>
                      {field?.value && field?.value.value ? field.value.value : field?.value}
                    </FieldChipText>
                  </FieldCardChip>
                ))}
            </Box>
            {updatedFieldList.length > 3 && (
              <CardMoreOption onClick={() => setIsDetailModal({ isOpen: true, moreDetails: data })}>
                {updatedFieldList.length - 3} more <ArrowForwardIcon fontSize={'16px'} />
              </CardMoreOption>
            )}
          </ICPCardViewsGrid>
        </CardContent>
      </Card>
      <IcpModal
        open={isOpenModal}
        content={
          <>
            <div>
              {!data.status ? 'Activated ICPs can be used.' : 'Deactivated ICPs cannot be used.'}
            </div>
            <div>Are you sure you want to proceed?</div>
          </>
        }
        secondButtonTitle={!data.status ? 'Activate' : 'Deactivate'}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        onSubmit={() => handleUpdateICP()}
      />
      <IcpModal
        isErrorButton={true}
        open={isOpenDeleteModal}
        content={
          <>
            <div>Deleting ICPs will permanently remove all data.</div>
            <div>Are you sure you want to proceed?</div>
          </>
        }
        secondButtonTitle="Delete"
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onSubmit={() => handelDeleteICP()}
      />
    </>
  );
};

export default ICPCard;
