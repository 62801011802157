import { makeStyles } from '@mui/styles';
import { themeColors } from 'src/modules/lms/config';
import styled from 'styled-components';

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  heading: {
    fontWeight: '300',
    fontSize: '26px',
    color: themeColors.greyHeadingText,
  },
  lastSection: {
    paddingRight: '92px',
  },
  links: {
    color: themeColors.greyText,
    fontWeight: '300',
    fontSize: '14px',
  },
  paragraph: {
    fontWeight: '400',
    fontSize: '18px',
    color: themeColors.greyParagraphText,
    lineHeight: '28px',
  },
  courseTagSection: {
    background: themeColors.bg3,
    borderRadius: '5px',
    '& div': {
      padding: 0,
      margin: 0,
    },

    '& > div': { display: 'flex', flexDirection: 'column', gap: '20px' },

    '& .title': {
      color: '#FFD47E',
      fontWeight: 400,
    },

    '& .tags-container': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',

      '& button': {
        margin: 0,
        fontSize: '12px',
        color: '#ffffffe0',
        borderColor: '#ffffffe0',
        padding: '4px 12px',
        height: 'unset !important',
      },
    },
  },
  courseImg: {
    margin: '6px 14px 14px 14px',
  },
  courseImg2: {
    margin: '16px 14px 14px 14px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '700',
    color: themeColors.yellow,
  },
  courseDetailWrapper: {
    margin: '24px 24px 16px',

    '& .course-detail-container, & .expert-section-container, & .other-course-container': {
      padding: '32px',
      background: '#0C1721',
      height: 'fit-content',
      borderRadius: '8px',
      gap: '32px',
    },

    '& .expert-section-container': {
      flex: 1,
      gap: '32px',
      display: 'flex',
      flexDirection: 'column',

      '& .title': {
        fontSize: '18px',
        fontWeight: '500',
        color: '#ffffffe0',
        margin: 0,
      },

      '& .expert-avatar-container': {
        '& button': {
          borderRadius: '50%',

          '& .avatar': {
            border: '2px solid transparent',
            padding: '2px',
            width: '68px',
            height: '68px',
            aspectRatio: 1,

            '& img': {
              borderRadius: '50%',
            },

            '&.active': {
              borderColor: 'rgb(255, 212, 126)',
            },
          },
        },
      },

      '& .expert-details': {
        '& .name': {
          color: '#FFD47E',
          fontSize: '18px',
          fontWeight: '500',
        },
        '& .description': {
          color: '#FFFFFFB2',
        },
      },
    },

    '& .other-course-container': {
      flex: 1,
      gap: '20px',
      display: 'flex',
      flexDirection: 'column',

      '& .title': {
        fontSize: '18px',
        fontWeight: '500',
        color: '#ffffffe0',
        margin: 0,
      },

      '& > div > div': {
        maxWidth: 'unset',
        margin: 0,
        borderRadius: '6px',
      },
    },
  },
  breadcrumbs: {
    '& .MuiBreadcrumbs-separator': {
      margin: 0,
      '& .MuiSvgIcon-root': { color: themeColors.greyText, fontSize: '20spx' },
    },
  },
  courseTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    position: 'relative',
    color: '#D2D2D2',
    fontSize: '20px',
    fontWeight: '600',
  },
  searchIcon: {
    color: 'white !important',
  },
  Search: {
    marginBottom: '30px',
    color: '#FAFAFA !important',
    opacity: '0.6',
    borderRadius: '10px',
    background: '#193247 !important',
    ' & input': {
      color: '#FAFAFA !important',
      borderRadius: '10px',
      background: ' #193247 !important',
    },
    '& label': {
      color: '#FAFAFA !important',
    },
    ' &:focus': {
      background: 'transparent !important',
    },
  },
  sessionsContainer: {
    padding: '8px 0',

    '& .title': {
      fontSize: '20px',
      fontWeight: '600',
    },

    '& .progress-bar-container': {
      '& .progress-bar > div': { padding: 0 },
      '& .progress-title': { color: '#FFD47E' },
      '& .tick-icon': {
        width: '24px',
        height: '24px',
        color: 'rgba(255, 212, 126, 0.3)',
        '&.active': {
          color: 'rgb(255, 212, 126)',
        },
      },
    },

    '& .session-wrapper': {
      position: 'relative',

      '& .list-number': {
        width: '40px',
        minWidth: '40px',
        height: '40px',
        minHeight: '40px',

        '&.current .sessionCount': {
          background: '#0C1721',
          border: '2px dashed #FFD47E',
          color: '#FFD47E',
        },
        '&.inactive .sessionCount': {
          background: '#0C1721',
          border: '2px dashed #FFFFFF4D',
          color: '#FFFFFFB2',
        },

        '& .before': {
          position: 'absolute',
          height: '100%',
          zIndex: 1,
          top: 'calc(-50% + -20px)',
          left: '19px',
          borderLeft: '2px solid #FFD47E',

          '&.current': {
            borderLeft: '2px dashed #FFD47E',
          },

          '&.inactive': {
            borderLeft: '2px dashed #555d64',
          },
        },

        '& .sessionCount': {
          position: 'absolute',
          top: '70px',
          background: '#FFD47E',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid #FFD47E',
          zIndex: 100000,
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#000000DE',
          width: '40px',
          minWidth: '40px',
          height: '40px',
          minHeight: '40px',
        },
      },
    },
  },
  descriptionBox: {
    color: '#ffffffe6',
    flex: 1,
    '& div, span, applet, object, iframe, h1,h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0',
        padding: '0px',
        border: '0',
        fontSize: '100%',
        font: 'inherit',
        verticalAlign: 'baseline',
        whiteSpace: 'normal',
      },
    '& ul': {
      paddingLeft: '40px',
    },
  },
  assignCourseModal: {
    '& .MuiDialog-paper': {
      background: '#15222E',
      borderRadius: '5px',

      '& .input-desc': {
        color: '#FFFFFFB2',
      },

      '& .MuiInputBase-root': {
        background: 'transparent',
        border: '1px solid #FFFFFF3B',
      },

      '& .action-button': {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
        justifyContent: 'flex-end',

        '& button': {
          width: 'fit-content',
        },
      },

      '& .assigned-user': {
        background: '#FFFFFF29',
        borderRadius: '24px',
        padding: '6px 8px',
        alignItems: 'center',
        gap: '10px',

        '& .MuiAvatar-root': {
          width: '28px',
          height: '28px',
        },

        '& .name': {
          fontSize: '13px',
          color: 'white',
        },

        '& .remove-icon': {
          borderRadius: '50%',
        },
      },
    },
  },
}));

export default useStyles;

export const Desc = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.2px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: rgba(255, 255, 255, 0.87);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 24px 0px 10px 0px;
  }
  & p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2px;
  }
  & a {
    color: #ffd47e;
  }
`;
