import React, { useMemo, useState } from 'react';
import { Typography, Card, CardContent, CardHeader, Stack, ButtonBase } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';
import { ConfirmDialog } from 'src/components/App';
import { isEmpty } from 'lodash';
import AddIcon from '@mui/icons-material/Add';

import CreditAllocationForm from './CreditAllocationForm';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDataProviders } from '../../utils/dataProviderStore';
import { useSelector } from 'react-redux';

const defaultValue = {
  user: {},
  credit: 0,
  expiry: undefined,
};

function CreditAllocation({ creditAllocation, defaultCreditLimit }) {
  const [showModal, setShowModal] = useState(false);
  const [editCredit, setEditCredit] = useState({});
  const [deleteCredit, setDeleteCredit] = useState({});
  const [deleting, setDeleting] = useState(false);
  const accountLimit = useSelector((state) => state.app.tenant)?.defaultCreditsLimit;

  const totalAccountCredit = useMemo(() => {
    let count = 0;
    (creditAllocation || []).forEach((allocation) => {
      count += Number(allocation.credits);
    });
    if (editCredit?.credits) {
      count = count - Number(editCredit?.credits);
    }
    return Number(accountLimit) - count;
  }, [creditAllocation, editCredit, accountLimit]);

  const disabledAddBespoke = useMemo(() => {
    let count = 0;
    (creditAllocation || []).forEach((allocation) => {
      count += Number(allocation.credits);
    });
    return count >= Number(accountLimit);
  }, [creditAllocation, editCredit, accountLimit]);

  const { deleteCreditAllocation } = useDataProviders();

  const handleClose = () => {
    setShowModal(false);
    setEditCredit({});
  };

  const handleDelete = async () => {
    setDeleting(true);
    await deleteCreditAllocation(deleteCredit);
    setDeleting(false);
    setDeleteCredit({});
  };

  return (
    <>
      <Card>
        <CardHeader
          sx={{ '& .MuiCardHeader-subheader': { color: 'rgba(0, 0, 0, .87)', fontWeight: 500 } }}
          subheader="Bespoke credit allocation"
          subheaderTypographyProps={{ color: 'textPrimary' }}
        />
        <CardContent sx={{ paddingTop: '10px' }}>
          {creditAllocation.map((allocation, index) => {
            return (
              <Stack direction="row" gap={2} key={`credit-allocation-item-${index}`} mb={0.5}>
                <Stack
                  direction="row"
                  gap={1}
                  divider={<div>|</div>}
                  key={`credit-allocation-item-${index}`}
                  mb={0.5}
                >
                  <Typography>
                    {allocation?.user?.fname} {allocation?.user?.lname}
                  </Typography>
                  <Typography>${allocation.credits} credits</Typography>
                  {allocation.expire && <Typography>until {allocation.expire}</Typography>}
                </Stack>

                <Spacer x={3} />
                <ButtonBase
                  iconButton
                  onClick={() => {
                    setEditCredit(allocation);
                    setShowModal(true);
                  }}
                >
                  <EditIcon sx={{ fontSize: '16px', fill: '#1976D2' }} />
                </ButtonBase>
                <ButtonBase
                  iconButton
                  onClick={() => {
                    setDeleteCredit(allocation);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: '16px', fill: '#1976D2' }} />
                </ButtonBase>
              </Stack>
            );
          })}
          <Spacer x={1} y={1} />
          <Button
            color="secondary"
            disabled={disabledAddBespoke}
            startIcon={<AddIcon />}
            sx={{ fontSize: '16px' }}
            onClick={() => {
              !disabledAddBespoke && setShowModal(true);
            }}
          >
            Add bespoke allocation
          </Button>
        </CardContent>
      </Card>
      <CreditAllocationForm
        open={showModal}
        editCredit={editCredit}
        onClose={handleClose}
        defaultCreditLimit={defaultCreditLimit}
        totalAccountCredit={totalAccountCredit}
        accountCredit={accountLimit}
      />
      <ConfirmDialog
        title="Delete Bespoke Allocation"
        open={!isEmpty(deleteCredit)}
        onClose={() => {
          setDeleteCredit({});
        }}
        onConfirm={handleDelete}
        buttonTitle={'Yes, Continue'}
        cancelButtonColor="secondary"
        cancelButtonVariant="outlined"
        confirmButtonVariant="contained"
        loading={deleting}
      >
        <Typography variant="body1">Are you sure you want to delete this allocation?</Typography>
      </ConfirmDialog>
    </>
  );
}

export default CreditAllocation;
