import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';
import { isEmpty } from 'lodash';

export const fetchAssignedReport = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/tasks/report${filter}`, null, 'GET');
};

export const fetchUsersReport = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/user/lms/report${filter}`, null, 'GET');
};

export const fetchAssigned = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/tasks${filter}`, null, 'GET');
};

export const globalSearch = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/globalsearch${filter}`, null, 'GET');
};

export const getLeftNav = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`lms/homepage/leftnavigation${filter}`, null, 'GET');
};

export const getCompo1 = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`lms/homepage/component1${filter}`, null, 'GET');
};

export const getCompo2 = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`lms/homepage/component2${filter}`, null, 'GET');
};

export const getCompo3 = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`lms/homepage/component3${filter}`, null, 'GET');
};

export const getExperts = () => {
  return api(`/lms/expert`, null, 'get');
};

export const getTags = () => {
  return api(`/lms/tag`, null, 'get');
};

export const getExpertsLookup = async (search = '', from = 0, size = 10) => {
  try {
    let res = [];
    const filtersObj = {
      ...(search && search !== '' ? { _search: search } : {}),
      ...(from >= 0 ? { _from: from } : {}),
      ...(size ? { _size: size } : {}),
    };
    let filter = `?${convertObjectToQuerystring(filtersObj)}`;
    res = await api(`/lms/expert${filter}`, null, 'get');
    return res.experts.map((item) => ({
      ...item,
      name: `${item.fname} ${item.lname}`,
    }));
  } catch (error) {
    return [];
  }
};

export const getTagsLookup = async (search) => {
  try {
    let res = [];
    const filtersObj = {
      ...(search && search !== '' ? { name: search } : ''),
    };
    let filter = `?${convertObjectToQuerystring(filtersObj)}`;
    res = await api(`/lms/tag${filter}`, null, 'get');
    return res.tags;
  } catch (error) {
    return [];
  }
};

export const getSessions = (payload) => {
  return api(`/actions/getbullhorncontacts`, null, 'get');
};

export const fetchSessions = () => {
  return api(`/lms/session`, null, 'get');
};

export const fetchGetStartedData = () => {
  return api(`/lms/onboarding`, null, 'get');
};

export const fetchList = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/favourite${filter}`, null, 'get');
};

export const fetchLibrary = (filters = '') => {
  if (filters?.categoryType === 'search') delete filters?.categoryType;
  if (filters && !isEmpty(filters)) filters = `?${convertObjectToQuerystring(filters)}`;
  return api(`/lms/library${filters}`, null, 'get');
};;

export const deleteList = (id) => {
  return api(`/lms/favourite/${id}`, null, 'delete');
};

export const addList = (payload) => {
  return api(`/lms/favourite`, payload, 'post');
};

export const getHomePage = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }

  return api(`/lms/homepage${filter}`, null, 'get');
};

export const getSession = (id = '') => {
  return api(`/lms/session/${id}`, null, 'get');
};

export const getPillar = (id = '') => {
  return api(`/lms/pillar/${id}`, null, 'get');
};

export const getCourse = (id = '') => {
  return api(`/lms/course/${id}`, null, 'get');
};
export const fetchCourses = () => {
  return api(`/lms/course`, null, 'get');
};
export const fetchTemplates = async (filters) => {
  let res = [];
  const filtersObj = {
    _from: 0,
    _size: 10,
    ...filters,
  };
  let filter = `?${convertObjectToQuerystring(filtersObj)}`;
  try {
    return await api(`/lms/template${filter}`, null, 'get');
  } catch (error) {
    return res;
  }
};
export const fetchNotes = async (filters) => {
  let res = [];
  const filtersObj = {
    _from: 0,
    _size: 10,
    ...filters,
  };
  let filter = `?${convertObjectToQuerystring(filtersObj)}`;

  try {
    const response = await api(`/lms/note${filter}`, null, 'get');
    return response;
  } catch (error) {
    return res;
  }
};
export const deleteNote = (id) => {
  return api(`/lms/note/${id}`, null, 'delete');
};
export const getTemplate = (id = '') => {
  return api(`/lms/template/${id}`, null, 'get');
};
export const getNote = (id = '') => {
  return api(`/lms/note/session/${id}`, null, 'get');
};

export const createTemplate = (payload) => {
  return api(`/lms/template`, payload, 'post');
};
export const createNote = (payload) => {
  return api(`/lms/note`, payload, 'post');
};

export const editNote = (payload, id) => {
  return api(`/lms/note/${id}`, payload, 'put');
};
export const editTemplate = (payload, id) => {
  return api(`/lms/template/${id}`, payload, 'put');
};

export const shareTemplate = (payload, templateId) => {
  return api(`/lms/template/send/${templateId}`, payload, 'post');
};

export const deleteTemplate = (id) => {
  return api(`/lms/template/${id}`, null, 'delete');
};

export const getTenantCourses = () => {
  return api(`/lms/course/tenantCourses`, null, 'get');
};
export const getSessionScore = (user, session, dateObj = null) => {
  let url = 'roleplay/score';
  if (user.length) {
    let newUser = user.map((it) => it.value).join(',');
    url += `?user_eq=${newUser}`;
  }
  if (session.length) {
    let newSession = session.map((it) => it.value).join(',');

    if (url.split('?').length > 1) {
      url += `&session_eq=${newSession}`;
    } else {
      url += `?session_eq=${newSession}`;
    }
  }
  if (dateObj) {
    let { startDate, endDate } = dateObj;

    if (url.split('?').length > 1) {
      url += `&createdAt_gte=${startDate}&createdAt_lte=${endDate}`;
    } else {
      url += `?createdAt_gte=${startDate}&createdAt_lte=${endDate}`;
    }
  }
  return api(url, null, 'get');
};

export const getUserScore = (id) => {
  return api(`roleplay/score?user_eq=${id}`, null, 'get');
};
export const getDashboardUsers = () => {
  return api('user?_from=0&_size=-1&status_eq=active', null, 'get');
};

export const fetchRoleplaySummary = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = convertObjectToQuerystring(filters);
  }
  return api(`roleplay/scoreusersummary?${filter}`, null, 'get');
};

export const fetchRoleplaySummaryBack = (users, dateObj = null) => {
  let userString = users.join(',');
  let url = `roleplay/scoreusersummary`;
  if (userString.length) {
    url += `?users=${userString}`;
  }
  if (dateObj) {
    let { startDate, endDate } = dateObj;

    if (url.split('?').length > 1) {
      url += `&createdAt_gte=${startDate}&createdAt_lte=${endDate}`;
    } else {
      url += `?createdAt_gte=${startDate}&createdAt_lte=${endDate}`;
    }
  }
  return api(url, null, 'get');
};

export const fetchHistory = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/history${filter}`, null, 'get');
};
export const deleteHistory = (id) => {
  return api(`lms/history/${id}`, null, 'delete');
};
export const updateHistory = (id, payload) => {
  return api(`lms/history/${id}`, payload, 'put');
};

export const getEmbedURL = (id) => {
  return api(`/roleplay/embedurl?session=${id}`, null, 'get');
};
export const sendScore = (payload) => {
  return api(`/roleplay/score`, payload, 'POST');
};

export const getReportSummary = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/summary${filter}`, null, 'get');
};

export const getReportSummaryGraph = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/summary/graph${filter}`, null, 'get');
};

export const getCompetitions = () => {
  return api(`/lms/competition/my`, null, 'get');
};

export const getInprogressSessions = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`/lms/progress/session${filter}`, null, 'get');
};

export const getCompletedSessions = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/progress/session/completed${filter}`, null, 'get');
};

export const getCompletedData = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/lms/progress/completed${filter}`, null, 'get');
};

export const getInprogressCourses = () => {
  return api(`/lms/progress/course`, null, 'get');
};

export const getCoursesCompletionStatus = async (id) => {
  return await api(`/lms/progress/course/${id}`, null, 'get');
};

export const getCourseAssignedTo = async (id, type) => {
  return await api(`/lms/tasks?_from=0&_size=-1&${type}_eq=${id}`, null, 'get');
};

export const setCourseAssignTo = async (data) => {
  return await api(`/lms/task/assign/bulk`, data, 'post');
};

export const removeCourseAssignTo = async (id) => {
  return await api(`/lms/task/${id}`, null, 'delete');
};

export const getLmsSummaryCount = () => {
  return api(`/lms/summary/count`, null, 'get');
};

export const fetchAllProgress = () => {
  return api(`/lms/progress`, null, 'get');
};

export const fetchPages = () => {
  return api(`/lms/pages`, null, 'get');
};

export const fetchSessionCourses = (sessionId) => {
  return api(`/lms/session/${sessionId}/course`, null, 'get');
};

export const fetchCompetitionLeaderBoard = (competitionId) => {
  return api(`/lms/competition/${competitionId}/leaderboard`, null, 'get');
};

export const fetchSessionSummary = (sessionId) => {
  return api(`/lms/session/${sessionId}/summary`, null, 'get');
};
