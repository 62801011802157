import { Grid, Box } from '@mui/material';
import {
  StyledFilterCard,
  StyledFilterCardContent,
  StyledFilterCardTitle,
  StyledFilterCardValue,
} from './styles';

const FilterCard = ({ title, value, onClick, activeCard }) => (
  <Grid item xs={2}>
    <StyledFilterCard activeCard={activeCard} onClick={onClick}>
      <StyledFilterCardContent>
        <StyledFilterCardTitle>{title}</StyledFilterCardTitle>
        <StyledFilterCardValue>{value || 0}</StyledFilterCardValue>
      </StyledFilterCardContent>
    </StyledFilterCard>
  </Grid>
);

const FilterDataCard = ({ seqCardData, filters, handleCardClick }) => {
  const filterData = [
    { title: 'Prospect enrolled', value: seqCardData?.prospectEnrolled?.value },
    { title: 'Messaged', value: seqCardData?.prospectContacted?.value, filterKey: 'messaged' },
    { title: 'Opened', value: seqCardData?.prospectsOpened?.value, filterKey: 'uniqueopens' },
    {
      title: 'Clicked',
      value: seqCardData?.prospectsClicked?.value,
      filterKey: 'uniqueclicks',
    },
    {
      title: 'Replied',
      value: seqCardData?.prospectsReplied?.value,
      filterKey: 'uniquereplies',
    },
    {
      title: 'Interested',
      value: seqCardData?.prospectsInterested?.value,
      filterKey: 'interested',
    },
  ];

  const handleFilterClick = (filter) => {
    const { filterKey } = filter;
    const updatedFilters = { ...filters };
    Object.keys(filters).forEach((key) => {
      updatedFilters[key] = false;
    });
    if (filterKey) {
      updatedFilters[filterKey] = !filters[filterKey];
      handleCardClick(updatedFilters);
    } else {
      handleCardClick(updatedFilters);
    }
  };

  // if (filter.filterKey) {
  //   if (filter.filterKey === 'status') {
  //     const isHaveValue = filters[filter.filterKey] === filter.filterValue;
  //     let updatedFilters = { ...filters };
  //     Object.keys(filters).forEach((key) => {
  //       updatedFilters[key] = false;
  //     });
  //     if (isHaveValue) {
  //       delete updatedFilters.status;
  //       handleCardClick(updatedFilters);
  //     } else {
  //       updatedFilters[filter.filterKey] = filter.filterValue;
  //       handleCardClick(updatedFilters);
  //     }
  //   }else {
  //     let updatedFilters = {...filters};
  //     Object.keys(filters).forEach((key) => {
  //       updatedFilters[key] = false;
  //     });
  //     updatedFilters[filter.filterKey] = !filters[filter.filterKey];
  //     handleCardClick(updatedFilters);
  //   }
  // }
  return (
    <Box my={'16px'}>
      <Grid container spacing={2}>
        {filterData.map((filter) => (
          <FilterCard
            activeCard={filters[filter.filterKey]}
            key={filter.title}
            onClick={() => handleFilterClick(filter)}
            title={filter.title}
            value={filter.value}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default FilterDataCard;
