import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { convertObjectToQuerystring } from '../../../../utils/helper';
import { fetchSeqDashboardsReport, fetchSeqDashboardsUser } from '../../../app/actions/appActions';
import usePersistState from '../../../../components/hooks/usePersistState';
import { getDifferenceBetweenDates, getRelativeDateRange } from '../../../../utils/dateUtils';
import { fetchSequenceStepGrid } from '../../actions/sequenceActions';

const seqSummaryContext = createContext({});

export const useSeqSummaryStore = () => {
  const {
    filters,
    setFilters,
    fetchSequenceReport,
    setChartType,
    chartType,
    loading,
    sequenceReport,
    userStats,
    fetchUserStats,
    fetchStepsStats,
    seqStepsStats,
    makeChartTypeDisable,
    seqId,
  } = useContext(seqSummaryContext);
  return {
    sequenceReport,
    makeChartTypeDisable,
    loading,
    fetchStepsStats,
    filters,
    setFilters,
    fetchSequenceReport,
    seqStepsStats,
    setChartType,
    chartType,
    userStats,
    fetchUserStats,
    seqId,
  };
};

const SeqSummaryProvider = ({ children }) => {
  const user = useSelector((state) => state?.auth?.user);
  const sequence = useSelector((state) => state.sequence.sequence);
  const dispatch = useDispatch();
  const seqId = sequence?.id || window?.location?.pathname.split('/')[2];
  const [loading, setLoading] = useState({
    sequenceReport: false,
    sequenceStats: false,
    userStats: false,
  });
  const [userStats, setUserStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });
  const [seqStepsStats, setSeqStepStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });
  const [sequenceReport, setSequenceReport] = useState({});
  const [filters, setFilters] = usePersistState('seqDetailViewFilters');
  const [chartType, setChartType] = usePersistState('seqDetailViewChartType', 'daily');
  const [makeChartTypeDisable, setMakeChartTypeDisable] = useState([]);

  useEffect(() => {
    const persistedData = localStorage.getItem('seqDetailViewFilters');
    const data = persistedData ? JSON.parse(persistedData) : {};
    const updatedFilters = { ...data };
    if (!data?.isFirstTime) {
      const enrolledBy = data?.enrolledby ?? [];
      updatedFilters.enrolledby = [...enrolledBy, user];
      updatedFilters.isFirstTime = true;
    }
    if (!data?.createdAt) {
      updatedFilters.createdAt = { id: 'last30Days', name: 'Last 30 days' };
    }
    setFilters(updatedFilters);
  }, []);

  const getFilter = () => {
    let filter = {};
    Object.keys(filters || {}).map((key, index) => {
      if (filters[key] && key === 'createdAt' && !isEmpty(filters[key])) {
        const dateRange = getRelativeDateRange(filters[key]);
        filter = {
          ...filter,
          createdAt_gte: dateRange?.start,
          createdAt_lte: dateRange?.end,
        };
      } else if (filters[key] && key === 'enrolledby' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          enrolledby: filters[key]?.map((assignedTo) => assignedTo.id),
        };
      }
    });
    return filter;
  };

  useEffect(() => {
    if (chartType) fetchSequenceReport();
  }, [filters, chartType]);

  const fetchSequenceReport = async () => {
    const filter = {
      sequences: seqId,
      ...getFilter(),
    };
    try {
      setLoading((prevState) => ({
        ...prevState,
        sequenceReport: true,
      }));
      if (filter?.createdAt_gte && filter?.createdAt_lte) {
        const diff = getDifferenceBetweenDates(filter?.createdAt_gte, filter?.createdAt_lte);
        let fieldDisables = [];
        if (diff > 730) {
          fieldDisables.push('daily');
          fieldDisables.push('weekly');
          if (chartType === 'daily' || chartType === 'weekly') {
            setChartType('monthly');
          }
        } else if (diff > 360) {
          fieldDisables.push('daily');
          if (chartType === 'daily') {
            setChartType('weekly');
          }
        }
        setMakeChartTypeDisable(fieldDisables);
      } else {
        setMakeChartTypeDisable([]);
      }
      if (chartType) {
        filter['chartType'] = chartType;
      }
      let query = '';
      filter.uniquedata = true;
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboardsReport(query, resolve, reject));
      });

      response
        .then((resolvedValue) => {
          setSequenceReport(resolvedValue);
          setLoading((prevState) => ({
            ...prevState,
            sequenceReport: false,
          }));
        })
        .catch((error) => {
          setSequenceReport({});
          setLoading((prevState) => ({
            ...prevState,
            sequenceReport: false,
          }));
        });
    } catch (error) {
      setSequenceReport({});
      setLoading((prevState) => ({
        ...prevState,
        sequenceReport: false,
      }));
    }
  };

  const fetchUserStats = async (newPage, sort) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        userStats: true,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        sequences: seqId,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboardsUser(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading((prevState) => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.users,
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: [],
            paging: {
              ...prevState?.paging,
              count: 0,
            },
          }));
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        userStats: false,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };

  const fetchStepsStats = async (newPage, sort) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        sequenceStats: true,
      }));
      setSeqStepStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSequenceStepGrid(seqId, query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading((prevState) => ({
            ...prevState,
            sequenceStats: false,
          }));
          setSeqStepStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.steps?.sort(function (a, b) {
              return a?.eventOrder - b?.eventOrder;
            }),
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            sequenceStats: false,
          }));
          setSeqStepStats((prevState) => ({
            ...prevState,
            data: [],
            paging: {
              ...prevState?.paging,
              count: 0,
            },
          }));
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        sequenceStats: false,
      }));
      setSeqStepStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };

  return (
    <seqSummaryContext.Provider
      value={{
        filters,
        setFilters,
        seqStepsStats,
        fetchSequenceReport,
        setChartType,
        chartType,
        loading,
        sequenceReport,
        userStats,
        fetchUserStats,
        fetchStepsStats,
        makeChartTypeDisable,
      }}
    >
      {children}
    </seqSummaryContext.Provider>
  );
};

export const withSeqSummaryProvider = (Component) => (props) =>
  (
    <SeqSummaryProvider>
      <Component {...props} />
    </SeqSummaryProvider>
  );
