import images from 'src/config/images';
import SendIcon from '@mui/icons-material/Send';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SpeedIcon from '@mui/icons-material/Speed';

export const data = [
  {
    name: 'Quick start',
    icon_name: 'quickStart',
    key: 'overview',
    items: [
      {
        type: 'accordion',
        key: 'mailbox',
        name: 'Connect your mailbox',
        title: 'Connect your mailbox',
        content:
          '<p style={{fontWeight: 400}}>SuperReach sends emails from your own mailbox to deliver a seamless experience for you and your prospects. \r\n<br><br>\r\nTo connect your mailbox and kick start your outreach, click the button below and follow the instructions.</p>',
        thumbnail: "connectMailBox",
        duration: '145',
        status: 1,
        action: 'connect_mailbox',
        action_label: 'Connect your mailbox',
        icon: 'mark_email_read',
      },
      {
        type: 'accordion',
        key: 'downloadExtension',
        name: 'Download the chrome extension',
        title: 'Download the chrome extension',
        content:
          '<p style={{fontWeight: 400}}>Download our chrome extension to enroll contacts directly from sites such as LinkedIn. Click the link to redirect to our page on the web store.\r\n<br><br>\r\nOnce the extension is installed, it will automatically load when you’re on LinkedIn. To find out more about the extension check out the Outreach section below.</p>',
        thumbnail: 'downloadChromeExtension',
        action: 'download_chrome_extension',
        action_label: 'Go to the Chrome store',
        action_source:
          'https://chrome.google.com/webstore/detail/superreach-sales-hiring-a/nfjemjifleiijedpmipbgbpefcopdkci?hl=en',
        duration: '150',
        icon: 'cloud_download',
      },
    ],
  },
  {
    name: 'Outreach',
    key: 'outReach',
    icon_name: 'outReach',
    content:
      'Read through our simple and easy to follow guides to become an outreach expert in no time.',
    items: [
      {
        type: 'externalLink',
        key: 'howToCreateASequence',
        name: 'How to create a sequence',
        title: 'How to create a sequence',
        duration: '145',
        action_source: 'https://help.superreach.com/en/articles/6207733-how-to-create-a-sequence',
        icon: 'send',
      },
      {
        type: 'externalLink',
        key: 'gettingToKnowExtension',
        name: 'Getting to know the chrome extension',
        title: 'Getting to know the chrome extension',
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/6201516-understanding-the-chrome-extension',
        icon: 'extension',
      },
      {
        type: 'externalLink',
        key: 'addContactToSequenceExtension',
        name: 'Adding a contact to your sequence in the extension',
        title: 'Adding a contact to your sequence in the extension',
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/6223199-how-to-add-a-contact-to-your-sequence-in-the-chrome-extension',
        icon: 'add_circle',
      },
      {
        type: 'externalLink',
        key: 'understandingTasks',
        name: 'Understanding Tasks ',
        title: 'Understanding Tasks ',
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/6233788-understanding-the-task-function',
        icon: 'assignment_turned_in',
      },
    ],
  },
  {
    key: 'successTips',
    name: 'Success Tips',
    icon_name: 'successTips',
    content:
      "We've analysed thousands of sequences to understand what works (and what doesn't!) Use our success tips to supercharge your outreach from day one.",
    items: [
      {
        type: 'externalLink',
        key: 'subjectLines',
        name: "Subject Lines Your Prospects Won't Ignore",
        title: "Subject Lines Your Prospects Won't Ignore",
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/7206967-subject-lines-your-prospects-won-t-ignore',
        icon: 'subject',
      },
      {
        type: 'externalLink',
        key: 'buildingKillerSequence',
        name: 'Building A Killer Sequence',
        title: 'Building A Killer Sequence',
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/7174401-a-guide-to-sequences-in-superreach',
        icon: 'account_tree',
      },
      {
        type: 'externalLink',
        key: 'personalizeAtScale',
        name: 'How To Personalise At Scale',
        title: 'How To Personalise At Scale',
        duration: '145',
        action_source:
          'https://help.superreach.com/en/articles/7206627-hyper-personalisation-at-scale',
        icon: 'auto_fix_high',
      },
    ],
  },
];
