import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchSessions } from '../actions/LMSActions';
import LMSRecruitment from '../components/Recruitement';
import { useHomeProvider, withHomePageStore } from 'src/modules/lms/utils/homeStore';

const LMSRecruitmentContainer = (props) => {
  const { filters, fetchComp1, fetchComp2, fetchComp3 } = useHomeProvider();

  useEffect(() => {
    fetchComp1(filters);
    fetchComp2(filters);
    fetchComp3(filters);
  }, [filters]);

  return (
    <>
      <LMSRecruitment {...props} />
    </>
  );
};

export default withHomePageStore(LMSRecruitmentContainer);
