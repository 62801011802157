import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Box, ListItemButton, Menu, Stack, Typography } from '@mui/material';
import { Form, Button } from 'src/components/shared';
import { populateTemplate } from 'src/modules/app/api/appApis';
import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { useStyles } from './styles';
import { currentDateTimeISO } from 'src/utils/dateUtils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduledEmail from 'src/components/shared/ScheduledEmail';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const TaskReplyForm = ({
  type,
  email,
  user,
  sendTaskReply,
  closeReplyForm,
  includeTemplateData = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState({});
  const [customDate, setCustomDate] = useState(null);
  const [contacts, setContacts] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [template, setTemplate] = useState(null);
  const [scheduledEmailTime, setScheduledEmailTime] = useState('');
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const classes = useStyles();
  const [submitButtonText, setSubmitButtonText] = useState('now');
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFocus = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const fetchContent = (subject, body, contact, user, template = null) => {
    setLoading(true);
    populateTemplate(subject, body, contact, user)
      .then((res) => {
        setTemplate(template);
        setContent(res.content);
        setSubject(res.subject);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user && user?.id) {
      setFrom({ ...user, name: `${user.fname} ${user.lname}` });
    }
    if (
      (type == 'reply' || type == 'replyAll') &&
      email &&
      email?.subject &&
      email?.subject !== ''
    ) {
      setSubject(`Re: ${email.subject}`);
    } else if (email) {
      setSubject(email.subject);
    }
  }, [type, email, user]);

  useEffect(() => {
    setContacts({
      ...email.contact,
      ...(email?.contact?.fname && {
        name: `${email?.contact?.fname}${
          email?.contact?.lname ? ' ' + email?.contact?.lname : null
        }`,
      }),
    });
  }, [email]);

  const handleSubmit = async (values) => {
    try {
      const data = JSON.parse(
        JSON.stringify({
          ...values,
          sendOn: currentDateTimeISO({ minutesToBeAdded: 5 }),
          allowForceSend: true,
        }),
      );
      data.attachments = data?.attachments?.length ? data.attachments.map((file) => file.id) : [];
      if (data?.isReplyReminderEnabled) {
        const { date, sequence } = data?.replyReminder;
        data.replyReminder = {
          ...data?.replyReminder,
          sequence: sequence?.id,
          date:
            date === 'custom'
              ? moment(customDate)?.format('YYYY-MM-DD')
              : moment().add('days', date)?.format('YYYY-MM-DD'),
        };
      } else delete data?.replyReminder;
      if (scheduledEmailTime) data.scheduledOn = scheduledEmailTime;
      if (scheduledEmailTime) data.sendOn = scheduledEmailTime;
      if (includeTemplateData) {
        data.includeTemplateData = includeTemplateData;
      }
      data.type = 'reply';
      setLoading(true);
      await sendTaskReply(data);
      setLoading(false);
      closeReplyForm();
      props.fetchTaskReplies(email.task.id, { pageNo: 0, perPage: 10 }, {});
    } catch (error) {}
  };

  const fetchSequenceDetails = async (val) => {
    const newSequences = await fetchSequencesLookup(val);
    if (newSequences && newSequences.length > 0) {
      const sequenceResults = newSequences.sort(
        (a, b) => (a.accessType === null) - (b.accessType === null) || a.accessType - b.accessType,
      );
      return await sequenceResults;
    }
  };

  return (
    <Form
      initialValues={{
        sender: from,
        contact: contacts,
        subject: subject,
        content: content,
        task: email?.task?.id || '',
        ...(email?.task?.sequence ? { sequence: email?.task?.sequence } : {}),
        ...(email?.task?.seqStep ? { sequenceStep: email?.task?.seqStep } : {}),
        status: 'pending',
        template: template,
        isReplyReminderEnabled: false,
        replyReminder: {
          type: null,
          date: null,
          sequence: null,
        },
      }}
      validationSchema={Yup.object().shape({
        isReplyReminderEnabled: Yup.boolean(),
        replyReminder: Yup.object().when('isReplyReminderEnabled', {
          is: true,
          then: Yup.object().shape({
            type: Yup.string().nullable().required('Please select required actions!'),
            date: Yup.string().nullable().required('Please select required days!'),
            sequence: Yup.object()
              .nullable()
              .test('sequence', 'Please enter a valid sequence.', function (value) {
                const { type } = this.parent;
                if (type === 'Enroll_Sequence') {
                  return Object.keys(value || {})?.length;
                }
                return true;
              }),
          }),
        }),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, ...formProps }) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setErrorMsg({
                type:
                  values?.isReplyReminderEnabled && !values?.replyReminder?.type
                    ? 'Please select required actions!'
                    : '',
                date:
                  values?.isReplyReminderEnabled && !values?.replyReminder?.date
                    ? 'Please select required days!'
                    : '',
              });
              formProps.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
              className={classes.box}
            >
              <Box width="100%" display="flex" flexDirection={'column'}>
                <Box
                  display="flex"
                  border={'1px solid #70707070'}
                  padding={'14px 16px'}
                  marginBottom={'-17px'}
                  sx={{
                    borderBottomWidth: 0,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: '14px',
                  }}
                >
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                    sx={{ color: 'rgba(0,0,0,0.36)' }}
                  >
                    Reply to:{' '}
                    <Typography color="textSecondary" component="span" variant="body2">
                      {contacts?.fname + ' ' + contacts?.lname}
                    </Typography>
                  </Typography>
                </Box>
                <Form.Field.AutoComplete
                  variant="outlined"
                  sx={{
                    marginBottom: '-25px !important',
                    '& .MuiInputBase-root': {
                      borderRadius: 0,
                      borderBottomWidth: 0,
                      padding: '7px 9px',
                      fontSize: '14px',
                    },
                    '& fieldset': {
                      borderBottomWidth: 0,
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      '&:before': {
                        border: 'none !important',
                      },
                      '&:after': {
                        border: '1px solid #70707060',
                      },
                    },
                  }}
                  options={[]}
                  fullWidth
                  clearOnEscape={true}
                  name="template"
                  placeholder={'Apply template'}
                  onChange={(val) => {
                    if (val && val?.id) {
                      formProps.setFieldValue('template', val.id);
                      formProps.setFieldValue('content', val.content);
                      formProps.setFieldValue('subject', val.subject);
                      fetchContent(
                        val.subject,
                        val.content,
                        values.contact.id,
                        values.sender.id,
                        val,
                      );
                    }
                  }}
                  remoteMethod={(val) => {
                    return fetchTemplatesLookup(val, 'email');
                  }}
                  optLabel="name"
                  optValue="id"
                />
                <Form.Field.Input
                  fullWidth
                  sx={{
                    marginBottom: '-5px !important',
                    '& .MuiInputBase-root': {
                      borderRadius: 0,
                      borderBottomWidth: 0,
                      '& input': {
                        padding: '14.5px 14px',
                        fontSize: '14px',
                      },
                    },
                  }}
                  variant="outlined"
                  name="subject"
                  placeholder="Subject"
                  showPicker={true}
                  options={replacementVariables}
                  optValue="value"
                  optLabel="text"
                  emojiPicker={true}
                />

                <Form.Field.TextEditor
                  fullWidth
                  toolbarLocation="bottom"
                  variant="outlined"
                  placeHolder="Your email reply..."
                  name="content"
                />

                <Form.Field.File
                  fullWidth
                  variant="outlined"
                  name="attachments"
                  label="Files"
                  maxSize={5}
                />

                <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                  {values?.replyReminder?.type === 'Enroll_Sequence' && (
                    <Form.Field.AutoComplete
                      multiple={false}
                      fullWidth
                      options={[]}
                      variant="outlined"
                      name="replyReminder.sequence"
                      label="Choose Sequence"
                      optLabel="name"
                      optValue="id"
                      groupBy={(option) => capitalizeFirstLetter(option?.accessType)}
                      getOptionLabel={(option) => option.name || ''}
                      remoteMethod={async (val) => {
                        return await fetchSequenceDetails(val);
                      }}
                    />
                  )}
                </Box>
              </Box>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
                width="100%"
              >
                <Button iconButton loading={loading} disabled={loading} onClick={closeReplyForm}>
                  <DeleteIcon />
                </Button>
                <Stack direction={'row'}>
                  {submitButtonText === 'now' ? (
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      loading={loading}
                      disabled={loading}
                      sx={{
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        borderRight: '1px solid #00000020',
                      }}
                    >
                      Send {capitalizeFirstLetter(type)}
                    </Button>
                  ) : (
                    <ScheduledEmail
                      onClose={() => setScheduledEmailTime('')}
                      onSubmit={(value) => {
                        setScheduledEmailTime(value);
                        formProps.submitForm();
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        loading={loading}
                        disabled={loading}
                        sx={{
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                          borderRight: '1px solid #00000020',
                        }}
                      >
                        Send {capitalizeFirstLetter(type)} later
                      </Button>
                    </ScheduledEmail>
                  )}
                  <Button
                    aria-controls={'menuId'}
                    aria-haspopup="true"
                    variant={'contained'}
                    color={'secondary'}
                    disabled={loading}
                    onClick={handleFocus}
                    sx={{
                      width: 40,
                      minWidth: 'unset',
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                    }}
                  >
                    <ArrowDropDownOutlinedIcon />
                  </Button>
                  <Menu id={'menuId'} anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <ListItemButton
                      onClick={() => {
                        setSubmitButtonText('now');
                        handleClose();
                      }}
                    >
                      Send {capitalizeFirstLetter(type)}
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setSubmitButtonText('later');
                        handleClose();
                      }}
                    >
                      Send {capitalizeFirstLetter(type)} later
                    </ListItemButton>
                  </Menu>
                </Stack>
              </Stack>
            </Box>
          </form>
        );
      }}
    </Form>
  );
};

export default TaskReplyForm;
