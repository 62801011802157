import React from 'react';
import { debounce } from 'lodash';

import Empty from 'src/components/lms/Empty';
import FilterBar from 'src/components/lms/Filterbar';
import Icon from 'src/components/lms/Icon';
import Session from 'src/components/lms/Session';
import Course from 'src/components/lms/Course';
import useRequest from 'src/components/hooks/useRequest';

import { Grid, Pagination, Stack, Typography } from '@mui/material';

import BannerSection from './BannerSection';

import { fetchLibrary, getExpertsLookup, getTagsLookup } from '../../api/LMSApis';
import { useStyles } from './styles';
import { Spacer } from 'src/components/shared';
import SessionListSkeleton from '../SessionListSkeleton';

import backgroundImageForSalesAndRecruitment from 'src/assets/lms/SalesLibraryBG.jpeg';
import backgroundImageForSeacrh from 'src/assets/lms/SearchBg.jpeg';

const LMSLibrary = ({ search = '' }) => {
  const [appliedFilters, setAppliedFilters] = React.useState({
    _from: 0,
    _size: 12,
    categoryType: location?.pathname?.split('/')?.[2],
    ...(search ? { _search: search } : {}),
  });

  const payload = {
    ...appliedFilters,
    ...(appliedFilters?._sort ? { _sort: appliedFilters?._sort?.value } : {}),
    ...(appliedFilters?.tags ? { tags: appliedFilters?.tags?.map(({ id }) => id) } : {}),
    ...(appliedFilters?.experts ? { experts: appliedFilters?.experts?.map(({ id }) => id) } : {}),
    ...(appliedFilters?.contentType
      ? { contentType: appliedFilters?.contentType?.map(({ value }) => value) }
      : {}),
  };

  const [libraryData, { loading: libraryListLoading }] = useRequest(fetchLibrary, payload);

  const clearFilter = (key) => {
    delete appliedFilters[key];
    setAppliedFilters({ ...appliedFilters });
  };

  const classes = useStyles();

  const filtersOne = [
    {
      key: 'contentType',
      label: 'Showing All',
      type: 'dropdown',
      optLabel: 'label',
      optValue: 'value',
      searchable: true,
      multiple: true,
      startIcon: <Icon name="cards" />,
      onClear: () => clearFilter('contentType'),
      options: [
        {
          label: 'Session',
          value: 'session',
        },
        {
          label: 'Course',
          value: 'course',
        },
        {
          label: 'Program',
          value: 'program',
        },
      ],
    },
    {
      key: 'tags',
      label: 'Tags',
      type: 'dropdown',
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      multiple: true,
      customHeader: true,
      startIcon: <Icon name="tags" />,
      onClear: () => clearFilter('tags'),
      remoteMethod: getTagsLookup,
    },
    {
      key: 'experts',
      label: 'Experts',
      type: 'dropdown',
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      multiple: true,
      startIcon: <Icon name="user" />,
      onClear: () => clearFilter('experts'),
      remoteMethod: getExpertsLookup,
    },
  ];

  const filtersTwo = [
    {
      key: '_sort',
      label: 'Sort',
      type: 'dropdown',
      optLabel: 'label',
      optValue: 'value',
      searchable: true,
      multiple: false,
      startIcon: <Icon name="sort" />,
      onClear: () => clearFilter('_sort'),
      options: [
        {
          label: 'Title',
          value: 'title',
        },
        {
          label: 'Created Date',
          value: 'createdAt',
        },
      ],
    },
  ];

  return (
    <Stack className={classes.SalesLibraryContainer}>
      <BannerSection
        title={appliedFilters?.categoryType !== 'search' && 'Elevate your Sales Skills'}
        subTitle={
          appliedFilters?.categoryType !== 'search' &&
          'with industry experts and world-class techniques'
        }
        backgroundImage={
          appliedFilters?.categoryType !== 'search'
            ? backgroundImageForSalesAndRecruitment
            : backgroundImageForSeacrh
        }
        helperText={
          appliedFilters?.categoryType === 'search' && (
            <div style={{ display: 'flex', gap: '8px' }}>
              Showing <Typography color={'secondary'}>{libraryData?.total?.value ?? 0}</Typography>{' '}
              search results for{' '}
              <Typography color={'secondary'}>{appliedFilters._search}</Typography>
            </div>
          )
        }
        initialSearchText={search}
        onSearchChange={(search) =>
          setAppliedFilters((prevState) => {
            return {
              ...prevState,
              _search: search,
            };
          })
        }
        searchLoading={false}
      />

      <Stack className={classes.SalesLibraryContent}>
        <Stack className={'filter-conatiner'}>
          <FilterBar
            fullView
            filters={filtersOne}
            value={appliedFilters}
            onChange={(key, value) =>
              setAppliedFilters((prevState) => {
                return {
                  ...prevState,
                  [key]: value,
                };
              })
            }
          />

          <FilterBar
            fullView
            filters={filtersTwo}
            value={appliedFilters}
            onChange={(key, value) =>
              setAppliedFilters((prevState) => {
                return {
                  ...prevState,
                  [key]: value,
                };
              })
            }
          />
        </Stack>

        {libraryListLoading ? (
          <SessionListSkeleton />
        ) : libraryData?.library?.length ? (
          <React.Fragment>
            <Grid container display={'flex'} spacing={2}>
              {libraryData?.library?.map((item, index) => {
                item.id = item?.id || item?._id;
                if (item?.contentType === 'course')
                  return (
                    <Grid key={item?.id} item xs={12} md={6} lg={3}>
                      <Course key={index} course={item} />
                    </Grid>
                  );
                else if (item?.contentType === 'session')
                  return (
                    <Grid key={item?.id} item xs={12} md={6} lg={3}>
                      <Session key={index} session={item} />
                    </Grid>
                  );
                else return <React.Fragment key={index} />;
              })}
            </Grid>
            <Stack alignItems="center" justifyContent="center">
              <Pagination
                count={Math.ceil(libraryData?.total?.value / (appliedFilters?._size ?? 1))}
                page={appliedFilters._from + 1}
                defaultPage={1}
                perPage={appliedFilters._size}
                onChange={(_, page) =>
                  setAppliedFilters((prevState) => {
                    return {
                      ...prevState,
                      _from: page - 1,
                    };
                  })
                }
              />
            </Stack>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Spacer y={4} />
            <Empty
              title="Nothing to see here."
              desc="Check out some of our Courses and Sessions to get started!"
            />
          </React.Fragment>
        )}
      </Stack>
    </Stack>
  );
};;;;;

export default LMSLibrary;
