import React, { useEffect } from 'react';
import LMSHome from '../components/homeLegacy';
import { useHomeProvider, withHomePageStore } from 'src/modules/lms/utils/homeStore';

const LMSHomeContainer = (props) => {
  const { filters, fetchComp1, fetchComp2, fetchComp3 } = useHomeProvider();

  useEffect(() => {
    fetchComp1(filters);
    fetchComp2(filters);
    fetchComp3(filters);
  }, [filters]);

  return (
    <>
      <LMSHome {...props} />
    </>
  );
};

export default withHomePageStore(LMSHomeContainer);
