import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import images from 'src/config/images';
import Stack from '@mui/material/Stack';

import Item from './Item';
import { getMenuItems } from 'src/config/learning/menu';
import { drawerWidth, drawerWidthCollapsed } from 'src/config/learning';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import learningImages from 'src/config/learning/images';
import CloseIcon from '@mui/icons-material/Close';
import pckg from '../../../../package.json';
import moment from 'moment';
import Icon from '../Icon';
import { useHistory } from 'react-router-dom';

import { Logo, CollapsedLogo, ToggleButton, CollapsedContainer, MenuItem } from './styles';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import UseMenu from '../Header/UseMenu';
import { ReactComponent as Lock } from 'src/assets/lms/icons/lock.svg';
import { useSelector } from 'react-redux';
import usePermission from 'src/components/hooks/usePermission';
// import { useSelector } from 'react-redux';

import { useIntercom } from 'react-use-intercom';

function LMSSidebar({
  window,
  mobileOpen,
  handleDrawerClose,
  handleDrawerTransitionEnd,
  collapsed,
  toggleCollapsed,
  ...props
}) {
  const { boot, show } = useIntercom();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [role] = usePermission();
  const container = window !== undefined ? () => window().document.body : undefined;

  const menuItems = getMenuItems(role);

  const drawer = (
    <div>
      {collapsed ? (
        <CollapsedContainer>
          <CollapsedLogo src={learningImages.logoIcon} className="logo" />
          <ToggleButton onClick={toggleCollapsed} className="collapseButton">
            <ChevronRightIcon />
          </ToggleButton>
        </CollapsedContainer>
      ) : (
        <Toolbar>
          <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <IconButton size="small" onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Link to={'/lms'}>
              {collapsed ? (
                <CollapsedLogo src={learningImages.logoIcon} />
              ) : (
                <Logo src={images.app.logoWhite} />
              )}
            </Link>
            {!mobileOpen && !collapsed && (
              <ToggleButton onClick={toggleCollapsed}>
                <ChevronLeftIcon />
              </ToggleButton>
            )}
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '34px',
              }}
            >
              <UseMenu isHideSearch />
            </Box>
          </Stack>
        </Toolbar>
      )}

      <Divider />
      <Stack height="calc(100vh - 66px)" direction="column" justifyContent="space-between">
        <List>
          {menuItems.map((menuItem, index) => (
            <Item
              key={`menu-parent-${index}`}
              menuItem={menuItem}
              collapsed={collapsed}
              handleDrawerClose={handleDrawerClose}
              isMobileSidebar
            />
          ))}
        </List>

        <List>
          <Divider />

          <MenuItem>
            <ListItemButton onClick={() => history.push('/tasks')}>
              <ListItemIcon>
                <Icon name="outreach" />
              </ListItemIcon>
              {!collapsed && (
                <>
                  <ListItemText primary="Outreach" />
                  {!props.hasOutreachAccess && <Icon name="lock" />}
                </>
              )}
            </ListItemButton>
          </MenuItem>

          <MenuItem>
            <ListItemButton
              onClick={async () => {
                try {
                  await boot();
                  await show();
                } catch (error) {}
              }}
            >
              <ListItemIcon>
                <Icon name="help" />
              </ListItemIcon>
              {!collapsed && <ListItemText primary="Help Center" />}
            </ListItemButton>
          </MenuItem>

          {!collapsed && (
            <MenuItem dense sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText
                secondary={`v.${pckg.version} © SuperReach ${moment().format(
                  'YYYY',
                )}. All rights reserved.`}
                sx={{ textAlign: 'left', padding: '0px 16px' }}
              />
            </MenuItem>
          )}
        </List>
      </Stack>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: collapsed ? drawerWidthCollapsed : drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '100%',
            backgroundImage: 'none',
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: collapsed ? drawerWidthCollapsed : drawerWidth,
            overflow: 'hidden',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default LMSSidebar;
