import React from 'react';
import styled from 'styled-components';
import VideoPlayer from 'src/components/shared/VideoPlayer';
import Stack from '@mui/material/Stack';
import ProgressBar from '../ProgressBar';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';

function CompetitionThumbnail({ data }) {
  const [viewVideoModal, setViewVideoModal] = React.useState(false);
  const onComplete = () => null;

  return (
    <Stack>
      <VideoThumbContainer>
        <VideoThumb>
          {data?.session?.thumbnailImage ? (
            <img className="thumbnail" src={data.session.thumbnailImage} />
          ) : (
            <>
              <SessionPlaceholder style={{ width: '64px', color: '#808080' }} />
            </>
          )}
        </VideoThumb>
        <ProgressWrap>
          <ProgressBar total={data?.roleplays || 0} completed={data?.roleplaysCompleted || 0} />
        </ProgressWrap>
      </VideoThumbContainer>
      <VideoPlayer
        view={viewVideoModal}
        video={data?.session?.videoUrl ?? ''}
        onClose={() => setViewVideoModal(false)}
        onComplete={onComplete}
      />
    </Stack>
  );
}

export default CompetitionThumbnail;

const ProgressWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

const VideoThumbContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 12px;
  aspect-ratio: 256/144;
  height: auto;
  width: 100%;
  .refreshButton {
    display: none;
    width: 44px;
    height: 44px;
    background-color: #ffffff;
    border-radius: 44px;
    z-index: 999;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      fill: #000000;
    }
  }
  & .MuiLinearProgress-root {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & .playIcon {
    display: none;
    position: absolute;
    top: 0;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 10;
    width: 48px;
    height: 48px;
    z-index: 999;
    color: #ffffff;
  }
  & .backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 23, 33, 60);
    border-radius: 4px;
    z-index: 10;
    opacity: 0.4;
    border-radius: 0;
    /* z-index: 9; */
  }
  &:hover .backdrop,
  &:hover .playIcon {
    display: block;
  }
  &:hover .refreshButton {
    display: flex;
  }
  & .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const VideoThumb = styled.div`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #193247;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    border-radius: 8px;
  }
  & .playPlaceholder {
    border-radius: 50%;
    z-index: 10;
  }
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: var(--Dark-Gradient, linear-gradient(180deg, rgba(9, 13, 17, 0) 0%, #090d11 100%));
`;
