import { Box, Card, CardContent, Grid, Link, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';

import { Drawer, Form } from 'src/components/shared';
import images from 'src/config/images';
import { capitalizeFirstLetter } from 'src/utils/helper';

import { useDataProviders } from '../../utils/dataProviderStore';
import { ProviderCounter, ProviderLogo } from './styles';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Accordion } from 'src/components/App';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Children } from 'react';
import { findIndex, map, size } from 'lodash';
import { tenantIntegration } from '../../config/integrationData';
import TeamForm from '../Integrations/IntegrationForm';
import { useSelector } from 'react-redux';

const ProviderCard = ({
  item,
  index,
  handleChange,
  disabled = false,
  changeContract,
  filteredIntegrations,
  disableContractChange = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });
  const integration =
    filteredIntegrations?.find(({ name }) => name?.toLowerCase() === item?.name?.toLowerCase()) ||
    {};
  const onDrawerClose = () => {
    setDrawerOpen({ edit: null, open: false });
  };
  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      sx={{
        '& .MuiCardContent-root': {
          padding: '16px',
        },
        '&:hover': {
          '& .number-box': { background: '#4FC3F7' },
          '& .card-box': { borderColor: '#1976D2' },
          '& .drag-box': { visibility: 'visible !important', cursor: 'pointer' },
        },
      }}
      {...props}
    >
      {!disabled && <ProviderCounter className="number-box">{index + 1}</ProviderCounter>}

      <Card
        elevation={0}
        className="card-box"
        sx={{ border: '1px solid rgba(0,0,0,0.12)', flex: 1 }}
      >
        <CardContent>
          <Stack direction="row" alignItems="center" gap={2.5}>
            <ProviderLogo src={images.integrations[item.name]} />

            <Box flex={1}>
              <Typography fontWeight={500}>
                {item.label || capitalizeFirstLetter(item.name)}
              </Typography>
              <Stack direction="row" gap={0.7} flex={1} alignItems={'center'}>
                <Typography color="textSecondary" fontSize="12px">
                  {item?.contract?.toLowerCase() === 'superreach' || !item?.contract
                    ? 'SuperReach'
                    : capitalizeFirstLetter(item?.contract)}{' '}
                  contract
                </Typography>
                {item?.charge && item.charge !== '' && (
                  <>
                    <Typography color="textSecondary" fontSize="12px">
                      |
                    </Typography>
                    <Typography color="textSecondary" fontSize="12px">
                      {item.charge} cents
                    </Typography>
                  </>
                )}

                {item.contract !== 'customer' && item?.price && (
                  <Typography color="textSecondary" fontSize="12px">
                    | {item.price} cents
                  </Typography>
                )}

                {!disableContractChange && (
                  <Fragment>
                    <Typography color="textSecondary" fontSize="12px">
                      |
                    </Typography>
                    <Link
                      underline="hover"
                      color="secondary.main"
                      fontSize={12}
                      component="button"
                      onClick={() => changeContract(item)}
                    >
                      Change contract
                    </Link>
                  </Fragment>
                )}
              </Stack>
            </Box>

            <Stack direction="row" gap={1}>
              <Typography component="span" color="textSecondary" variant="body2">
                Spent{' '}
                <Typography component="span" color="secondary" variant="body2">
                  ${item?.spend || 0}
                </Typography>
              </Typography>
              <Typography component="span" color="secondary" variant="body2">
                |
              </Typography>
              <Typography component="span" color="textSecondary" variant="body2">
                Not Found{' '}
                <Typography component="span" color="secondary" variant="body2">
                  {item?.null || 0}
                </Typography>
              </Typography>
              <Typography component="span" color="secondary" variant="body2">
                |
              </Typography>
              <Typography component="span" color="textSecondary" variant="body2">
                Found{' '}
                <Typography component="span" color="secondary" variant="body2">
                  {item?.found || 0}
                </Typography>
              </Typography>
            </Stack>

            <Form
              initialValues={{
                status: item?.status === 'active' ? true : false,
              }}
              enableReinitialize={true}
            >
              <Tooltip arrow placement="top" title="Change status">
                <Box sx={{ '& div, label, span': { margin: '0 !important' } }}>
                  <Form.Field.Switch
                    name="status"
                    value={disabled ? false : item.status}
                    disabled={disabled && loading}
                    onChange={async (val) => {
                      const isCustomerContract = item.contract === 'customer';
                      const isIntegrationInactive = integration.status !== 'active';
                      const hasNoApiKey = !integration?.integration?.apikey;
                      if (
                        disabled &&
                        isCustomerContract &&
                        (hasNoApiKey || isIntegrationInactive)
                      ) {
                        return setDrawerOpen({
                          edit: integration,
                          open: true,
                        });
                      }
                      setLoading(true);
                      await handleChange({
                        ...item,
                        status: val ? 'active' : 'inactive',
                      });
                      setLoading(false);
                    }}
                  />
                </Box>
              </Tooltip>
            </Form>
          </Stack>
          <Drawer
            title={`${drawerOpen.edit?.name} Setting`}
            open={drawerOpen.open}
            onClose={onDrawerClose}
          >
            <TeamForm
              editUser={drawerOpen.edit}
              onComplete={async () => {
                setDrawerOpen({ edit: null, open: false });
                setLoading(true);
                await handleChange({
                  ...item,
                  status: 'active',
                });
                setLoading(false);
              }}
              tenantIntegration={tenantIntegration}
              {...props}
              onClose={onDrawerClose}
            />
          </Drawer>
        </CardContent>
      </Card>
      {!disabled && <DragIndicatorIcon className="drag-box" style={{ visibility: 'hidden' }} />}
    </Stack>
  );
};

function DataProviders({
  tab,
  changeContract,
  selected,
  props,
  // updateSettings,
  // tenantSettings,
}) {
  const {
    waterfall,
    updateWaterfallService,
    selectedWaterfall,
    setSelectedWaterfall,
    updateWaterFall,
  } = useDataProviders();
  const defaultWaterfall = useSelector((state) => state.app.globals.defaultWaterfall);
  const [loading, setLoading] = useState(false);

  const handleChange = async (item) => {
    setLoading(true);
    const payload = {
      type: tab?.id,
      service: item.name,
      status: item.status,
    };
    await updateWaterfallService(selectedWaterfall?.id, payload);
    setLoading(false);
  };

  const data = useMemo(() => {
    return selectedWaterfall[tab?.id]
      ? selectedWaterfall[tab?.id].sort((a, b) => a.order - b.order)
      : [];
  }, [selectedWaterfall, tab]);

  const activeData = useMemo(() => {
    return selectedWaterfall[tab?.id]
      ? selectedWaterfall?.[tab?.id]
          .sort((a, b) => a.order - b.order)
          .filter(({ status }) => status === 'active')
      : [];
  }, [selectedWaterfall, tab]);

  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }
    let newItems = Array.from(activeData);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    const sortedOrder = map(newItems, 'order').sort();

    for (let i = 0; i < size(sortedOrder); i++) {
      newItems[i].order = sortedOrder[i];
    }

    let mainArr = Array.from(data);

    map(newItems, (item) => {
      mainArr[findIndex(mainArr, { name: item.name })].order = item.order;
    });

    setSelectedWaterfall({ ...selectedWaterfall, [tab?.id]: mainArr });

    updateWaterFall(selectedWaterfall.id, { [tab?.id]: mainArr }, false);
  }

  const integrations = props?.integrations?.data?.reduce((acc, obj) => {
    acc[obj?.name] = obj;
    return acc;
  }, {});

  const filteredIntegrations = tenantIntegration.map((obj) => {
    if (Object.keys(integrations).includes(obj?.name)) return integrations[obj?.name];
    else return obj;
  });

  return (
    <Stack gap={3}>
      {/* <Stack direction="row" justifyContent="space-between">
        <Typography color="textSecondary">{tab?.name || ''}</Typography>
        <Button
          size="small"
          color="secondary"
          endIcon={<OpenInNewIcon />}
          onClick={() => {
            history.push('/admin/integrations');
          }}
        >
          Add my own provider
        </Button>
      </Stack> */}
      {/* <Typography variant="body2" color="textSecondary">
        The order of the waterfall is shown in the list below. The order is subject to change as we
        optimise performance against your requests.
      </Typography> */}
      <Box width={'100%'} mt={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => {
              return (
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Children.toArray(
                    map(activeData, (item, index) => {
                      const disableContractChange =
                        item.contract === 'customer' &&
                        !defaultWaterfall?.[tab?.id]?.find(
                          (service) => service?.name === item?.name,
                        )?.name;
                      return (
                        <Draggable
                          // key={`provider-item-${index}`}
                          id={`provider-item-${index}`}
                          draggableId={`provider-item-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ProviderCard
                                  {...{
                                    item,
                                    index,
                                    handleChange,
                                    changeContract,
                                    filteredIntegrations,
                                    disableContractChange,
                                  }}
                                />
                              </Grid>
                            );
                          }}
                        </Draggable>
                      );
                    }),
                  )}
                  {provided.placeholder}
                </Grid>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Box>
      <Accordion
        title={
          <Typography fontWeight={500} fontSize={16} color={'#00000099 !important'}>
            Disabled data providers
          </Typography>
        }
        subTitle={
          <Typography fontSize={14} color="#00000099">
            Disabled providers will not be called in the waterfall. If you want to re-enable a
            provider, switch the toggle back to active.
          </Typography>
        }
        sx={{
          '&.MuiAccordion-root': {
            background: 'transparent',
            borderBottom: 'none !important',
          },
          '& .MuiAccordionSummary-root': {
            borderBottom: 'none !important',

            '& .accordion-title': {
              color: '#000000 !important',
              fontSize: '18px',
              fontWeight: 500,
            },
          },
        }}
      >
        {data
          ?.filter(({ status }) => status !== 'active')
          .map((item, index) => {
            const disableContractChange =
              item.contract === 'customer' &&
              !defaultWaterfall?.[tab?.id]?.find((service) => service?.name === item?.name)?.name;
            return (
              <ProviderCard
                {...{
                  ...props,
                  item,
                  index,
                  handleChange,
                  changeContract,
                  key: `provider-item-${index}`,
                  disabled: true,
                  selected,
                  loading: loading,
                  filteredIntegrations,
                  disableContractChange,
                }}
              />
            );
          })}
      </Accordion>
    </Stack>
  );
}

export default DataProviders;
