import React, { useState } from 'react';
import { Container, Box, Typography } from '@mui/material';

import { Tabs } from 'src/components/App';
import { dashboardTabs1 } from '../../config';
import { FixedTitleBar } from './styles';
import { withDashboardProvider } from '../../utils/dashboardStore';
import Sequences from '../../components/dashboard/Sequences';

function DashboardView() {
  const [currentTab, setCurrentTab] = useState('sequences');

  const onTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  return (
    <>
      {/*<FixedTitleBar>*/}
      {/*  <Tabs*/}
      {/*    tabs={dashboardTabs1}*/}
      {/*    onChange={onTabChange}*/}
      {/*    value={currentTab}*/}
      {/*    style={{ marginLeft: '16px' }}*/}
      {/*  />*/}
      {/*</FixedTitleBar>*/}
      <Container>
        {/*<Box mt={8}>*/}
          <Typography
            sx={{
              color: '#81868B',
              fontSize: '14px',
              fontWeight: 500,
              mt: '12px',
            }}
          >
            This dashboard, all the numbers are linked to the number enrolled.
          </Typography>
          <Sequences />
        {/*</Box>*/}
      </Container>
    </>
  );
}

export default withDashboardProvider(DashboardView);
