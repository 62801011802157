import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Stack, Typography, Button, ButtonBase } from '@mui/material';
import images from 'src/config/images';
import { Modal } from 'src/components/shared';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import toast from '../../../../utils/toast';
import { useHistory, useLocation } from 'react-router';

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_ID,
  isItemsModel: true,
});

function PaymentMethods({ cards, managePayments, updatePaymentMethod, primaryCard }) {
  // const [chargeBeeUrl, setChargeBeeUrl] = useState();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.search === '?add-card=true') {
      onManagePayments();
    }
  }, []);

  const CardItem = ({ card }) => {
    let maskedNumber = card?.masked_number || '';
    if (maskedNumber && maskedNumber !== '') {
      maskedNumber = maskedNumber
        .replace(/.(?=.{4})/g, '*')
        .match(/.{1,4}/g)
        .join(' ');
    } else {
      return null;
    }
    const isPrimary = primaryCard?.payment_source_id === card.id;

    return (
      <Stack
        gap={2}
        flexDirection={'row'}
        alignItems={'center'}
        width={'fit-content'}
        sx={{ '&:hover': { '& .action': { visibility: 'visible' } } }}
      >
        <Card
          variant="outlined"
          sx={{
            maxWidth: 400,
            height: '54px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="row"
            p={1.6}
            gap={5}
            alignItems="center"
            width={'100%'}
            justifyContent={'space-between'}
          >
            <img src={images.billing[card.brand]} style={{ maxWidth: '40px' }} />
            {maskedNumber}
            <Stack
              alignItems="center"
              sx={{
                padding: '4px 12px',
                background: 'black',
                marginLeft: '28px',
                color: 'white',
                borderRadius: '4px',
                fontSize: '14px',
                visibility: isPrimary ? 'visible' : 'hidden',
              }}
            >
              Primary
            </Stack>
          </Stack>
        </Card>
        {/* <ButtonBase
          onClick={onManagePayments}
          className="action"
          sx={{ visibility: 'hidden', color: '#0000008A' }}
        >
          <EditIcon />
        </ButtonBase> */}
      </Stack>
    );
  };

  const onManagePayments = async () => {
    try {
      cbInstance.openCheckout({
        hostedPage: function () {
          return new Promise(function (resolve, reject) {
            managePayments()
              .then((res) => {
                resolve({ url: res?.hosted_page?.url });
              })
              .catch((err) => {
                toast.error(err?.error?.message || 'An error ocurred! Please try again.');
              });
          });
        },
        close: function () {
          history.replace('/admin/billing/details');
          updatePaymentMethod?.();
          // window.location.reload();
        },
      });
    } catch (error) {
      toast.error(error?.message || 'An error ocurred! Please try again.');
    }
  };

  return (
    <Box
      sx={{
        '& .MuiCard-root': {
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        },
        '& .MuiCardContent-root': {
          padding: '20px !important',
        },
      }}
    >
      <Card>
        <CardContent sx={{ display: 'flex' , flexDirection: 'column', gap:"30px" ,alignItems: 'baseline'}}>
          <Stack justifyContent={'space-between'} direction="row" width={'100%'}>
            <Typography color="textPrimary" sx={{ fontWeight: 500, fontSize: '18px' }}>
              Payment Method
            </Typography>
            <Button color="secondary" sx={{ textTransform: 'none' }} onClick={onManagePayments}>
              Manage cards
            </Button>
          </Stack>

          <Stack gap={2.5}>
            {cards?.map((card) => (
              <CardItem card={card} />
            ))}
          </Stack>

          <Button
            sx={{ marginTop: 4, textTransform: 'none' }}
            onClick={onManagePayments}
            color="secondary"
          >
            <AddIcon />
            Add a new card
          </Button>
        </CardContent>
        {/*{chargeBeeUrl && (*/}
        {/*  <Modal*/}
        {/*    open={true}*/}
        {/*    onClose={() => {*/}
        {/*      setChargeBeeUrl();*/}
        {/*      updatePaymentMethod();*/}
        {/*    }}*/}
        {/*    sx={{*/}
        {/*      '.MuiDialog-paper': {*/}
        {/*        width: '500px',*/}
        {/*        '.MuiDialogTitle-root': { position: 'absolute', width: 'fit-content', right: 0 },*/}
        {/*        '.MuiDialogActions-spacing': { display: 'none' },*/}
        {/*        '.MuiDialogContent-root': {*/}
        {/*          padding: '0',*/}
        {/*          iframe: {*/}
        {/*            width: 'calc(100% - 6px)',*/}
        {/*            minHeight: 480,*/}
        {/*          },*/}
        {/*        },*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <iframe src={chargeBeeUrl} />*/}
        {/*  </Modal>*/}
        {/*)}*/}
      </Card>
    </Box>
  );
}

export default PaymentMethods;
