import React from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Session from 'src/components/lms/Session';
import { Grid } from '@mui/material';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';

function HomeSessions({ sessions, title }) {
  if (!sessions.length) return null;

  return (
    <LMSCard
      title={title}
      icon={<SessionPlaceholder style={{ width: 24, height: 24, color: '#ffffff' }} />}
    >
      <Grid container spacing={2}>
        {sessions.map((session, index) => {
          return (
            <Grid item lg={3} md={4} xs={6} key={`sales-home-session-${index}`}>
              <Session session={session} />
            </Grid>
          );
        })}
      </Grid>
    </LMSCard>
  );
}

export default HomeSessions;
