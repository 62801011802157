export const contactBulkActions = [
  {
    title: 'Add to sequence',
    key: 'addToSequence',
  },
  {
    title: 'Add to crm',
    key: 'addtocrm',
  },
  // {
  //   title: 'Assign user',
  //   key: 'assignUser',
  // },
  {
    title: 'Remove from sequence',
    key: 'removeFromSequence',
  },
  {
    title: 'Remove from draft',
    key: 'removeFromDraft',
  },
  {
    title: 'Delete',
    key: 'delete',
  },
];

export const contactStatusList = [
  { label: 'Draft', value: 'draft' },
  { label: 'Enrolled', value: 'enrolled' },
  { label: 'Completed', value: 'completed' },
  { label: 'Completed Interested', value: 'interested' },
  { label: 'Completed not Interested', value: 'notInterested' },
  { label: 'Unsubscribed', value: 'unsubscribed' },
  { label: 'Error', value: 'error' },
];

export const contactsTabs = [
  {
    id: 'allContacts',
    name: 'All contacts',
  },
];

export const noteType = [
  {
    // id: 1,
    id: 'Note',
    name: 'Note',
  },
  {
    // id: 2,
    id: 'Email',
    name: 'Email',
  },
  {
    // id: 3,
    id: 'Call',
    name: 'Call',
  },
  {
    // id: 4,
    id: 'Meeting',
    name: 'Meeting',
  },
  {
    id: 'Prescreen-Bullhorn',
    name: 'Prescreen-Bullhorn',
  },
  {
    id: 'BD Call-Bullhorn',
    name: 'BD Call-Bullhorn',
  },
  {
    id: 'Outbound Call-Bullhorn',
    name: 'Outbound Call-Bullhorn',
  },
  {
    id: 'Inbound Call-Bullhorn',
    name: 'Inbound Call-Bullhorn',
  },
  {
    // id: 4,
    id: 'Client Visit-Bullhorn',
    name: 'Client Visit-Bullhorn',
  },
  {
    // id: 4,
    id: 'CV Sent-Bullhorn',
    name: 'CV Sent-Bullhorn',
  },
  {
    // id: 4,
    id: 'Reference Check-Bullhorn',
    name: 'Reference Check-Bullhorn',
  },
  {
    // id: 4,
    id: 'Left Message-Bullhorn',
    name: 'Left Message-Bullhorn',
  },
  {
    // id: 4,
    id: 'Email-Bullhorn',
    name: 'Email-Bullhorn',
  },
  {
    // id: 3,
    id: 'Appointment-Bullhorn',
    name: 'Appointment-Bullhorn',
  },
  {
    // id: 3,
    id: 'Other-Bullhorn',
    name: 'Other-Bullhorn',
  },
];

export const sequenceEnrollmentStatus = {
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  HOLD: 'hold',
  FAILED: 'failed',
  BOUNCED: 'bounced',
  INTERESTED: 'interested',
  NOT_INTERESTED: 'notInterested',
  UNSUBSCRIBED: 'unsubscribed',
  NEVER: 'never',
  ERROR: 'error',
  REPLIED: 'replied',
  PAUSED: 'paused',
  DRAFT: 'draft',
  NEVER_SEQUENCED: 'Never Sequenced',
};

export const countryCodes = {
  1: {
    countryNameCode: [
      'US',
      'AG',
      'AI',
      'AS',
      'BB',
      'BM',
      'BS',
      'CA',
      'DM',
      'DO',
      'GD',
      'GU',
      'JM',
      'KN',
      'KY',
      'LC',
      'MP',
      'MS',
      'PR',
      'SX',
      'TC',
      'TT',
      'VC',
      'VG',
      'VI',
    ],
    mask: '(***) ***-****',
  },
  7: { countryNameCode: ['RU', 'KZ'] },
  20: { countryNameCode: ['EG'] },
  27: { countryNameCode: ['ZA'] },
  30: { countryNameCode: ['GR'] },
  31: { countryNameCode: ['NL'] },
  32: { countryNameCode: ['BE'] },
  33: { countryNameCode: ['FR'] },
  34: { countryNameCode: ['ES'] },
  36: { countryNameCode: ['HU'] },
  39: { countryNameCode: ['IT', 'VA'] },
  40: { countryNameCode: ['RO'] },
  41: { countryNameCode: ['CH'] },
  43: { countryNameCode: ['AT'] },
  44: { countryNameCode: ['GB', 'GG', 'IM', 'JE'] },
  45: { countryNameCode: ['DK'] },
  46: { countryNameCode: ['SE'] },
  47: { countryNameCode: ['NO', 'SJ'] },
  48: { countryNameCode: ['PL'] },
  49: { countryNameCode: ['DE'] },
  51: { countryNameCode: ['PE'] },
  52: { countryNameCode: ['MX'] },
  53: { countryNameCode: ['CU'] },
  54: { countryNameCode: ['AR'] },
  55: { countryNameCode: ['BR'] },
  56: { countryNameCode: ['CL'] },
  57: { countryNameCode: ['CO'] },
  58: { countryNameCode: ['VE'] },
  60: { countryNameCode: ['MY'] },
  61: { countryNameCode: ['AU', 'CC', 'CX'] },
  62: { countryNameCode: ['ID'] },
  63: { countryNameCode: ['PH'] },
  64: { countryNameCode: ['NZ'] },
  65: { countryNameCode: ['SG'] },
  66: { countryNameCode: ['TH'] },
  81: { countryNameCode: ['JP'] },
  82: { countryNameCode: ['KR'] },
  84: { countryNameCode: ['VN'] },
  86: { countryNameCode: ['CN'] },
  90: { countryNameCode: ['TR'] },
  91: { countryNameCode: ['IN'], mask: '*****-*****' },
  92: { countryNameCode: ['PK'] },
  93: { countryNameCode: ['AF'] },
  94: { countryNameCode: ['LK'] },
  95: { countryNameCode: ['MM'] },
  98: { countryNameCode: ['IR'] },
  211: { countryNameCode: ['SS'] },
  212: { countryNameCode: ['MA', 'EH'] },
  213: { countryNameCode: ['DZ'] },
  216: { countryNameCode: ['TN'] },
  218: { countryNameCode: ['LY'] },
  220: { countryNameCode: ['GM'] },
  221: { countryNameCode: ['SN'] },
  222: { countryNameCode: ['MR'] },
  223: { countryNameCode: ['ML'] },
  224: { countryNameCode: ['GN'] },
  225: { countryNameCode: ['CI'] },
  226: { countryNameCode: ['BF'] },
  227: { countryNameCode: ['NE'] },
  228: { countryNameCode: ['TG'] },
  229: { countryNameCode: ['BJ'] },
  230: { countryNameCode: ['MU'] },
  231: { countryNameCode: ['LR'] },
  232: { countryNameCode: ['SL'] },
  233: { countryNameCode: ['GH'] },
  234: { countryNameCode: ['NG'] },
  235: { countryNameCode: ['TD'] },
  236: { countryNameCode: ['CF'] },
  237: { countryNameCode: ['CM'] },
  238: { countryNameCode: ['CV'] },
  239: { countryNameCode: ['ST'] },
  240: { countryNameCode: ['GQ'] },
  241: { countryNameCode: ['GA'] },
  242: { countryNameCode: ['CG'] },
  243: { countryNameCode: ['CD'] },
  244: { countryNameCode: ['AO'] },
  245: { countryNameCode: ['GW'] },
  246: { countryNameCode: ['IO'] },
  247: { countryNameCode: ['AC'] },
  248: { countryNameCode: ['SC'] },
  249: { countryNameCode: ['SD'] },
  250: { countryNameCode: ['RW'] },
  251: { countryNameCode: ['ET'] },
  252: { countryNameCode: ['SO'] },
  253: { countryNameCode: ['DJ'] },
  254: { countryNameCode: ['KE'] },
  255: { countryNameCode: ['TZ'] },
  256: { countryNameCode: ['UG'] },
  257: { countryNameCode: ['BI'] },
  258: { countryNameCode: ['MZ'] },
  260: { countryNameCode: ['ZM'] },
  261: { countryNameCode: ['MG'] },
  262: { countryNameCode: ['RE', 'YT'] },
  263: { countryNameCode: ['ZW'] },
  264: { countryNameCode: ['NA'] },
  265: { countryNameCode: ['MW'] },
  266: { countryNameCode: ['LS'] },
  267: { countryNameCode: ['BW'] },
  268: { countryNameCode: ['SZ'] },
  269: { countryNameCode: ['KM'] },
  290: { countryNameCode: ['SH', 'TA'] },
  291: { countryNameCode: ['ER'] },
  297: { countryNameCode: ['AW'] },
  298: { countryNameCode: ['FO'] },
  299: { countryNameCode: ['GL'] },
  350: { countryNameCode: ['GI'] },
  351: { countryNameCode: ['PT'] },
  352: { countryNameCode: ['LU'] },
  353: { countryNameCode: ['IE'] },
  354: { countryNameCode: ['IS'] },
  355: { countryNameCode: ['AL'] },
  356: { countryNameCode: ['MT'] },
  357: { countryNameCode: ['CY'] },
  358: { countryNameCode: ['FI', 'AX'] },
  359: { countryNameCode: ['BG'] },
  370: { countryNameCode: ['LT'] },
  371: { countryNameCode: ['LV'] },
  372: { countryNameCode: ['EE'] },
  373: { countryNameCode: ['MD'] },
  374: { countryNameCode: ['AM'] },
  375: { countryNameCode: ['BY'] },
  376: { countryNameCode: ['AD'] },
  377: { countryNameCode: ['MC'] },
  378: { countryNameCode: ['SM'] },
  380: { countryNameCode: ['UA'] },
  381: { countryNameCode: ['RS'] },
  382: { countryNameCode: ['ME'] },
  383: { countryNameCode: ['XK'] },
  385: { countryNameCode: ['HR'] },
  386: { countryNameCode: ['SI'] },
  387: { countryNameCode: ['BA'] },
  389: { countryNameCode: ['MK'] },
  420: { countryNameCode: ['CZ'] },
  421: { countryNameCode: ['SK'] },
  423: { countryNameCode: ['LI'] },
  500: { countryNameCode: ['FK'] },
  501: { countryNameCode: ['BZ'] },
  502: { countryNameCode: ['GT'] },
  503: { countryNameCode: ['SV'] },
  504: { countryNameCode: ['HN'] },
  505: { countryNameCode: ['NI'] },
  506: { countryNameCode: ['CR'] },
  507: { countryNameCode: ['PA'] },
  508: { countryNameCode: ['PM'] },
  509: { countryNameCode: ['HT'] },
  590: { countryNameCode: ['GP', 'BL', 'MF'] },
  591: { countryNameCode: ['BO'] },
  592: { countryNameCode: ['GY'] },
  593: { countryNameCode: ['EC'] },
  594: { countryNameCode: ['GF'] },
  595: { countryNameCode: ['PY'] },
  596: { countryNameCode: ['MQ'] },
  597: { countryNameCode: ['SR'] },
  598: { countryNameCode: ['UY'] },
  599: { countryNameCode: ['CW', 'BQ'] },
  670: { countryNameCode: ['TL'] },
  672: { countryNameCode: ['NF'] },
  673: { countryNameCode: ['BN'] },
  674: { countryNameCode: ['NR'] },
  675: { countryNameCode: ['PG'] },
  676: { countryNameCode: ['TO'] },
  677: { countryNameCode: ['SB'] },
  678: { countryNameCode: ['VU'] },
  679: { countryNameCode: ['FJ'] },
  680: { countryNameCode: ['PW'] },
  681: { countryNameCode: ['WF'] },
  682: { countryNameCode: ['CK'] },
  683: { countryNameCode: ['NU'] },
  685: { countryNameCode: ['WS'] },
  686: { countryNameCode: ['KI'] },
  687: { countryNameCode: ['NC'] },
  688: { countryNameCode: ['TV'] },
  689: { countryNameCode: ['PF'] },
  690: { countryNameCode: ['TK'] },
  691: { countryNameCode: ['FM'] },
  692: { countryNameCode: ['MH'] },
  850: { countryNameCode: ['KP'] },
  852: { countryNameCode: ['HK'] },
  853: { countryNameCode: ['MO'] },
  855: { countryNameCode: ['KH'] },
  856: { countryNameCode: ['LA'] },
  880: { countryNameCode: ['BD'] },
  886: { countryNameCode: ['TW'] },
  960: { countryNameCode: ['MV'] },
  961: { countryNameCode: ['LB'] },
  962: { countryNameCode: ['JO'] },
  963: { countryNameCode: ['SY'] },
  964: { countryNameCode: ['IQ'] },
  965: { countryNameCode: ['KW'] },
  966: { countryNameCode: ['SA'] },
  967: { countryNameCode: ['YE'] },
  968: { countryNameCode: ['OM'] },
  970: { countryNameCode: ['PS'] },
  971: { countryNameCode: ['AE'] },
  972: { countryNameCode: ['IL'] },
  973: { countryNameCode: ['BH'] },
  974: { countryNameCode: ['QA'] },
  975: { countryNameCode: ['BT'] },
  976: { countryNameCode: ['MN'] },
  977: { countryNameCode: ['NP'] },
  992: { countryNameCode: ['TJ'] },
  993: { countryNameCode: ['TM'] },
  994: { countryNameCode: ['AZ'] },
  995: { countryNameCode: ['GE'] },
  996: { countryNameCode: ['KG'] },
  998: { countryNameCode: ['UZ'] },
};
