import React from 'react';
import { connect } from 'react-redux';
import RegisterView from './RegisterView';
import { register, registerReVerification, thirdPartyRegister } from '../../actions/authActions';
import { PageTitle } from 'src/components/App';

export const RegisterContainer = (props) => {
  return (
    <>
      <PageTitle title="Sign Up" />
      <RegisterView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  register: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(register(data, resolve, reject));
    });
  },
  thirdPartyRegister: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(thirdPartyRegister(data, resolve, reject));
    });
  },
  registerReVerification: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(registerReVerification(id, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
