import { Typography } from '@mui/material';
import styled from 'styled-components';
import images from 'src/config/images';
import { makeStyles } from '@mui/styles';

export const ExternalLinkWrapper = styled.div`
  border-bottom: 2px solid #ececec;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SRArrow = styled.img.attrs({
  src: images.app.SRArrow,
})`
  width: 20px;
`;

export const IntroTitle = styled(Typography).attrs({})`
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 116.7%;
  color: #000000;
`;

export const IntroDescription = styled(Typography)`
  height: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  /* color: #000000; */
  display: inline-block;
`;

export const GettingStartedButton = styled.div`
  position: fixed;
  right: 24px;
  bottom: 38px;
  /* width: 178px; */
  width: 46px;
  height: 30px;
  background: #1e88e5;
  border-radius: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  transition: all 0.3s ease-out;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    width: 178px;
  }
  &:hover img {
    margin-right: 5px;
  }
  &:hover p {
    display: block;
  }
  display: flex;
  align-items: center;
  z-index: 999;
`;

export const GettingStartedText = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #ffffff;
  width: 120px;
  display: none;
  min-width: 120px;
  margin-left: 14px;
`;

export const GettingStartedIcon = styled.img.attrs((props) => ({
  src: images.app.gettingStartedIcon,
}))`
  /* margin-right: 5px; */
`;

export const useStyles = makeStyles({
  stepperIndex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '0px 7px',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: '20px',
    width: '20px',
    height: '20px',
    marginRight: 8,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  stepperCheckIcon: {
    width: 26,
    height: 26,
    marginRight: 8,
    color: '#66BB6A',
  },
  // ACCORDION COMPONENT STYLES : START
  outerAccordionInfoText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    lineHeight: '150%',
  },
  outerAccordionInfoTextComplete: {
    color: '#26CF9F',
    fontWeight: 500,
  },
  outerAccordionIconWrap: {
    position: 'relative',
  },
  outerAccordionCompletedCheck: {},
  outerAccordionIcon: {
    marginRight: 10,
    fontSize: 24,
  },
  accordionContainer: {},
  outerAccordionContainer: {
    margin: '0 0 16px !important',
    background: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    borderWidth: 1,
    '&:before': {
      background: 'transparent',
    },
  },
  outerAccordionDetails: {
    flexDirection: 'column',
    padding: '8px 16px 0',
  },
  innerAccordionContainer: {
    borderRadius: '10px',
    background: 'transparent',
    borderBottom: '1px solid #ECECEC',
    margin: '0 0 8px !important',
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&.Mui-expanded': {
      boxSizing: 'border-box',
    },
  },
  completedStep: {},
  innerAccordionDetails: {
    paddingLeft: 46,
  },
  outerAccordionSummary: {
    minHeight: 60,
    maxHeight: 60,
    background: 'transparent',
    overflow: 'hidden',
    padding: '6px 28px',
    '& .MuiAccordionSummary-content': {
      display: 'block',
      margin: '0',
    },
    '&.Mui-expanded': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
      },
    },
    '& .title': {
      fontSize: '20px',
      fontWeight: '700',
    },
  },
  innerAccordionSummary: {
    minHeight: 56,
    maxHeight: 56,
    margin: '0 !important',
    '& .title': {
      fontSize: '16px',
      fontWeight: '600',
    },
    '& .icon': {
      fontSize: 28,
    },
  },
  accordionProgress: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '3px',
    left: -16,
    backgroundColor: '#dddddd !important',
    borderRadius: 4,
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#66BB6A',
    },
  },
  accordionInfoIcon: {
    width: 20,
    marginRight: 10,
  },
  stepMediaThumbnail: {
    width: '100%',
    cursor: 'pointer',
  },
  videoModal: {
    width: '100%',
    '& .modal-dialog': {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'calc(100vw - 25%)',
      margin: '0 auto',
      '& .modal-content': {
        background: 'transparent',
      },
    },
  },
  // ACCORDION COMPONENT STYLES : END

  // INTRO COMPONENT STYLES : START
  introContainer: {
    width: 'calc(100vw - 50% - 160px)',
    maxWidth: 500,
    minWidth: 440,
    position: 'absolute',
    left: 'calc(100vw - 50% - 200px)',
    top: 140,
  },
  introContent: {
    position: 'relative',
  },
  introManImage: {
    maxWidth: 230,
    position: 'absolute',
    right: -150,
    bottom: -75,
    zIndex: 1,
  },
  introBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start',
    padding: 20,
    border: '1px solid #80ABCD',
    borderRadius: 5,
    zIndex: 2,
    backgroundColor: '#ffffff',
  },
  introFromText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  introSRLogo: {
    marginLeft: 10,
  },
  // INTRO COMPONENT STYLES : END
});
