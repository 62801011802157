import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Button as MuiButton, Paper } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

export const SavingTemplateButton = ({ setFieldValue, template, setSaveAsNew }) => {
  const options = ['Save', 'Save as template'];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  const handleMenuItemClick = (event, index) => {
    if (index === 1) {
      setFieldValue('saveAsNew', true);
      setSaveAsNew(true);
      if (template && template?.id) {
        setFieldValue('name', `${template.name} copy`);
      }
    } else {
      setSaveAsNew(false);
      setFieldValue('saveAsNew', false);
    }
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        color={'primary'}
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <MuiButton
          color={'primary'}
          sx={{
            background: '#1976D2',
            color: '#fff',
            borderWidth: '0px !important',
            textTransform: 'none',
            '&:hover': {
              background: '#1976D2',
            },
          }}
          type={'submit'}
        >
          {options[selectedIndex]}
        </MuiButton>
        <MuiButton
          size="small"
          sx={{
            background: '#1565C0',
            color: '#fff',
            '&:hover': {
              background: '#1565C0',
            },
          }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </MuiButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
