import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonBase, Typography, Chip, Stack } from '@mui/material';
import images from 'src/config/images';

export function useColumns(data) {
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!data || !data.length) {
      setColumns([]);
    } else {
      setColumns([
        {
          label: 'Name',
          name: 'name',
          options: {
            sort: false,
            customBodyRenderLite: (dataIndex) => {
              const item = data[dataIndex];
              if (!item) return null;
              return (
                <Typography sx={{ color: '#A0BED9' }}>
                  {item.user.fname} {item.user.lname}
                </Typography>
              );
            },
          },
        },
        {
          label: 'In progress',
          name: 'sessions',
          options: {
            sort: false,
            customBodyRenderLite: (dataIndex) => {
              const item = data[dataIndex];
              if (!item) return null;
              return (
                <Stack direction="row" gap={1}>
                  <img src={images.LMS.sessions} />
                  <Typography color="textPrimary">{item?.sessions} sessions</Typography>
                </Stack>
              );
            },
          },
        },
        {
          label: 'Role-plays',
          name: 'roleplays',
          options: {
            sort: false,
            customBodyRenderLite: (dataIndex) => {
              const item = data[dataIndex];
              if (!item) return null;
              return (
                <Stack direction="row" gap={1}>
                  <img src={images.LMS.roleplay} />
                  <Typography color="textPrimary">{item?.roleplaysCount}</Typography>
                </Stack>
              );
            },
          },
        },
        {
          label: 'Average score',
          name: 'score',
          options: {
            sort: false,
            customBodyRenderLite: (dataIndex) => {
              const item = data[dataIndex];
              if (!item) return null;
              return (
                <Stack direction="row" gap={1}>
                  <img src={images.LMS.score} />
                  <Typography color="textPrimary">
                    {item?.average ? item.average.toFixed(2) : 0}
                  </Typography>
                </Stack>
              );
            },
          },
        },
        {
          label: 'Detailed report',
          name: 'score',
          options: {
            sort: false,
            customBodyRenderLite: (dataIndex) => {
              const item = data[dataIndex];
              if (!item) return null;
              const roleplayCount = item?.roleplaysCount || 0;

              return (
                <ButtonBase
                  onClick={() => {
                    history.push(`/lms/dashboard/${item.user?.id || item.user?._id}`);
                  }}
                  disabled={!roleplayCount}
                >
                  <Typography
                    color={!roleplayCount ? 'textSecondary' : 'secondary'}
                    sx={{ opacity: !roleplayCount ? 0.5 : 1 }}
                  >
                    View Report
                  </Typography>
                </ButtonBase>
              );
            },
          },
        },
      ]);
    }
  }, [data]);

  return columns;
}
