import React from 'react';
import { useSelector } from 'react-redux';

import { useHomeProvider } from 'src/modules/lms/utils/homeStore';
import Slider from 'react-slick';
import { ButtonBase, Card, CardContent, Typography, Link } from '@mui/material';
import styled from 'styled-components';

import CourseBanner from 'src/modules/lms/components/LMScourse/components/CourseDetails/CourseBanner';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Spacer } from 'src/components/shared';
import { useHistory } from 'react-router-dom';

function HomeTenantCourses() {
  const { tenantCourses } = useHomeProvider();
  const tenant = useSelector((state) => state.app.tenant);

  const history = useHistory();

  const Next = (props) => {
    const { className, style, onClick } = props;
    return (
      <NextButton style={{ display: 'block' }} onClick={onClick}>
        <ArrowBackIosNewIcon />
      </NextButton>
    );
  };

  const Prev = (props) => {
    const { className, style, onClick } = props;
    return (
      <PrevButton style={{ display: 'block' }} onClick={onClick}>
        <ArrowForwardIosIcon />
      </PrevButton>
    );
  };

  if (!tenantCourses.length) return null;

  return (
    <Card>
      <CardContent sx={{ padding: '24px', backgroundColor: '#0C1721' }}>
        <Typography color="secondary" variant="h2">
          {tenant.name} courses
        </Typography>
        <Spacer x={2} y={2} />
        <Wrapper>
          <Slider
            dots={true}
            arrows={true}
            slidesToShow={1}
            nextArrow={<Prev />}
            prevArrow={<Next />}
          >
            {tenantCourses.map((course, index) => {
              return (
                <div key={`tenant-course-${index}-${course.id}`}>
                  <ButtonBase
                    sx={{ display: 'block', width: '100%' }}
                    onClick={() => {
                      history.push(`/lms/course/${course.id}`);
                    }}
                  >
                    <CourseBanner course={course} loading={false} />
                  </ButtonBase>
                </div>
              );
            })}
          </Slider>
        </Wrapper>
      </CardContent>
    </Card>
  );
}

export default HomeTenantCourses;

const Wrapper = styled.div`
  margin-bottom: 26px;
  & .slick-dots button:before {
    border-radius: 10px;
    opacity: 0.2;
    background: #fff;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    color: transparent;
  }
  & .bannerWrapper {
    background-color: #193247;
  }
  & .slick-dots .slick-active button:before {
    opacity: 1;
    color: transparent;
  }
  & .slick-dots {
    /* margin-top: 12px; */
    bottom: -40px;
  }
`;

const NextButton = styled.div`
  position: absolute;
  left: 8px;
  top: calc(50% - 16px);
  z-index: 99;
  width: 32px;
  height: 32px;
  background-color: rgba(12, 23, 33, 0.1);
  border-radius: 32px;
  & svg {
    font-size: 20px;
    top: calc(50% - 10px);
    position: relative;
    left: calc(50% - 10px);
    /* fill: rgba(12, 23, 33, 1); */
  }
`;
const PrevButton = styled.div`
  position: absolute;
  right: 8px;
  top: calc(50% - 16px);
  z-index: 99;
  width: 32px;
  height: 32px;
  background-color: rgba(12, 23, 33, 0.1);
  border-radius: 32px;
  & svg {
    font-size: 20px;
    top: calc(50% - 10px);
    position: relative;
    left: calc(50% - 10px);
    /* fill: rgba(12, 23, 33, 1); */
  }
`;
