import React, { useState } from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Icon from 'src/components/lms/Icon';
import Session from 'src/components/lms/Session';
import Course from 'src/components/lms/Course';
import FilterBar from 'src/components/lms/Filterbar';
import Empty from 'src/components/lms/Empty';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import useRequest from 'src/components/hooks/useRequest';
import { fetchList, getTagsLookup } from '../../api/LMSApis';
import { Spacer } from 'src/components/shared';

import SessionListSkeleton from '../../components/SessionListSkeleton';
import { Box } from '@mui/material';
import { scrollToRef } from 'src/utils/helper';

import RecommendedSessions from '../../components/home/RecommendedSessions';
import RecommendedCourses from '../../components/home/RecommendedCourses';

function Completed() {
  const [applied, setApplied] = useState({});
  const [paging, setPaging] = useState({
    perPage: 20,
    page: 0,
  });

  const wrapperRef = React.useRef();

  const filters = [
    {
      key: 'sort',
      title: 'Sort',
      type: 'dropdown',
      remote: false,
      cancellable: true,
      icon: <Icon name="sort" />,
      options: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'createdAt',
        },
      ],
    },
    {
      key: 'tags',
      title: 'Tag',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      icon: <Icon name="tags" />,
      remoteMethod: getTagsLookup,
    },
  ];

  const tags = applied?.tags || {};

  const dir = applied?.sort && applied?.sort?.value == 'name' ? ':asc' : ':desc';
  const payload = {
    ...applied,
    ...(applied.sort ? { _sort: applied.sort?.value + dir || '' } : { _sort: 'createdAt:desc' }),
    _from: paging.page * paging.perPage,
    _size: paging.perPage,
    ...(applied._search ? { _search: applied._search } : {}),
    ...(applied?.tags ? { tags: applied?.tags.id } : {}),
  };
  delete payload.sort;

  const [data, { loading, error }] = useRequest(fetchList, payload);

  const handleFilter = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setApplied({
        ...applied,
        [key]: value,
      });
    } else {
      delete applied[key];
      setApplied({
        ...applied,
      });
    }
  };

  const favourites = data?.favourite || [];
  // const favourites = [];

  const handlePageChange = (e, page) => {
    setPaging((prevState) => {
      return {
        ...prevState,
        page: page - 1,
      };
    });
    scrollToRef(wrapperRef);
  };
  const total = data?.total?.value || 0;

  return (
    <>
      <LMSCard
        icon={<Icon name="saved" style={{ width: '24px', height: '24px' }} />}
        title="Saved"
        ref={wrapperRef}
      >
        <FilterBar
          showSearch={true}
          filters={filters}
          key="completed-filters"
          value={applied}
          onChange={handleFilter}
        />

        <Spacer y={4} />

        {loading ? (
          <SessionListSkeleton />
        ) : favourites.length ? (
          <React.Fragment>
            <Grid container spacing={2}>
              {favourites.map((item, index) => (
                <Grid item xs={6} md={6} lg={3} key={`saved-list-${item.id}`}>
                  {item?.session && item?.session?.id && <Session session={item?.session} />}
                  {item?.course && item?.course?.id && <Course course={item?.course} />}
                </Grid>
              ))}
            </Grid>

            <Spacer y={3} />

            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
              <Pagination
                count={total ? Math.ceil(total / paging.perPage) : 0}
                page={paging.page + 1}
                defaultPage={1}
                onChange={handlePageChange}
              />
            </Box>
          </React.Fragment>
        ) : (
          <>
            <Empty
              title={'Nothing to see here.'}
              desc={'Check out some of our Courses and Sessions to get started!'}
            />
          </>
        )}
      </LMSCard>
      {!loading && !favourites.length && (
        <>
          <Spacer x={2} y={2} />
          <RecommendedSessions title="Recommended Sessions" />
          <Spacer x={2} y={2} />
          <RecommendedCourses title="Recommended Courses" />
        </>
      )}
    </>
  );
}

export default Completed;
