import React from 'react';
import * as Yup from 'yup';
import { Box, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { Form, Button } from 'src/components/shared';

const PersonalDetailsForm = ({ onSubmit, loading }) => {
  return (
    <Form
      initialValues={{
        firstName: '',
        lastName: '',
        companyName: '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed.')
          .max(51, 'Max 51 characters allowed')
          .required('Please enter your first name.'),
        lastName: Yup.string()
          .matches(/^[A-Za-z]+$/, 'Only alphabets are allowed.')
          .max(51, 'Max 51 characters allowed')
          .required('Please enter your last name.'),
        companyName: Yup.string()
            .matches(/^[A-Za-z0-9\s._ -]+$/, 'Only alphabets, numbers, spaces, hyphens, and dots are allowed.')
            .max(101, 'Max 101 characters allowed')
          .required('Please enter your company name.'),
      })}
      onSubmit={onSubmit}
      validateOnChange={true}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid xs={6} item>
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  variant="outlined"
                  name="firstName"
                  errorBorder
                  placeholder="First name"
                />
              </Grid>
              <Grid xs={6} item>
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  variant="outlined"
                  errorBorder
                  name="lastName"
                  placeholder="Last name"
                />
              </Grid>
              <Grid xs={12} item>
                <Form.Field.Input
                  fullWidth
                  page="auth"
                  variant="outlined"
                  name="companyName"
                  sx={{ marginTop: 0 }}
                  errorBorder
                  placeholder="Company name"
                />
              </Grid>
              <Grid xs={12} item>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading}
                    disabled={!props?.isValid || !props?.dirty}
                    fullWidth
                    size="large"
                    blackButton
                    endIcon={<ArrowForwardIcon />}
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

export default PersonalDetailsForm;
