import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog } from '@mui/material';
import {
  FlexColumnBox,
  MainBox,
  Content,
  ModalHeader,
  Heading,
  SecurityVerificationTitle,
} from './styles';
import images from '../../../../../config/images';

const SecurityVerificationFailed = ({ open, onClose }) => {
  return (
    <>
      <Dialog open={open}>
        <ModalHeader>
          <Heading>Security verification</Heading>
          <CloseIcon onClick={() => onClose()} sx={{ color: '#00000042', cursor: 'pointer' }} />
        </ModalHeader>
        <MainBox alignItems={'flex-start'}>
          <Box>
            <img src={images.user.securityVerificationFailed} width={'100%'} alt={''} />
          </Box>
          <FlexColumnBox gap={'8px'} sx={{ alignItems: 'flex-start' }}>
            <SecurityVerificationTitle>
              We were unable to verify your account
            </SecurityVerificationTitle>
            <Content>Close this window and try again to connect your LinkedIn account.</Content>
          </FlexColumnBox>
        </MainBox>
      </Dialog>
    </>
  );
};
export default SecurityVerificationFailed;
