import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Menu } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { Button } from 'src/components/shared';

import SequenceStages from './SequenceStages';

import { useTaskColumns } from './useTaskColumns';
import TasksBulkActions from './TasksBulkActions';
import { taskBulkActions } from '../../config';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchContactsLookup } from 'src/modules/contacts/api/contactApis';

import AddIcon from '@mui/icons-material/Add';

import { fetchEnrolment } from 'src/modules/sequence/api/sequenceApi';
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { linkedinTaskTypes } from '../../config';

function TasksGrid({ tasks, loading, tab, ...props }) {
  const { data, filters, paging, sort } = tasks;
  const popupState = usePopupState({ variant: 'popover', popupId: 'sequenceStagePopup' });
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [seqStag, setSeqStag] = useState(undefined);
  const [enrolment, setEnrolment] = useState(undefined);
  const [enrolmentLoading, setEnrolmentLoading] = useState(false);

  const taskFilters = [
    ...(tab === 'linkedin'
      ? [
          {
            key: 'taskType_eq',
            title: 'Type',
            type: 'dropdown',
            cancellable: true,
            isPrimary: true,
            options: linkedinTaskTypes,
          },
        ]
      : []),
    ...(tab !== 'replies'
      ? [
          {
            key: 'status',
            title: 'Status',
            type: 'dropdown',
            optLabel: 'name',
            optValue: 'id',
            cancellable: false,
            isPrimary: true,
            options: [
              {
                id: 'due',
                name: 'Due',
              },
              {
                id: 'completed',
                name: 'Completed',
              },
            ],
          },
        ]
      : []),
    {
      key: 'dueDate',
      title: 'Due on',
      type: 'relativeDateRange',
      custom: true,
      customOptions: tab === 'linkedin' ? {
        maxDate: new Date(),
      } : {},
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'lastWeek', name: 'Last week' },
      ],
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
    },
    {
      key: 'user',
      title: 'Assigned to',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
  ];

  useEffect(() => {
    if (seqStag?.enrollment && seqStag?.enrollment !== '') {
      setEnrolmentLoading(true);
      fetchEnrolment(seqStag?.enrollment)
        .then((res) => {
          setEnrolment(res.enrollment);
          setEnrolmentLoading(false);
        })
        .catch((err) => {
          setEnrolment(undefined);
          setEnrolmentLoading(false);
        });
    }
  }, [seqStag]);

  const loadSequenceStaging = (event, task) => {
    setSeqStag(task);
    popupState.setAnchorEl(event.target);
    popupState.open();
  };

  const taskColumns = useTaskColumns(
    data,
    props.toggleAddTaskForm,
    props.onDelete,
    '',
    loadSequenceStaging,
  );

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchTasks(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
      tab,
    );
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (filters[key] != value) {
        if (key === '_search') {
          props.fetchTasks(
            {
              ...paging,
              pageNo: 0,
            },
            { ...filters, [key]: value },
            sort,
            tab,
          );
        } else {
          props.fetchTasks(paging, { ...filters, [key]: value }, sort, tab);
        }
      }
    } else {
      if (filters[key] != value) {
        // filters[key] = '';
        delete filters[key];
        props.fetchTasks(paging, filters, sort, tab);
      }
    }
  };

  const onClearFilters = () => {};

  const onSort = (sort) => {
    props.fetchTasks(paging, filters, sort, tab);
  };

  const closeBulkAction = (refetch = false) => {
    setBulkAction(undefined);
    setRowsSelected([]);
    if (refetch === true) {
      props.fetchTasks(paging, filters, sort, tab);
    }
  };

  const selectedTasks = useMemo(() => {
    return data.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const onBulkAction = (key) => {
    if (key === 'launchTasks') {
      props.setTaskQueue(selectedTasks.map((item) => item.id));
    } else if (key === 'delete' && false) {
      bulkDeleteTasks(selectedTasks);
    } else {
      setBulkAction(key);
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={taskColumns.map((col, index) => ({
          ...col,
          options: taskColumns?.columns?.length
            ? { ...col.options, ...taskColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onClearFilters={onClearFilters}
        onSort={onSort}
        loading={loading?.tasks || false}
        filters={taskFilters}
        appliedFilters={filters}
        bulkActions={taskBulkActions}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Tasks',
          searchText: filters?._search ? filters._search : '',
          search: true,
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                startIcon={<AddIcon />}
                onClick={() => {
                  props.toggleAddTaskForm();
                }}
                disableElevation
              >
                <Typography variant="body2">Create task</Typography>
              </Button>
            );
          },
        }}
      />
      <Menu {...bindMenu(popupState)}>
        <SequenceStages
          seqStag={seqStag}
          enrolment={enrolment}
          enrolmentLoading={enrolmentLoading}
        />
      </Menu>
      <TasksBulkActions
        action={bulkAction}
        onClose={closeBulkAction}
        taskBulkUpdate={props.taskBulkUpdate}
        selectedTasks={selectedTasks}
      />
    </>
  );
}

export default TasksGrid;
