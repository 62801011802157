import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from '@mui/material';
import {
  FlexColumnBox,
  MainBox,
  Title,
  Content,
  ModalHeader,
  Heading,
  SecurityVerificationTitle,
} from './styles';
import { Button } from '../../../../../components/shared';
import Input from '../../../../../components/shared/Form/Input';

const SecurityVerification = ({ open, onResend, onSMSClick, linkedInOtp, setLinkedInOtp }) => {
  return (
    <>
      <Dialog open={open}>
        <ModalHeader>
          <Heading>Security verification</Heading>
          <CloseIcon sx={{ color: '#00000042' }} />
        </ModalHeader>
        <MainBox alignItems={'flex-start'}>
          <FlexColumnBox gap={'8px'} alignItems={'flex-start'}>
            <SecurityVerificationTitle>
              Enter the code that was sent to your mobile phone
            </SecurityVerificationTitle>
            <Content>
              To finish registering, please enter the verification code we gave you. It might take a
              few minutes to receive your code.
            </Content>
          </FlexColumnBox>
          <Input fullWidth value={linkedInOtp} variant="outlined" onChange={(e) => setLinkedInOtp(e?.target?.value)} />
          <FlexColumnBox gap={'8px'}>
            <Button
              fullWidth
              variant={'contained'}
              color={'secondary'}
              sx={{ fontWeight: 500 }}
              onClick={() => onSMSClick()}
            >
              Submit
            </Button>
            <Button
              variant={'text'}
              fullWidth
              color={'secondary'}
              sx={{ fontWeight: 700 }}
              onClick={() => onResend()}
            >
              Resend
            </Button>
          </FlexColumnBox>
        </MainBox>
      </Dialog>
    </>
  );
};
export default SecurityVerification;
