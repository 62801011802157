import React, { useEffect, useMemo, useState } from 'react';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';
import { useHistory } from 'react-router-dom';
import { fetchSessionSummary } from 'src/modules/lms/api/LMSApis';
import { capitalizeFirstLetter } from 'src/utils/helper';
import Wide from './Wide';
import Long from './Long';
import useRequest from 'src/components/hooks/useRequest';

function Session({
  session,
  type = 'grid',
  showAssigned = true,
  showCourse = true,
  action = undefined,
  assignedBy = {},
}) {
  const history = useHistory();
  const { progress, assigned, ...ppp } = useLmsHomeProvider();
  const [sessionCourses, setSessionCourses] = useState([]);
  const sessionProgress = progress.sessions[session?.id]
    ? progress.sessions[session?.id]
    : undefined;
  const title = capitalizeFirstLetter(session?.title || '');
  // const totalVideos = session?.totalVideos || 0;
  const videosWatched = sessionProgress?.stats?.videoWatched || 0;
  const isListType = type === 'list';

  const [summary, { loading }] = useRequest(fetchSessionSummary, session.id);

  const handleClick = () => {
    history.push(`/lms/session/${session.id}`);
  };

  const courseName = sessionCourses.length ? sessionCourses[0].title : '';
  const statusKey = session?.showRoleplay ? 'progressStatus' : 'status';
  const isCompleted =
    history?.location?.pathname === '/lms/completed'
      ? true
      : sessionProgress?.[statusKey] === 'completed'
      ? true
      : false;

  const isAssigned = useMemo(() => {
    if (session?.id) {
      return assigned.sessions && assigned.sessions.includes(session.id);
    }
    return false;
  }, [assigned, session]);

  const totalVideos = summary?.summary?.sessionVideos || 0;

  const renderCourse = (CourseComponent) => (
    <CourseComponent
      session={session}
      title={title}
      videosWatched={videosWatched}
      courseName={courseName}
      totalVideos={totalVideos}
      isAssigned={isAssigned}
      handleClick={handleClick}
      summary={summary}
      showAssigned={showAssigned}
      showCourse={showCourse}
      action={action}
      assignedBy={assignedBy}
      isCompleted={isCompleted}
    />
  );

  return isListType ? renderCourse(Wide) : renderCourse(Long);
}

export default Session;
