import React from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment/moment';

import { DateRangePickerAlt } from '../../shared';
import Select from './Select';
import { FlexContainer, StyledBox, StyledLabel, StyledValue } from './styles';

const AccordionFilters = (filter) => {
  const RenderField = React.memo(({ type, dataKey }) => {
    const initialDateRange = filter?.filterValue?.value;
    switch (type) {
      case 'select':
        return (
          <>
            <Select options={filter?.options} {...filter} />
          </>
        );
      case 'calendar':
        return (
          <Box sx={{ p: '8px 25px' }}>
            <FlexContainer display="flex" alignItems="center" justifyContent="center">
              {initialDateRange?.startDate && initialDateRange?.endDate && !filter?.isExpanded ? (
                <Grid container sx={{ margin: 0, justifyContent: 'space-between' }} spacing={1}>
                  <Grid xs={6} item>
                    <StyledBox>
                      <StyledLabel>From</StyledLabel>
                      <StyledValue>
                        {moment(initialDateRange?.startDate)?.format('ddd, MMM DD')}
                      </StyledValue>
                    </StyledBox>
                  </Grid>
                  <Grid xs={6} item>
                    <StyledBox>
                      <StyledLabel>From</StyledLabel>
                      <StyledValue>
                        {moment(initialDateRange?.endDate)?.format('ddd, MMM DD')}
                      </StyledValue>
                    </StyledBox>
                  </Grid>
                </Grid>
              ) : null}
              {filter?.isExpanded ? (
                <DateRangePickerAlt
                  onChange={(changedValue) => {
                    filter?.updateFilterValue({
                      key: dataKey,
                      value: { startDate: changedValue?.startDate, endDate: changedValue?.endDate },
                      type: 'calendar',
                    });
                  }}
                  isExpanded={filter?.isExpanded}
                  showDateDisplay={true}
                  initialDateRange={initialDateRange || { startDate: null, endDate: null }}
                  wrapperClassName="mui-date-range-picker"
                  isWithAccordion
                />
              ) : null}
            </FlexContainer>
          </Box>
        );
      default:
        return '';
    }
  });
  return (
    <>
      <RenderField type={filter?.subType} dataKey={filter?.name} />
    </>
  );
};
const areEqual = (prevProps, nextProps) => true;
export default React.memo(AccordionFilters, areEqual);
