import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  SalesLibraryContainer: {
    gap: '2rem',

    '& .filter-conatiner': {
      justifyContent: 'space-between',
      flexDirection: 'row',
      gap: '2rem',

      '& > div': {
        width: 'fit-content',
      },
    },

    '& button': {
      whiteSpace: 'pre',
    },
  },

  SalesLibraryContent: {
    marginTop: '1.5rem',
    gap: '1.5rem',
  },

  BannerSection: {
    '& .content-wrapper': {
      padding: '1.5rem',
      alignItems: 'center',
      gap: '1rem',
      margin: '-1.5rem',
      position: 'relative',

      '& .background': {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        width: '100%',
        height: '100%',
        objectFit: 'fill',
      },

      '& .header-content': {
        alignItems: 'center',

        '& .title': {
          fontSize: '2.75rem',
          lineHeight: '3.375rem',
          fontWeight: 200,
        },

        '& .sub-title': {
          fontSize: '1.25rem',
        },

        '& .helper-text': {
          fontSize: '1.25rem',
        },
      },

      '& .search-bar': {
        width: 'clamp(180px, 80%, 410px)',
        backgroundColor: '#00000080',
      },
    },
    '@media (width < 760px)': {
      '& .content-wrapper': {
        '& .header-content': {
          textAlign: 'center',
          width: '75%',
          '& .title': {
            fontSize: '2.25rem !important',
            lineHeight: '2.75rem !important',
            fontWeight: '100 !important',
          },

          '& .sub-title': {
            fontSize: '1.25rem !important',
            fontWeight: 300,
          },
        },
      },
    },
  },
}));
