import * as LMSTypes from './LMSTypes';

export const deleteHistory = (id, resolve, reject) => ({
  type: LMSTypes.DELETE_HISTORY,
  id,
  resolve,
  reject,
});

export const fetchSessions = (paging, filters, sort, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_SESSIONS,
  paging,
  filters,
  sort,
  resolve,
  reject,
});

export const fetchCourses = (resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_COURSES,
  resolve,
  reject,
});

export const deleteList = (id, resolve, reject) => ({
  type: LMSTypes.DELETE_LIST,
  id,
  resolve,
  reject,
});

export const fetchList = (paging, filters) => ({
  type: LMSTypes.FETCH_LIST,
  paging,
  filters,
});

export const setList = (data, resolve, reject) => ({
  type: LMSTypes.SET_LIST,
  data,
  resolve,
  reject,
});
export const setSessions = (data, resolve, reject) => ({
  type: LMSTypes.SET_SESSIONS,
  data,
  resolve,
  reject,
});

export const setCourses = (data, resolve, reject) => ({
  type: LMSTypes.SET_COURSES,
  data,
  resolve,
  reject,
});
export const fetchHomepage = (filters) => ({
  type: LMSTypes.FETCH_HOMEPAGE,
  filters,
});

export const setHomePage = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_HOMEPAGE,
    data,
    resolve,
    reject,
  };
};

export const fetchSession = (sessionId, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_SESSION,
  sessionId,
  resolve,
  reject,
});

export const setSession = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_SESSION,
    data,
    resolve,
    reject,
  };
};

export const fetchSessionScore = (userId, sessionId, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_SESSION_SCORE,
  userId,
  sessionId,
  resolve,
  reject,
});

export const setSessionScore = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_SESSION_SCORE,
    data,
    resolve,
    reject,
  };
};

export const fetchPillar = (pillarId, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_PILLAR,
  pillarId,
  resolve,
  reject,
});

export const setPillar = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_PILLAR,
    data,
    resolve,
    reject,
  };
};

export const fetchCourse = (courseId, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_COURSE,
  courseId,
  resolve,
  reject,
});

export const setCourse = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_COURSE,
    data,
    resolve,
    reject,
  };
};

export const fetchHistory = (paging, filters) => ({
  type: LMSTypes.FETCH_HISTORY,
  paging,
  filters,
});

export const setHistory = (data, resolve, reject) => {
  return {
    type: LMSTypes.SET_HISTORY,
    data,
    resolve,
    reject,
  };
};

export const fetchTemplates = (paging, filters, sort, resolve = () => {}, reject = () => {}) => ({
  type: LMSTypes.FETCH_TEMPLATES,
  paging,
  filters,
  sort,
  resolve,
  reject,
});

export const setTemplates = (data, resolve, reject) => ({
  type: LMSTypes.SET_TEMPLATES,
  data,
  resolve,
  reject,
});

export const deleteTemplate = (templateId, resolve, reject) => {
  return {
    type: LMSTypes.DELETE_TEMPLATE,
    templateId,
    resolve,
    reject,
  };
};
export const editTemplate = (payload, templateId, reject, resolve) => {
  return {
    type: LMSTypes.EDIT_TEMPLATE,
    templateId,
    payload,
    resolve,
    reject,
  };
};
export const editNote = (payload, noteId, reject, resolve) => {
  return {
    type: LMSTypes.EDIT_NOTE,
    noteId,
    payload,
    resolve,
    reject,
  };
};
export const fetchNotes = ({ paging, filters, sort, resolve = () => {}, reject = () => {} }) => ({
  type: LMSTypes.FETCH_NOTES,
  paging,
  filters,
  sort,
  resolve,
  reject,
});
export const setNotes = (data, resolve, reject) => ({
  type: LMSTypes.SET_NOTES,
  data,
  resolve,
  reject,
});
export const deleteNote = (noteId, resolve, reject) => {
  return {
    type: LMSTypes.DELETE_NOTES,
    noteId,
    resolve,
    reject,
  };
};
export const fetchScores = (resolve, reject) => ({
  type: LMSTypes.FETCH_SCORE,

  resolve,
  reject,
});

export const setScores = (data, resolve, reject) => ({
  type: LMSTypes.SET_SCORE,
  data,
  resolve,
  reject,
});

export const fetchReportSummary = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_REPORT_SUMMARY,
  filters,
  resolve,
  reject,
});

export const fetchReportCards = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_REPORT_CARDS,
  filters,
  resolve,
  reject,
});

export const fetchReportGraph = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_REPORT_CHARTS,
  filters,
  resolve,
  reject,
});

export const fetchLmsComponent1 = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_COMPONENT1,
  filters,
  resolve,
  reject,
});

export const fetchLmsComponent2 = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_COMPONENT2,
  filters,
  resolve,
  reject,
});

export const fetchLmsComponent3 = (filters, resolve, reject) => ({
  type: LMSTypes.FETCH_LMS_COMPONENT3,
  filters,
  resolve,
  reject,
});

export const fetchLmsSummary = (data) => ({
  type: LMSTypes.SET_LMS_SUMMARY,
  data,
});

export const fetchLmsProgress = (data) => ({
  type: LMSTypes.SET_LMS_PROGRESS,
  data,
});