import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default (access) => {
  const [role, setRole] = useState('');
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setRole(user.acl?.accessLevel ? user.acl?.accessLevel.toLowerCase() : 'standard');
  }, [user]);

  return [role, setRole];
};
