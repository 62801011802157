import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Spacer, Dropdown } from 'src/components/shared';
import DateRange from './DateRange';
import RelativeDateRange from './RelativeDateRange';
import SearchInput from './SearchInput';
import { uniqueId } from 'lodash';
import { capitalizeFirstLetter } from 'src/utils/helper';

function FilterBar({
  filters,
  onChange,
  onSearch,
  showSearch,
  appliedFilters,
  searchText,
  withClearButton,
}) {
  const uniqueKey = uniqueId('FilterBar-');

  const handleOnChange = ({ changedValue, filter, value }) => {
    const updatedValue = changedValue?.users ? changedValue?.users : changedValue;
    const previousValue = value?.users ? value?.users : value;
    if (filter?.multiple && _.isArray(updatedValue) && _.isArray(previousValue)) {
      const union = _.unionWith(updatedValue, previousValue, _.isEqual);
      const differences1 = _.differenceWith(union, updatedValue, _.isEqual);
      const differences2 = _.differenceWith(union, previousValue, _.isEqual);
      const differences = _.concat(differences1, differences2);
      if (differences?.length) onChange(filter.key, changedValue);
    } else onChange(filter.key, changedValue);
  };

  const RenderField = ({ filter, value, index }) => {
    switch (filter.type) {
      case 'relativeDateRange':
        const value1 = appliedFilters[filter.key];
        return (
          <Box>
            <RelativeDateRange
              filter={filter}
              value={value1}
              onChange={(value) => onChange(filter.key, value)}
              filters={appliedFilters}
              type="button"
              options={filter.options}
              customOptions={filter?.customOptions || undefined}
            />
          </Box>
        );

      case 'dropdown':
        const inputProps = {};
        if (filter.remote === true) {
          inputProps.options = [];
          inputProps.remoteMethod = (val) => filter.remoteMethod(val);
        } else {
          inputProps.options = filter.options;
        }
        return (
          <Dropdown
            index={index}
            selected={appliedFilters[filter.key] ? appliedFilters[filter.key] : undefined}
            onChange={(changedValue) => handleOnChange({ changedValue, filter, value })}
            fullWidth={true}
            optLabel={filter?.optLabel}
            optValue={filter?.optValue}
            multiple={filter?.multiple}
            label={filter?.title || capitalizeFirstLetter(filter?.key)}
            apply={true}
            value={value ? value : []}
            onClear={() => onChange(filter.key, undefined)}
            withClearButton={withClearButton}
            {...filter}
            {...inputProps}
          />
        );

      case 'dateRange':
        return (
          <DateRange
            filter={filter}
            value={value}
            onChange={(value) => {
              onChange(filter.key, value);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1.5}>
      {showSearch ? (
        <>
          <SearchInput onSearch={onSearch} searchText={searchText} />
          <Spacer x={1} y={1} />
        </>
      ) : null}

      {filters.map((filter, index) => {
        const value = appliedFilters[filter.key];
        return (
          <Box key={`filterBar-${uniqueKey}-filter=${index}`}>
            <RenderField filter={filter} value={value} index={index} />
          </Box>
        );
      })}
    </Box>
  );
}

FilterBar.propTypes = {
  filters: PropTypes.array,
  onChange: PropTypes.func,
  showSearch: PropTypes.bool,
  appliedFilters: PropTypes.any,
  onSearch: PropTypes.func,
  searchText: PropTypes.string,
  withClearButton: PropTypes.bool,
};

FilterBar.defaultProps = {
  filters: [],
  onChange: () => {},
  onSearch: () => {},
  showSearch: false,
  appliedFilters: () => {},
  searchText: '',
};

export default FilterBar;
