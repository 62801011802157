import styled from 'styled-components';
import { Paper, Container } from '@mui/material';

export const FixedTitleBar = styled.div`
  background-color: #031729;
  position: sticky;
  width: calc(100% + 52px);
  top: 50px;
  margin: -12px 0 0 -26px;
  height: 68px;
`;

export const ContactSidebar = styled(Paper)`
  width: 300px;
  height: ${props => props?.height ? `calc(100% - ${props?.height + 20}px)` : '100%'};
  /* min-height: calc(120vh - 10px); */
  left: 0;
  position: absolute;
  overflow-y: auto;
  max-width: 300px;
`;

export const ContactMainView = styled(Container)`
  width: 70%;
  height: 100%;
  /* min-height: calc(120vh - 10px); */
  margin-left: 300px;
`;
