import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Box, ButtonBase, Chip, Grid, Stack, Typography } from '@mui/material';
import { Modal, Form, Button, Avatar } from 'src/components/shared';
import SearchIcon from '@mui/icons-material/Search';
import { fetchSessionsLookup } from '../api/competitionApi';
import { RemoveRedEye } from '@mui/icons-material';
import { frequencyOptions, minTargetOptions, roleplayOptions } from 'src/modules/lms/utils/helper';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { postCompetition, putCompetition } from '../actions/competitionActions';
import CompetitionTemplatePreview from '../container/Competitions/CompetitionTemplatePreview';

function CompetitionForm({ competition, open, session, ...props }) {
  const [templatePreviewModal, setTemplatePreviewModal] = React.useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formRef = useRef();

  const handleClose = () => {
    props.handleClose();
    formRef.current.resetForm();
    setShowInstructions(false);
  };

  // const prizeOptions = useSelector(
  //   (state) => state?.app?.tenant?.tenant_settings?.[0]?.competitionSettings?.prizes,
  // );
  const prizeOptions = useSelector((state) => state?.app?.globals?.competitionSettings?.prizes);

  const roleplayOpts = roleplayOptions();
  const minRolePlayOpts = minTargetOptions();
  const notificationOpts = frequencyOptions();

  const handleSubmit = (values) => {
    return new Promise((resolve, reject) => {
      if (competition && competition?.id) {
        dispatch(putCompetition(competition.id, values, resolve, reject));
      } else {
        dispatch(postCompetition(values, resolve, reject));
      }
    });
  };

  const showInput = competition && competition?.id ? competition.status === 'draft' : true;
  const showStartDate =
    competition?.id && competition?.startDate && competition.status !== 'draft'
      ? moment(competition?.startDate) > moment()
        ? true
        : false
      : true;

  return (
    <>
      <Form
        initialValues={{
          session: competition?.session || session || {},
          endDate: competition?.endDate || moment().add('days', 3)?.format('YYYY-MM-DD'),
          instructions: competition?.instructions || '',
          roleplays: competition?.roleplays || '10',
          minimumRoleplays: competition?.minimumRoleplays || '4.5',
          prize: competition?.prize || '',
          startDate: competition?.startDate || moment().format('yyyy-MM-DD'),
          users: competition?.users || [],
          updateEmailFrequency: competition?.updateEmailFrequency || 'daily',
          sendUpdateEmails: competition?.sendUpdateEmails || false,
          prize: competition?.prize || '',
          invite: '',
          inviteSendDate: '',
          inviteSendTime: '',
          isPublished: competition?.prize || false,
        }}
        validationSchema={Yup.object().shape({
          users: Yup.array().test('users', 'Please choose at-least one user.', function (item) {
            return item.length;
          }),
          session: Yup.object().test('session', 'Please choose a session.', function (item) {
            return item?.id && item?.id !== '';
          }),
          prize: Yup.string().required('Please choose prize.'),
          roleplays: Yup.string().required('Please choose role-plays.'),
          minimumRoleplays: Yup.string().required('Please choose a target.'),
          ...(showStartDate
            ? {
                startDate: Yup.date('Please enter a valid date.').min(
                  moment().subtract(1, 'days'),
                  'Start date cannot be in past!',
                ),
                endDate: Yup.date().min(
                  Yup.ref('startDate'),
                  "End date can't be before Start date",
                ),
              }
            : {
                endDate: Yup.date('Please enter a valid date.').min(
                  moment().subtract(1, 'days'),
                  'End date cannot be in past!',
                ),
              }),
        })}
        enableReinitialize={true}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            await handleSubmit(values);
            setLoading(false);
            handleClose();
          } catch (error) {}
        }}
        innerRef={formRef}
      >
        {({ values, ...formProps }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formProps.submitForm();
              return false;
            }}
            noValidate
          >
            <Modal
              open={open}
              title={competition?.id ? 'Edit competition' : 'Create a competition'}
              size="md"
              renderActions={() => (
                <Stack direction="row" justifyContent="flex-end" gap={2} padding={1}>
                  <Button disabled={loading} color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  {showInput && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      loading={loading}
                      onClick={() => {
                        formProps.setFieldValue('isPublished', false);
                        formProps.submitForm();
                      }}
                    >
                      Save as draft
                    </Button>
                  )}

                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      formProps.setFieldValue('isPublished', true);
                      formProps.submitForm();
                    }}
                    loading={loading}
                  >
                    {showInput ? 'Publish' : 'Update'}
                  </Button>
                </Stack>
              )}
              onClose={handleClose}
            >
              {showInput && (
                <>
                  <Stack direction="row" alignItems="flex-end" gap={1} mb={-1}>
                    <Typography variant="body2" color="textPrimary">
                      Choose a session
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <i>One session per competition</i>
                    </Typography>
                  </Stack>

                  <Form.Field.AutoComplete
                    options={[]}
                    fullWidth
                    name="session"
                    placeholder="Search the SuperReach Learning Library "
                    variant="outlined"
                    remoteMethod={(val) => {
                      return fetchSessionsLookup(val);
                    }}
                    optValue="id"
                    optLabel="title"
                    inputProps={{
                      startAdornment: <SearchIcon />,
                    }}
                    // size="small"
                  />

                  <Stack direction="row" alignItems="flex-end" gap={1}>
                    <ButtonBase onClick={() => setShowInstructions(!showInstructions)}>
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <AddIcon />
                        <Typography variant="body2" color="textPrimary">
                          Add Instructions
                        </Typography>
                      </Stack>
                    </ButtonBase>
                    <Typography variant="caption" color="textSecondary">
                      <i>This content will be shown to competitors when completing a AI Coach.</i>
                    </Typography>
                  </Stack>

                  <Box sx={{ display: showInstructions ? 'block' : 'none' }}>
                    <Form.Field.TextEditor
                      fullWidth
                      height={200}
                      variant="outlined"
                      name="instructions"
                      showFontEditor={false}
                      showFontStyle={false}
                      showAlignProps={false}
                      showListProps={false}
                      showAttachments={false}
                      placeHolder="Add any information that will help your competitors to do their best in the competition."
                    />
                  </Box>
                </>
              )}

              <Stack direction="row" justifyContent="space-between" mt={showInput ? 2 : 0} mb={-2}>
                <Typography variant="body2" color="textPrimary">
                  Choose your competitors
                </Typography>
                <Button
                  startIcon={<RemoveRedEye />}
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setTemplatePreviewModal(true);
                  }}
                >
                  Preview invite
                </Button>
              </Stack>

              <Form.Field.AutoComplete
                multiple={true}
                options={[]}
                fullWidth
                name="users"
                placeholder="Select users"
                variant="outlined"
                remoteMethod={(val) => {
                  return fetchUsersLookup(val);
                }}
                checkboxes={true}
                optValue="id"
                optLabel="name"
                inputProps={{
                  startAdornment: <SearchIcon />,
                }}
                sx={{
                  '& .MuiChip-root': {
                    display: 'none',
                  },
                }}
              />

              {values?.users && values?.users.length ? (
                <Stack direction="row" gap={1} flexWrap="wrap">
                  {values?.users.map((user, index) => (
                    <Chip
                      variant="outlined"
                      key={`competition-user-${user.id}`}
                      label={`${user.fname} ${user.lname}`}
                      avatar={
                        <Avatar
                          size={24}
                          src={user.userImage}
                          name={`${user.fname.slice(0, 1)}${user.lname.slice(0, 1)}`}
                        />
                      }
                      onDelete={() => {
                        formProps.setFieldValue(
                          'users',
                          values.users.filter((item) => item.id !== user.id),
                        );
                      }}
                    />
                  ))}
                </Stack>
              ) : null}

              <Grid container spacing={2} mt={0}>
                {showStartDate && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" sx={{ marginBottom: -1 }}>
                      Start date
                    </Typography>
                    <Form.Field.Datepicker
                      fullWidth
                      rows={4}
                      variant="inline"
                      name="startDate"
                      disablePast={true}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Typography variant="body2" sx={{ marginBottom: -1 }}>
                    End date
                  </Typography>
                  <Form.Field.Datepicker
                    fullWidth
                    rows={4}
                    variant="inline"
                    name="endDate"
                    disablePast={true}
                  />
                </Grid>
              </Grid>

              {showInput && (
                <>
                  <Grid container spacing={2} mt={0}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: -1 }}>
                        How many role-plays must be completed?
                      </Typography>
                      <Form.Field.Select
                        multiple={false}
                        options={roleplayOpts}
                        optLabel="label"
                        optValue="value"
                        fullWidth
                        name="roleplays"
                        placeholder="Choose number of role-plays"
                        variant="outlined"
                        showNone={false}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: -1 }}>
                        What’s the target
                      </Typography>
                      <Form.Field.Select
                        multiple={false}
                        options={minRolePlayOpts}
                        optLabel="label"
                        optValue="value"
                        fullWidth
                        name="minimumRoleplays"
                        placeholder="Choose the score that users must aim for"
                        variant="outlined"
                        showNone={false}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mt={0}>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" alignItems="flex-end" gap={1} mb={-1}>
                        <Typography variant="body2" color="textPrimary">
                          The prize
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          <i>The prize will be displayed on the leaderboard </i>
                        </Typography>
                      </Stack>

                      <Form.Field.Select
                        options={prizeOptions}
                        fullWidth
                        name="prize"
                        placeholder="Select prize"
                        variant="outlined"
                        optLabel="name"
                        optValue="prize"
                        showNone={false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: -1 }}>
                        Notifications
                      </Typography>
                      <Form.Field.Select
                        multiple={false}
                        options={notificationOpts}
                        fullWidth
                        name="updateEmailFrequency"
                        placeholder="Notifications"
                        variant="outlined"
                        showNone={false}
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="body2" sx={{ marginBottom: -3 }}>
                    Send invite
                  </Typography>

                  <Form.Field.Radio
                    name="invite"
                    color="secondary"
                    direction="column"
                    row={true}
                    options={[
                      {
                        value: 'immediately',
                        label: (
                          <Typography variant="body2" color="textSecondary">
                            Immediately
                          </Typography>
                        ),
                      },
                      {
                        value: 'tomorrowMorning',
                        label: (
                          <Typography variant="body2" color="textSecondary">
                            Tomorrow morning
                          </Typography>
                        ),
                      },
                      {
                        value: 'nextMonday',
                        label: (
                          <Typography variant="body2" color="textSecondary">
                            Next Monday
                          </Typography>
                        ),
                      },
                      {
                        value: 'custom',
                        label: (
                          <Typography variant="body2" color="textSecondary">
                            Custom
                          </Typography>
                        ),
                      },
                    ]}
                    value={values?.crmCheckDuplicatesInAllTypes}
                  />

                  {values?.invite === 'custom' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <Form.Field.Datepicker
                          disablePast={true}
                          fullWidth
                          variant="inline"
                          name="inviteSendDate"
                          label="date"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Form.Field.Timepicker
                          fullWidth
                          variant="outlined"
                          name="inviteSendTime"
                          label="Time"
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Modal>
          </form>
        )}
      </Form>
      <CompetitionTemplatePreview
        open={templatePreviewModal}
        competitionId={competition?.id}
        invite={true}
        handleClose={() => setTemplatePreviewModal(false)}
        handleOpen={() => setTemplatePreviewModal(true)}
      />
    </>
  );
}

export default CompetitionForm;
