import api from 'src/api';
import { isEmpty } from 'lodash';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const taskBulkUpdate = (payload) => {
  return api(`/task/bulk`, payload, 'put');
};

export const getTaskDetails = (taskId) => {
  return api(`/task/${taskId}`, null, 'get');
};

export const sendTaskEmail = (payload) => {
  return api('/emailmessage', payload, 'post');
};
export const putTaskEmail = (id, payload) => {
  return api(`/emailmessage/${id}`, payload, 'put');
};

export const deleteTaskEmail = (id) => {
  return api(`/emailmessage/${id}`, null, 'delete');
};

export const createTask = (task) => {
  return api('/task', task, 'post');
};

export const updateTask = (taskId, task) => {
  return api(`/task/${taskId}`, task, 'put');
};

export const deleteTask = (taskId) => {
  return api(`/task/${taskId}`, null, 'delete');
};

export const fetchTasks = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/task${filter}`, null, 'get');
};

export const fetchUpcomingTasks = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/task/upcoming${filter}`, null, 'get');
};

export const fetchTaskReplies = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/emailmessage${filter}`, null, 'get');
};

export const fetchTaskErrors = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/errors${filter}`, null, 'get');
};

export const fetchActiveTaskErrors = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/errors/active${filter}`, null, 'get');
};

export const changeTaskStatus = (taskId, status) => {
  const payload = { status };
  return api(`/automation/change-task-status/${taskId}`, payload, 'put');
};

export const fetchTaskOptionsFilters = () => {
  return api('/automation/get-task-filter', null, 'get');
};

export const getTaskTabs = () => {
  return api('/automation/tabs/get-tabs?object=task', null, 'get');
};

export const fetchTaskIds = async (tabId) => {
  return api(`/automation/get-task-tab-ids?tab=${tabId}&plateform=ministorm`, '', 'GET');
};

export const fetchOutbox = (filters) => {
  let filter = '';
  if (!isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring({ ...filters, error_neq: true })}`;
  }
  return api(`/task${filter}`, null, 'get');
};

export const fetchInbox = (filters) => {
  let filter = '';
  if (!isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/task/inbox${filter}`, null, 'get');
};

export const fetchSent = (filters) => {
  let filter = '';
  if (!isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/task/sent${filter}`, null, 'get');
};
