import React from 'react';
import { DropdownV2 } from 'src/components/shared';

const RenderFullViewFilter = ({ filter, value, onChange }) => {
  if (filter?.type === 'dropdown')
    return (
      <DropdownV2
        selected={value ? value : filter?.multiple ? [] : {}}
        onChange={onChange}
        label={filter?.title}
        version="v2"
        {...filter}
      />
    );
  return <React.Fragment />;
};

export default RenderFullViewFilter;
