import React from 'react'
import * as Yup from 'yup';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { Spacer, Form, Button } from 'src/components/shared';
import PropTypes from 'prop-types';

const PropertyForm = ({ handleSubmit, propertyValues, propertyType, onFormClose, handleCopySubmit }) => {
    let isEdit = "name" in propertyValues ? true : false
    return (
        <>
            <Typography variant="body1" color="textSecondary">
                {`Write a new ${propertyType} for the AI Coach.`}
            </Typography>
            <Spacer x={2} y={2} />
            <Form
                initialValues={{
                    [propertyType]: propertyValues?.name || "",
                }}
                validationSchema={Yup.object().shape({
                    [propertyType]: Yup.string().required('Please enter course overview'),
                })}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                validateOnChange={false}
            >
                {(props) => {
                    return (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                props.submitForm();
                                return false;
                            }}
                            style={{ height: '100%' }}
                            noValidate
                        >
                            <Box display="flex" flexDirection="column" height="100%">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Form.Field.Textarea
                                            minRows={2}
                                            variant="outlined"
                                            name={propertyType}
                                            placeholder={`Add ${propertyType}`}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Spacer x={2} y={2} />
                                <Box mt={2} display="flex" gap={"1rem"} justifyContent={"flex-end"}>
                                    <Button
                                        variant="text"
                                        onClick={onFormClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={isEdit ? "outlined" : "contained"}
                                        color="secondary"
                                        type="submit"
                                    >
                                        Save & Insert
                                    </Button>
                                    {isEdit && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={(e) => handleCopySubmit(props)}
                                        >
                                            Save A Copy & Insert
                                        </Button>
                                    )}

                                </Box>
                            </Box>
                        </form>
                    );
                }}
            </Form>
        </>
    )
}


PropertyForm.propTypes = {
    propertyValues: PropTypes.object,
    propertyType: PropTypes.string,
    onFormClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCopySubmit: PropTypes.func
};

PropertyForm.defaultProps = {
    propertyValues: {},
    propertyType: "",
    onFormClose: () => { },
    handleSubmit: () => { },
    handleCopySubmit: () => { }
};

export default PropertyForm