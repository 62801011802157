import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, InputAdornment } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';

import {
  instagramRegex,
  linkedinRegex,
  facebookRegex,
  twitterRegex,
  githubRegex,
  stackofRegex,
} from 'src/config';
import images from 'src/config/images';
import { ReactComponent as TwitterX } from '../../../../assets/icons/twitter.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';

function ContactDetailsForm({ onClose, contactType, onSubmit, formData }) {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    linkedin: '',
    linkedinRecruiter: '',
    linkedinSalesNavigator: '',
    facebook: '',
    instagram: '',
    twitter: '',
    github: '',
    stackoverflow: '',
  });

  useEffect(() => {
    setInitialValues({
      linkedin: formData?.linkedin || '',
      linkedinRecruiter: formData?.linkedinRecruiter || '',
      linkedinSalesNavigator: formData?.linkedinSalesNavigator || '',
      facebook: formData?.facebook || '',
      instagram: formData?.instagram || '',
      twitter: formData?.twitter || '',
      github: formData?.github || '',
      stackoverflow: formData?.stackoverflow || '',
    });
  }, [formData]);

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        linkedin: Yup.string().matches(linkedinRegex, 'Enter valid linkedin profile url!'),
        facebook: Yup.string().matches(facebookRegex, 'Enter valid facebook profile url!'),
        instagram: Yup.string().matches(instagramRegex, 'Enter valid instagram profile url!'),
        twitter: Yup.string().matches(twitterRegex, 'Enter valid twitter profile url!'),
        github: Yup.string().matches(githubRegex, 'Enter valid github profile url!'),
        stackoverflow: Yup.string().matches(stackofRegex, 'Enter valid stackoverflow profile url!'),
      })}
      onSubmit={async (values, form) => {
        try {
          setLoading(true);
          await onSubmit(values, form);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
              height="100%"
            >
              <Box>
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  name="linkedin"
                  label="LinkedIn"
                  tip={'https://www.linkedin.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon htmlColor="#007BB5" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  name="linkedinRecruiter"
                  label="LinkedIn Recruiter"
                  tip={'https://www.linkedin.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon htmlColor="#007BB5" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  name="linkedinSalesNavigator"
                  label="LinkedIn Sales Navigator"
                  tip={'https://www.linkedin.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon htmlColor="#007BB5" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  // InputLabelProps={{ shrink: true }}
                  name="facebook"
                  label="Facebook"
                  tip={'https://www.facebook.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon htmlColor="#23599C" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="instagram"
                  // InputLabelProps={{ shrink: true }}
                  label="Instagram"
                  tip={'https://www.instagram.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon htmlColor="#bc2a8d" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="twitter"
                  // InputLabelProps={{ shrink: true }}
                  label="Twitter"
                  tip={'https://www.twitter.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterX />
                      </InputAdornment>
                    ),
                  }}
                />
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="github"
                  // InputLabelProps={{ shrink: true }}
                  label="Github"
                  tip={'https://www.github.com/profileURL'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GitHubIcon htmlColor="#333" />
                      </InputAdornment>
                    ),
                  }}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  name="stackoverflow"
                  // InputLabelProps={{ shrink: true }}
                  label="Stackoverflow"
                  tip="https://www.stackoverflow.com/profileURL"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={images.app.stackOverflow} style={{ width: 24 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      size="medium"
                      onClick={onClose}
                      fullWidth={true}
                      color="secondary"
                      variant="outlined"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="medium"
                      type="submit"
                      fullWidth={true}
                      color="secondary"
                      variant="contained"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default ContactDetailsForm;
