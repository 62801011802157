import { isEmpty } from 'lodash';
import moment from 'moment';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const updateNotifications = (ids, data) => {
  return api(
    `note/notifications`,
    {
      ids,
      data,
    },
    'put',
  );
};

export const fetchNotificationCount = () => {
  return api(`note/notifications/count`, null, 'get');
};

export const clearAllNotifications = () => {
  return api(`note/notifications/clear`, {}, 'put');
};

export const getNotifications = (paging, filter) => {
  const filters = {
    _from: paging?.pageNo * paging?.perPage || 0,
    _size: paging?.perPage || 10,
    ...filter,
  };
  let query = '';
  if (filters && !isEmpty(filters)) {
    query = convertObjectToQuerystring(filters);
  }
  return api(`note/notifications?${query}`, null, 'get');
};

export const fetchDailySendingLimit = (id = '') => {
  return api(`emailmessage/limit${id ? `/${id}` : ''}`, null, 'get');
};

export const sendTestEmail = (data) => {
  return api(`template/testemail`, data, 'post');
};

export const testPopulateTemplate = (subject, content, contact, sender) => {
  return api(
    `template/testpopulate`,
    {
      sender,
      contact,
      templateBody: content,
      subject,
    },
    'post',
  );
};

export const populateTemplate = (subject, content, contact, sender) => {
  return api(
    `template/populate`,
    {
      sender,
      contact,
      templateBody: content,
      subject,
    },
    'post',
  );
};

export const populateTemplateId = (data) => {
  return api(`template/populate`, data, 'post');
};

export const fetchOnboardingData = (userId) => {
  return api(`user/${userId}/onboarding`, null, 'get');
};

export const putOnboardingData = (userId, key, value) => {
  const payload = {
    [key]: value,
  };
  return api(`user/${userId}/onboarding`, payload, 'put');
};

export const updatePanelContact = (panelId, letPanelContactId, payload) => {
  return api(`panel/${panelId}/${letPanelContactId}`, payload, 'put');
};

export const getPanelContacts = (panelId) => {
  return api(`panel/${panelId}/contacts`, null, 'get');
};

export const panelBulkUpdate = (payload) => {
  return api(`panel/bulk`, payload, 'PUT');
};

export const panelBulkAdd = (boardId, payload) => {
  return api(`dashboard/${boardId}/panel/bulk`, payload, 'POST');
};

export const addContactToPanel = (panelId, contactId, sortOrder) => {
  const payload = {
    contact: contactId,
    sortOrder,
  };
  return api(`panel/${panelId}/contacts`, payload, 'post');
};

export const removeContactFromPanel = (panelId, contactId) => {
  const payload = {
    contact: contactId,
  };
  return api(`panel/${panelId}/contact/${contactId}`, null, 'delete');
};

export const fetchGlobals = () => {
  return api(`globalconfig`, null, 'get');
};

export const fetchTaskCount = (user) => {
  let today = new Date().toISOString();
  return api(`taskCounts?createdBy_eq=${user.id}&dueOn=${today}`, null, 'get');
};

export const createPanel = (dashboardId, payload) => {
  return api(`dashboard/${dashboardId}/panel`, payload, 'post');
};

export const editPanel = (panelId, payload) => {
  return api(`dashboard/panel/${panelId}`, payload, 'put');
};

export const createDashboard = (payload) => {
  return api(`dashboard`, payload, 'post');
};

export const updateDashboard = (boardId, payload) => {
  return api(`dashboard/${boardId}`, payload, 'put');
};

export const deleteDashboard = (boardId) => {
  return api(`dashboard/${boardId}`, null, 'delete');
};

export const deletePanel = (panelId) => {
  return api(`dashboard/panel/${panelId}`, null, 'delete');
};

export const fetchDashboards = () => {
  return api(`dashboard`, null, 'get');
};

export const fetchPanels = (dashboardId) => {
  return api(`dashboard/${dashboardId}/panel`, null, 'get');
};

export const fetchTemplatesLookup = async (search, type, size = 10) => {
  let res = [];
  const filters = {
    _from: 0,
    _size: size,
    _search: search,
    type_eq: type,
    status_eq: 'active',
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  // filter = '';
  try {
    const response = await api(`/template/my${filter}`, null, 'get');
    res = response.templates;
    const privateTemplates = [];
    const teamTemplates = [];
    const organisationTemplates = [];
    res?.flatMap((template) => {
      if (template.permissionType === 'team')
        teamTemplates.push({ ...template, permissionType: 'team', accessType: 'Team' });
      else if (template.permissionType === 'private')
        privateTemplates.push({ ...template, permissionType: 'private', accessType: 'Private' });
      else
        organisationTemplates.push({
          ...template,
          permissionType: 'organisation',
          accessType: 'Organisation',
        });
    });
    return [...privateTemplates, ...teamTemplates, ...organisationTemplates];
  } catch (error) {
    return res;
  }
};

export const fetchTags = () => {
  return api(`automation/tags`, null, 'get');
};

export const fetchTenantConfig = (tenantId) => {
  return api(`tenant/${tenantId}`, null, 'get');
};

export const updateTenant = (tenantId, data) => {
  return api(`tenant/${tenantId}`, data, 'put');
};

export const fetchAiPromptForm = () => {
  return api('aipromptform', null, 'get');
};

export const fetchTenantFields = async (crmName) => {
  const defaultFields = await api(
    `/crmdefault?${crmName ? `crm=${crmName}&` : ``}type=default_fields`,
    null,
    'get',
  );
  const defaultMappings = await api(
    `/crmdefault?${crmName ? `crm=${crmName}&` : ``}type=default_mapping`,
    null,
    'get',
  );
  const crmConfig = {};
  defaultFields?.data?.forEach((ele) => (crmConfig[ele.crm] = {}));
  defaultFields?.data?.forEach((ele) => (crmConfig[ele.crm]['defaultFields'] = ele.config || {}));
  defaultMappings?.data?.forEach((ele) => {
    const config = ele.config;
    const keys = Object.keys(config);
    keys.map((key) => {
      const data = config[key].map((ele) => ({ ...ele, status: true }));
      config[key] = data;
    });
    crmConfig[ele.crm]['defaultMappings'] = config || {};
  });
  return crmConfig;
};

export const fetchCRMNotes = async (crmName) => {
  return await api(`crmdefault/get${crmName}notes`, null, 'get');
};

export const fetchTypesNotes = async () => {
  return await api(`/note/integration/types`, null, 'get');
};

export const fetchSrNotes = async () => {
  return await api(`crmdefault/getsrnotestypes`, null, 'get');
};

export const fetchTenantData = async (id) => {
  return await api(`crmconfig?tenantIntegration_eq=${id}`, null, 'get');
};

export const RefreshSyncData = async (id) => {
  return await api(`crmconfig/refreshsync/${id}`, null, 'get');
};

export const mappedNote = async (id, notes) => {
  return await api(`crmconfig/crmcustomnotes/${id}`, { notes }, 'post');
};

export const addNoteAction = async (id, note) => {
  return await api(`/note/types`, note, 'post');
};

export const saveTenantConfig = (payload) => {
  return api(`crmconfig`, payload, 'post');
};

export const updateTenantConfig = (id, payload) => {
  return api(`crmconfig/${id}`, payload, 'put');
};

export const fetchReplacementVariables = () => {
  return api(`automation/replacement-variables`, null, 'get');
};

export const fetchTemplates = (type, admin, status = undefined) => {
  let query = '';
  const params = {
    type,
    admin,
    ...(status && { status }),
  };
  if (!_.isEmpty(params)) {
    query = `?${convertObjectToQuerystring(params)}`;
  }
  return api(`automation/template${query}`, null, 'get');
};

export const fetchTemplate = (templateId) => {
  return api(`automation/template/${templateId}`, null, 'get');
};

export const getTaskCategories = () => {
  return api(`automation/get-task-cates`, null, 'get');
};

export const fileUpload = (uuid, file, onProgress) => {
  let formData = new FormData();
  formData.append('file', file);
  // formData.append('uuid', uuid);
  return api(`upload`, formData, 'post', true, onProgress);
};

export const deleteFile = (id) => {
  return api(`upload/${id}`, null, 'delete');
};

export const fetchFile = (id) => {
  return api(`upload/${id}`, null, 'get');
};

export const fetchFiles = (uuid) => {
  return [];
  return api(`automation/files?uuid=${uuid}`, null, 'get');
};

export const createAttribute = (payload) => {
  return api(`attributes`, payload, 'post');
};

export const getAttribute = (attributeId) => {
  return api(`attributes/${attributeId}`, null, 'get');
};

export const updateAttribute = (attributeId, payload) => {
  return api(`attributes/${attributeId}`, payload, 'put');
};

export const deleteAttribute = (attributeId) => {
  return api(`attributes/${attributeId}`, null, 'delete');
};

export const getLookup = (lookupId) => {
  return api(`lookup/${lookupId}`, null, 'get');
};

export const checkEmailInEnrollment = (email) => {
  return api(`emails/checkEnrollment`, { emailId: email }, 'post');
};

export const createLookup = (payload) => {
  return api(`lookup`, payload, 'post');
};

export const postLookupItems = (lookupId, payload) => {
  return api(`lookup/${lookupId}/items`, payload, 'post');
};

export const fetchSequencesReport = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`stats/sequencereport${filter}`, null, 'get');
};

export const fetchTaskReport = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`stats/task${filter}`, null, 'get');
};

export const fetchContactsReport = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`stats/contact${filter}`, null, 'get');
};

export const fetchEmailReport = (filters) => {
  let filter = `?${convertObjectToQuerystring(filters)}`;
  return api(`stats/email${filter}`, null, 'get');
};

export const fetchActivityReport = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`stats/activityreport${filter}`, null, 'get');
};

export const getSequenceDashboardStats = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`sequence/dashboardStats${filter}`, null, 'get');
};

export const getSequenceDashboardUserStats = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`user/dashboardStats${filter}`, null, 'get');
};

export const getSequenceDashboardTeamStats = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`team/dashboardStats${filter}`, null, 'get');
};

export const getChartDataDashboard = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`board/sequenceReport${filter}`, null, 'get');
};

export const getActivityDashboardStats = (filters) => {
  let filter = '';
  if (filters && !isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`user/activityDashboardStats${filter}`, null, 'get');
};

export const seqDashboardStats = (query) => {
  return api(`sequence/dashboardStats?${query}`, null, 'get');
};

export const seqDashboardUserStats = (query) => {
  return api(`user/dashboardStats?${query}`, null, 'get');
};

export const seqDashboardTeamStats = (query) => {
  return api(`team/dashboardStats?${query}`, null, 'get');
};

export const seqDashboardReportStats = (query) => {
  return api(`board/sequenceReport?${query}`, null, 'get');
};

export const activityDashboardStats = (query) => {
  return api(`user/activityDashboardStats?${query}`, null, 'get');
};

export const getChartDataActivityDashboard = (query) => {
  return api(`board/activityReport?${query}`, null, 'get');
};
