import Sequences from '../container/Sequences';
import Sequence from '../container/Sequence';

export default [
  {
    title: 'Sequences',
    component: Sequences,
    url: '/sequences',
    exact: true,
    auth: true,
  },
  {
    title: 'Create sequence',
    component: Sequence,
    url: '/sequence',
    exact: true,
    auth: true,
  },
  {
    title: 'Automated email',
    component: Sequence,
    url: '/sequence/:id',
    exact: true,
    auth: true,
  },
  {
    title: 'Automated email',
    component: Sequence,
    url: '/sequence/:id/:tab',
    exact: true,
    auth: true,
  },
];
