import { Grid, Stack, Box } from '@mui/material';
import {
  BlackChip,
  BlackChipText,
  CustomerBlockTitleText,
  CustomerCardBlock,
  EmptyContent,
  EmptyCustomerCard,
  EmptyICPCard,
  EmptyICPTitle,
  EmptyListContent,
  FieldChip,
  FieldChipText,
  ICPFieldViews,
} from './style';
import images from 'src/config/images';
import { Button } from '../../../../components/shared';
import { emptyICPFieldList } from './utils';

const EmptyIcp = ({ setShowICPForm }) => {
  return (
    <>
      <EmptyICPCard>
        <Grid container columnSpacing={'40px'}>
          <Grid item xs={6}>
            <Stack gap={'24px'} alignItems={'flex-start'}>
              <EmptyICPTitle>
                Create your ideal customer profile and zero in
                <br /> on top prospects
              </EmptyICPTitle>
              <EmptyContent>With your ICP, you’ll be able to:</EmptyContent>
              <Stack gap={'16px'}>
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                  <img src={images.icons.checkBlueIcon} alt={''} />
                  <EmptyListContent>
                    Focus your strategies on specific, high-value prospects{' '}
                  </EmptyListContent>
                </Stack>
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                  <img src={images.icons.checkBlueIcon} alt={''} />
                  <EmptyListContent>
                    Allow AI to create more personalised messaging
                  </EmptyListContent>
                </Stack>
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                  <img src={images.icons.checkBlueIcon} alt={''} />
                  <EmptyListContent>
                    Enable AI to automatically flag ICP matching prospects
                  </EmptyListContent>
                </Stack>
                <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
                  <img src={images.icons.checkBlueIcon} alt={''} />
                  <EmptyListContent>Practice with ICP specific coaching sessions</EmptyListContent>
                </Stack>
              </Stack>
              <Box mt={2}>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() =>
                    setShowICPForm({ isOpen: true, edit: false, clone: false, id: null })
                  }
                >
                  Get started now
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <EmptyCustomerCard>
              <CustomerCardBlock>
                <BlackChip>
                  <BlackChipText>Customer</BlackChipText>
                </BlackChip>
                <CustomerBlockTitleText>Decision Makers in London</CustomerBlockTitleText>
              </CustomerCardBlock>
              <ICPFieldViews>
                {emptyICPFieldList?.map((field) => (
                  <FieldChip>
                    <img src={images.icp[field?.icon]} alt={''} />
                    <FieldChipText>{field?.label}</FieldChipText>
                  </FieldChip>
                ))}
              </ICPFieldViews>
            </EmptyCustomerCard>
          </Grid>
        </Grid>
      </EmptyICPCard>
    </>
  );
};
export default EmptyIcp;
