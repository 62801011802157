import React, { useState, useMemo } from 'react';
import useRequest from 'src/components/hooks/useRequest';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import { AppGrid } from 'src/components/App';
import FilterBar from 'src/components/lms/Filterbar';
import Icon from 'src/components/lms/Icon';

import { fetchUsersReport } from '../../api/LMSApis';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';

import { useTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getRelativeDateRange } from 'src/utils/dateUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import usePersistState from 'src/components/hooks/usePersistState';

function UsersReport() {
  const [filters, setFilters] = usePersistState('lmsUsersReport', {
    createdAt: { id: 'last30Days', name: 'Last 30 days' },
  });

  const [paging, setPaging] = useState({
    pageNo: 0,
    perPage: 10,
  });

  let payload = {
    ...filters,
    _from: paging.pageNo * paging.perPage,
    _size: paging.perPage,
    ...(filters?.team && filters?.team?.id ? { team: filters?.team?.id } : {}),
  };

  if (filters?.createdAt && !isEmpty(filters?.createdAt)) {
    const dateRange = getRelativeDateRange({ ...filters?.createdAt });
    payload = {
      ...payload,
      updatedAt_gte: dateRange.start,
      updatedAt_lte: dateRange.end,
    };
  }
  delete payload.createdAt;

  const filtersAvailable = [];
  const { palette } = useTheme();

  const [data, { loading, error, refetch }] = useRequest(fetchUsersReport, payload);
  const usersData = data?.arr || [];

  const handleFilter = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setFilters({
        ...filters,
        [key]: value,
      });
    } else {
      const prev = JSON.parse(JSON.stringify(filters));
      delete prev[key];
      setFilters(prev);
    }
  };

  const filtersArr = [
    {
      key: 'team',
      title: 'Team',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      icon: <Icon name="team" size={20} />,
      remoteMethod: fetchTeamsLookup,
    },
    {
      key: 'createdAt',
      title: 'Date',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'last30Days', name: 'Last 30 days' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      icon: <AccessTimeIcon />,
      isPrimary: true,
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        label: 'Name of User',
        name: 'fname',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: '150px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              minWidth: '150px',
              textTransform: 'capitalize',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            return (
              <Typography color="primary">
                {row.fname} {row.lname}
              </Typography>
            );
          },
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="roleplay" color={palette.secondary.main} size={20} />
            <Typography variant="body2">Roleplays</Typography>
          </Stack>
        ),
        name: 'rolePlays',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '120px', minWidth: '120px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '120px',
              minWidth: '120px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="score" color={palette.secondary.main} size={24} />
            <Typography variant="body2">Average score</Typography>
          </Stack>
        ),
        name: 'rolePlays',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '140px', minWidth: '140px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '140px',
              minWidth: '140px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            const avg = row.rolePlays ? Math.ceil(row.totalScore / row.rolePlays) : 0;
            return <Typography color="primary">{avg}</Typography>;
          },
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
            <Icon name="videos" color={palette.secondary.main} size={20} />
            <Typography variant="body2">Videos watched</Typography>
          </Stack>
        ),
        name: 'videosWatched',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '140px', minWidth: '140px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '140px',
              minWidth: '140px',
              textTransform: 'capitalize',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        label: (
          <Stack direction="row" gap={1} alignItems="center">
            <Icon name="activity" color={palette.secondary.main} size={20} />
            <Typography variant="body2">Recent activity</Typography>
          </Stack>
        ),
        name: 'rolePlays',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '160px', minWidth: '160px', textTransform: 'capitalize' },
          }),
          setCellProps: () => ({
            style: {
              maxWidth: '160px',
              minWidth: '160px',
              textTransform: 'capitalize',
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = usersData[dataIndex];
            return (
              <Typography color="textSecondary" variant="body2">
                {row?.lastActivityDate ? moment(row.lastActivityDate).format(dateTimeFormat) : '-'}
              </Typography>
            );
          },
        },
      },
    ];
  }, [usersData]);

  const handleTableChange = ({ pageNo, perPage }) => {
    setPaging({
      pageNo,
      perPage,
    });
  };

  const total = data?.total?.value || 0;

  return (
    <>
      <Box mt={2} mb={2} zIndex={999999} alignItems="center">
        <FilterBar
          showSearch={true}
          filters={filtersArr}
          key="completed-filters"
          value={filters}
          onChange={handleFilter}
        />
      </Box>
      <AppGrid
        selectableRows="none"
        columns={columns}
        data={usersData}
        filters={filtersAvailable}
        appliedFilters={filters}
        onTableChange={handleTableChange}
        loading={loading}
        pagingType="links"
        stickyCol={false}
        options={{
          pagination: true,
          page: paging?.pageNo,
          rowsPerPage: paging?.perPage,
          count: total,
          serverSide: true,
          searchPlaceholder: 'Search invoice',
          searchText: filters?._search ? filters._search : '',
          search: false,
        }}
      />
    </>
  );
}

export default UsersReport;
