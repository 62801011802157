import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import SummaryReportCardContent from './SummaryReportCardContent';

function SummaryReportCards({ sequence, loading }) {
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const Next = () => {
    return (
      <Arrow
        style={{
          position: 'absolute',
          top: '46%',
          right: '-30px',
          zIndex: 1,
          borderRadius: '50%',
          color: 'rgba(0, 0, 0, 0.23)',
        }}
        onClick={() => {
          sliderRef?.current?.slickNext();
        }}
        disabled={currentIndex === 1}
      >
        <ChevronRightIcon
          sx={{
            color: 'rgba(0, 0, 0, 0.23) !important',
            fill: 'rgba(0, 0, 0, 0.23) !important',
            cursor: 'pointer',
          }}
        />
      </Arrow>
    );
  };

  const Prev = () => {
    return (
      <Arrow
        style={{
          position: 'absolute',
          top: '46%',
          left: '-30px',
          zIndex: 1,
          borderRadius: '50%',
          color: 'rgba(0, 0, 0, 0.23)',
        }}
        onClick={() => {
          sliderRef?.current?.slickPrev();
        }}
        disabled={currentIndex === 0}
      >
        <ChevronLeftIcon
          sx={{
            color: 'rgba(0, 0, 0, 0.23) !important',
            fill: 'rgba(0, 0, 0, 0.23) !important',
            cursor: 'pointer',
          }}
        />
      </Arrow>
    );
  };

  const settings = {
    dots: false,
    // adaptiveHeight: true,
    speed: 500,
    slidesToShow: 6,
    // variableWidth: true,
    slidesToScroll: 6,
    infinite: false,
    nextArrow: <Next />,
    prevArrow: <Prev />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  // var settings = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 4,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <div className="slider-container seq-slider" style={{ maxWidth: 'calc(100vw - 326px)' }}>
      <Slider
        arrows={true}
        afterChange={setCurrentIndex}
        slidesPerView="auto"
        {...settings}
        ref={sliderRef}
      >
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          toolTipTitle={'This shows the number of enrollments'}
          type="text"
          fill="#1976D2"
          title="Enrollments"
          value={sequence?.prospectEnrolled?.value || 0}
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#1976D2"
          title="Active"
          value={sequence?.prospectsInProgress?.value || 0}
          percentage={sequence?.prospectsInProgress?.progress || 0}
          total={100}
          toolTipTitle={'The number of contacts currently in progress.'}
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#42C340"
          title="Opened"
          value={sequence?.prospectsOpened?.value || 0}
          percentage={sequence?.prospectsOpened?.progress || 0}
          total={100}
          toolTipTitle={
            'Based on the number contacted, this shows the number of prospects that have opened an email where open tracking has been enabled.'
          }
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#42C340"
          title="Clicked"
          value={sequence?.prospectsClicked?.value || 0}
          percentage={sequence?.prospectsClicked?.progress || 0}
          total={100}
          toolTipTitle={
            'Based on the number contacted, this shows the number of prospects that have click an email where click tracking has been enabled.'
          }
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#E69904"
          title="Replied"
          value={sequence?.prospectsReplied?.value || 0}
          percentage={sequence?.prospectsReplied?.progress || 0}
          total={100}
          toolTipTitle={
            'Based on the number contacted, this shows the number of prospects that have replited to an email .'
          }
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#033EAC"
          title="Interested"
          value={sequence?.prospectsInterested?.value || 0}
          percentage={sequence?.prospectsInterested?.progress || 0}
          total={100}
          toolTipTitle={
            'Based on the number contacted, this shows the number of prospects that have been marked as interested.'
          }
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          fill="#033EAC"
          title="Not interested"
          value={sequence?.prospectsNotInterested?.value || 0}
          percentage={sequence?.prospectsNotInterested?.progress || 0}
          total={100}
          toolTipTitle={
            'Based on the number contacted, this shows the number of prospects that have been marked as not interested.'
          }
        />
        <SummaryReportCardContent
          loading={loading.sequenceReport}
          type="text"
          fill="#1976D2"
          title="Error"
          value={sequence?.prospectsError?.value || 0}
          toolTipTitle={'The number of contacts currently in error status.'}
        />
        <SummaryReportCardContent withoutcontent />
      </Slider>
    </div>
  );
}

export default SummaryReportCards;

const Arrow = styled(IconButton)`
  padding: 4px;
  z-index: 99999999999;
  & svg {
    color: ${(props) => (props.disabled ? '#ffffff24' : '#ffffff80')};
    fill: ${(props) => (props.disabled ? '#ffffff24' : '#ffffff80')};
  }
`;

const StyledCardBox = styled(Box)`
  width: 256px;
  height: 201px;
  border-radius: 5.175px;
  border: 1.035px solid rgba(0, 0, 0, 0.12);
  background: #fff;
`;
