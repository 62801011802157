import React from 'react';
import { debounce, uniqueId } from 'lodash';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { Button, Form } from 'src/components/shared';
import darkTheme from 'src/config/darkTheme';

function DropdownV2({
  label = '',
  options = [],
  startIcon = <React.Fragment />,
  key = '',
  onChange = () => {},
  onBlur = () => {},
  onClear = () => {},
  optLabel = '',
  optValue = '',
  selected = null,
  remoteMethod = null,
  searchable = false,
  className = '',
  multiple = false,
  searchPlaceholder = 'Search...',
  tooltip = '',
  ButtonStyle,
  MenuStyle,
  sx,
  ...props
}) {
  selected = selected ? selected : multiple ? [] : {};

  const searchRef = React.useRef();
  const id = uniqueId(`filter-picker-${key}`);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [remoteOptions, setRemoteOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState(selected);

  const isSelected = multiple ? selected.length > 0 : !!selected?.[optValue];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (typeof remoteMethod === 'function') fetchRemoteData('');
  };

  const onSearchInputChange = React.useCallback(
    debounce(async () => {
      fetchRemoteData(searchRef.current?.value || '');
    }, 500),
    [],
  );

  const fetchRemoteData = async (val) => {
    try {
      setLoading(true);
      const data = await new Promise(async (resolve, reject) => {
        try {
          resolve(await remoteMethod(val));
        } catch (error) {
          reject(error);
        }
      });
      setRemoteOptions(data || []);
      setLoading(false);
    } catch (error) {
      setRemoteOptions([]);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const allOptions = React.useMemo(() => {
    return (
      [...options, ...remoteOptions].sort((a, b) => {
        const isCheckedA = selectedOptions?.length
          ? selectedOptions.some((item) => item[optValue] === a[optValue])
          : false;
        const isCheckedB = selectedOptions?.length
          ? selectedOptions.some((item) => item[optValue] === b[optValue])
          : false;
        if (isCheckedA === isCheckedB) {
          return 0;
        }
        return isCheckedA ? -1 : 1;
      }) || []
    );
  }, [options, remoteOptions, selectedOptions]);

  return (
    <Stack sx={sx} className={className}>
      <Tooltip title={tooltip}>
        <Button
          aria-controls={id}
          variant={isSelected ? 'contained' : 'outlined'}
          color={isSelected ? 'secondary' : 'primary'}
          disableElevation={true}
          startIcon={startIcon}
          endIcon={
            isSelected ? (
              <Stack direction="row" gap="6px" zIndex="10">
                <Typography>|</Typography>
                <ButtonBase
                  onClick={() => {
                    onClear();
                    setSelectedOptions(multiple ? [] : {});
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </ButtonBase>
              </Stack>
            ) : (
              <React.Fragment />
            )
          }
          sx={{
            padding: '8px 16px',
            position: 'relative',
            borderRadius: '48px',
            color: isSelected ? darkTheme.palette.secondary.main : darkTheme.palette.text.secondary,
            background: isSelected ? 'rgba(255, 212, 126, 0.12)' : 'transparent',
            '&:hover': {
              background: 'rgba(160, 190, 217, 0.08) !important',
            },
            ...ButtonStyle,
          }}
        >
          <ButtonBase
            sx={{
              position: 'absolute',
              borderRadius: '48px',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 8,
            }}
            onClick={handleClick}
          ></ButtonBase>
          <Typography noWrap sx={{ maxWidth: '144px' }} variant="body2">
            {isSelected
              ? selected.length
                ? selected?.map((item) => item?.[optLabel])?.join(', ')
                : selected[optLabel] || label
              : label}
          </Typography>
        </Button>
      </Tooltip>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: '375px',
            maxWidth: '375px',
            maxHeight: '500px',
            overflow: 'hidden',
            '& ul': {
              padding: 0,
            },
          },
          ...MenuStyle,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'sticky',
            zIndex: 10,
            backgroundColor: '#15222E',
            padding: '20px',
          }}
        >
          <Stack width={'100%'} gap={'4px'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack gap={'8px'} direction={'row'} alignItems={'center'}>
                {startIcon}
                <Typography fontSize={18}>{label}</Typography>
              </Stack>
              <ButtonBase onClick={handleClose}>
                <CloseIcon />
              </ButtonBase>
            </Stack>
            {searchable && (
              <Form initialValues={{ [`menu-${id}-input`]: '' }}>
                <Form.Field.InputDebounced
                  name={`menu-${id}-input`}
                  inputRef={searchRef}
                  placeholder={searchPlaceholder}
                  variant="outlined"
                  size="small"
                  autoFocus
                  fullWidth
                  onChange={onSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : searchRef?.current ? (
                          <IconButton
                            onClick={() => {
                              searchRef.current.value = '';
                            }}
                          >
                            <CloseIcon style={{ color: 'white' }} />
                          </IconButton>
                        ) : (
                          <SearchIcon sx={{ fill: 'white' }} />
                        )}
                      </>
                    ),
                  }}
                />
              </Form>
            )}
          </Stack>
        </Stack>

        <Stack>
          {loading ? (
            <MenuItem>
              <Typography>Loading...</Typography>
            </MenuItem>
          ) : allOptions.length > 0 ? (
            <div style={{ maxHeight: '270px', overflowY: 'auto' }}>
              {allOptions.map((option, index) => {
                const highlightSearchText = (text) => {
                  const parts = searchRef?.current?.value
                    ? text?.split(new RegExp(`(${searchRef?.current?.value})`, 'gi'))
                    : [text];
                  return (
                    <Typography
                      sx={{
                        maxWidth: '280px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                      }}
                    >
                      {parts?.map((part, i) =>
                        part?.toLowerCase() === searchRef?.current?.value?.toLowerCase() ? (
                          <Typography sx={{ fontWeight: 'bold' }} key={i}>
                            {part}
                          </Typography>
                        ) : (
                          part
                        ),
                      )}
                    </Typography>
                  );
                };
                return (
                  <MenuItem
                    key={`${id}-option-${index}`}
                    value={option[optValue]}
                    onClick={() =>
                      setSelectedOptions((prevState) => {
                        if (multiple) {
                          const isExistIndex = prevState?.findIndex(
                            (item) => item?.[optValue] === option?.[optValue],
                          );
                          if (isExistIndex >= 0) {
                            prevState.splice(isExistIndex, 1);
                            return [...prevState];
                          } else return [...prevState, option];
                        } else return option;
                      })
                    }
                    dense={multiple}
                  >
                    <Typography
                      noWrap
                      sx={{
                        maxWidth: '345px',
                        width: '100%',
                        padding: '6px 12px 6px 0',
                        textTransform: 'capitalize',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label={highlightSearchText(option[optLabel])}
                          checked={
                            multiple
                              ? !!selectedOptions?.find(
                                  (item) => item?.[optValue] === option?.[optValue],
                                )?.[optValue]
                              : selectedOptions?.[optValue] === option?.[optValue]
                          }
                          sx={{ height: '24px' }}
                        />
                      </FormGroup>
                    </Typography>
                  </MenuItem>
                );
              })}
            </div>
          ) : (
            <MenuItem disabled>
              <Typography>No options.</Typography>
            </MenuItem>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'sticky',
            zIndex: 10,
            backgroundColor: '#15222E',
            padding: '20px',
          }}
        >
          <Button
            onClick={() => {
              onClear();
              setSelectedOptions(multiple ? [] : {});
            }}
            color="secondary"
          >
            Clear All
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onChange(selectedOptions);
              handleClose();
            }}
            color="secondary"
          >
            Apply
          </Button>
        </Stack>
      </Menu>
    </Stack>
  );
}

export default DropdownV2;
