import axios from 'axios';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const checkUserVerificationStatus = (uid, tid) => {
  return api(`/user/verificationStatus?uid=${uid}&tid=${tid}`, null, 'get');
};

export const registerUser = (data) => {
  return api(`/user/register`, data, 'post');
};

export const signupjourney = (data) => {
  return api(`/signupjourney`, data, 'post');
};

export const updateSignupjourney = (data) => {
  return api(`/signupjourney/${data?.id}`, data, 'put');
};

export const signUpJourneyGetData = async (email) => {
  return await api(`/signupjourney?email=${email}`, 'get');
};

export const registerUserWithThirdPartyServices = ({ tempID, tenant }) => {
  return api(`/auth/callback/login?tempID=${tempID}&tenant=${tenant}`, null, 'get');
};

export const registerUserSetPassword = (uid, data) => {
  return api(`/user/updatepassword/${uid}`, data, 'put');
};

export const loginUser = (data) => {
  return api('/user/login', data, 'post');
};

export const getProfile = () => {
  return api('/user/me', null, 'get');
};

export const getUsers = () => {
  return api('/user', null, 'get');
};

export const forgotPassword = (payload) => {
  return api('/user/forgotpassword', payload, 'post');
};

export const resetPassword = (payload, token) => {
  let { userId, password } = payload;
  let data = { password: password };
  return api(`/user/updatepassword/${userId}`, data, 'put');
};

export const updateUser = (payload) => {
  return api(`/user/${payload.id}`, payload.data, 'put');
};

export const getUserById = (id) => {
  return api(`/user/${id}`, null, 'get');
};

export const resendVerifictionEmail = (id) => {
  return api(`/user/resendverificationemail/${id}`, null, 'get');
};

export const fetchTeams = (teamsTotal) => {
  return api(`/team?status_eq=active&_size=${teamsTotal}`, null, 'get');
};

export const fetchOffices = (officeTotal) => {
  return api(`/office?status_eq=active&_size=${officeTotal}`, null, 'get');
};

export const setNlToken = (code, userid) => {
  return api(`/user/nltokanmaker?code=${code}&uid=${userid}`, null, 'get');
};

export const disconnectIntegration = (id, ststus) => {
  return api(`/user/nlrevoke`, null, 'get');
};

export const uploadImage = (file) => {
  return api(`/upload`, file, 'post');
};

export const fetchAdminDomainLookup = async (search, setDomainOption = () => {}) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/roleplay/domains${filter}`, null, 'get');
  const optionList = (res.domains || [])?.map(({ id, name }) => {
    return { label: name, value: id };
  });
  if (setDomainOption) {
    setDomainOption(optionList);
  }
  return optionList;
};

export const fetchAdminIndustryLookup = async (search, setIndustryOptions = () => {}) => {
  let res = [];
  const filters = {
    _search: search,
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  res = await api(`/roleplay/industry${filter}`, null, 'get');
  const optionList = (res.industries || [])?.map(({ id, name }) => {
    return { label: name, value: id };
  });
  if (setIndustryOptions) {
    setIndustryOptions(optionList);
  }
  return optionList;
};

export const loginWithGoogle = (params = '') => {
  return api(`/auth/google${params}`, null, 'get');
};

export const getUserDataViaGoogleLogin = (params = '') => {
  return api(`/auth/callback/login?tempID=${params}`, null, 'get');
};

export const loginWithMicrosoft = (params = '') => {
  return api(`/auth/ms/login${params}`, null, 'get');
};

export const enrollAssignmentPermissions = (payload) => {
  return api('/enrolmentAssignmentPermissions', payload, 'post');
};

export const enrollAssignmentsDeleted = (payload) => {
  return api('/enrolmentAssignmentPermissions/bulkdelete', payload, 'delete');
};

export const getEnrollUser = (id) => {
  return api(`/enrolmentAssignmentPermissions?sender=${id}`, null, 'get');
};

export const getAssignerUser = async (id) => {
  const response = await api(
    `/enrolmentAssignmentPermissions?assigner=65ae10effcd59eae7014cf65`,
    null,
    'get',
  );
  if (response?.length) {
    return response.map((item) => ({
      ...item?.sender,
      name: item?.sender?.fname + ' ' + item?.sender?.lname,
    }));
  }
  return [];
};

export const enrollAssignmentDelete = (id) => {
  return api(`/enrolmentAssignmentPermissions/${id}`, null, 'delete');
};

export const allEnrollAssignmentDelete = (id) => {
  return api(`/enrolmentAssignmentPermissions/bulkdelete`, id, 'delete');
};

export const verifyUserAndGetData = (ac = '', uid = '') => {
  return api(`/verify/verifyuser?ac=${ac}&u=${uid}&authcheck=true`, null, 'get');
};

export const loginLinkedIn = async (userId, username, password, userAgent, screenResolution) => {
  const response = await axios.get(`https://ipapi.co/json/`);
  return api(
    `/linkedin/connect`,
    {
      userId,
      username,
      password,
      location: response?.data?.country_name?.toLowerCase() ?? '',
      userAgent: userAgent || navigator.userAgent,
      screenResolution: {
        width: screenResolution?.width || window.screen.width,
        height: screenResolution?.height || window.screen.height,
        colorDepth: screenResolution?.colorDepth || window.screen.colorDepth,
        pixelDepth: screenResolution?.pixelDepth || window.screen.pixelDepth,
        availWidth: screenResolution?.availWidth || window.screen.availWidth,
        availHeight: screenResolution?.availHeight || window.screen.availHeight,
      },
    },
    'post',
  );
};

export const checkLinkedInOtp = (linkedInId, otp) => {
  return api(`/linkedin/connect/sendotp/${linkedInId}`, { otp }, 'put');
};

export const getLinkedInStatus = (linkedInId) => {
  return api(`/linkedin/connect/status/${linkedInId}`, null, 'get');
};

export const setLinkedInAutomationSetting = (settings) => {
  return api(`/linkedin/automation/settings`, settings, 'put');
};

export const getVoiceTabs = () => {
  return api('/toneofvoice', null, 'get');
};

export const fetchVoices = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/toneofvoice${filter}`, null, 'get');
};

export const saveVoiceData = async (data) => {
  const userData = await api('/toneofvoice', data, 'post');
  return userData;
};
export const fetchVoiceById = async (id) => {
  return await api(`/toneofvoice/${id}`, null, 'get');
};

export const updateVoiceStatus = (rowData) => {
  const payload = {
    status: rowData.status,
  };
  return api(`/toneofvoice/${rowData.id}`, payload, 'put');
};

export const updateVoiceData = (rowData) => {
  return api(`/toneofvoice/${rowData.id}`, rowData.data, 'put');
};

export const saveICPData = async (data) => {
  return await api('/icp', data, 'post');
};

export const fetchICP = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/icp${filter}`, null, 'get');
};

export const fetchICPById = async (id) => {
  return await api(`/icp/${id}`, null, 'get');
};

export const updateICPData = async (data) => {
  return await api(`/icp/${data.id}`, data, 'put');
};

export const deleteICPData = async (id) => {
  return await api(`/icp/${id}`, null, 'delete');
};

