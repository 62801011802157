import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CardContent, CircularProgress, Box } from '@mui/material';
import { PageTitle } from 'src/components/App';
import Contact from 'src/modules/contacts/components/Contact';

import TaskContactHead from '../../components/Task/TaskContactHead';
import TaskView from './TaskView';

import { toggleAddTaskForm, toggleContactDrawer } from 'src/modules/app/actions/appActions';
import {
  fetchTaskReplies,
  fetchTask,
  setTasksTab,
  putTask,
  sendTaskReply,
  putTaskReply,
  navigateNextTask,
  deleteTask,
} from '../../actions/taskActions';

import {
  fetchContact,
  fetchContactTask,
  fetchContactNote,
  fetchSequenceEnrollment,
  putContactEmail,
} from 'src/modules/contacts/actions/contactActions';

import { ContactSidebar, FixedTitleBar, ContactMainView } from './styles';
import { isNull, isEmpty } from 'lodash';

export const TaskContainer = (props) => {
  const contactId = props.match.params.id;
  const [height, setHeight] = useState();

  useEffect(() => {
    props.fetchTask(contactId);
  }, [props.match.params.id]);

  useEffect(() => {
    const warningElem = document.getElementById('warning-element');
    if (warningElem && warningElem?.offsetHeight) {
      setHeight(warningElem?.offsetHeight || 0);
    }
  }, []);

  useEffect(() => {
    if (props.task?.contact && props.contactnew.id !== props.task?.contact.id) {
      props.fetchContact(props.task?.contact.id);
      props.fetchContactTask(props.task?.contact.id);
      props.fetchContactNote(props.task?.contact.id);
      props.fetchSequenceEnrollment(props.task?.contact.id);
    }
  }, [props.task?.contact]);
  //props.task.status = 'pending';

  return (
    <>
      <PageTitle title="Task Details" />
      <FixedTitleBar>
        <CardContent sx={{ padding: '8px 16px' }}>
          {props.task && (
            <TaskContactHead contact={props.task?.contact} isLoading={props.isLoading} {...props} />
          )}
        </CardContent>
      </FixedTitleBar>
      <ContactSidebar height={height}>
        <Contact
          contact={props?.contactnew}
          sequenceEnrollment={props.sequenceEnrollment}
          isLoading={props.isLoading}
          showBack={false}
        />
      </ContactSidebar>
      <ContactMainView>
        {props.isLoading ? (
          <Box mt={14} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size="sm" color="secondary" />
          </Box>
        ) : (
          <>{props.task && <TaskView {...props} />}</>
        )}
      </ContactMainView>
    </>
  );
};

const mapStateToProps = (state) => ({
  contactnew: state.contacts.contact,
  contactTask: state.contacts.contactTask,
  contactNote: state.contacts.contactNote,
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  isLoading: state.contacts.loading.contact,
  task: state.tasks.task,
  currentTab: state.tasks.currentTab,
  taskReplies: state.tasks.taskReplies,
  user: state.auth.user,
  loader: state.tasks.loading,
  globals: state.app.globals,
  taskError: state.tasks.taskError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
  fetchContactTask: (contactId) => dispatch(fetchContactTask(contactId)),
  fetchContactNote: (contactId) => dispatch(fetchContactNote(contactId)),
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  //navigateNextTask: () => dispatch(navigateNextTask()),
  toggleContactDrawer: (contactId) => dispatch(toggleContactDrawer(contactId)),
  toggleAddTaskForm: (task = {}) => dispatch(toggleAddTaskForm(task)),
  setTasksTab: (tab) => dispatch(setTasksTab(tab)),
  fetchTask: (taskId) => dispatch(fetchTask(taskId)),
  fetchTaskReplies: (taskId, paging, filters) =>
    dispatch(fetchTaskReplies(taskId, paging, filters)),
  putTask: (id, task) => {
    return new Promise((resolve, reject) => {
      dispatch(putTask(id, task, resolve, reject));
    });
  },
  sendTaskReply: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(sendTaskReply(data, resolve, reject));
    });
  },
  putTaskReply: (id, data) => {
    return new Promise((resolve, reject) => {
      dispatch(putTaskReply(id, data, resolve, reject));
    });
  },
  putContactEmail: (contactId, taskId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putContactEmail(contactId, taskId, payload, resolve, reject));
    });
  },
  navigateNextTask: (view) => {
    return new Promise((resolve, reject) => {
      dispatch(navigateNextTask({ view, resolve, reject }));
    });
  },
  deleteTask: (taskId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteTask(taskId, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
