import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CompetitionsView from './CompetitionsView';
import { fetchCompetitionsList, setCompetitionsList } from '../../actions/competitionActions';

export const CompetitionsContainer = (props) => {
  useEffect(() => {
    props.fetchCompetitions(props?.competitions?.paging, props?.competitions?.filters);
  }, []);
  return <CompetitionsView {...props} />;
};

const mapStateToProps = (state) => ({
  competitions: state.competitions?.competitionsList,
  loading: state.competitions.loading.competitionsList,
  user: state.auth.user,
  fields: state.competitions.competitionList,
});

const mapDispatchToProps = (dispatch) => ({
  setCompetitions: (competition) => dispatch(setCompetitionsList(competition)),
  fetchCompetitions: (paging, filters, sort) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCompetitionsList(paging, filters, sort, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionsContainer);
