import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';

import { getExpertsLookup, getTagsLookup } from '../../api/LMSApis';

import HomeBannerSection from './HomeBannerSection';
import HomeSkeleton from '../home/HomeSkeleton';
import HomeGetStartedSection from './HomeGetStartedSection';
import HomeSessions from './HomeSessions';
import HomeTenantCourses from './HomeTenantCourses';
import AIpreview from '../AILearning/AIpreview';
import { Skeleton1, Skeleton2, Skeleton3 } from './HomeSkeleton';

import { useHomeProvider, withHomePageStore } from 'src/modules/lms/utils/homeStore';

export const LMSHome = (props) => {
  const [open, setOpen] = useState(false);
  const [allExperts, setAllExperts] = useState([]);

  const { sales, loading, filters, setFilters, tenantCourses } = useHomeProvider();

  const handleClose = () => {
    window.localStorage.setItem('ai', true);
    setOpen(false);
  };

  const clearTags = () => {
    setFilters((prevState) => ({
      ...prevState,
      tags: [],
    }));
  };

  const clearExps = () => {
    setFilters((prevState) => ({
      ...prevState,
      experts: [],
    }));
  };

  const reportsFilter = [
    {
      key: 'tags',
      title: 'All tags',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      customHeader: true,
      cancellable: false,
      onClear: clearTags,
      remoteMethod: async (value) => {
        return getTagsLookup(value);
      },
    },
    {
      key: 'experts',
      title: 'All experts',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      cancellable: false,
      apply: true,
      customHeader: true,
      onClear: clearExps,
      remoteMethod: async (value) => {
        return getExpertsLookup(value, 0, -1);
      },
    },
  ];

  useEffect(() => {
    if (!window.localStorage.getItem('ai')) setOpen(true);
    getExpertsLookup('', 0, -1).then(setAllExperts);
  }, []);

  const onFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return (
    <Box>
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FilterBar
          filters={reportsFilter?.map((filter) => {
            if (filter.key === 'experts')
              return {
                ...filter,
                title:
                  filters?.experts?.length === 0 || filters?.experts?.length === allExperts?.length
                    ? 'All experts'
                    : 'Experts',
              };
            else return filter;
          })}
          appliedFilters={filters}
          onChange={onFilterChange}
        />
      </Box>
      <Spacer x={2} y={2} />
      {tenantCourses.length > 0 && (
        <>
          <HomeTenantCourses />
          <Spacer x={2} y={2} />
        </>
      )}
      {(sales.component1.length > 0 || loading.component1) && (
        <>
          <Spacer x={2} y={2} />
          {loading.component1 ? (
            <HomeSkeleton />
          ) : (
            <HomeBannerSection
              sectionData={sales.component1}
              title={sales.component1Title}
              homePage={props.homePage}
              loading={false}
            />
          )}
        </>
      )}
      {(sales.component3.length > 0 || loading.component3) && (
        <>
          <Spacer x={2} y={2} />
          {loading.component3 ? (
            <Skeleton3 />
          ) : (
            <HomeSessions
              sessions={sales.component3}
              loading={false}
              title={sales?.component3Title || 'The sessions'}
            />
          )}
        </>
      )}

      {/*
      {loading.component2 ? (
        <Skeleton2 />
      ) : (
        <HomeGetStartedSection
          sectionData={sales.component2}
          title={sales.component2Title}
          homePage={props.homePage}
          loading={false}
        />
      )}
      <Spacer x={2} y={2} />
      <AIpreview open={open} handleClose={handleClose} user={props.user} /> */}
    </Box>
  );
};

export default LMSHome;
