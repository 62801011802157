import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './styles';

const ButtonLoader = ({ loading }) => {
  if (!loading) return null;
  return <CircularProgress color="inherit" size={20} />;
};

const ButtonComponent = forwardRef(
  (
    {
      sx = {},
      className,
      variant,
      size,
      fullWidth,
      disabled,
      color,
      iconButton,
      loading,
      blackButton,
      ...props
    },
    ref,
  ) => {
    const isDisabled = disabled || loading;

    const classes = useStyles();

    return (
      <React.Fragment>
        {iconButton ? (
          <IconButton
            className={`${className} ${blackButton ? classes.root : ''}`}
            disabled={isDisabled}
            color={color}
            onClick={() => {
              props.onClick();
            }}
            size={size}
            {...props}
            sx={{
              ...sx,
              borderRadius: '4px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.54)',
              ':hover': {
                color: '#031729',
              },
            }}
          >
            {loading ? (
              <ButtonLoader loading={loading} />
            ) : (
              <React.Fragment>{props.children}</React.Fragment>
            )}
          </IconButton>
        ) : (
          <Button
            style={sx}
            sx={{
              ...sx,
              cursor: 'pointer',
              textTransform: 'none',
              ':hover': {
                ...(variant === 'contained'
                  ? {
                      boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
                    }
                  : {}),
              },
            }}
            className={`${className} ${blackButton ? classes.root : ''}`}
            variant={variant}
            fullWidth={fullWidth}
            size={size}
            disabled={isDisabled}
            color={color}
            disableElevation
            {...props}
            endIcon={loading ? <ButtonLoader loading={loading} /> : props?.endIcon}
          >
            <React.Fragment>{props.children}</React.Fragment>
          </Button>
        )}
      </React.Fragment>
    );
  },
);

ButtonComponent.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  iconButton: PropTypes.bool,
  loading: PropTypes.bool,
};

ButtonComponent.defaultTypes = {
  className: undefined,
  variant: 'contained',
  size: 'medium',
  fullWidth: false,
  disabled: false,
  color: 'default',
  iconButton: false,
  loading: false,
};

export default ButtonComponent;
