import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { connect, useSelector } from 'react-redux';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { MainContainer, AppContainer, TabItem } from './LMSStyle';
import LMSHeader from 'src/modules/lms/components/LMSHeader';
import Sidebar from 'src/modules/lms/components/LMSSidebar';
import { useMemo } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { withHomePageStore, useHomeProvider } from 'src/modules/lms/utils/homeStore';
import { Modal } from 'src/components/shared';

function AppLMSLayout({ children, pathname, hasLmsAccess, hasOutreachAccess, ...props }) {
  let locations = useLocation();
  const history = useHistory();
  let noLayoutPaths = ['report', 'league'];
  const { sales, recruitment } = useHomeProvider();

  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.admin.admin.loading);
  const homePage = useSelector((state) => state.lms.homePage);

  const accessLevel =
      user?.acl && user?.acl?.accessLevel ? user?.acl?.accessLevel : '';
  const { subscriptionStatus } = user ?? {};

  const isAdmin = accessLevel.toLowerCase() === 'admin';
  const isNotActive = ['inactive', 'paused', 'cancelled'].includes(subscriptionStatus);
  const isSubscriptionFailed = ['failure', 'needs_attention'].includes(subscriptionStatus);

  useEffect(() => {
    if (user?.id) {
      if (isAdmin && isSubscriptionFailed) {
        history.push('/admin/billing/details');
      }
    }
  }, [user]);

  const noLayout = useMemo(() => {
    return noLayoutPaths.some((v) => locations.pathname.includes(v));
  }, [locations.pathname]);

  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
      <CssBaseline />
      <AppContainer path={locations.pathname} id={'lms'} className={'Inter-font-family'}>
        <>
          {noLayout ? null : (
            <Sidebar
              sales={sales.nav}
              recruitment={recruitment.nav}
              user={user}
              hasLmsAccess={hasLmsAccess}
              hasOutreachAccess={hasOutreachAccess}
            />
          )}

          <MainContainer>
            {noLayout ? null : <LMSHeader {...props} />}
            <Container maxWidth={'lg'}>
              <Box mt={10} sx={{ padding: '32px 0' }}>
                {children}
                {/* {noLayout ? null : children} */}
              </Box>
            </Container>
          </MainContainer>
        </>
        <Modal
          open={window?.location?.pathname?.split('/')?.[2] !== 'billing' && isAdmin && isNotActive}
          title={'Subscription Expired'}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '10px',
            },
            '& .MuiDialogTitle-root': {
              height: '65px',
              boxSizing: 'border-box',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              '& > div': {
                alignItems: 'baseline',
              },
              '& .MuiButtonBase-root': {
                display: 'none',
              },
            },
          }}
        >
          <Stack gap={'20px'} padding={'20px 16px 0'}>
            <Typography
              sx={{
                color: 'rgba(0, 0, 0, 0.87))',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            >
              It looks like your subscription has lapsed. To continue accessing all modules and
              unlocking the full potential of our platform, please contact your SuperReach Success
              Manager to renew!
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ width: 'fit-content', marginLeft: 'auto' }}
              onClick={() => {
                window.open('https://superreach.com/book-a-demo/', '_blank');
                history.push('/admin/billing/details');
              }}
            >
              Book a Renewal Call
            </Button>
          </Stack>
        </Modal>
      </AppContainer>
    </IntercomProvider>
  );
}

export default withHomePageStore(AppLMSLayout);
