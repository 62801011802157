import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Tooltip, Chip, Typography, Box, Link, Stack } from '@mui/material';
import { Spacer, Menu, Button } from 'src/components/shared';
import { getStepTitle, getStepIcon } from '../../utils/sequenceHelper';
import { green, red, blue } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import { HtmlTooltip } from './styles';

export const StatsText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${(props) =>
    props.count == 0 ? 'rgba(0, 0, 0, 0.87)' : props.count > 50 ? green[500] : red[500]} !important;
`;

const calculatePercentage = (value, total) => {
  let progress = 0;
  if (parseInt(value) > 0) {
    progress = Math.floor((100 * value) / total);
  }
  return ` (${progress}%)`;
};
const getPercentage = (value, total) => {
  let progress = 0;
  if (parseInt(value) > 0) {
    progress = Math.floor((100 * value) / total);
  }
  return progress;
};

export function useSequenceContactsColumns(gridData) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        label: 'Step type',
        name: 'eventType',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            return (
              <Box display="flex" alignItems="center">
                {getStepIcon(event?.eventType)}
                <Spacer x={1} y={1} />

                <Typography variant="body2" color="textPrimary">
                  {getStepTitle(event?.eventType)}
                </Typography>
              </Box>
            );
          },
        },
      },
      // {
      //   name: 'subject',
      //   label: 'Subject',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     draggable: false,
      //     setCellHeaderProps: (value) => ({
      //       style: { maxWidth: '200px' },
      //     }),
      //     setCellProps: () => ({
      //       style: { maxWidth: '200px' },
      //     }),
      //     customBodyRenderLite: (dataIndex) => {
      //       const data = gridData[dataIndex];
      //
      //       var subject =
      //         data?.emailTemplates && data?.emailTemplates.length
      //           ? data?.emailTemplates[0].subject
      //           : '';
      //       return (
      //         <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: subject }} />}>
      //           <Typography
      //             variant="body2"
      //             noWrap
      //             color="textSecondary"
      //             sx={{
      //               display: '-webkit-box',
      //               overflow: 'hidden',
      //               WebkitBoxOrient: 'vertical',
      //               WebkitLineClamp: 2,
      //             }}
      //             component="div"
      //           >
      //             <div dangerouslySetInnerHTML={{ __html: subject }} />
      //           </Typography>
      //         </HtmlTooltip>
      //       );
      //     },
      //   },
      // },
      {
        label: 'Enrolled',
        name: 'enrolled',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const totalEnrolments = event?.stats?.enrollStepContact || 0;
            if (
              event &&
              event?.eventType &&
              (event.eventType === 'wait' || event.eventType === 'endsquence')
            ) {
              return (
                <Typography color="textSecondary" variant="body2">
                  n/a
                </Typography>
              );
            }
            return (
              <StatsText count={0}>
                {totalEnrolments}
              </StatsText>
            );
          },
        },
      },
      // {
      //   label: 'Completed',
      //   name: 'total',
      //   options: {
      //     sort: false,
      //     draggable: false,
      //     customBodyRenderLite: (dataIndex) => {
      //       const event = gridData[dataIndex];
      //       const totalCompleted = event?.stats?.totalCompleted || 0;
      //       const totalCompletedRate = event?.stats?.totalCompletedRate || 0;
      //       if (
      //         event &&
      //         event?.eventType &&
      //         (event.eventType === 'wait' || event.eventType === 'endsquence')
      //       ) {
      //         return (
      //           <Typography color="textSecondary" variant="body2">
      //             n/a
      //           </Typography>
      //         );
      //       }
      //       return (
      //         <StatsText count={0}>
      //           {totalCompleted} ({totalCompletedRate}%)
      //         </StatsText>
      //       );
      //     },
      //   },
      // },
      {
        label: 'In progress',
        name: 'pending',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const inProgress = event?.stats?.inProgress || 0;
            const inProgressRate = event?.stats?.inProgressFilterRate || 0;
            if (
              event &&
              event?.eventType &&
              (event.eventType === 'wait' || event.eventType === 'endsquence')
            ) {
              return <Typography color="textSecondary">n/a</Typography>;
            }
            return (
              <StatsText count={0}>
                {inProgress} ({inProgressRate}%)
              </StatsText>
            );
          },
        },
      },
      {
        name: 'opened',
        label: 'Opened',
        // label: (
        //   <Tooltip
        //     title={
        //       <React.Fragment>
        //         <Typography variant="body2" textAlign="center">
        //           Tracking the number of contacts that opened sent emails
        //         </Typography>
        //       </React.Fragment>
        //     }
        //   >
        //     <Stack direction="row" spacing={1}>
        //       <Typography variant="body2" fontWeight="500">
        //         Opened
        //       </Typography>
        //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
        //     </Stack>
        //   </Tooltip>
        // ),
        options: {
          sort: false,
          draggable: true,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const uniqueOpens = event?.stats?.uniqueOpens || 0;
            const uniqueOpenRate = event?.stats?.uniqueOpenRate || 0;
            if (
              event &&
              event?.eventType &&
              event.eventType !== 'selectEmailTemplate' &&
              event.eventType !== 'email'
            ) {
              return <Typography color="textSecondary">-</Typography>;
            }
            return (
              <StatsText count={uniqueOpenRate}>
                {uniqueOpens} ({uniqueOpenRate}%)
              </StatsText>
            );
          },
        },
      },
      {
        name: 'clicked',
        label: 'Clicked',
        // label: (
        //   <Tooltip
        //     title={
        //       <React.Fragment>
        //         <Typography variant="body2" textAlign="center">
        //           Tracks the number of times your emails were clicked on. Please note that keep this
        //           tracking enabled could affect the deliverability of emails.
        //         </Typography>
        //       </React.Fragment>
        //     }
        //   >
        //     <Stack direction="row" spacing={1}>
        //       <Typography variant="body2" fontWeight="500">
        //         Clicked
        //       </Typography>
        //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
        //     </Stack>
        //   </Tooltip>
        // ),
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const uniqueClicks = event?.stats?.uniqueClicks || 0;
            const uniqueClickRate = event?.stats?.uniqueClickRate || 0;
            if (
              event &&
              event?.eventType &&
              event.eventType !== 'selectEmailTemplate' &&
              event.eventType !== 'email'
            ) {
              return <Typography color="textSecondary">-</Typography>;
            }
            return (
              <StatsText count={uniqueClickRate}>
                {uniqueClicks} ({uniqueClickRate}%)
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Reply',
        name: 'reply',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const uniqueReplies = event?.stats?.uniqueReplies || 0;
            const uniqueReplyRate = event?.stats?.uniqueReplyRate || 0;
            if (
              event &&
              event?.eventType &&
              event.eventType !== 'selectEmailTemplate' &&
              event.eventType !== 'email'
            ) {
              return <Typography color="textSecondary">-</Typography>;
            }
            return (
              <StatsText count={uniqueReplyRate}>
                {uniqueReplies} ({uniqueReplyRate}%)
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Interested',
        name: 'interested',
        options: {
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const event = gridData[dataIndex];
            const interested = event?.stats?.interested || 0;
            const interestedRate = event?.stats?.interestedRate || 0;
            if (
                event &&
                event?.eventType &&
                event.eventType !== 'selectEmailTemplate' &&
                event.eventType !== 'email'
            ) {
              return <Typography color="textSecondary">-</Typography>;
            }
            return (
                <StatsText count={interestedRate}>
                  {interested} ({interestedRate}%)
                </StatsText>
            );
          },
        },
      },
    ]);
  }, [gridData]);

  return columns;
}
