import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Spacer, Modal } from 'src/components/shared';
import useStyles from './style';
import PropTypes from 'prop-types';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CloseIcon from '@mui/icons-material/Close';
import PropertyForm from './PropertyForm';
import { ConfirmDialog } from 'src/components/App';
import { createObjection, createTechnique, updateObjection, updateTechnique } from 'src/modules/admin/api/adminApi';
import Search from './SearchProperty';


const RoleplayProperty = ({ heading, addText, values, setUpdatedValues, updatedRoleplay, saveError }) => {
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [propertyValues, setPropertyValues] = useState({});
    const [propertyType, setPropertyType] = useState("");


    const handleDialogSubmit = () => {
        setUpdatedValues({
            ...updatedRoleplay,
            [`${propertyType}_ids`]: updatedRoleplay[`${propertyType}_ids`]?.filter((item) => item.id !== propertyValues.id)
        })
        setIsDialogOpen(false)
    }
    const toggleDeleteModal = () => {
        setIsDialogOpen(false)
    }

    const handleOpenDialog = (content) => {
        setPropertyType(heading?.slice(0, -1).toLowerCase())
        setPropertyValues(content)
        setIsDialogOpen(true)
    }
    const onFormClose = () => {
        setIsModelOpen(false)
    }

    const handleModelOpen = (type, content) => {
        setIsModelOpen(true);
        setPropertyType(heading?.slice(0, -1).toLowerCase())
        if (type === "edit") {
            setPropertyValues(content)
        } else {
            setPropertyValues({})
        }
    }

    const handleCopySubmit = async (e) => {
      let payload = {};
      try {
        if (propertyType === 'technique') {
          payload = {
            name: e.values[propertyType],
            text: e.values[propertyType],
          };
          let {
            technique: { technique },
          } = await createTechnique(payload);
          setUpdatedValues({
            ...updatedRoleplay,
            technique_ids: [...updatedRoleplay.technique_ids, technique],
          });
        } else if (propertyType === 'objection') {
          payload = {
            name: e.values[propertyType],
            text: e.values[propertyType],
          };
          let {
            objection: { objection },
          } = await createObjection(payload);
          setUpdatedValues({
            ...updatedRoleplay,
            objection_ids: [...updatedRoleplay.objection_ids, objection],
          });
        }
      } catch (error) {
        console.error('Error: ', error);
      }
      setIsModelOpen(false);
    };

    const handleSubmit = async (e) => {
      try {
        let payload = {};
        if (propertyType === 'technique' && 'name' in propertyValues) {
          payload = {
            name: e[propertyType],
            text: e[propertyType],
          };
          let {
            technique: { technique },
          } = await updateTechnique(payload, propertyValues.id);
          setUpdatedValues({
            ...updatedRoleplay,
            technique_ids: updatedRoleplay.technique_ids?.map((item) =>
              item.id === technique[0]?.id ? technique[0] : item,
            ),
          });
        } else if (propertyType === 'objection' && 'name' in propertyValues) {
          payload = {
            name: e[propertyType],
            text: e[propertyType],
          };
          let {
            objection: { objection },
          } = await updateObjection(payload, propertyValues.id);
          setUpdatedValues({
            ...updatedRoleplay,
            objection_ids: updatedRoleplay.objection_ids?.map((item) =>
              item.id === objection?.id ? objection : item,
            ),
          });
        } else if (propertyType === 'technique') {
          payload = {
            name: e[propertyType],
            text: e[propertyType],
          };
          let {
            technique: { technique },
          } = await createTechnique(payload);
          setUpdatedValues({
            ...updatedRoleplay,
            technique_ids: [...updatedRoleplay.technique_ids, technique],
          });
        } else if (propertyType === 'objection') {
          payload = {
            name: e[propertyType],
            text: e[propertyType],
          };
          let {
            objection: { objection },
          } = await createObjection(payload);
          setUpdatedValues({
            ...updatedRoleplay,
            objection_ids: [...updatedRoleplay.objection_ids, objection],
          });
        }
      } catch (error) {
        console.error('Error', error);
      }
      setIsModelOpen(false);
    };

    const handleInsertProperty = (property, selectedItems) => {
        setUpdatedValues({
            ...updatedRoleplay,
            [`${property}_ids`]: [...updatedRoleplay.objection_ids, ...selectedItems]
        })
    }

    return (
        <Box className={classes.RoleplayProperty}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className="property-header">
                <Box display={"flex"} gap={"2rem"} alignItems={"center"}>
                    <Typography variant="h4" gutterBottom>{heading}</Typography>
                    {saveError[heading.toLowerCase()] && (
                        <Button variant="text" color="error">{`*A minimum of 3 ${heading.toLowerCase()} are required`}</Button>
                    )}
                </Box>
                <HelpOutlineIcon />
            </Box>
            <Spacer x={2} y={2} />
            <Box className="property-content">
                <Box className="property-filter-section">
                    <Search property={heading?.slice(0, -1).toLowerCase()} handleInsertProperty={handleInsertProperty} />
                </Box>
                <Spacer x={2} y={2} />
                <Box className="property-items">
                    {values.map((content) => (
                        <Box className="property-item" key={content?.id}>
                            <Box>
                                <Typography>{content?.name}</Typography>
                            </Box>
                            <Box display={"flex"}>
                                <Button onClick={() => handleModelOpen("edit", content)}><DriveFileRenameOutlineIcon /></Button>
                                <Button onClick={() => handleOpenDialog(content)}><CloseIcon /></Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Spacer x={2} y={2} />
                <Box className="add-new-btn" onClick={() => handleModelOpen("add")}>
                    <AddBoxOutlinedIcon />
                    <Typography>{addText}</Typography>
                </Box>
            </Box>

            <Modal
                open={isModelOpen}
                onClose={onFormClose}
                title={`Add ${propertyType}`}
                size="xs"
            >
                <PropertyForm
                    handleSubmit={handleSubmit}
                    propertyValues={propertyValues}
                    propertyType={propertyType}
                    onFormClose={onFormClose}
                    handleCopySubmit={handleCopySubmit}
                />
            </Modal>

            <ConfirmDialog
                title={"Are you sure?"}
                open={isDialogOpen}
                onClose={() => {
                    toggleDeleteModal();
                }}
                buttonTitle={"Yes, Delete"}
                confirmButtonColor={"error"}
                confirmButtonVariant="contained"
                onConfirm={handleDialogSubmit}
            >
                <Typography>You are about to delete the selected items.</Typography>
            </ConfirmDialog>
        </Box>
    )
}

RoleplayProperty.propTypes = {
    heading: PropTypes.string,
    addText: PropTypes.string,
    values: PropTypes.array,
    setUpdatedValues: PropTypes.func,
    updatedRoleplay: PropTypes.object,
    saveError: PropTypes.object
};

RoleplayProperty.defaultProps = {
    heading: "",
    addText: "",
    values: [],
    setUpdatedValues: () => { },
    updatedRoleplay: {},
    saveError: {}
};

export default RoleplayProperty