import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import InboxView from './InboxView';
import { Tabs, PageTitle, Pagination } from 'src/components/App';
import { inboxTabs } from '../../config';

import {
  clearTaskReplies,
  fetchInbox,
  fetchTaskReplies,
  deleteTaskReply,
  fetchSent,
  fetchOutboxTasks,
  taskBulkUpdate,
  setOutbox,
} from '../../actions/taskActions';
import {
  clearContactData,
  fetchContact,
  fetchSequenceEnrollment,
  putEnrollment,
} from 'src/modules/contacts/actions/contactActions';
import SentView from '../sent/SentView';
import OutboxView from '../outbox/OutboxView';

export const InboxContainer = (props) => {
  const [currentTab, setCurrentTab] = useState();

  useEffect(() => {
    if (props.history.location.pathname) {
      setCurrentTab(props.history.location.pathname.split('/')[1]);
    }
  }, [props.history.location.pathname]);

  const onTabChange = (data) => {
    props.history.push(`/${data.id}`);
  };

  return (
    <>
      {/*<PageTitle title="Inbox" />*/}
      <Tabs
        tabs={inboxTabs}
        value={currentTab}
        onChange={onTabChange}
        justifyContent="space-between"
      />
      {currentTab === 'inbox' && <InboxView {...props} />}
      {currentTab === 'sent' && <SentView {...props} />}
      {currentTab === 'outbox' && <OutboxView {...props} loading={props.outBoxLoading} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.tasks.loading,
  inbox: state.tasks.inbox,
  taskReplies: state.tasks.taskReplies,
  contact: state.contacts.contact,
  contactLoading: state.contacts.loading.contact,
  user: state.auth.user,
  sent: state.tasks.sent,
  outbox: state.tasks.outbox,
  outBoxLoading: state.tasks.loading.outbox,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskReplies: () => dispatch(clearTaskReplies()),
  clearContactData: () => dispatch(clearContactData()),
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
  fetchInbox: (filters, paging) => dispatch(fetchInbox(filters, paging)),
  fetchTaskReplies: (taskId) => dispatch(fetchTaskReplies(taskId)),
  fetchSent: (filters, paging) => dispatch(fetchSent(filters, paging)),
  deleteTaskReply: (replyId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteTaskReply(replyId, resolve, reject));
    });
  },
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  fetchOutboxTasks: (contactId, paging, filters, sort) =>
    dispatch(fetchOutboxTasks(contactId, paging, filters, sort)),
  taskBulkUpdate: (tasks, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(taskBulkUpdate(tasks, payload, resolve, reject));
    });
  },
  setOutbox: (data, total) => dispatch(setOutbox(data, total)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InboxContainer);
