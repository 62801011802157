import React from 'react';
import { LMSCard } from 'src/components/lms';
import Skeleton from '@mui/lab/Skeleton';
import { Spacer } from 'src/components/shared';
import Slider from 'src/components/lms/Slider';
import SessionSkeleton from 'src/components/lms/Session/Skeleton';
import { Grid } from '@mui/material';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

function HomeSkeleton() {
  const { isMobile } = useMediaQuery();

  const sliderProps = {
    slidesToShow: 4,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <LMSCard
        icon={<Skeleton animation="wave" height={24} width={24} />}
        title={<Skeleton animation="wave" height={24} width={68} />}
        action={<Skeleton animation="wave" height={20} width={68} />}
      >
        <Slider {...sliderProps}>
          <SessionSkeleton />
          <SessionSkeleton />
          <SessionSkeleton />
          <SessionSkeleton />
        </Slider>
      </LMSCard>
      <Spacer x={2} y={2} />
    </>
  );
}

export default HomeSkeleton;
