import React from 'react';

// CustomComponent
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Form from 'src/components/shared/Form';
import Modal from 'src/components/shared/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

// Custom Component
import Button from 'src/components/shared/Button';
import toast from 'src/utils/toast';

// Icons
import { ReactComponent as RemoveIcon } from 'src/assets/lms/icons/removeIcon.svg';
import { ReactComponent as AddIcon } from 'src/assets/lms/icons/addIcon.svg';
import SearchIcon from '@mui/icons-material/Search';

// Apis
import api from 'src/api';
import { fetchTeamLookup, fetchUsersLookup } from 'src/modules/users/api/usersApi';

// Helpers
import { capitalizeFirstLetter } from 'src/utils/helper';

// styles
import { makeStyles } from '@mui/styles';

// stores
import { useLmsHomeProvider } from '../utils/lmsStore';
import { CircularProgress } from '@mui/material';

const AssignThis = ({ open, onClose, type, id }) => {
  const classes = useStyles();
  const {
    courseAssignedTo,
    getTaskById,
    assignTaskTo,
    removeTaskFrom,
    fetchSummary,
    isTaskFetching,
  } = useLmsHomeProvider();
  const [courseAssignedToBtnLoading, setCourseAssignedToBtnLoading] = React.useState(false);
  const [teamsNdUsers, setTeamsNdUsers] = React.useState([]);
  const [courseWillAssignTo, setCourseWillAssignTo] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const teams = await fetchTeamLookup();
      const { users = [] } = await api(`/user?_from=0&_size=-1&lms=active`, null, 'get');
      setTeamsNdUsers([
        ...(teams?.map((team) => {
          return { ...team, accessType: 'Team' };
        }) ?? []),
        ...(users?.map((user) => {
          return { ...user, name: user.fname + ' ' + user.lname, accessType: 'People' };
        }) ?? []),
      ]);
    };
    fetchData();
    () => {
      setCourseAssignedToBtnLoading(false);
      setCourseWillAssignTo([]);
      setTeamsNdUsers([]);
    };
  }, []);

  React.useEffect(() => {
    if (id && type) getTaskById(id, type);
  }, [id, type]);

  React.useEffect(() => {
    setCourseWillAssignTo([
      ...(courseAssignedTo ?? [])?.map((item) => {
        return { ...item, edit: true };
      }),
    ]);
  }, [courseAssignedTo]);

  const handleRemoveCourseAsignedTo = async (taskId) => {
    setCourseAssignedToBtnLoading(true);
    await removeTaskFrom(taskId);
    const result = await getTaskById(id, type);
    setCourseWillAssignTo([
      ...(result ?? [])?.map((item) => {
        return { ...item, edit: true };
      }),
    ]);
    fetchSummary();
    setCourseAssignedToBtnLoading(false);
    toast.success(capitalizeFirstLetter(type) + ' is removed from assigned user.');
  };

  const handleCourseAsignedTo = async () => {
    setCourseAssignedToBtnLoading(true);
    await assignTaskTo({
      users: courseWillAssignTo?.flatMap(({ id, accessType, edit }) =>
        accessType === 'People' && !edit ? id : [],
      ),
      teams: courseWillAssignTo?.flatMap(({ id, accessType, edit }) =>
        accessType === 'Team' && !edit ? id : [],
      ),
      [type]: id,
    });
    fetchSummary();
    const result = await getTaskById(id, type);
    setCourseWillAssignTo([
      ...(result ?? [])?.map((item) => {
        return { ...item, edit: true };
      }),
    ]);
    setCourseAssignedToBtnLoading(false);
    toast.success(capitalizeFirstLetter(type) + ' is assigned to selected users.');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      className={classes.assignCourseModal}
      title={'Assign this ' + type}
    >
      <Form
        initialValues={{
          courseAssignedTo: [],
        }}
        enableReinitialize={true}
        onSubmit={handleCourseAsignedTo}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Stack gap={4} className="modal-wrapper">
                <Stack gap={2}>
                  <Typography className="input-desc">
                    Select the person whom you are assigning this to
                  </Typography>
                  <Form.Field.AutoComplete
                    name="courseAssignedTo"
                    fullWidth
                    multiple
                    options={teamsNdUsers}
                    variant="outlined"
                    className={'search-bar'}
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    groupBy={(option) => option?.accessType ?? ''}
                    getOptionLabel={(option) => option.name ?? ''}
                    renderOption={(option, props) => (
                      <Stack component="li" {...props} sx={{ padding: '0 !important' }}>
                        <Stack
                          sx={{
                            background: 'black',
                            alignItems: 'flex-start !important',
                            textAlign: 'start',
                            justifyContent: 'space-between !important',
                            flexDirection: 'row',
                            padding: '12px 16px !important',
                            fontsize: '14px',
                            textTransform: 'capitalize',
                            width: '100%',
                            color: props?.['aria-selected'] ? '#FFFFFF80' : 'white',
                            '&:hover': {
                              background: '#2a343d',
                            },
                            '& svg': {
                              filter: props?.['aria-selected'] ? 'grayscale(1)' : 'none',
                            },
                          }}
                        >
                          {option.name}
                          <AddIcon />
                        </Stack>
                      </Stack>
                    )}
                    optLabel="name"
                    optValue="id"
                    value={courseWillAssignTo}
                    onChange={(data) =>
                      setCourseWillAssignTo(
                        data.map(({ name, assignedTo, id, accessType, edit }) => {
                          return {
                            assignedTo: name || assignedTo,
                            name: name || assignedTo,
                            id,
                            accessType,
                            edit,
                          };
                        }),
                      )
                    }
                  />
                </Stack>
                <Stack gap={2}>
                  <Typography>Assigning to:</Typography>
                  {isTaskFetching ? (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <Stack direction={'row'} flexWrap={'wrap'} gap={1.5}>
                      {courseWillAssignTo?.map(({ id, assignedTo, edit }) => (
                        <Stack className="assigned-user" direction="row">
                          <Avatar />
                          <Typography className="name">{assignedTo}</Typography>
                          <ButtonBase
                            disabled={courseAssignedToBtnLoading}
                            className="remove-icon"
                            onClick={() => {
                              if (edit) handleRemoveCourseAsignedTo(id);
                              else
                                setCourseWillAssignTo((prevState) =>
                                  prevState.filter((item) => item.id !== id),
                                );
                            }}
                          >
                            <RemoveIcon />
                          </ButtonBase>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
                <Stack className="action-button">
                  <Button disabled={courseAssignedToBtnLoading} color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    loading={courseAssignedToBtnLoading}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Assign
                  </Button>
                </Stack>
              </Stack>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  assignCourseModal: {
    '& .MuiDialog-paper': {
      background: '#15222E',
      borderRadius: '5px',

      '& .input-desc': {
        color: '#FFFFFFB2',
      },

      '& .MuiInputBase-root': {
        background: 'transparent',
        border: '1px solid #FFFFFF3B',
      },

      '& .action-button': {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
        justifyContent: 'flex-end',

        '& button': {
          width: 'fit-content',
        },
      },

      '& .assigned-user': {
        background: '#FFFFFF29',
        borderRadius: '24px',
        padding: '6px 8px',
        alignItems: 'center',
        gap: '10px',

        '& .MuiAvatar-root': {
          width: '28px',
          height: '28px',
        },

        '& .name': {
          fontSize: '13px',
          color: 'white',
        },

        '& .remove-icon': {
          borderRadius: '50%',
        },
      },
    },
  },
}));

export default AssignThis;
