import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Spacer, Button, Modal } from 'src/components/shared';

import { getTaskTypeIcon, getTaskTypeText } from '../../utils/tasksUtils';
import TaskDueDateForm from './TaskDueDateForm';
import TaskHeaderSkeleton from './TaskHeaderSkeleton';

import { capitalizeName } from 'src/modules/contacts/utils/contactUtils';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SkipNextIcon from '@mui/icons-material/SkipNext';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import LinkedInIcon from '@mui/icons-material/LinkedIn';

function TaskContact({ contact, handleDelete, loader, isLoading, type, ...props }) {
  const [showDueDateForm, setShowDueDateForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const contactSocialLinks = useSelector((state) => state.contacts.contact.socialLinks);
  const tasksQueue = useSelector((state) => state.tasks.tasksQueue);
  const name = capitalizeName(`${contact?.fname || ''} ${contact?.lname || ''}`);
  let currentEmployer = {};
  if (contact?.workHistory && contact?.workHistory.length) {
    currentEmployer = contact.workHistory.filter((work) => work.isCurrent === true)[0];
  }

  const icon = getTaskTypeIcon(props.task['taskType'], '1.5rem', '#90CAF9');
  const taskTypeText = getTaskTypeText(props.task['taskType']);

  const tasksObj = useSelector((state) => state.tasks.tasks);

  const taskNumberStr = useMemo(() => {
    if (tasksQueue && tasksQueue.length) {
      const start = tasksQueue.findIndex((item) => item === props.task.id);
      if (start >= 0) {
        return `${start + 1} of ${tasksQueue.length}`;
      }
    } else {
      const paging = tasksObj.paging;
      const currentNumber = tasksObj.data.findIndex((task) => task.id === props.task.id) + 1;
      const currentPage = currentNumber === 0 ? 1 : currentNumber;
      return `${currentPage + paging.pageNo * paging.perPage} of ${paging.count}`;
    }
  }, [tasksObj, props.task, tasksQueue]);

  const markTaskComplete = async () => {
    setLoading(true);
    await props.putTask(props.task.id, {
      status: 'completed',
    });
    setLoading(false);
  };

  const onEditTask = () => {
    props.toggleAddTaskForm(props.task);
  };

  const closeDueDateForm = () => {
    setShowDueDateForm(false);
  };

  const handleAction = async () => {
    try {
      let linkedIn = '';
      if (contactSocialLinks.length) {
        linkedIn = contactSocialLinks.filter((item) => item.type === 'linkedin')[0].link;
      }
      if (linkedIn && linkedIn !== '') {
        window.open(`${linkedIn}?srtask=${props.task.id}`, '_blank').focus();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const hasLinkedIn = useMemo(() => {
    if (contactSocialLinks && Array.isArray(contactSocialLinks) && contactSocialLinks.length) {
      return contactSocialLinks.filter((item) => item.type === 'linkedin' && item?.link).length > 0;
    }
    return false;
  }, [contactSocialLinks]);

  const handleOnSend = async () => {
    setLoading(true);
    await props.navigateNextTask(); // ask Jeet later
    await props.putTask(props.task.id, {
      scheduledOn: new Date().toISOString(),
      ...(props?.task?.taskType === 'email' ? { allowForceSend: true } : {}),
    });
    setLoading(false);
  };

  if (isLoading) {
    return <TaskHeaderSkeleton />;
  }

  const onNext = () => {
    setLoading(true);
    props?.navigateNextTask()
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Box display="flex" alignItems="center">
        <Box>{icon}</Box>
        <Spacer x={1} />
        <Box>
          <Box display="flex" alignItems="flex-start" flexDirection="row">
            <Typography color="rgba(255, 255, 255, 0.7)" variant="body1">
              {taskTypeText} Task -&nbsp;
            </Typography>
            <Typography color="#fff" variant="body1" component="span" fontWeight={500}>
              {name}
            </Typography>
          </Box>
        </Box>
        {hasLinkedIn && (
          <>
            &nbsp;&nbsp;
            <Button
              onClick={handleAction}
              size="small"
              color="secondary"
              sx={{ color: '#90CAF9' }}
              endIcon={<OpenInNewIcon />}
            >
              View LinkedIn Profile
            </Button>
          </>
        )}
      </Box>

      <Box display="flex" alignItems="center">
        {type !== 'errors' ? (
          <>
            {props.task?.status !== 'completed' ? (
              <Button
                sx={{ color: '#ddd', border: '1px solid #ddd' }}
                color="success"
                variant="outlined"
                size="small"
                startIcon={<DoneIcon />}
                loading={loading}
                onClick={markTaskComplete}
              >
                {/* {props?.task?.taskType === 'email' ? 'SKIP' : 'COMPLETE'} */}
                Done
              </Button>
            ) : (
              <Button
                sx={{ color: '#ddd', border: '1px solid #ddd' }}
                color="success"
                variant="contained"
                size="small"
                loading={loading}
                startIcon={<DoneIcon />}
              >
                COMPLETED
              </Button>
            )}
          </>
        ) : null}

        <Spacer x={1} />
        <Button
          sx={{ color: '#ddd', border: '1px solid #ddd' }}
          color="success"
          variant="outlined"
          loading={loading}
          disabled={loading}
          size="small"
          startIcon={<SkipNextIcon />}
          onClick={() => {
            onNext();
          }}
        >
          Next
        </Button>

        <Modal
          open={showDueDateForm}
          onClose={closeDueDateForm}
          backdrop={false}
          title="Change due date"
        >
          <TaskDueDateForm task={props.task} onClose={closeDueDateForm} putTask={props.putTask} />
        </Modal>
        <Spacer x={2} />
        <Typography sx={{ color: '#dddddd' }} variant="body2">
          {taskNumberStr}
        </Typography>
        <Spacer x={1} />
        <Button iconButton variant="outlined" size="small" onClick={history.goBack}>
          <CloseIcon sx={{ color: '#fff', fill: '#999999', fontSize: 20, marginLeft: 0.5 }} />
        </Button>
      </Box>
    </Box>
  );
}

TaskContact.propTypes = {
  contact: PropTypes.object,
  index: PropTypes.number,
  showEdit: PropTypes.bool,
  showClone: PropTypes.bool,
};

TaskContact.defaultProps = {
  contact: {},
  index: 0,
  showEdit: false,
  showClone: false,
};

const isTaskTypeLinkedin = (type) => {
  switch (type) {
    case 'linkedin':
    case 'linkedinConnection':
    case 'linkedinMessage':
    case 'linkedinMail':
    case 'linkedinViewProfile':
      return true;
    default:
      return false;
  }
};

export default TaskContact;
