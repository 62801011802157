import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spacer } from 'src/components/shared';
import { Tooltip, Chip, Typography, Box } from '@mui/material';
import { AppGrid } from 'src/components/App';
import { useStyles } from '../../containers/integrations/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TeamGridActions from './TeamGridActions';
import AddIcon from '@mui/icons-material/Add';
import { teamBulkAction } from './config';
import TeamActionDrawer from './TeamActionDrawer';
import { capitalizeFirstLetter } from 'src/utils/helper';

function TeamsGrid({
  drawerToggle,
  teams,
  teamBulkUpdate,
  updateTeamStatus,
  globals,
  loading,
  ...props
}) {
  const classes = useStyles();
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { data, filters, paging, sort } = teams;

  const teamsColumns = useMemo(
    () => [
      {
        name: 'view',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box justifyContent="center" display="flex">
                <Tooltip title="View Team">
                  <VisibilityIcon
                    fontSize={'small'}
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                    onClick={() => {
                      drawerToggle({ edit: row, open: true });
                    }}
                  />
                </Tooltip>
              </Box>
            );
          },
        },
      },

      {
        label: 'Team name',
        name: 'name',
        options: {
          filter: true,
          sort: true,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 180 },
          }),
          setCellProps: () => ({
            style: { minWidth: 180 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Link
                to="/admin/teams"
                className="text-truncate td-anchor-content-ellipsis"
                onClick={() => {
                  drawerToggle({ edit: rData, open: true });
                }}
              >
                <Typography variant="body2" color="secondary">
                  {`${capitalizeFirstLetter(rData.name)}`}
                </Typography>
              </Link>
            );
          },
        },
      },
      {
        label: 'Team leader',
        name: 'teamLead',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 50 },
          }),
          setCellProps: () => ({
            style: { minWidth: 50 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <div>
                {capitalizeFirstLetter(rData?.teamLead?.fname)}{' '}
                {capitalizeFirstLetter(rData?.teamLead?.lname)}
              </div>
            );
          },
        },
      },

      {
        label: 'Users',
        name: 'user',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 90 },
          }),
          setCellProps: () => ({
            style: { minWidth: 90 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return <div>{rData.teamMembers.length}</div>;
          },
        },
      },

      {
        label: 'Status',
        name: 'status',
        options: {
          filter: true,
          sort: true,
          draggable: true,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 40 },
          }),
          setCellProps: () => ({
            style: { minWidth: 40 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Box
                className={`${
                  rData?.status === 'active'
                    ? classes.gridContentRowDataActive
                    : classes.gridContentRowDataDeActive
                }`}
              >
                <Button
                  sx={{
                    color: '#FFFFFF',
                    width: '80%',
                    background: rData?.status === 'active' ? '#66BB6A' : '#EF5350',
                  }}
                >
                  {rData?.status === 'active' ? 'Active' : 'Deactive'}
                </Button>
              </Box>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = data.find((task) => task.id === value);
            return (
              <TeamGridActions
                drawerToggle={drawerToggle}
                updateTeamStatus={updateTeamStatus}
                status={row?.status}
                row={row}
                {...props}
              />
            );
          },
        },
      },
    ],
    [data],
  );

  const handlePerPageChange = (data1, data2) => {};

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchTeams(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          sort,
        );
      } else {
        props.fetchTeams(paging, { ...filters, [key]: value }, sort);
      }
    } else {
      delete filters[key];
      props.fetchTeams(paging, filters, sort);
    }
  };

  const onSort = (sort) => {
    props.fetchTeams(paging, filters, sort);
  };

  const handleTableChange = ({ pageNo, perPage, searchText, sortOrder }) => {
    props.fetchTeams(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  const handleColumnChange = (data1, data2) => {
  };

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction(undefined);
    setRowsSelected([]);
  };
  const selectTeams = useMemo(() => {
    return data?.filter((item, index) => rowsSelected.includes(index));
  }, [rowsSelected, data]);

  const bulkDeleteContacts = async () => {
    try {
      setIsDeleting(true);
      await props.contactBulkAction(bulkAction, selectedContacts, {});
      setIsDeleting(false);
      closeBulkAction();
    } catch (error) {
      setI;
      sDeleting(false);
    }
  };

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={teamsColumns.map((col, index) => ({
          ...col,
          options: teamsColumns?.columns?.length
            ? { ...col.options, ...teamsColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onSort={onSort}
        onTableRowPerPageChange={handlePerPageChange}
        onTableChange={handleTableChange}
        onColumnChange={() => handleColumnChange(false)}
        onFilterChange={onFilterChange}
        appliedFilters={filters}
        loading={loading?.teams}
        bulkActions={teamBulkAction}
        onBulkAction={onBulkAction}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Teams',
          searchText: filters?._search ? filters._search : '',
          searchOpen: true,
          search: true,
          filter: false,
          download: false,
          viewColumns: false,
          selectToolbarPlacement: 'replace',
          renderCustomFilterActions: () => {
            return (
              <Button
                variant="contained"
                color="secondary"
                size="medium"
                // startIcon={<AddIcon />}
                onClick={() => {
                  drawerToggle({ edit: null, open: true });
                }}
              >
                <Typography variant="body2">Create Team</Typography>
              </Button>
            );
          },
          onRowSelectionChange: (selected, all) => {
            setRowsSelected(all.map((row) => row.dataIndex));
          },
          rowsSelected: rowsSelected,
        }}
      />

      <TeamActionDrawer
        action={bulkAction}
        onClose={closeBulkAction}
        teamBulkUpdate={teamBulkUpdate}
        selectTeams={selectTeams}
        globals={globals}
      />
    </>
  );
}

export default TeamsGrid;
