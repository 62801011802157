import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainBox = styled(Stack)({
  padding: '24px',
  width: '460px',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: '10px',
  background: '#fff',
  gap: '24px',
});

export const Title = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '23.34px',
});

export const Content = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20.02px',
  letterSpacing: '0.17px',
});

export const FlexColumnBox = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export const ModalHeader = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 24px 16px 24px',
  borderBottom: '1px solid #0000001F',
});

export const Heading = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Roboto',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '21.6px',
  letterSpacing: '-0.5px',
});

export const SecurityVerificationTitle = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '23.34px',
});
