import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import AIpreview from '../AILearning/AIpreview';

import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';
import { Skeleton1, Skeleton2, Skeleton3 } from '../homeLegacy/HomeSkeleton';
import HomeSkeleton from '../home/HomeSkeleton';

// import HomeBannerSection from './HomeBannerSection';
// import Home from '../homeLegacy/HomeSessions';
import HomeBannerSection from '../homeLegacy/HomeBannerSection';
import Home from '../homeLegacy/HomeSessions';
import HomeGetStartedSection from './HomeGetStartedSection';

import { useHomeProvider } from 'src/modules/lms/utils/homeStore';

import { getExpertsLookup, getTagsLookup } from '../../api/LMSApis';
import { Typography } from '@mui/material';
import { themeColors } from '../../config';
import Empty from 'src/components/lms/Empty';

export const LMSRecruitment = (props) => {
  const [open, setOpen] = useState(false);
  const [allExperts, setAllExperts] = useState([]);

  const { sales, recruitment, loading, filters, setFilters } = useHomeProvider();

  const handleClose = () => {
    window.localStorage.setItem('ai', true);
    setOpen(false);
  };

  useEffect(() => {
    if (!window.localStorage.getItem('ai')) setOpen(true);
    getExpertsLookup('', 0, -1).then(setAllExperts);
  }, []);

  const clearTags = () => {
    setFilters((prevState) => ({
      ...prevState,
      tags: [],
    }));
  };

  const clearExps = () => {
    setFilters((prevState) => ({
      ...prevState,
      experts: [],
    }));
  };

  const reportsFilter = [
    {
      key: 'tags',
      title: 'All tags',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      customHeader: true,
      cancellable: false,
      onClear: clearTags,
      remoteMethod: async (value) => {
        return getTagsLookup(value);
      },
    },
    {
      key: 'experts',
      title: 'All experts',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      cancellable: false,
      apply: true,
      customHeader: true,
      remoteMethod: async (value) => {
        return getExpertsLookup(value, 0, -1);
      },
    },
  ];

  const onFilterChange = (key, value) => {
    // fetchHome({ [key]: value });
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  const empty =
    (!recruitment.component1 || !recruitment.component1.length) &&
    (!recruitment.component2 || !recruitment.component2.length) &&
    (!recruitment.component3 || !recruitment.component3.length) &&
    !loading.component1 &&
    !loading.component2 &&
    !loading.component3;

  return (
    <Box>
      <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
        <FilterBar
          filters={reportsFilter?.map((filter) => {
            if (filter.key === 'experts')
              return {
                ...filter,
                title:
                  filters?.experts?.length === 0 || filters?.experts?.length === allExperts?.length
                    ? 'All experts'
                    : 'Experts',
              };
            else return filter;
          })}
          appliedFilters={filters}
          onChange={onFilterChange}
        />
      </Box>

      <Spacer x={2} y={2} />

      {empty && (
        <Empty
          title="Nothing to see here."
          desc="Check out some of our Courses and Sessions to get started!"
        />
      )}
      {loading.component1 ? (
        <HomeSkeleton />
      ) : (
        <HomeBannerSection
          // sectionData={props.homePage?.mainSections?.component1?.filter(
          //   (k) => k.published && k?.categoryType === 'recruitment',
          // )}
          homePage={props.homePage}
          loading={false}
          sectionData={recruitment.component1}
          title={recruitment.component1Title}
        />
      )}

      <Spacer x={3} y={3} />
      {/* {loading.component2 ? (
        <Skeleton2 />
      ) : (
        <HomeGetStartedSection
          // sectionData={props.homePage?.mainSections?.component2?.filter(
          //   (k) => k.published && k?.categoryType === 'recruitment',
          // )}
          homePage={props.homePage}
          loading={false}
          sectionData={recruitment.component2}
          title={recruitment.component2Title}
        />
      )}
      <Spacer x={3} y={3} /> */}

      {loading.component3 ? (
        <Skeleton3 />
      ) : (
        <Home
          title={recruitment?.component3Title || 'The sessions'}
          sessions={recruitment.component3}
          loading={false}
          categoryType={'recruitment'}
        />
      )}
      {/* <AIpreview open={open} handleClose={handleClose} user={props.user} /> */}
    </Box>
  );
};

export default LMSRecruitment;
