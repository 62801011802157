import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.grey,
    borderRadius: "7px",
    "& .MuiInputBase-root": {
      boxShadow:
        "0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset",
      background: (props) =>
        props?.errorBorder && props?.error ? `rgba(211, 47, 47, 0.04)` : theme.palette.text.white1,
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'rgba(102, 102, 102, 1)'
    },

    "& .MuiFormHelperText-root": {
      marginLeft: 0,
      marginTop: "4.5px",
    },
    "& .MuiOutlinedInput-root:hover fieldset" : {
      border:"1px solid #1976d2 !important",
    },
    "& .MuiOutlinedInput-root:active fieldset" : {
      border:"1px solid #1976d2 !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      border: "1px solid #1976d2 !important",  // Green color when active/focused
    },
    "& .MuiOutlinedInput-root fieldset" : {
      border: (props) =>
          props?.error ? `1px solid ${theme.palette.error.main} !important` : "none !important",
    },
  },
}));
