import React from 'react';
import PropTypes from 'prop-types';
import usePermission from './usePermission';

function Restricted({ to, children }) {
  const allowed = {
    standard: ['standard'],
    manager: ['standard', 'manager'],
    admin: ['standard', 'manager', 'admin'],
  };
  const [role] = usePermission();
  if (allowed[role] && allowed[role].includes(to)) {
    return children;
  }
  return null;
}

Restricted.propTypes = {
  action: PropTypes.oneOf(['admin', 'manager', 'standard']),
};
Restricted.defaultProps = {
  action: 'collapse',
};

export default Restricted;
