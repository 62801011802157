export const parseOnboardingData = (data) => {
  return {
    overview: {
      mailbox: data?.onboarding?.overview?.mailbox || false,
      downloadExtension: data?.onboarding?.overview?.downloadExtension || false,
    },
    outReach: {
      howToCreateASequence: data?.helpCenter?.howToCreateASequence || false,
      gettingToKnowExtension: data?.helpCenter?.gettingToKnowExtension || false,
      addContactToSequenceExtension: data?.helpCenter?.addContactToSequenceExtension || false,
      understandingTasks: data?.helpCenter?.understandingTasks || false,
    },
    successTips: {
      subjectLines: data?.helpCenter?.subjectLines || false,
      buildingKillerSequence: data?.helpCenter?.buildingKillerSequence || false,
      personalizeAtScale: data?.helpCenter?.personalizeAtScale || false,
    },
  };
};

export const parseOnboardingDataUpdate = (onboarding, key, value) => {
  let response = onboarding;
  if (Object.keys(value) && Object.keys(value).length) {
    const helpKey = Object.keys(value)[0];
    switch (true) {
      case helpKey === 'howToCreateASequence':
      case helpKey === 'gettingToKnowExtension':
      case helpKey === 'addContactToSequenceExtension':
      case helpKey === 'understandingTasks':
        response = {
          ...onboarding,
          outReach: {
            ...onboarding.outReach,
            ...value,
          },
        };
        break;

      case helpKey === 'buildingKillerSequence':
      case helpKey === 'personalizeAtScale':
      case helpKey === 'subjectLines':
        response = {
          ...onboarding,
          successTips: {
            ...onboarding.successTips,
            ...value,
          },
        };
        break;

      default:
        break;
    }
  } else {
    Object.keys(response).map((item) => {
      if (['downloadExtension', 'mailbox']?.includes(key) && item === 'overview')
        response[item][key] = value;
    });
  }
  return response;
};

export const parseModuleAttributes = (attributes) => {
  const modules = {
    contacts: [],
    tasks: [],
    sequences: [],
    users: [],
  };

  const unassignedFields = {
    contacts: [],
    tasks: [],
    sequences: [],
    users: [],
  };

  attributes.map((attribute) => {
    switch (true) {
      case attribute.inContact === true:
        modules.contacts.push(attribute);
        break;

      case attribute.inSequence === true:
        modules.sequences.push(attribute);
        break;

      case attribute.inTask === true:
        modules.tasks.push(attribute);
        break;

      case attribute.inUser === true:
        modules.users.push(attribute);
        break;

      case attribute.inContact === false &&
        attribute.inSequence === false &&
        attribute.inTask === false &&
        attribute.inUser === false:
        if (attribute.scope && attribute.scope.length) {
          if (attribute.scope.includes('contacts')) {
            unassignedFields.contacts.push(attribute);
          }
          if (attribute.scope.includes('sequences')) {
            unassignedFields.sequences.push(attribute);
          }
          if (attribute.scope.includes('tasks')) {
            unassignedFields.tasks.push(attribute);
          }
          if (attribute.scope.includes('user')) {
            unassignedFields.users.push(attribute);
          }
        }
        break;
    }
  });

  // modules.contacts = modules.contacts.sort(function (a, b) {
  //   return a?.gridParams && a?.gridParams?.contacts && b?.gridParams && b?.gridParams?.contacts
  //     ? a.gridParams.contacts.sortOrder - b.gridParams.contacts.sortOrder
  //     : -1;
  // });

  // modules.sequences = modules.sequences.sort(function (a, b) {
  //   return a?.gridParams && a?.gridParams?.sequences && b?.gridParams && b?.gridParams?.sequences
  //     ? a.gridParams.sequences.sortOrder - b.gridParams.sequences.sortOrder
  //     : -1;
  // });

  // modules.tasks = modules.tasks.sort(function (a, b) {
  //   return a?.gridParams && a?.gridParams?.tasks && b?.gridParams && b?.gridParams?.tasks
  //     ? a.gridParams.tasks.sortOrder - b.gridParams.tasks.sortOrder
  //     : -1;
  // });

  return { modules, unassignedFields };
};

export const getQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = {};

  for (let param of searchParams.entries()) {
    const [key, value] = param;
    queryParams[key] = value;
  }

  return queryParams;
};

export const processContent = (content, source) => {
  if (!content) return '';

  // Replace \n with <br />
  const withLineBreaks = content.replaceAll('/n', '<br />');

  // Remove specific part from URLs in the source string
  const removeSpecificUrl = source ? source.replaceAll(process.env.REACT_APP_BASE_API_URL, '') : null;
  // Replace URLs with anchor tags
  const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const withLinks = withLineBreaks.replace(
    urlPattern,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
  );

  // Wrap phrases starting with "Link" and ending with "." in anchor tags
  const linkPhrasePattern = /Link\b/g; // Adjusted regex pattern for "Link" phrases
  const withCustomLinks = withLinks.replace(
      linkPhrasePattern,
      (match) =>
          `<a href="${removeSpecificUrl}" style="text-decoration: underline; color: #1976D2;">${match}</a>`,
  );

  return withCustomLinks;
};
