import * as React from 'react';
import {
  FormControlLabel,
  List,
  Button,
  Radio,
  RadioGroup,
  Tooltip,
  Stack,
  Box,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import images from '../../../../../config/images';

const CustomFormControlLabel = styled(FormControlLabel)`
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .MuiFormControlLabel-label {
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .Mui-checked {
    color: #1976d2;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 4px;
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  text-transform: none;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  gap: 8px;
  //flex: 1 0 0;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 8px;
`;

const buttonList = [
  {
    label: 'Automated',
    value: 'automation',
    icon: true,
    tooltipDes: 'The email will be send automatically when scheduled.',
  },
  {
    label: 'Manual',
    value: 'manual',
    icon: true,
    tooltipDes: 'The email will need to be manually sent.',
  },
];

export default function SequenceFormTabBar({
  templateAutomationType,
  setTemplateAutomationType,
  showAiWriter,
}) {
  return (
    <>
      {showAiWriter ? (
        <StyledRadioGroup aria-label="Your plan" name="people" defaultValue="Individual">
          {buttonList.map((item, index) => (
            <StyledButton
              sx={(theme) => ({
                background:
                  templateAutomationType === item?.value
                    ? theme.palette.background.darkBlue
                    : '#F5F5F5',
              })}
              onClick={() => setTemplateAutomationType(item?.value)}
              fullWidth
            >
              <Tooltip
                title={
                  <Stack padding={'16px'} maxWidth={'372px'}>
                    <Stack gap="8px">
                      <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: '16px' }} />
                        <Typography fontSize={'16px'} fontWeight={400}>
                          Further instructions
                        </Typography>
                      </Box>
                      <Typography fontSize={'14px'} fontWeight={400}>
                        {item?.tooltipDes}
                      </Typography>
                    </Stack>
                  </Stack>
                }
                arrow
                placement={'bottom'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#082037',
                      '& .MuiTooltip-arrow': {
                        color: '#082037',
                      },
                    },
                  },
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <CustomFormControlLabel
                    sx={(theme) => ({
                      color:
                        templateAutomationType === item?.value
                          ? theme.palette.secondary.main
                          : '#333',
                      marginRight: '0px',
                    })}
                    value={item?.value}
                    control={<Radio checked={templateAutomationType === item?.value} />}
                    label={item?.label}
                  />
                  <ErrorOutlineOutlinedIcon sx={{ fontSize: '16px', color: '#757575' }} />
                </div>
              </Tooltip>
            </StyledButton>
          ))}
        </StyledRadioGroup>
      ) : null}
    </>
  );
}
