import styled from 'styled-components';
import { CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const StyledCardContent = styled(CardContent)`
  padding: 16px 8px;
  padding-bottom: 14px !important;
  position: relative;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.18px;
`;

export const useStyles = makeStyles(() => ({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      '& button': {
        marginRight: 0,
      },
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  centeredCell: {
    textAlign: 'center',
    '& div:nth-child(2) ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
}));
