import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import List from '../../components/Inbox/List';
import Messages from '../../components/Inbox/Messages';
import Profile from 'src/modules/contacts/components/Contact/Profile';
import { Spacer } from 'src/components/shared';
import { FilterBar } from 'src/components/App';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import Skeleton from 'src/modules/contacts/components/Contact/Skeleton';
import Empty from '../../components/Inbox/Empty';
import { isArray, isEmpty } from 'lodash';
import { fetchSequenceEnrollment } from 'src/modules/contacts/actions/contactActions';
import { useDispatch } from 'react-redux';
import { Pagination } from 'src/components/App';

const InboxView = ({ inbox, loading, taskReplies, ...props }) => {
  const { paging, filters, data } = inbox;
  const isAdmin = props.user?.isAdmin || false;
  const history = useHistory();
  const dispatch = useDispatch();

  const allFilters = [
    {
      key: 'scheduledAt',
      title: 'Date',
      type: 'relativeDateRange',
      custom: true,
      options: [
        { id: 'today', name: 'Received Today' },
        { id: 'yesterday', name: 'Received Yesterday' },
        { id: 'last7days', name: 'Received Last 7 days' },
        { id: 'last10days', name: 'Received Last 10 days' },
      ],
      showCustom: true,
      isPrimary: true,
    },
    ...(isAdmin
      ? [
          {
            key: 'user',
            title: 'User',
            type: 'dropdown',
            remote: true,
            optLabel: 'name',
            optValue: 'id',
            cancellable: false,
            searchable: true,
            isPrimary: true,
            remoteMethod: async (value) => {
              return fetchUsersLookup(value);
            },
          },
        ]
      : []),
    {
      key: 'sequence',
      title: 'Sequence',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchSequencesLookup(value);
      },
    },
  ];

  const currentTask = useMemo(() => {
    if (data && data.length && taskReplies?.taskId && taskReplies?.taskId !== '') {
      return data.filter((task) => task?.id === taskReplies?.taskId)[0];
    }
    return undefined;
  }, [data, taskReplies?.taskId]);

  const email = useMemo(() => {
    let emailType = 'work';
    if (currentTask?.sequence && currentTask?.sequence?.id) {
      emailType = currentTask?.sequence?.audienceEmailType === 'work' ? 'work' : 'personal';
    }
    if (taskReplies?.data && taskReplies?.data.length) {
      return taskReplies?.data[0]?.contact &&
        taskReplies?.data[0]?.contact?.emails &&
        taskReplies?.data[0]?.contact?.emails.length
        ? taskReplies?.data[0]?.contact?.emails.filter((email) => email.type == emailType)[0]
            ?.email || ''
        : '';
      // return taskReplies?.data[0].to;
    }
    return '';
  }, [taskReplies?.data]);

  useEffect(() => {
    if (!filters?.user) {
      fetchInbox(
        {
          ...filters,
          user: {
            ...props.user,
            name: `${props.user.fname} ${props.user.lname}`,
          },
        },
        paging,
      );
    } else {
      fetchInbox(filters, paging);
    }
    props.clearTaskReplies();
    props.clearContactData();
    return () => {
      props.clearTaskReplies();
      props.clearContactData();
    };
  }, []);

  useEffect(() => {
    props.contact?.id && dispatch(fetchSequenceEnrollment(props.contact?.id));
  }, [props.contact]);

  const fetchInbox = (filters, paging) => {
    props.fetchInbox(filters, paging);
  };

  const refetch = () => {
    fetchInbox(filters, paging);
  };

  const onFilterChange = (key, value) => {
    if (isArray(value) && !value.length) {
      return false;
    }
    const prev = JSON.stringify(filters[key]);
    if (value && typeof value !== 'undefined' && prev != JSON.stringify(value)) {
      fetchInbox(
        {
          ...filters,
          [key]: value,
        },
        paging,
      );
    } else {
      delete filters[key];
      fetchInbox(filters, paging);
    }
  };

  const handleSearch = (value) => {
    const key = '_search';
    if (value && value !== '') {
      fetchInbox(
        {
          ...filters,
          [key]: value,
        },
        paging,
      );
    } else {
      delete filters[key];
      fetchInbox(filters, paging);
    }
  };
  if (!data.length && !loading.inbox && isEmpty(filters))
    return (
      <Empty
        title="No email replies, yet."
        description="Start enrolling prospects today to get your inbox going!"
        buttonTitle="Start enrolling"
        onClick={() => {
          history.push('/contacts');
        }}
      />
    );

  const handlePageChange = ({ page, perPage }) => {
    fetchInbox(filters, { pageNo: page, perPage });
  };

  return (
    <Container disableGutters maxWidth="none">
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <FilterBar
            showSearch={false}
            filters={allFilters}
            appliedFilters={{ ...filters, status: { id: 'completed' } }}
            onChange={onFilterChange}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Pagination
            count={paging.count}
            page={paging.pageNo}
            onChange={handlePageChange}
            perPage={paging.perPage}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <List
            data={data}
            loading={loading?.inbox || false}
            onSearch={handleSearch}
            search={filters?._search || ''}
            messageKey="latestReplyByDate"
            refetch={refetch}
            type="inbox"
            selected={taskReplies?.taskId || undefined}
            filters={JSON.parse(JSON.stringify(filters))}
            {...props}
          />
        </Grid>
        <Grid item md={8} lg={8} xl={9}>
          <div
            style={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 180px)',
            }}
          >
            {props.contactLoading ? (
              <Skeleton showButtons={false} />
            ) : (
              <Profile
                contact={props.contact}
                putEnrollment={props.putEnrollment}
                showEdit={false}
                showAddress={false}
                showEmail={true}
                email={email}
              />
            )}

            <Spacer x={5} y={5} />
            <Messages
              taskReplies={taskReplies}
              {...props}
              type="inbox"
              messagesLoading={loading?.taskReplies || false}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InboxView;
