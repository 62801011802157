import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';
import Wide from './Wide';
import Long from './Long';

function Course({ course, type = 'grid', assignedBy = {} }) {
  const history = useHistory();
  const { assigned, progress } = useLmsHomeProvider();
  const courseProgress = course?.id ? progress?.courses?.[course?.id] : {};
  const handleClick = () => {
    history.push(`/lms/course/${course.id}`);
  };
  const isAssigned = useMemo(() => {
    return assigned.courses && assigned.courses.includes(course.id);
  }, [assigned, course]);

  const totalSessions = course?.sessions?.filter((k) => k.published)?.length ?? 0;
  const completedSessions = courseProgress?.stats?.completed ?? 0;
  const leftSessions = totalSessions - completedSessions ?? 0;
  const isListType = type === 'list';

  const isCompleted =
    history?.location?.pathname === '/lms/completed'
      ? true
      : courseProgress?.progressStatus && courseProgress?.progressStatus === 'completed'
      ? true
      : false;

  const renderCourse = (CourseComponent) => (
    <CourseComponent
      course={course}
      image={course?.tallTileImage}
      isAssigned={isAssigned}
      leftSessions={leftSessions}
      totalSessions={totalSessions}
      completedSessions={completedSessions}
      handleClick={handleClick}
      isListType={isListType}
      assignedBy={assignedBy}
      isCompleted={isCompleted}
    />
  );

  return isListType ? renderCourse(Wide) : renderCourse(Long);
}

export default Course;
