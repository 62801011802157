import React from 'react';
import { Stack, useTheme } from '@mui/material';

import images from 'src/config/images';
import { Button } from 'src/components/shared';

export const BottomButton = ({
  onSkip,
  onNext,
  nextButtonTitle,
  loading,
  nextDisabled = false,
}) => {
  const theme = useTheme();

  return (
    <Stack
      mt={'40px'}
      gap={'8px'}
      direction="row"
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      {onSkip && (
        <Button
          sx={{
            color: '#9E9E9E',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.4px',
          }}
          variant="text"
          loading={loading}
          onClick={onSkip}
        >
          Skip
        </Button>
      )}
      {onNext && (
        <Button
          variant="contained"
          color="secondary"
          // loading={loading}
          endIcon={<img src={images.icons.nextIcon} alt="Next Icon" />}
          blackButton
          disabled={nextDisabled}
          loading={loading}
          onClick={onNext}
          sx={{
            fontSize: '16px',
            letterSpacing: '0.4px',
            lineHeight: '24px',
            padding: '8px 24px',
          }}
        >
          {nextButtonTitle || 'Next'}
        </Button>
      )}
    </Stack>
  );
};
