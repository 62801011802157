import React from 'react';
import { Box, Stack } from '@mui/material';
import images from 'src/config/images';
import { ContentDescription, ContentHeader } from './styles';
import { Button } from 'src/components/shared';

const WelcomeScreen = ({ userJourney, setUserJourney, handleUpdateUserJourney, loading }) => {
  return (
    <>
      <Stack gap={'36px'}>
        <ContentHeader>Welcome</ContentHeader>
        <ContentDescription>
          Generate more revenue! Unlock your potential with streamlined sales processes, empowering
          you to focus on high-impact tasks.
        </ContentDescription>
      </Stack>
      <Box sx={{ textAlign: 'center', mb: '36px', mt: '28px' }}>
        <img src={images.journey.welocomeScreen} width={'100%'}/>
      </Box>
      <Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleUpdateUserJourney(userJourney, 'completed', 'downloadChrome');
          }}
          loading={loading}
          fullWidth
          size="large"
          blackButton
          sx={{ height: '56px', width: '490px', fontSize: '16px', letterSpacing: '0.4px' }}
        >
          Get started
        </Button>
      </Box>
    </>
  );
};
export default WelcomeScreen;
