import React, { useState } from 'react';
import { Stack } from '@mui/material';

import VoicesGrid from './VoicesGrid';
import { Spacer } from '../../../../components/shared';

function VoicesView({ voicesTabs, voices, total, userBulkUpdate, ...props }) {
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  return (
    <Stack gap={0.5} mb={2}>
      <VoicesGrid drawerToggle={setDrawerOpen} voices={voices} {...props} />
    </Stack>
  );
}

export default VoicesView;
