import React, { useState, useEffect, useMemo } from 'react';
import { AppGrid, ConfirmDialog } from 'src/components/App';
import { useSequenceContactsColumns } from './useColumns';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { enrolmentStatusOptions, openRateOptions } from '../../config/index';
import { isEmpty } from 'lodash';
import FilterDataCard from './FilterDataCard';
import { contactBulkActions } from '../../../contacts/config';
import { bulkAddToCrmContacts } from '../../../contacts/api/contactApis';
import toast from '../../../../utils/toast';
import ContactActionDrawer from '../../../contacts/components/Contacts/ContactActionDrawer';
import { Box, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getRelativeDateRange } from '../../../../utils/dateUtils';
import { convertObjectToQuerystring } from '../../../../utils/helper';
import moment from 'moment/moment';

const maxLengthForSequenceAction = 15;

const SequenceContactsGrid = ({
  sequencesContacts,
  seqId,
  contactsLoading,
  integrations,
  sequence,
  ...props
}) => {
  const { data, filters, paging, sort } = sequencesContacts;
  const [rowsSelected, setRowsSelected] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [seqCardData, setSeqCardData] = useState({});
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onDeleteContact = async (contact) => {
    setLoading(true);
    const response = await props.deleteContact(contact.id);
    setLoading(false);
    if (!response?.error) {
      props.fetchSequenceContacts(paging, filters, seqId, sort);
    }
  };

  const contactColumns = useSequenceContactsColumns(
    data,
    props?.toggleAddContactForm,
    onDeleteContact,
  );

  useEffect(() => {
    fetchSequenceReport();
  }, [filters]);

  useEffect(() => {
    props.fetchSequenceContacts(paging, filters, seqId, sort);
  }, [paging.pageNo, seqId]);

  const bulkActions = useMemo(() => {
    let filteredBulkActions = contactBulkActions;
    if (props.user?.acl?.accessLevel !== 'Admin')
      filteredBulkActions = contactBulkActions.filter((action) => action.key !== 'delete');
    return filteredBulkActions?.flatMap((action) => {
      if (action.key === 'addtocrm') {
        const isBullhornActive = () => {
          const bullhornIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'bullhorn',
          );
          return (
            bullhornIntegration?.status === 'active' &&
            !bullhornIntegration?.errorMessage &&
            !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !bullhornIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isJobAdderActive = () => {
          const jobadderIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'jobadder',
          );
          return (
            jobadderIntegration?.status === 'active' &&
            !jobadderIntegration?.errorMessage &&
            !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !jobadderIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isVincereActive = () => {
          const vincereIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'vincere',
          );
          return (
            vincereIntegration?.status === 'active' &&
            !vincereIntegration?.errorMessage &&
            !vincereIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !vincereIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isSalesforceActive = () => {
          const salesforceIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'salesforce',
          );
          return (
            salesforceIntegration?.status === 'active' &&
            !salesforceIntegration?.errorMessage &&
            !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !salesforceIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isHubspotActive = () => {
          const hubspotIntegration = integrations?.find(
            (o) => o?.name?.toLowerCase() === 'hubspot',
          );
          return (
            hubspotIntegration?.status === 'active' &&
            !hubspotIntegration?.errorMessage &&
            !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') &&
            !hubspotIntegration?.statusMessage?.toLowerCase()?.includes('token invalid')
          );
        };
        const isAnyCRMActive =
          isBullhornActive() ||
          isJobAdderActive() ||
          isVincereActive() ||
          isSalesforceActive() ||
          isHubspotActive();
        const options = [
          ...(!isHubspotActive() && !isSalesforceActive()
            ? [
                {
                  label: 'Candidate',
                  value: 'candidate',
                },
              ]
            : []),
          {
            label: 'Contact',
            value: 'contact',
          },
          ...(isBullhornActive()
            ? [
                {
                  label: 'Lead',
                  value: 'lead',
                },
              ]
            : []),
        ];

        const callBackFunc = async (val) => {
          closeBulkAction();
          if (val) {
            const res = await bulkAddToCrmContacts(selectedContactIds, val?.value);
            if (res.data)
              toast.success(
                `Your request to add ${selectedContactIds.length} contacts to CRM has been submitted. Please check for successful addition after a few minutes.`,
                'tc',
              );
          }
        };
        if (isAnyCRMActive)
          return {
            ...action,
            ...(options?.length === 1
              ? {
                  onClick: () => callBackFunc(options?.[0]),
                }
              : {
                  onChange: callBackFunc,
                  options: options,
                  optLabel: 'label',
                  optValue: 'value',
                  type: 'dropdown',
                }),
          };
        else return [];
      }
      if (action.key === 'addToSequence')
        return {
          ...action,
          ...(rowsSelected?.length > maxLengthForSequenceAction ? { disabled: true } : {}),
        };
      return action;
    });
  }, [contactBulkActions, props.user, rowsSelected, integrations]);

  const selectedContacts = useMemo(() => {
    return data
      .filter((item, index) => rowsSelected.includes(index))
      ?.map((item) => item?.contactObj);
  }, [rowsSelected, data]);

  const onBulkAction = (key) => {
    setBulkAction(key);
  };

  const closeBulkAction = (key) => {
    setBulkAction('');
    setRowsSelected([]);
    setSelectedContactIds([]);
  };

  const bulkDeleteContacts = async () => {
    try {
      setIsDeleting(true);
      await props.contactBulkAction(bulkAction, selectedContacts, {});
      setIsDeleting(false);
      closeBulkAction();
    } catch (error) {
      setIsDeleting(false);
    }
  };

  const contactFilters = [
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      remote: false,
      options: enrolmentStatusOptions,
      optLabel: 'label',
      optValue: 'value',
      cancellable: true,
      isPrimary: true,
    },
    {
      key: 'enrolledBy',
      title: 'Enrolled by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'enrolledDate',
      title: 'Enrolled date',
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
    // {
    //   key: 'openRate',
    //   title: 'Open rate (%)',
    //   type: 'dropdown',
    //   options: openRateOptions(),
    //   optLabel: 'label',
    //   optValue: 'value',
    //   cancellable: true,
    //   searchable: true,
    //   isPrimary: true,
    // },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchSequenceContacts(
      {
        pageNo,
        perPage,
      },
      filters,
      seqId,
      sort,
    );
  };

  const onSort = (data) => {
    props.fetchSequenceContacts(paging, filters, seqId, data);
  };

  const fetchSequenceReport = async () => {
    try {
      const status = filters?.status || {};
      const openRate = filters?.openRate?.value || undefined;
      let openRateArr = [];
      if (openRate) {
        openRateArr = openRate.split('-');
      }
      const filter = {
        ...(filters?.enrolledBy && filters.enrolledBy?.id
          ? { enrolledby: filters.enrolledBy?.id }
          : {}),
        ...(status && status?.value ? { status: status?.value } : {}),
        ...(openRate && openRateArr.length
          ? {
              openrate_gte: openRateArr[0],
              openrate_lte: openRateArr[1],
            }
          : {}),
      };
      if (filters?.enrolledDate && !isEmpty(filters?.enrolledDate)) {
        const dateRange = getRelativeDateRange(filters?.enrolledDate);
        filter.createdAt_gte = dateRange?.start;
        filter.createdAt_lte = dateRange?.end;
      } else {
        filter.createdAt_gte = moment(sequence?.createdAt).format('YYYY-MM-DD');
        filter.createdAt_lte = moment().format('YYYY-MM-DD');
      }
      filter.uniquedata = true;
      let query = ``;
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = await props.fetchSeqDashboardsReport(
        query ? query + `&sequences=${seqId}` : `sequences=${seqId}`,
      );
      setSeqCardData(response || {});
    } catch (error) {}
  };

  const onFilterChange = (key, value) => {
    if (!key) return;
    if (value && typeof value !== 'undefined') {
      if (key === '_search') {
        props.fetchSequenceContacts(
          {
            ...paging,
            pageNo: 0,
          },
          { ...filters, [key]: value },
          seqId,
          sort,
        );
      } else {
        props.fetchSequenceContacts(paging, { ...filters, [key]: value }, seqId, sort);
      }
    } else {
      delete filters[key];
      if (key === '_search' && !value) {
        filters['_search'] = '';
      }
      props.fetchSequenceContacts(paging, filters, seqId, sort);
    }
  };

  const handleCardClick = (newFilters) => {
    props.fetchSequenceContacts(paging, { ...newFilters }, seqId, sort);
  };

  const isIncludeUnsubscribedUser = selectedContacts?.find(
    (user) => user.status === 'unsubscribed',
  );

  return (
    <>
      <AppGrid
        selectableRows="multiple"
        columns={contactColumns.map((col, index) => ({
          ...col,
          options: contactColumns?.columns?.length
            ? { ...col.options, ...contactColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onSort={onSort}
        loading={contactsLoading || loading}
        filters={contactFilters}
        appliedFilters={filters}
        onBulkAction={onBulkAction}
        renderComponentAfterFilters={
          <FilterDataCard
            filters={filters}
            seqCardData={seqCardData}
            handleCardClick={handleCardClick}
          />
        }
        bulkActions={bulkActions}
        options={{
          ...(!isEmpty(sort) && {
            sortOrder: {
              name: sort?.name || 'none',
              direction: sort?.direction || 'none',
            },
          }),
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          rowsSelected: rowsSelected,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search contacts',
          searchSx: {
            width: '364px',
          },
          search: true,
          searchText: filters?._search ? filters._search : '',
          setRowProps: (_, dataIndex) => {
            return data[dataIndex]?.contactObj?.status === 'unsubscribed'
              ? { style: { background: '#F4F4F4', opacity: 0.6 } }
              : {};
          },
          onRowSelectionChange: (_, all) => {
            setRowsSelected(
              all.flatMap((row) =>
                data[row.dataIndex]?.contactObj?.status === 'unsubscribed' ? [] : row.dataIndex,
              ),
            );
            setSelectedContactIds(
              all?.reduce((acc, { index }) => {
                if (data[index]?.id && data[index]?.contactObj?.status !== 'unsubscribed')
                  acc?.push(data[index]?.contactObj?.id);
                return acc;
              }, []),
            );
          },
          textLabels: {
            body: {
              noMatch: 'Sorry, no matching records found. Please enroll contacts to the sequence.',
            },
          },
        }}
      />

      {/* drawer to add contact in seq or delete from sequence*/}
      <ContactActionDrawer
        action={
          !['addToSequence', 'delete'].includes(bulkAction) ||
          (!isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction))
            ? bulkAction
            : false
        }
        onClose={closeBulkAction}
        contactBulkAction={props.contactBulkAction}
        selectedContacts={selectedContacts}
      />

      <ConfirmDialog
        title={
          isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)
            ? 'Add to sequence'
            : ['delete'].includes(bulkAction)
            ? 'Delete Contact(s)'
            : ''
        }
        showCancelButton={!isIncludeUnsubscribedUser}
        buttonTitle={
          isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)
            ? 'Remove unsubscribed user(s)'
            : isIncludeUnsubscribedUser && ['delete'].includes(bulkAction)
            ? 'Cancel'
            : ['delete'].includes(bulkAction)
            ? 'Delete'
            : ''
        }
        confirmButtonVariant={'contained'}
        open={
          (isIncludeUnsubscribedUser && ['addToSequence'].includes(bulkAction)) ||
          ['delete'].includes(bulkAction)
        }
        onClose={closeBulkAction}
        onConfirm={() => (isIncludeUnsubscribedUser ? closeBulkAction() : bulkDeleteContacts())}
        loading={isDeleting}
      >
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          {isIncludeUnsubscribedUser ? (
            <Stack direction={'row'} gap={'12px'}>
              <InfoIcon style={{ fill: '#EF5350' }} />
              <Typography variant="body1" color="danger">
                {['addToSequence'].includes(bulkAction)
                  ? 'Your selection contains unsubscribed contacts. Please remove them to continue with enrollment.'
                  : ['delete'].includes(bulkAction)
                  ? 'Unsubscribed users cannot be deleted to ensure your outreach stays within deliverability standards and thresholds.Are you sure you want to delete selected contacts.'
                  : ''}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Are you sure you want to delete selected contacts.
            </Typography>
          )}
        </Box>
      </ConfirmDialog>
    </>
  );
};

export default SequenceContactsGrid;
