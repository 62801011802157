import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

import { getCurrentUserJourneyStep } from 'src/utils/helper';

import {
  DownloadChromeExtensionView,
  GoalsView,
  WelcomeScreen,
  ExperienceView,
  GetStarted,
} from './Journey';
import { CopyRight } from 'src/layouts/styles';
import images from 'src/config/images';
import JourneyStepper from '../../../../components/shared/Stepper/JourneyStepper';
import { updateSignupjourney } from '../../../auth/api/authApis';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAddSequenceForm } from '../../actions/appActions';

const stepData = {
  welcomeScreen: 0,
  downloadChrome: 1,
  experience: 2,
  goals: 3,
  getStarted: 4,
};

const hasStepper = ['downloadChrome', 'experience', 'goals', 'getStarted'];
const hideLogo = ['experience'];

const JourneySteps = {
  welcomeScreen: WelcomeScreen,
  downloadChrome: DownloadChromeExtensionView,
  experience: ExperienceView,
  goals: GoalsView,
  getStarted: GetStarted,
};

const OutReachJourneyView = (props) => {
  const { userJourney, setUserJourney } = props;
  const dispatch = useDispatch();
  const router = useSelector((state) => state.router);
  const [currentJourneyStep, setCurrentJourneyStep] = useState('');
  const [step, setStep] = useState();
  const [loading, setLoading] = useState(false);
  const refferal = router?.location?.query?.refferal;

  useEffect(() => {
    const stepKey = getCurrentUserJourneyStep(userJourney);
    if (stepKey) {
      if (stepKey === 'allDone') {
        if (refferal) {
          props.history.push(refferal);
        } else {
          // props.history.push('/onboarding');
          props.history.push('/outreachvideolibrary');
        }
        return;
      }
      setCurrentJourneyStep(stepKey);
      setStep(stepData[stepKey] || 0);
    } else {
      setCurrentJourneyStep('welcomeScreen');
      setStep(0);
    }
  }, [props.userJourney]);

  const handleUpdateUserJourney = async (jourenyData, data, nextScreen) => {
    const payload = {
      ...jourenyData,
      onboardingSteps: { ...jourenyData?.onboardingSteps, [currentJourneyStep]: data },
    };
    setLoading(true);
    if (!nextScreen) {
      payload.onboardingCompleted = true;
    }
    try {
      await updateSignupjourney(payload);
      setUserJourney(payload);
      if (!nextScreen) {
        if (refferal) {
          props.history.push(refferal);
        } else {
          // props.history.push('/outreachvideolibrary');
          // props.history.push('/onboarding');
          dispatch(toggleAddSequenceForm());
        }
      }
      setCurrentJourneyStep(nextScreen);
      setStep(step + 1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const JourneyStep = JourneySteps[currentJourneyStep];

  const isCenter = currentJourneyStep === 'goals';

  return (
    <StyledMain isCenter={isCenter}>
      <StyledContent isCenter={isCenter}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ textAlign: 'center', mb: '36px' }}>
            <img src={images.app.logo} alt="" />
          </Box>
          {hasStepper.includes(currentJourneyStep) && (
            <JourneyStepper
              sx={{ marginBottom: '32px !important' }}
              totalSteps={4}
              currentStep={step - 1}
            />
          )}
          {JourneyStep && (
            <JourneyStep
              loading={loading}
              handleUpdateUserJourney={handleUpdateUserJourney}
              userJourney={props?.userJourney}
              setUserJourney={props?.setUserJourney}
            />
          )}
        </Box>
      </StyledContent>
      <CopyRight sx={{ marginBottom: '10px' }}>
        © SuperReach {new Date().getFullYear()} - All rights reserved.
      </CopyRight>
    </StyledMain>
  );
};

export default OutReachJourneyView;

const StyledMain = styled(Stack)`
  height: ${({ isCenter }) => (isCenter ? 'calc(100% - 40px)' : '100vh')};
  max-width: 500px;
  margin: 0 auto;
  margin-top: ${({ isCenter }) => (isCenter ? '40px' : '0')};
`;

const StyledContent = styled(Stack)`
  margin-top: ${({ isCenter }) => (isCenter ? '10px' : '100px')};
  margin-bottom: ${({ isCenter }) => (isCenter ? '0' : '30px')};
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')};
  height: ${({ isCenter }) => (isCenter ? 'calc(100% - 30px)' : '100%')};
  align-items: center;
  overflow-y: ${({ isCenter }) => (isCenter ? 'auto' : 'visible')};
`;
