import React from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
// import UpdateVoiceView from './UpdateVoiceView';
import { fetchVoiceById, updateVoice } from '../../actions/authActions';
import CreateVoiceView from '../createBrandVoice/CreateVoiceView';

const UpdateBrandVoiceContainer = ({ getVoiceById, updateVoice, props }) => {
  return (
    <>
      <PageTitle title="My Profile" />
      <CreateVoiceView getVoiceById={getVoiceById} updateVoice={updateVoice} {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getVoiceById: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchVoiceById(id, resolve, reject));
    });
  },
  updateVoice: (rowData) => {
    return new Promise((resolve, reject) => {
      dispatch(updateVoice(rowData, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBrandVoiceContainer);
