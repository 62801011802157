import React from 'react';
import { Wrapper, NavBar, Content } from './styles';
import { Box, Typography } from '@mui/material';
import { Stepper, Button, Spacer } from 'src/components/shared';

const steps = ['Details', 'Steps', 'Settings', 'Launch'];
const AISteps = ['Generate prompt', 'Steps', 'Settings', 'Launch'];

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import images from '../../../../config/images';

function SequenceNavBar({
  sequence,
  isAIWriter,
  onClose,
  activeStep,
  setActiveStep,
  publishTask,
  saveAsDraft,
  loading,
  saveAsDraftLoading,
}) {
  return (
    <Wrapper>
      <NavBar>
        <Content>
          <Box display="flex" flex={1} sx={{ gap: '10px', alignItems: 'center' }}>
            <ChevronLeftIcon sx={{ color: '#00000042', cursor: 'pointer' }} onClick={onClose} />
            {sequence?.name && (
              <>
                <Typography sx={{ color: '#000000DE', fontSize: '18px', fontWeight: 500 }}>{sequence?.name}</Typography>
                {activeStep !== 0 && (
                  <EditIcon
                    onClick={() => setActiveStep(0)}
                    sx={{ height: '24px', width: '24px', color: '#0000008a', cursor: 'pointer' }}
                  />
                )}
              </>
            )}
          </Box>
          <Box display="flex" flex={1} justifyContent="center">
            <Stepper
              seqNav
              steps={isAIWriter ? AISteps : steps}
              activeStep={activeStep}
              onChange={(step) => {
                if (step < activeStep) {
                  setActiveStep(step);
                }
              }}
            />
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end">
            <Button
              loading={saveAsDraftLoading}
              style={{ color: 'rgba(0,0,0,0.36)' }}
              onClick={() => (activeStep == 3 ? saveAsDraft() : onClose())}
            >
              {activeStep == 3 ? 'Save as draft' : 'Cancel'}
            </Button>
            <Spacer basis={2} />
            <Button
              loading={loading}
              variant="contained"
              color="secondary"
              startIcon={
                activeStep == 0 || activeStep == 3 ? (
                  <img src={images.sequence.CreateIcon} />
                ) : (
                  <></>
                )
              }
              {...{
                ...(activeStep !== 2 &&
                  activeStep !== 0 && {
                    onClick: (e) => {
                      e?.preventDefault();
                      if (activeStep == 3) {
                        publishTask();
                      } else if (activeStep !== 2 && activeStep !== 0) {
                        setActiveStep(activeStep + 1);
                      } else {
                      }
                    },
                  }),
              }}
              {...{
                ...(activeStep === 0 && {
                  type: 'submit',
                  form: 'create-sequence-form',
                }),
              }}
              {...{
                ...(activeStep === 2 && {
                  type: 'submit',
                  form: 'sequence-settings-form',
                }),
              }}
            >
              {activeStep == 0
                ? `${sequence.id ? 'Update' : 'Create'} sequence`
                : activeStep == 3
                ? 'Publish sequence'
                : 'Next'}
            </Button>
          </Box>
        </Content>
      </NavBar>
    </Wrapper>
  );
}

export default SequenceNavBar;
