import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { Spacer } from 'src/components/shared';

function DataSkeleton() {
  return (
    <Stack gap={2}>
      {Array.from({ length: 8 }).map(() => (
        <Stack direction={'row'} gap={3}>
          <Skeleton height={180} width={300} sx={{ transform: 'scale(1)' }} />
          <Stack gap={2} flex={1}>
            <Skeleton height={20} width={300} sx={{ transform: 'scale(1)' }} />
            <Stack gap={1}>
              <Skeleton height={16} width={'100%'} sx={{ transform: 'scale(1)' }} />
              <Skeleton height={16} width={'100%'} sx={{ transform: 'scale(1)' }} />
            </Stack>
            <Spacer y={2} />
            <Skeleton height={16} width={300} sx={{ transform: 'scale(1)' }} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default DataSkeleton;
