import React, { useEffect } from 'react';
import { fetchChargifyLink } from 'src/modules/admin/api/adminApi';
import { useHistory, useLocation } from 'react-router-dom';
export const BillingContainer = (props) => {
  const history = useHistory();
  const location = useLocation();
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const fetchApi = async () => {
    const chargifyLink = await fetchChargifyLink();
    return { chargifyLink };
  };
  useEffect(() => {
    fetchApi()
      .then((data) => {
        const link = data?.chargifyLink.link;
        openInNewTab(link.url);
      })
      .catch((error) => console.error('Error: ', error));
  }, []);

  useEffect(() => {
    if (history?.location?.pathname == `/admin/billings`) {
      history.goBack();
    }
  }, [history?.location?.pathname]);
  return <></>;
};

export default BillingContainer;
