import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import OutReachJourneyView from './OutReachJourneyView';
import { signupjourney, signUpJourneyGetData } from '../../../auth/api/authApis';
import { verifyUser } from '../../../auth/actions/authActions';

const OutReachJourney = (props) => {
  const dispatch = useDispatch();
  const router = useSelector((state) => state.router);
  const uid = router?.location?.query?.uid || undefined;
  const tid = router?.location?.query?.ac || undefined;

  const [userJourney, setUserJourney] = useState({});

  useEffect(() => {
    if (tid && uid) {
      fetchUser();
    }
  }, [router?.location?.query]);

  const fetchUser = async () => {
    dispatch(verifyUser(tid, uid));
    // if (data?.user) {
    //   dispatch(setAuth(data?.user));
    // }
  };

  useEffect(() => {
    props?.user?.email && getUserJourney();
  }, [props?.user]);

  const getUserJourney = async () => {
    await signUpJourneyGetData(props?.user?.email)
      .then(async (res) => {
        if (res?.length > 0) {
          setUserJourney({ ...res[0] });
        } else {
          const payload = {
            user: props?.user?.id,
            email: props?.user?.email,
            fname: props?.user?.firstName,
            lname: props?.user?.lastName,
            tenant: props?.user?.tenants[0]?.name,
            password: props?.user?.password || '',
          };
          await signupjourney(payload);
          getUserJourney();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <OutReachJourneyView userJourney={userJourney} setUserJourney={setUserJourney} {...props} />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OutReachJourney);
