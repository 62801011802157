import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { createContext, useState, useContext, useEffect } from 'react';
import { getDifferenceBetweenDates, getRelativeDateRange } from 'src/utils/dateUtils';
import { convertObjectToQuerystring } from 'src/utils/helper';
import usePersistState from 'src/components/hooks/usePersistState';
import { useDispatch } from 'react-redux';
import {
  fetchSeqDashboards,
  fetchSeqDashboardsReport,
  fetchSeqDashboardsTeam,
  fetchSeqDashboardsUser,
} from '../actions/appActions';
const dashContext = createContext({});

export const useDashboardStore = () => {
  const {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
  } = useContext(dashContext);
  return {
    filters,
    setFilters,
    fetchSequenceReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    sequenceReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
  };
};

export const DashboardProvider = ({ children }) => {
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState({
    sequenceReport: false,
    sequenceStats: false,
    userStats: false,
    teamStats: false,
  });
  const [sequenceReport, setSequenceReport] = useState({});
  const [makeChartTypeDisable, setMakeChartTypeDisable] = useState([]);
  const [filters, setFilters] = usePersistState('seqDashboardFilters', {});
  const [chartType, setChartType] = usePersistState('seqDashboardChartType', 'daily');
  const [sequenceStats, setSequenceStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  const [userStats, setUserStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  const [teamStats, setTeamStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });

  useEffect(() => {
    const persistedData = localStorage.getItem('seqDashboardFilters');
    const data = persistedData ? JSON.parse(persistedData) : {};
    const updatedFilters = { ...data };
    if (!data?.isFirstTime) {
      const enrolledBy = data?.enrolledby ?? [];
      updatedFilters.enrolledby = [...enrolledBy, user];
      updatedFilters.isFirstTime = true;
    }
    if (!data?.createdAt) {
      updatedFilters.createdAt = { id: 'last30Days', name: 'Last 30 days' };
    }
    setFilters(updatedFilters);
  }, []);

  const getFilter = () => {
    let filter = {};

    Object.keys(filters).map((key, index) => {
      if (filters[key] && key === 'createdAt' && !isEmpty(filters[key])) {
        const dateRange = getRelativeDateRange(filters[key]);
        filter = {
          ...filter,
          createdAt_gte: dateRange?.start,
          createdAt_lte: dateRange?.end,
        };
      } else if (filters[key] && key === 'sequences' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          sequences: filters[key]?.map((sequence) => sequence.id),
        };
      } else if (filters[key] && key === 'teams' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          teams: filters[key]?.map((team) => team.id),
        };
      } else if (filters[key] && key === 'enrolledby' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          enrolledby: filters[key]?.map((assignedTo) => assignedTo.id),
        };
      }
    });
    return filter;
  };

  const fetchSequenceStats = async (newPage, sort) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        sequenceStats: true,
      }));
      setSequenceStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };

      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }

      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboards(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading((prevState) => ({
            ...prevState,
            sequenceStats: false,
          }));
          setSequenceStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.sequences,
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            sequenceStats: false,
          }));
          setSequenceStats({
            ...sequenceStats,
            data: [],
            paging: {
              ...sequenceStats?.paging,
              count: 0,
            },
          });
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        sequenceStats: false,
      }));
      setSequenceStats({
        ...sequenceStats,
        data: [],
        paging: {
          ...sequenceStats?.paging,
          count: 0,
        },
      });
    }
  };

  const fetchUserStats = async (newPage, sort) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        userStats: true,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboardsUser(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading((prevState) => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.users,
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          setLoading((prevState) => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: [],
            paging: {
              ...prevState?.paging,
              count: 0,
            },
          }));
        });
    } catch (error) {
      setLoading((prevState) => ({
        ...prevState,
        userStats: false,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };

  const fetchTeamStats = async (newPage, sort) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        teamStats: true,
      }));
      setTeamStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState?.paging,
          pageNo: newPage?.pageNo,
          perPage: newPage?.perPage,
        },
        sort,
      }));
      let filter = {
        _from: newPage?.pageNo * newPage?.perPage,
        _size: newPage?.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort?.name}:${sort?.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboardsTeam(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading((prevState) => ({
            ...prevState,
            teamStats: false,
          }));
          setTeamStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.teams,
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          console.error('Error: ', error);
          setLoading((prevState) => ({
            ...prevState,
            teamStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: [],
            paging: {
              ...prevState?.paging,
              count: 0,
            },
          }));
        });
    } catch (error) {
      console.error('Error: ', error);
      setLoading((prevState) => ({
        ...prevState,
        teamStats: false,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState?.paging,
          count: 0,
        },
      }));
    }
  };

  useEffect(() => {
    // fetchSequenceStats(sequenceStats.paging);
    // fetchUserStats(userStats.paging);
    fetchSequenceReport();
  }, [filters]);

  const fetchSequenceReport = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        sequenceReport: true,
      }));
      let filter = getFilter();

      if (filter?.createdAt_gte && filter?.createdAt_lte) {
        const diff = getDifferenceBetweenDates(filter?.createdAt_gte, filter?.createdAt_lte);
        let fieldDisables = [];
        if (diff > 730) {
          fieldDisables.push('daily');
          fieldDisables.push('weekly');
          if (chartType === 'daily' || chartType === 'weekly') {
            setChartType('monthly');
          }
        } else if (diff > 360) {
          fieldDisables.push('daily');
          if (chartType === 'daily') {
            setChartType('weekly');
          }
        }
        setMakeChartTypeDisable(fieldDisables);
      } else {
        setMakeChartTypeDisable([]);
      }
      if (chartType) {
        filter['chartType'] = chartType;
      }
      let query = '';
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchSeqDashboardsReport(query, resolve, reject));
      });
      setLoading((prevState) => ({
        ...prevState,
        sequenceReport: false,
      }));
      response
        .then((resolvedValue) => {
          setSequenceReport(resolvedValue);
        })
        .catch((error) => {
          setSequenceReport({});
        });
    } catch (error) {
      setSequenceReport({});
      setLoading((prevState) => ({
        ...prevState,
        sequenceReport: false,
      }));
    }
  };

  return (
    <dashContext.Provider
      value={{
        filters,
        setFilters,
        sequenceReport,
        sequenceStats,
        userStats,
        teamStats,
        loading,
        setChartType,
        chartType,
        fetchSequenceReport,
        fetchSequenceStats,
        fetchUserStats,
        fetchTeamStats,
        makeChartTypeDisable,
      }}
    >
      {children}
    </dashContext.Provider>
  );
};

export const withDashboardProvider = (Component) => (props) =>
  (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  );
