import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './style';
import { EmptyIcon } from 'src/modules/lms/components/LMSCompetition/EmptyIcon';

export default function EmptyState({
  handleCompetitionCreationModal = () => {},
  firstCompetition = false,
}) {
  const classes = useStyles();
  const isUser = ['standard']?.includes(user?.acl?.accessLevel?.toLowerCase()); 
  return (
    <Grid container display={'flex'} justifyContent={'center'}>
      <Grid item xl={12} lg={12} md={12} xs={12} display={'flex'} mt={4} justifyContent={'center'}>
        <EmptyIcon />
      </Grid>
      <Grid item xl={12} lg={12} md={12} xs={12} display={'flex'} mt={4} justifyContent={'center'}>
        {isUser || !firstCompetition ? (
          <Typography className={classes.emptyTitle} mb={3}>
            Results not found.
          </Typography>
        ) : (
          <Typography className={classes.emptyTitle} mb={3}>
            Create Your First Competition to Get Started.
          </Typography>
        )}
      </Grid>
      {!isUser && (
        <Grid item xl={12} lg={12} md={12} xs={12} display={'flex'} justifyContent={'center'}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCompetitionCreationModal}
            className={classes.createButton}
          >
            Create competition
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
