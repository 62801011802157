import styled from 'styled-components';
import {Button as MUIButton} from "@mui/material";

export const PasswordLintAlert = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

export const ThirdPartyLoginButton = styled(MUIButton)(({ theme }) => ({
    color: '#1E293B',
    fontSize: '14px',
    fontFamily: 'Inter',
    padding: '6px 16px',
    background:
        'linear-gradient(0deg, #EAEAEA, #EAEAEA), linear-gradient(180deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 100%)',
    '&:hover': {
        background:
            'linear-gradient(0deg, #EAEAEA, #EAEAEA), linear-gradient(180deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 100%)',
    },
    '& .MuiButton-startIcon': {
        marginLeft: 0,
    },
    textTransform: 'none',
}));
