import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { EventCard } from '../eventsStyles';
import { Box, Typography } from '@mui/material';
import { Spacer } from 'src/components/shared';

const LinkedinCard = ({ event, ...props }) => {
  const title = useMemo(() => {
    switch (event.eventType) {
      case 'linkedinConnection':
        return `LinkedIn connection (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;

      case 'linkedinViewProfile':
        return `LinkedIn view profile (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;

      case 'linkedinMail':
        return `LinkedIn inmail (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;

      case 'linkedinMessage':
        return `LinkedIn message (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;

      default:
        return `LinkedIn message (${event.mode === 'automation' ? 'Automated' : 'Manual'})`;
    }
  }, [event]);

  return (
    <EventCard
      icon={<LinkedInIcon style={{ color: '#1282B2' }} />}
      showEdit={true}
      showDelete={true}
      event={event}
      showHover={event.eventType !== 'linkedinViewProfile'}
      {...props}
      title={title}
    />
  );
};

LinkedinCard.propTypes = {
  event: PropTypes.object,
};

export default LinkedinCard;
