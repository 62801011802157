import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { themeColors } from '../../config';
import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const useStyles = makeStyles(({ palette }) => ({
  root: {},
  imageRatio: {
    height: 'auto',
    objectFit: 'contain',
  },
  numbering: {
    position: 'absolute',
    zIndex: '1',
    marginLeft: '1.5%',
    marginTop: '1.5%',
    background: '#CF0175',
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: 'white',
    fontSize: '24px',
    fontWeight: 800,
  },
  container: {
    borderRadius: '3px',
    background: themeColors.bg3,
  },
  alignment: {
    padding: '24px',
    marginLeft: '-1%',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'white',
    '& span': {
      color: themeColors.yellow,
    },
  },
  noPadding: {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  noPaddingTop: {
    paddingTop: '0px !important',
  },
  banner: {
    padding: '22px',
    borderRadius: '3px',
    background: themeColors.bg3,
  },
  SessionContainer: {
    borderRadius: '3px',
    background: themeColors.bg3,
  },
  startedContainer: {
    flexWrap: 'nowrap',
    overflowX: 'auto',
    justifyContent: 'flex-start',
  },
  Search: {
    marginBottom: '30px',
    color: '#FAFAFA !important',
    opacity: '0.6',
    borderRadius: '10px',
    background: '#193247 !important',
    ' & input': {
      color: '#FAFAFA !important',
      borderRadius: '10px',
      background: ' #193247 !important',
    },

    '& label': {
      color: '#FAFAFA !important',
    },
    ' &:focus': {
      background: 'transparent !important',
    },
  },
  searchInput: {
    borderRadius: 10,
    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      backgroundColor: themeColors.inputBg,
      background: themeColors.inputBg,

      color: '#CCCCCC',
      borderRadius: 10,
    },
    '& label': {
      color: '#CCCCCC',
    },
    '& .css-foqa0v-MuiInputBase-root-MuiOutlinedInput-root': {
      paddingRight: '0px',
    },
  },
}));

export const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  color: themeColors.greyText,
}));
export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: themeColors.greyText,
}));
export const StyledYellowHeader = styled(Typography)(({ theme }) => ({
  color: themeColors.yellow,
  fontWeight: 700,
  fontSize: 18,
}));
