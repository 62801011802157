import React from 'react';
import {
  Head,
  Gradient,
  Background,
  ContentLong,
  AssignedIcon,
  SessionImage,
  Wrapper,
  Title,
} from './styles';
import { Spacer } from 'src/components/shared';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ProgressBar from '../ProgressBar';
import images from 'src/config/images';

import Icon from 'src/components/lms/Icon';
import { palette } from 'src/config/darkTheme';

function Long({
  course,
  image,
  isAssigned,
  leftSessions,
  totalSessions,
  completedSessions,
  handleClick,
  isCompleted,
}) {
  const thumbnailImage = image && image !== '' ? image : images.LMS.placeholder;

  return (
    <Wrapper onClick={handleClick}>
      <Stack height={'100%'}>
        <Background className="backgroundImage" image={thumbnailImage}>
          <SessionImage src={thumbnailImage} />
          <Gradient />
          <Head>
            <ProgressBar total={totalSessions} completed={completedSessions} />
            <Stack
              direction="row"
              px={2}
              justifyContent={leftSessions > 0 ? 'space-between' : 'flex-end'}
            >
              {leftSessions > 0 && (
                <Typography variant="body2" color="textSecondary" textAlign="left">
                  {leftSessions} SESSION{leftSessions === 1 ? '' : 'S'} LEFT
                </Typography>
              )}
              <Icon
                name="tick"
                size={20}
                color={isCompleted ? palette.secondary.main : 'rgba(255, 212, 126, 0.30)'}
              />
            </Stack>
          </Head>

          <ContentLong>
            <Title>{course?.title}</Title>
            <Spacer x={1} y={1} />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary" component="span" noWrap>
                Course by{' '}
                <Typography variant="body2" color="textPrimary" component="span">
                  <b>SuperReach</b>
                </Typography>
              </Typography>
              {isAssigned || (true && <AssignedIcon />)}
            </Stack>
          </ContentLong>
        </Background>
      </Stack>
    </Wrapper>
  );
}

export default Long;
