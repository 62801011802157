import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Box,
  Typography,
  ButtonBase,
  CircularProgress,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { FilterBar, Tabs, ConfirmDialog, Empty } from 'src/components/App';
import { Modal, Spacer } from 'src/components/shared';

import BoardActions from '../../components/Board/BoardActions';
import SwimlaneActions from '../../components/Board/SwimlaneActions';
import DashboardSkeleton from '../../components/dashboard/Skeleton';
import BoardForm from '../../components/Board/BoardForm';
import BoardStages from '../../components/Board/BoardStages';
import BoardContactsGrid from '../../components/Board/BoardContactsGrid';
import CloneBoardForm from '../../components/Board/CloneBoardForm';
import Board from '../../components/Board';
import SharedBoardsMenu from '../../components/Board/SharedBoardsMenu';
import EmptyBoard from '../../components/Board/EmptyBoard';

import Contacts from '../../components/dashboard/contacts';
import Panel from '../../components/dashboard/panel';
import PanelSkeleton from '../../components/dashboard/panel/Skeleton';
import AddPanel from '../../components/dashboard/panel/AddPanel';
import PanelFilters from '../../components/dashboard/panel/PanelFilters';
import { useWindowDimensions, useViewport } from 'src/components/hooks';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { fetchUsersLookup } from 'src/modules/admin/api/adminApi';

import EditIcon from '@mui/icons-material/Edit';
import { FixedTitleBar, Panels, PanelsWrapper, FormWrapper } from './styles';
import { layoutsData } from '../../config/boardConfig';
import { useHorizontalScroll } from 'src/components/hooks/useHorizontalScroll';
import { useHistory } from 'react-router';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';

const BoardsView = ({
  boards,
  boardWidgets,
  loading,
  activeBoard,
  loader,
  panels,
  panelSearch,
  boardFilters,
  ...props
}) => {
  const [layouts, setLayouts] = useState(layoutsData);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const widgets = JSON.parse(JSON.stringify(layoutsData.lg));

  const viewport = useViewport();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showBoardForm, setShowBoardForm] = useState(false);
  const [editBoard, setEditBoard] = useState({});
  const [deleteBoard, setDeleteBoard] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [defaultAction, setDefaultAction] = useState('');
  const [showStagesSettings, setShowStagesSettings] = useState(false);
  const [showCloneBoardForm, setShowCloneBoardForm] = useState(false);
  const [swimlaneType, setSwimlaneType] = useState('panels');

  const scrollRef = useHorizontalScroll();

  const board = useMemo(() => {
    return boards[activeBoard];
  }, [boards, activeBoard]);

  const appliedFilters = useMemo(() => {
    return boardFilters[activeBoard];
  }, [activeBoard, boardFilters]);

  const filters = useMemo(() => {
    if (board?.type && board?.type !== '') {
      return board?.type === 'swimlanes'
        ? []
        : [
            {
              key: 'createdAtRange',
              title: 'Filter By Date',
              type: 'relativeDateRange',
              custom: true,
              options: [
                { id: 'today', name: 'Today' },
                { id: 'yesterday', name: 'Yesterday' },
                { id: 'thisWeek', name: 'This week' },
                { id: 'lastWeek', name: 'Last week' },
                { id: 'thisMonth', name: 'This month' },
                { id: 'lastMonth', name: 'Last month' },
                { id: 'last3Month', name: 'Last 3 months' },
              ],
              isPrimary: true,
            },
            {
              key: 'teams',
              title: 'All Team',
              type: 'dropdown',
              remote: true,
              optLabel: 'name',
              optValue: 'id',
              cancellable: false,
              searchable: true,
              isPrimary: true,
              checkbox: true,
              multiple: true,
              apply: true,
              ...(appliedFilters?.users && appliedFilters?.users.length
                ? {
                    disabled: true,
                    tip: 'To select team filter, first you have to remove user filter. You can’t use both at the same time.',
                  }
                : {}),
              remoteMethod: async (value) => {
                return fetchTeamsLookup(value);
              },
            },
            {
              key: 'users',
              title: 'All Users',
              type: 'dropdown',
              remote: true,
              optLabel: 'name',
              optValue: 'id',
              cancellable: false,
              searchable: true,
              isPrimary: true,
              checkbox: true,
              multiple: true,
              apply: true,
              disabled: appliedFilters?.teams && appliedFilters?.teams.length,
              ...(appliedFilters?.teams && appliedFilters?.teams.length
                ? {
                    disabled: true,
                    tip: 'To select user filter, first you have to remove team filter. You can’t use both at the same time.',
                  }
                : {}),
              remoteMethod: async (value) => {
                return fetchUsersLookup(value);
              },
            },
          ];
    }
    return [];
  }, [board, appliedFilters]);

  const isFilterApplied =
    appliedFilters?.team?.users?.length ||
    (appliedFilters?.createdAtRange?.id && appliedFilters?.createdAtRange?.id !== 'thisMonth');

  const defaultFilterObj = {
    createdAtRange: { id: 'thisMonth', name: 'This month' },
  };

  useEffect(() => {
    const boardId = props.match?.params?.boardId;
    if (!boardId && activeBoard) history.replace('/boards/' + activeBoard);
    if (!isFilterApplied) props.setBoardFilters(defaultFilterObj, boardId);
  }, [activeBoard]);

  useEffect(() => {
    const boardId = props.match?.params?.boardId;
    if (boardId && boardId !== '' && boards && boards[boardId]) {
      props.setActiveBoard(boardId, viewport);
    }
  }, [props.match, boards, viewport]);

  const toggleSwimlaneType = () => {
    setSwimlaneType(swimlaneType === 'panels' ? 'grid' : 'panels');
  };

  const handleOpen = (open) => {
    setIsOpen(open);
  };

  const tabs = useMemo(() => {
    const data = [];
    Object.keys(boards).map((key) => {
      if (boards[key]?.pinned && boards[key]?.pinned === true) {
        data.push({
          name: boards[key].name,
          tabColor: boards[key].tabColor,
          id: key,
        });
      }
    });
    return data;
  }, [boards]);

  const handleEditBoard = (board) => {
    setShowBoardForm(true);
    setEditBoard(board);
  };

  const pinBoard = () => {
    props.putBoard(board?.id, {
      pinned: board?.pinned ? !board.pinned : true,
    });
  };

  const toggleWidgetsModal = () => {
    setShowBoardForm(true);
    setDefaultAction('widgets');
  };

  const toggleTemplatesModal = () => {
    setShowBoardForm(true);
    setDefaultAction('templates');
  };

  const onCloseModal = () => {
    setShowBoardForm(false);
    setDefaultAction('');
    setEditBoard({});
  };

  const handleClear = () => {
    props.clearBoardFilters(activeBoard);
    props.setBoardFilters(defaultFilterObj, activeBoard);
  };

  const handleDeleteBoard = async () => {
    try {
      setDeleting(true);
      await props.deleteBoard(deleteBoard.id);
      setDeleting(false);
      setDeleteBoard({});
      setEditBoard({});
      setShowBoardForm(false);
    } catch (error) {}
  };

  const onSearch = (search) => {
    props.searchPanelContacts(search);
  };

  const onFilterChange = (key, value) => {
    props.setBoardFilters({ ...appliedFilters, [key]: value }, activeBoard);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddBoard = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {loading?.boards === true ? (
        <FixedTitleBar>
          <DashboardSkeleton />
          <Box
            mt={5}
            mb={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <CircularProgress color="secondary" />
          </Box>
        </FixedTitleBar>
      ) : (
        <>
          <FixedTitleBar>
            <Tabs
              tabs={tabs}
              onChange={(value) => {
                // props.setActiveBoard(value.id);
                props.history.push(`/boards/${value.id}`);
              }}
              value={activeBoard}
              showNewTabButton={true}
              buttonLabel="Board"
              handleNewTab={(e) => {
                // setShowBoardForm(true);
                handleAddBoard(e);
              }}
              variant="scrollable"
              scrollButtons="auto"
              renderActiveTabItemActions={(tab) => {
                return (
                  <ButtonBase
                    onClick={() => {
                      handleEditBoard(boards[tab.id]);
                    }}
                  >
                    <EditIcon sx={{ color: '#00000060', fontSize: '20px' }} />
                  </ButtonBase>
                );
              }}
              tabBgColor="tabColor"
            />
          </FixedTitleBar>

          {!activeBoard || activeBoard === '' ? (
            <EmptyBoard handleAddBoard={handleAddBoard} />
          ) : (
            <>
              {board?.type === 'swimlanes' ? (
                <Box mt="48px">
                  <PanelsWrapper type={swimlaneType}>
                    <Contacts setIsOpen={handleOpen} />
                    {loader.panels === true ? (
                      <Panels ref={scrollRef} isOpen={isOpen}>
                        <PanelSkeleton />
                      </Panels>
                    ) : (
                      <Box>
                        <Box
                          sx={{ paddingLeft: '38px', width: '90%' }}
                          mt={1}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {activeBoard && activeBoard !== '' ? (
                            <FilterBar
                              showSearch={true}
                              filters={filters}
                              appliedFilters={appliedFilters}
                              onChange={(data) => {
                              }}
                              onSearch={onSearch}
                              searchText={panelSearch}
                            />
                          ) : null}

                          <SwimlaneActions
                            handleEditBoard={handleEditBoard}
                            board={board}
                            pinBoard={pinBoard}
                            setShowStagesSettings={setShowStagesSettings}
                            setShowCloneBoardForm={setShowCloneBoardForm}
                            swimlaneType={swimlaneType}
                            toggleSwimlaneType={toggleSwimlaneType}
                          />
                        </Box>
                        <Box display={swimlaneType === 'panels' ? 'block' : 'none'}>
                          <Panels ref={scrollRef} isOpen={isOpen}>
                            {Object.keys(panels).map((panelId) => {
                              const panel = panels[panelId];
                              return (
                                <Panel
                                  key={`panel-${panelId}`}
                                  panel={panel}
                                  putPanel={props.putPanel}
                                  deletePanel={props.deletePanel}
                                  panelSearch={panelSearch}
                                />
                              );
                            })}
                            <AddPanel postPanel={props.postPanel} />
                          </Panels>
                        </Box>
                        {swimlaneType === 'grid' ? <BoardContactsGrid panels={panels} /> : null}
                      </Box>
                    )}
                  </PanelsWrapper>
                </Box>
              ) : (
                <Container
                  maxWidth="lg"
                  sx={{
                    padding: '24px !important',
                    marginTop: '48px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  {activeBoard && activeBoard !== '' ? (
                    <>
                      <Box display="flex" justifyContent="space-between">
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                          <FilterBar
                            filters={filters}
                            appliedFilters={appliedFilters}
                            onChange={onFilterChange}
                          />
                          {isFilterApplied !== 0 && (
                            <Button
                              variant="text"
                              disableElevation={true}
                              onClick={handleClear}
                              startIcon={<ClearAllIcon />}
                              sx={{
                                height: 'fit-content',
                                color: 'white',
                                textTransform: 'none',
                                fontWeight: 400,
                                backgroundColor: '#1976D2',
                                padding: '6px 8px',
                                '&:hover': {
                                  background: '#1976D2',
                                },
                              }}
                            >
                              <Typography noWrap sx={{ maxWidth: '144px' }} variant="body2">
                                Clear Filter
                              </Typography>
                            </Button>
                          )}
                        </Box>
                        <BoardActions
                          handleEditBoard={handleEditBoard}
                          board={board}
                          pinBoard={pinBoard}
                          toggleWidgetsModal={toggleWidgetsModal}
                          setShowCloneBoardForm={setShowCloneBoardForm}
                        />
                      </Box>
                      <Board
                        layouts={layouts}
                        widgets={boardWidgets}
                        updateBoardLayout={props.updateBoardLayout}
                        toggleWidgetsModal={toggleWidgetsModal}
                        toggleTemplatesModal={toggleTemplatesModal}
                        handleBoardResize={props.handleBoardResize}
                      />
                    </>
                  ) : null}

                  <Spacer x={4} y={4} />
                </Container>
              )}
            </>
          )}
        </>
      )}

      <Modal size="lg" open={showBoardForm} onClose={onCloseModal} padding={false}>
        <BoardForm
          onClose={onCloseModal}
          postBoard={props.postBoard}
          putBoard={props.putBoard}
          editBoard={editBoard}
          setDeleteBoard={setDeleteBoard}
          templateCategories={props.templateCategories}
          boardCategories={props.boardCategories}
          loading={loading}
          defaultAction={defaultAction}
          addWidgetsToBoard={props.addWidgetsToBoard}
          removeWidgetsFromBoard={props.removeWidgetsFromBoard}
          useTemplateForBoard={props.useTemplateForBoard}
          board={board}
        />
      </Modal>

      <Modal
        size="sm"
        title="Manage Board Stages"
        open={showStagesSettings}
        onClose={() => {
          setShowStagesSettings(false);
        }}
      >
        <BoardStages
          board={board}
          onClose={() => {
            setShowStagesSettings(false);
          }}
        />
      </Modal>

      <Modal
        size="xs"
        title="Clone Board"
        open={showCloneBoardForm}
        onClose={() => {
          setShowCloneBoardForm(false);
        }}
      >
        <CloneBoardForm
          board={board}
          postBoard={props.postBoard}
          cloneBoard={(payload) => {
            return props.cloneBoard(board.id, payload);
          }}
          onClose={() => {
            setShowCloneBoardForm(false);
          }}
        />
      </Modal>

      <ConfirmDialog
        title="Delete board"
        open={Boolean(deleteBoard?.id)}
        onClose={() => {
          setDeleteBoard({});
        }}
        loading={deleting}
        onConfirm={handleDeleteBoard}
      >
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to delete <b>{deleteBoard?.name}</b> board?
        </Typography>
      </ConfirmDialog>

      <SharedBoardsMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        boards={boards}
        setShowBoardForm={setShowBoardForm}
        putBoard={props.putBoard}
      />
    </>
  );
};

export default BoardsView;
