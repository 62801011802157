import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  videoThumbContainer: {
    aspectRatio: 1.778,
    background: 'linear-gradient(rgb(34, 34, 34) 0%, rgb(85, 85, 85) 100%)',
    borderRadius: '12px',
    height: 'auto',
    position: 'relative',
    width: '100%',
    '&:hover': {
      '& .backdrop': {
        opacity: 0.4,
      },
      '& .video-play-wrapper': {
        opacity: 0.8,
      },
    },
  },
  backdrop: {
    backgroundColor: 'rgba(12, 23, 33, 60)',
    borderRadius: '8px',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    opacity: 0,
    zIndex: 10,
  },
  thumbnailImage: {
    borderRadius: '8px',
    height: '100%',
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 9,
  },
  videoPlayWrapper: {
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    objectFit: 'contain',
    opacity: 0.6,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 11,
  },
  playPlaceholderButtom: {
    borderRadius: '50%',
  },
  contentContainer: {
    gap: '16px',
    height: '100%',
  },
  contentHeader: {
    fontSize: '20px',
    fontWeight: 600,
  },
  contentDescription: {
    fontSize: '16px',
  },
  actionContainer: {
    alignItems: 'end',
    flex: 1,
    flexDirection: 'row',
    gap: '16px',
  },
  imagePlaceholder: {
    backgroundColor: 'linear-gradient(rgb(34, 34, 34) 0%, rgb(85, 85, 85) 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& svg': {
      width: '120px',
    },
  },
}));

export default useStyles;
