import React from 'react';
import Stack from '@mui/material/Stack';
import GettingStarted from '../../components/GettingStarted';
import HomeCompetitions from '../../components/home/HomeCompetitions';
import HomeInProgress from '../../components/home/HomeInProgress';
import HomeAssigned from '../../components/home/HomeAssigned';
import HomeSaved from '../../components/home/HomeSaved';
import RecommendedCourses from '../../components/home/RecommendedCourses';
import RecommendedSessions from '../../components/home/RecommendedSessions';
import HomeSkeleton from '../../components/home/HomeSkeleton';
import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';

function LMSHome() {
  const { homePage, loading } = useLmsHomeProvider();

  const components = homePage.component || [];

  const renderComponent = (component) => {
    switch (component.name) {
      // case 'myCompetitions':
      //   return <HomeCompetitions title={component.label} />;

      case 'inProgress':
      case 'progress':
        return <HomeInProgress title={component.label} />;

      case 'assignedToMe':
        return <HomeAssigned title={component.label} />;

      case 'saved':
        return <HomeSaved title={component.label} />;

      case 'recommendedSessions':
        return <RecommendedSessions title={component.label} />;

      case 'recommendedCourse':
        return <RecommendedCourses title={component.label} />;

      default:
        return <React.Fragment />;
    }
  };

  return (
    <Stack gap={3}>
      <GettingStarted />

      {loading?.home && <HomeSkeleton />}

      {components.length > 0 &&
        components.map((component, index) => (
          <div key={`homepage-component-${index}`}>{renderComponent(component)}</div>
        ))}
    </Stack>
  );
}

export default LMSHome;
