import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { themeColors } from '../../config';
import useStyles from './styles';
import { Box, IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ReplayIcon from '@mui/icons-material/Replay';
import StarIcon from '@mui/icons-material/Star';

export default function ActionSessionMenu({
  handleOnPreview = () => {},
  handleOnVideoFrame = () => {},
  handleOnFav = () => {},
  id,
  contentType,
  from,
  setHover = () => {},
  data,
  fav = false,
  play = false,
  ...props
}) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const NotStarted = () => {
    return (
      <svg
        width="25"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3200_61900)">
          <path
            d="M7.66406 4.16949V15.8357C7.66402 15.9823 7.69991 16.1264 7.76809 16.2533C7.83628 16.3802 7.93435 16.4855 8.0524 16.5585C8.17046 16.6316 8.30432 16.6698 8.44047 16.6693C8.57662 16.6688 8.71024 16.6296 8.82783 16.5557L18.1132 10.7226C18.2301 10.6492 18.3271 10.5441 18.3945 10.4178C18.4619 10.2915 18.4974 10.1483 18.4974 10.0026C18.4974 9.8569 18.4619 9.71374 18.3945 9.58741C18.3271 9.46108 18.2301 9.35599 18.1132 9.28263L8.82783 3.44951C8.71024 3.37562 8.57662 3.33644 8.44047 3.33594C8.30432 3.33544 8.17046 3.37364 8.0524 3.44668C7.93435 3.51972 7.83628 3.625 7.76809 3.75192C7.69991 3.87883 7.66402 4.02286 7.66406 4.16949Z"
            fill="white"
          />
          <path
            d="M4.33333 3.33594C4.53744 3.33596 4.73445 3.4109 4.88698 3.54653C5.03951 3.68217 5.13695 3.86906 5.16083 4.07177L5.16667 4.16927V15.8359C5.16643 16.0483 5.0851 16.2526 4.93929 16.4071C4.79349 16.5615 4.59421 16.6545 4.38217 16.6669C4.17014 16.6794 3.96135 16.6104 3.79847 16.4741C3.6356 16.3377 3.53092 16.1444 3.50583 15.9334L3.5 15.8359V4.16927C3.5 3.94826 3.5878 3.7363 3.74408 3.58002C3.90036 3.42374 4.11232 3.33594 4.33333 3.33594Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3200_61900">
            <rect width="25" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setHover(false);
  };

  const handlePreview = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleOnPreview();
    setHover(false);
  };

  const handlePlay = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (contentType === 'session') {
      history.push(`/lms/session/${id}?autoplay=video`);
    } else {
      history.push(`/lms/course/${id}`);
    }
  };

  return (
    <div className={classes.hoverScreen} onClick={handlePlay}>
      <Box className={classes.iconsBox}>
        <IconButton onClick={handlePreview}>
          <VisibilityIcon className={classes.visibilityIcon} />
        </IconButton>

        {/* {play ? (
          <IconButton onClick={handleOnVideoFrame}>
            <PlayArrowIcon />
          </IconButton>
        ) : null} */}

        <IconButton onClick={handlePlay}>
          <PlayArrowIcon />
        </IconButton>

        {/* <IconButton>
          <NotStarted />
        </IconButton> */}
        <IconButton onClick={handleOnFav}>
          {fav ? (
            <StarIcon className={classes.starIcon} />
          ) : (
            <StarBorderIcon className={classes.starIcon} />
          )}
        </IconButton>
        {/* <IconButton>
          <ReplayIcon />
        </IconButton> */}
      </Box>
    </div>
  );
}
