import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, Alert, Stack } from '@mui/material';
import { Modal, Form, Spacer, Button } from 'src/components/shared';

import { fetchTemplateDetails, updateTemplate, saveTemplate } from 'src/modules/admin/api/adminApi';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import {
  testPopulateTemplate,
  fetchTemplatesLookup,
  sendTestEmail,
} from 'src/modules/app/api/appApis';
import { updateTask } from 'src/modules/tasks/api/taskApis';
import { fetchOutboxTasks, setOutbox } from 'src/modules/tasks/actions/taskActions';
import { debounce } from 'lodash';

import EmailIcon from '@mui/icons-material/Email';
import toast from 'src/utils/toast';

function EditEmail({ task, title, handleClose, handleClick = () => null }) {
  if (!task.id) return null;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const [subjectPopulated, setSubjectPopulated] = useState('');
  const [contentPopulated, setContentPopulated] = useState('');

  const [errors, setErrors] = useState([]);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const user = useSelector((state) => state.auth.user);
  const outbox = useSelector((state) => state.tasks.outbox);

  const formRef = useRef();

  const refetch = (response) => {
    const data = outbox.data.map((item) => {
      if (item.id === task.id) {
        return {
          ...response.task,
          template: response.task?.template?.id || '',
        };
      }
      return item;
    });
    dispatch(setOutbox(data, { total: outbox.paging.count }));
    handleClick({
      ...response.task,
      template: response.task?.template?.id || '',
    });
    // dispatch(fetchOutboxTasks('', outbox.paging, outbox.filters, outbox.sort));
  };

  const errorString = useMemo(() => {
    if (errors && errors.length) {
      return `Please remove ${errors.join(', ')} to enable your sending.`;
    }
    return '';
  }, [errors]);

  useEffect(() => {
    if (task?.template && task?.template !== '' && task?.template !== null) {
      fetchTemplateDetails(task.template)
        .then((res) => {
          setTemplate(res.template);
          setContent(res.template.content);
          setContentPopulated(res.template.content);
          setSubjectPopulated(res.template.subject);
          setSubject(res.template.subject);
        })
        .catch((err) => {});
    } else {
      setSubject();
    }
  }, [task]);

  useEffect(() => {
    if (subject !== '' && content !== '') {
      fetchPopulateContent();
    }
  }, [subject, content]);

  const fetchPopulateContent = () => {
    if (task?.id && user?.id) {
      testPopulateTemplate(subject, content, task.contact.id, user.id)
        .then((response) => {
          setContentPopulated(response.content);
          setSubjectPopulated(response.subject);
          setErrors(response.errors);
        })
        .catch((error) => {});
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (values.template.id === task.template) {
        setLoading(true);
        const payload = {
          type: 'clonedEmail',
          name: values.template.name,
          subject: values.subject,
          content: values.content,
          category: values.template.category,
          isPublished: true,
          files:
            values?.template?.attachments?.length > 0
              ? values?.template?.attachments.map((file) =>
                  typeof file === 'string' ? file : file?.id,
                )
              : [],
          attachments: values.attachments.length ? values.attachments.map((file) => file?.id) : [],
          sharedWithTeams: values.template?.sharedWithTeams || [],
          sharedWithUsers: values.template?.sharedWithUsers || [],
          permissionType: values.template.permissionType,
          sourceTemplate: values.template.id,
        };
        let newTemplate = await saveTemplate(payload);
        const response = await updateTask(task.id, {
          description: values.content,
          template: newTemplate.template.id,
        });
        setLoading(false);
        toast.success('Email updated successfully.');
        handleClose(true);
        refetch(response);
      } else {
        setLoading(true);
        let newTemplate = await saveTemplate({
          type: 'clonedEmail',
          name: values.template.name,
          subject: values.subject,
          content: values.content,
          category: values.template.category,
          isPublished: true,
          files:
            values?.template?.attachments?.length > 0
              ? values?.template?.attachments.map((file) =>
                  typeof file === 'string' ? file : file?.id,
                )
              : [],
          attachments: values?.attachments?.length
            ? values.attachments.map((file) => file?.id)
            : [],
          sharedWithTeams: values.template?.sharedWithTeams || [],
          sharedWithUsers: values.template?.sharedWithUsers || [],
          permissionType: values.template.permissionType,
          sourceTemplate: values.template.id,
        });
        const response = await updateTask(task.id, {
          description: values.content,
          template: newTemplate.template.id,
        });
        setLoading(false);
        toast.success('Email updated successfully.');
        handleClose(true);
        refetch(response);
      }
    } catch (error) {
      toast.error(error?.error?.message || 'Error ocurred. Please try again.');
      setLoading(false);
    }
  };

  const handleContentChange = useCallback(
    debounce((value) => setContent(value), 500),
    [],
  );

  const sendEmail = async () => {
    try {
      const { content, subject } = formRef?.current?.values;
      const payload = {
        contact: task.contact.id,
        sender: user.id,
        subject,
        content,
      };
      const res = await sendTestEmail(payload);
      toast.success('Test email sent.');
    } catch (error) {}
  };

  return (
    <Modal open={true} title={title} maxWidth="md" onClose={handleClose}>
      <Form
        initialValues={{
          subject: template?.subject || '',
          content: template?.content || '',
          template: template,
          attachments: template?.attachments || [],
          // files: template?.attachments || [],
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        innerRef={formRef}
      >
        {({ values, ...props }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            noValidate
          >
            {errorString && errorString !== '' ? (
              <Box mb={2}>
                <Alert severity="error" sx={{ padding: '4px 12px' }}>
                  {errorString}
                </Alert>
              </Box>
            ) : null}
            <Grid container>
              <Grid item md={7}>
                <Box
                  mb={-0.5}
                  sx={{
                    borderColor: '#cccccc',
                    borderWidth: '1px 0px 0px 1px',
                    borderStyle: 'solid',
                    padding: '0px 10px',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      borderColor: '#cccccc',
                      borderWidth: '0px 0px 1px 0px',
                      borderStyle: 'solid',
                      padding: '0px 10px',
                      marginLeft: '-10px',
                      marginTop: '-6px',
                      marginBottom: '-6px',
                      width: '100%',
                    }}
                  >
                    <Box flex={1} pt={0.5}>
                      <Typography variant="body2" color="textSecondary">
                        Template
                      </Typography>
                    </Box>
                    <Box flex={3}>
                      <Form.Field.AutoComplete
                        fullWidth
                        name="template"
                        options={[template]}
                        optLabel="name"
                        optValue="id"
                        sx={{ marginTop: '0 !important' }}
                        remoteMethod={(val) => {
                          return fetchTemplatesLookup(val, 'email');
                        }}
                        onChange={(val) => {
                          setSubject(val.subject);
                          setContent(val.subject);
                          props.setFieldValue('subject', val.subject);
                          props.setFieldValue('content', val.content);
                        }}
                      />
                    </Box>
                  </Stack>
                  <Form.Field.InputDebounced
                    className="inline-form-select"
                    name="subject"
                    fullWidth={true}
                    emojiPicker={true}
                    showPicker={true}
                    options={replacementVariables}
                    optValue="value"
                    optLabel="text"
                    onChange={(val) => {
                      setSubject(val);
                    }}
                  />
                </Box>
                <Box sx={{ marginRight: '-1px' }}>
                  <Form.Field.TextEditor
                    name="content"
                    // onChange={(val) => {
                    //   setContent(val);
                    // }}
                    onChange={handleContentChange}
                  />
                </Box>
              </Grid>
              <Grid item md={5}>
                <Box
                  // position="fixed"
                  border="1px solid #cccccc"
                  borderBottom="none"
                  p={2}
                  maxHeight={'318px'}
                  minHeight={'318px'}
                  overflow="auto"
                >
                  <Typography>
                    <b>{subjectPopulated}</b>
                  </Typography>

                  {/* <Spacer x={1} y={1} /> */}
                  <Typography component="div">
                    <div dangerouslySetInnerHTML={{ __html: contentPopulated }} />
                  </Typography>
                </Box>
                <Box
                  border="1px solid #cccccc"
                  borderTop="none"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  pt={1}
                >
                  <Button color="secondary" startIcon={<EmailIcon />} fullWidth onClick={sendEmail}>
                    Send test email
                  </Button>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Form.Field.File name="attachments" maxSize={8} />
              </Grid>

              <Grid item md={12} display="flex" justifyContent="flex-end" mt={2}>
                <Button variant="contained" type="submit" color="secondary" loading={loading}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Modal>
  );

  return <div>EditEmail</div>;
}

export default EditEmail;
