import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { EmptyIcon } from './styles';
import images from 'src/config/images';
import SendIcon from '@mui/icons-material/Send';
import { isEmpty } from 'lodash';

const PublishSequence = ({ steps, events, loading, days, onPublish }) => {
  const eventsArr = Object.values(events);

  const duration = useMemo(() => {
    let seconds = 0;

    if (eventsArr && eventsArr.length) {
      const waitEvents = eventsArr.filter((item) => item.eventType === 'wait');
      if (waitEvents.length) {
        for (let i = 0; i < waitEvents.length; i++) {
          const event = waitEvents[i];
          switch (event.unit) {
            case 'minutes':
              seconds += event.waitTime * 60;
              break;

            case 'hours':
              seconds += event.waitTime * 3600;
              break;

            case 'days':
              seconds += event.waitTime * 86400;
              break;

            case 'weeks':
              seconds += event.waitTime * 86400 * 7;
              break;

            case 'months':
              seconds += event.waitTime * 86400 * 30;
              break;

            case 'years':
              seconds += event.waitTime * 86400 * 365;
              break;

            default:
            case 'seconds':
              seconds += event.waitTime;
              break;
          }
        }
      }
      return seconds > 0 ? Math.ceil(seconds / 86400) || 1 : 1;
    }

    return 0;
  }, [events, eventsArr]);

  const totalSteps = useMemo(() => {
    if (Object.keys(events).length === 0) return 0;
    return Object.values(events).filter(
      (item) => item.eventType !== 'wait' && item.eventType !== 'endsquence',
    )?.length;
  }, [events, eventsArr]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={440}
      margin="0 auto"
      height="100%"
      justifyContent="center"
      alignItems={'center'}
      pt={10}
    >
      <img src={images.sequence.PublishSequenceIcon} />
      <Spacer basis={5} />
      <Typography align="center">Your sequence has</Typography>
      <Typography align="center" component="span">
        <Typography color="primary" component="span">
          <span style={{ color: '#1976D2' }}>
            {totalSteps + (totalSteps > 1 ? ' steps' : ' step')}
          </span>{' '}
        </Typography>
        and will take
      </Typography>
      <Typography align="center" component="span">
        <Typography color="primary" component="span">
          <span style={{ color: '#1976D2' }}>{duration > 1 ? duration + ' days' : duration + ' day'}</span>{' '}
        </Typography>{' '}
        to complete
      </Typography>
      <Spacer basis={5} />
      <Box>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          startIcon={<img src={images.sequence.CreateIcon} />}
          onClick={onPublish}
          loading={loading}
        >
          Publish Sequence
        </Button>
      </Box>
    </Box>
  );
};

export default PublishSequence;
