import React, { useEffect, useState } from 'react';

import { PageTitle } from 'src/components/App';
import CreateVoiceView from './CreateVoiceView';
import { connect } from 'react-redux';
import { fetchVoiceById, saveVoice, updateVoice } from '../../actions/authActions';

export const createBrandVoiceContainer = ({ ...props }) => {
  return (
    <>
      <PageTitle title="Brand Voices" />
      <CreateVoiceView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getVoiceById: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchVoiceById(id, resolve, reject));
    });
  },
  updateVoice: (rowData) => {
    return new Promise((resolve, reject) => {
      dispatch(updateVoice(rowData, resolve, reject));
    });
  },
  saveVoice: (payload) => {
    return new Promise((resolve, reject) => {
      dispatch(saveVoice(payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(createBrandVoiceContainer);
