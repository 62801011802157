import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { ActivitiesWrapper } from './styles';
import ActivityItem from './ActivityItem';
import FilterItems from './FilterItems';
import Pagination from '@mui/material/Pagination';
import ContactActivitiesSkeleton from './ContactActivitiesSkeleton';
import { Empty } from 'src/components/App';

function ContactActivities(props) {
  const { data = [], paging = {}, filters: activeFilters } = props.contactNotes || {};
  const contactId = props.match.params.id;
  const handleChange = (event, value) => {
    props.fetchContactNotes(contactId, '', { pageNo: value - 1, perPage: 10 });
  };

  const handleTeamFilter = (key, val) => {
    props.fetchContactNotes(contactId, {
      teams: val,
    });
  };

  return (
    <>
      {props.isLoadingContactNotes ? (
        <ActivitiesWrapper>
          <ContactActivitiesSkeleton />
        </ActivitiesWrapper>
      ) : (
        <ActivitiesWrapper>
          <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
            <Typography variant="body2" color="textSecondary">
              Filter By
            </Typography>
            <Spacer basis={2} />
            <FilterItems
              fetchContactNote={props.fetchContactNotes}
              contact={props.contact}
              handleTeamFilter={handleTeamFilter}
              usersActiveFilters={activeFilters?.users_eq}
              typeActiveFilters={activeFilters?.type_eq}
              appliedFilters={activeFilters}
            />
          </Box>
          <Divider />
          <Spacer x={2} y={2} />
          {!data.length ? (
            <Empty title="No Activities found!" />
          ) : (
            data.map((note, index) => (
              <div key={`contact-activity-${index}`}>
                <ActivityItem
                  type={note.type || 'email'}
                  title={note.note || ''}
                  subtitle={note.task?.description || ''}
                  date={note.createdAt}
                />
                <Spacer x={2} y={2} />
              </div>
            ))
          )}
          {data.length ? (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent={'center'}
              mt={2}
            >
              <Pagination
                count={Math.ceil(paging.count / paging.perPage)}
                page={paging.pageNo + 1}
                onChange={handleChange}
              />
            </Box>
          ) : null}
        </ActivitiesWrapper>
      )}
    </>
  );
}

ContactActivities.propTypes = {};

export default ContactActivities;
