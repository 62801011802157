import React from 'react';
import { debounce } from 'lodash';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { useStyles } from './styles';

const BannerSection = ({
  title,
  subTitle,
  helperText,
  backgroundImage,
  initialSearchText,
  searchLoading,
  onSearchChange,
}) => {
  const classes = useStyles();
  const searchRef = React.useRef();

  React.useEffect(() => {
    searchRef.current.value = initialSearchText;
    onSearchChange(initialSearchText);
  }, [initialSearchText]);

  const onChange = debounce(async (event) => {
    try {
      const { value = '' } = event?.target ?? {};
      onSearchChange(value);
    } catch (error) {}
  }, 500);

  return (
    <Stack className={classes.BannerSection}>
      <Stack className="content-wrapper">
        {backgroundImage && <img className="background" src={backgroundImage} alt="background" />}
        {(title || subTitle) && (
          <Stack className="header-content">
            {title && <Typography className="title">{title}</Typography>}
            {subTitle && <Typography className="sub-title">{subTitle}</Typography>}
          </Stack>
        )}
        <TextField
          inputRef={searchRef}
          name="search"
          className="search-bar"
          variant="outlined"
          placeholder="Search"
          autoComplete="off"
          onChange={(event) => {
            event.persist();
            onChange(event);
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  fill: 'rgb(191, 191, 191)',
                  width: '1.75rem',
                  height: '1.75rem',
                  marginRight: '.75rem',
                }}
              />
            ),
            endAdornment: (
              <React.Fragment>
                {searchRef?.current ? (
                  <IconButton
                    onClick={() => {
                      onSearchChange('');
                      searchRef.current.value = '';
                    }}
                  >
                    <CloseIcon style={{ color: 'white' }} />
                  </IconButton>
                ) : null}
                {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
        {helperText && <Typography className="helper-text">{helperText}</Typography>}
      </Stack>
    </Stack>
  );
};

export default BannerSection;
