import React from 'react';
import { Container, Box, Typography } from '@mui/material';

import Activity from '../../components/dashboard/Activity';
import { withActivityDashboardProvider } from '../../utils/activityDashboardStore';

function ActivityDashboardView() {
  return (
    <>
      <Container>
        <Box>
          <Typography
            sx={{
              color: '#81868B',
              fontSize: '14px',
              fontWeight: 500,
              mt: '12px',
            }}
          >
            This dashboard shows the total activities based on the filters applied.
          </Typography>
          <Activity />
        </Box>
      </Container>
    </>
  );
}

export default withActivityDashboardProvider(ActivityDashboardView);
