import React, { useCallback, useState } from 'react'
import { FormControl, TextField, CircularProgress, Autocomplete, Box, Chip } from '@mui/material';
import useStyles from './style';
import SearchIcon from '@mui/icons-material/Search';
import { debounce, throttle } from 'lodash';
import api from 'src/api';
import SearchList from './SearchList';
import Paper from "@mui/material/Paper";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import images from 'src/config/images';

const DisIcon = styled.img`
  width: 48px;
`;

function CustomPaper({ children, buttons, chips, ...other }) {
    const anchor1 = document.querySelector(".search-popper")
    return (
        <Paper {...other} sx={{ width: anchor1?.clientWidth }}>
            {chips}
            {children}
            {buttons}
        </Paper>
    );
}


const Search = ({ property, handleInsertProperty }) => {
    const [options, setOptions] = useState([]);
    const [defaultVal, setDefaultVal] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [page, setPage] = useState(0);
    const perPage = 25;
    const classes = useStyles();
    const [filter, setFilter] = useState("all");
    const [selectedItems, setSelectedItems] = useState([])
    const [open, setOpen] = useState(false)

    const onType = useCallback(
      debounce(async (e) => {
        try {
          const { value } = e.target;
          if (value && value !== '' && value.length > 3) {
            setOpen(true);
            setFilter('all');
            setIsEmpty(false);
            setSearch(value);
            setOptions([]);
            setLoading(true);
            setDefaultVal({
              name: value,
              id: 0,
            });
            setSelectedItems([]);
            const data = await getRemoteData(value);
            if (data.length == 0) {
              setIsEmpty(true);
            }
            setOptions(data);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error: ', error);
        }
      }, 300),
      [],
    );
    const getRemoteData = async (val, type) => {
      return new Promise(async (resolve, reject) => {
        try {
          setHasMoreResults(false);
          const _from = page * perPage;
          let response;
          if (type) {
            response = await api(`/roleplay/${property}?_search=${val}&type=${type}`, null, 'get');
          } else {
            response = await api(`/roleplay/${property}?_search=${val}`, null, 'get');
          }

          if (response[property][property].length == perPage) {
            setPage(page + 1);
            setHasMoreResults(true);
          }
          resolve(response[property][property]);
        } catch (error) {
          reject(error);
        }
      });
    };

    const loadMoreResults = async () => {
      if (hasMoreResults) {
        setLoading(true);
        const response = await getRemoteData(search);
        setOptions([...options, ...response]);
        setLoading(false);
      }
    };

    const onClose = () => {
      setOptions([]);
      setDefaultVal({});
      setSearch('');
      setSelectedItems([]);
      setOpen(false);
    };

    const handleRemove = (id) => {
      if (options?.length === 1) {
        onClose();
      }
      setOptions((prev) => prev.filter((item) => item.id !== id));
    };

    const handleFilter = useCallback(
      throttle(async (type) => {
        setFilter(type);
        try {
          setLoading(true);
          const data = await getRemoteData(search, type);
          if (data.length == 0) {
            setIsEmpty(true);
          }
          setOptions(data);
          setLoading(false);
        } catch (error) {
          console.error('Error: ', error);
        }
      }, 300),
      [],
    );



    const optLabel = 'name';
    const optValue = 'id';

    const buttons = (
        <Box display="flex" justifyContent={"flex-end"} gap={"1rem"} padding="24px 40px" sx={{ borderTop: "1px solid rgba(0,0,0,0.23)" }}>
            <Chip
                label="Cancel"
                sx={{ margin: 1 }}
                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                onClick={(e) => {
                    setOpen(false)
                    onClose()
                }}
            />
            <Chip
                label="Insert"
                sx={{ margin: 1 }}
                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                color="secondary"
                icon={<DisIcon src={images.roleplay.insert_icon} width="24px" height="24px" />}
                onClick={(e) => {
                    handleInsertProperty(property, selectedItems)
                    setOpen(false)
                    onClose()
                }}
            />
        </Box>
    );

    const chips = (
        <Box display="flex" justifyContent={"flex-start"} padding="24px 40px" >
            <Chip
                {...(filter === "all" && {
                    color: "secondary",
                    variant: "outlined"
                })}
                label="All"
                sx={{ margin: 1 }}
                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                value="all"
                onClick={(e) => handleFilter("all")}
            />
            <Chip
                {...(filter === "superreach" && {
                    color: "secondary",
                    variant: "outlined"
                })}
                label="By SuperReach"
                sx={{ margin: 1 }}

                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                value="superreach"
                onClick={(e) => handleFilter("superreach")}
            />
            <Chip
                {...(filter === "custom" && {
                    color: "secondary",
                    variant: "outlined"
                })}
                label="Custom"
                value="custom"
                sx={{ margin: 1 }}
                onMouseDown={(event) => {
                    event.preventDefault();
                }}
                onClick={(e) => handleFilter("custom")}
            />
        </Box>
    );



    return (
        <FormControl fullWidth={true} margin="normal" className={classes.globalSearchRoot}>
            <Autocomplete
                multiple={false}
                noOptionsText={
                    loading ? 'Please wait...' : isEmpty ? 'No results found!' : 'Start typing...'
                }
                value={defaultVal}
                disabled={false}
                options={options}
                getOptionLabel={(option) => search || ''}
                isOptionEqualToValue={(option, value) => {
                    return option[optValue] === value[optValue];
                }}
                open={open}
                classes={{
                    popper: `${classes.globalSearchPopper} search-popper`,
                    listbox: classes.globalSearchListBox,
                }}
                renderOption={(props, option) => {
                    return <SearchList props={props} data={option} setSelectedItems={setSelectedItems} selectedItems={selectedItems} handleRemove={handleRemove} />
                }}
                onClose={onClose}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableCloseOnSelect={true}
                ListboxProps={{
                    onScroll: (event) => {
                        const listBoxNode = event.currentTarget;
                        if (listBoxNode.scrollTop + listBoxNode.clientHeight === listBoxNode.scrollHeight) {
                            loadMoreResults();
                        }
                    },
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            value={search}
                            placeholder="Search"
                            variant="outlined"
                            fullWidth={true}
                            onChange={(e) => {
                                e.persist();
                                onType(e);
                            }}
                            disabled={false}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <React.Fragment>
                                        <SearchIcon />
                                    </React.Fragment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    </React.Fragment>
                                ),
                                classes: { notchedOutline: classes.globalSearchNoBorder },
                            }}
                        />
                    );
                }}
                PaperComponent={CustomPaper}
                componentsProps={{ paper: { buttons: buttons, chips: chips } }}
                name={'globalSearch'}
            />
        </FormControl>
    )
}

Search.propTypes = {
    property: PropTypes.string,
    handleInsertProperty: PropTypes.func,
};

Search.defaultProps = {
    property: "",
    handleInsertProperty: () => { },
};

export default Search