import React, { useEffect, useState, useMemo } from 'react';
import { AppGrid } from 'src/components/App';
import { ueActivityReportColumns } from './ueActivityReportColumns';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

const ActivityReportGrid = ({ activityReport, loading, ...props }) => {
  const { data, paging, filters, sort } = activityReport;

  const reportFilters = [
    {
      key: 'user',
      title: 'User',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'createdAtRange',
      title: 'Filter By Date',
      type: 'dateRange',
      isPrimary: true,
    },
  ];

  useEffect(() => {
    fetchActivityReport(paging, filters, sort);
  }, []);

  // const columns = useMemo(() => {
  //   return ueActivityReportColumns(data);
  // }, [data]);
  const columns = ueActivityReportColumns(data);

  const fetchActivityReport = (paging, filters, sort) => {
    props.fetchActivityReport(paging, filters, sort);
  };

  const onSort = (sort) => {
    fetchActivityReport(paging, filters, sort);
  };

  const onFilterChange = (key, value) => {};

  const handleTableChange = ({ pageNo, perPage }) => {
    props.fetchTasks(
      {
        pageNo,
        perPage,
      },
      filters,
      sort,
    );
  };

  return (
    <>
      <AppGrid
        columns={columns.map((col, index) => ({
          ...col,
          options: columns?.columns?.length
            ? { ...col.options, ...columns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        onSort={onSort}
        loading={loading}
        filters={reportFilters}
        appliedFilters={filters}
        options={{
          sortOrder: {
            name: sort?.name || 'none',
            direction: sort?.direction || 'none',
          },
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
          selectableRows: 'multiple',
          searchPlaceholder: 'Search Tasks',
          searchText: filters?._search ? filters._search : '',
          search: false,
        }}
      />
    </>
  );
};

export default ActivityReportGrid;
