import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonBase, Typography, Chip, Stack, Menu, MenuItem, Tooltip } from '@mui/material';
import { Avatar, Button, Drawer, Modal, Spacer } from 'src/components/shared';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import IntegrationStatus from './IntegrationStatus';
import { ProfileWrapper, Left, Right, JobLocation, SequenceInfo } from './styles';
import {
  capitalizeName,
  getSequenceStatus,
  getSequenceStatusColorCode,
} from '../../utils/contactUtils';
import { sequenceEnrollmentStatus } from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import MailIcon from '@mui/icons-material/Mail';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { removeFromDraft } from 'src/modules/sequence/api/sequenceApi';
import { getActiveEnrolmentId, getEnrolments } from '../../saga/contactSagas';
import {
  enqueueSequencePersonalize,
  setReEnrolmentErrors,
  submitPersonalizeSequence,
} from 'src/modules/sequence/actions/enrolmentActions';
import toast from 'src/utils/toast';
import {
  addContactToSequence,
  fetchContactNotes,
  putEnrollment,
  setSequenceEnroll,
} from '../../actions/contactActions';
import SequenceStages from 'src/modules/tasks/components/Tasks/SequenceStages';
import { bounceWarningMessage } from 'src/modules/tasks/config';
import ContactDetailsForm from '../ContactDetails/ContactDetailsForm';
import BounceActions from 'src/modules/sequence/actions/BounceActions';
import { AddToSequenceForm } from '../ContactActions';
import { connect } from 'react-redux';

function Profile({
  contact,
  showEdit,
  showAddress,
  type,
  onEnrol,
  putEnrollment,
  showEmail,
  email,
  toggleContactActionDrawer = () => null,
  ...props
}) {
  const {
    IN_PROGRESS,
    COMPLETED,
    HOLD,
    FAILED,
    BOUNCED,
    ERROR,
    INTERESTED,
    NOT_INTERESTED,
    NEVER_SEQUENCED,
    DRAFT,
    NEVER,
    PAUSED,
    REPLIED,
  } = sequenceEnrollmentStatus;
  if (isNull(contact) || isEmpty(contact) || !contact) return null;

  const dispatch = useDispatch();
  const enrolments = useSelector(getEnrolments);
  const activeEnrollmentId =
    type === 'enrollment'
      ? useSelector(getActiveEnrolmentId)
      : useSelector((state) => state.contacts.sequenceEnrollment)?.[0]?.id;
  const sequenceEnrollment =
    type === 'enrollment'
      ? enrolments?.[activeEnrollmentId]
      : useSelector((state) => state.contacts.sequenceEnrollment)?.[0];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [bounceEmail, setBounceEmail] = useState(sequenceEnrollment?.selectedEmailId);
  const [showBounceForm, setShowBounceForm] = useState(false);
  const [showAddToSequence, setShowAddToSequence] = useState(false);
  const [modalType, setModalType] = React.useState('');
  const open = Boolean(anchorEl);
  const [showActions, setShowActions] = useState(false);
  const name = capitalizeName(`${contact?.fname} ${contact?.lname}`);
  const handleClick = (event) => {
    setModalType('menu');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setModalType('');
    setAnchorEl(null);
  };

  const seqStatus = sequenceEnrollment
    ? sequenceEnrollment?.status
    : sequenceEnrollmentStatus.NEVER;

  const jobTitle = contact?.jobTitle || undefined;
  const employer = contact?.employer || undefined;
  const activeSequence = sequenceEnrollment || {};

  const location = contact?.address && contact?.address.length > 0 ? contact?.address[0] : {};
  const jobLocation = `${location?.town || ''} ${
    location?.country ? ', ' + location?.country : ''
  }`;
  // const lastConnectedDate = moment(contact.updatedAt).format('ll') || '';
  const crmActiveField = ['bullhorn', 'vincere', 'jobadder', 'hubspot', 'salesforce']?.find(
    (crmField) => contact?.[crmField + 'Id'],
  );

  const crmActiveFieldType = crmActiveField
    ? contact?.[crmActiveField + 'Type'] === 'ClientContact'
      ? 'Contact'
      : contact?.[crmActiveField + 'Type']
    : null;

  const options = [
    ...([COMPLETED, INTERESTED, NOT_INTERESTED, NEVER, NEVER_SEQUENCED]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={(event) => {
              setShowAddToSequence(true);
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              Add to sequence
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([PAUSED, HOLD]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              putEnrollment(activeSequence?.id, {
                status: IN_PROGRESS,
              });
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <RestartAltRoundedIcon /> Resume
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([IN_PROGRESS, REPLIED]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              putEnrollment(activeSequence?.id, {
                status: INTERESTED,
              });
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <DoneRoundedIcon /> Completed, Interested
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([IN_PROGRESS, REPLIED]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              putEnrollment(activeSequence?.id, {
                status: NOT_INTERESTED,
              });
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <CloseRoundedIcon /> Completed, Not Interested
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([BOUNCED, ERROR, HOLD]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              putEnrollment(activeSequence?.id, {
                status: COMPLETED,
              });
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <DoneRoundedIcon /> Completed
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([IN_PROGRESS]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              putEnrollment(activeSequence?.id, {
                status: HOLD,
              });
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <PauseRoundedIcon /> Pause
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([BOUNCED]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={() => {
              setShowEmailModal(true);
              handleClose();
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <MailIcon /> Update selected email address
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([DRAFT]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={async () => {
              handleClose();
              const res = await removeFromDraft(activeEnrollmentId);
              if (res?.enrollment?.[0]?.id) {
                toast.success(
                  'Contact successfully removed from the draft',
                  type === 'enrollment' ? 'tl' : 'tr',
                );
                if (type === 'enrollment') {
                  let nextIndex = 0;
                  const filteredEnrollments = Object.keys(enrolments).reduce((acc, val, index) => {
                    if (enrolments?.[val]?.id !== activeEnrollmentId) acc[val] = enrolments[val];
                    else nextIndex = index;
                    return acc;
                  }, {});
                  dispatch(enqueueSequencePersonalize(filteredEnrollments, null, nextIndex));
                } else {
                  dispatch(setSequenceEnroll([{ ...res.enrollment?.[0], status: 'never' }]));
                }
              }
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              Remove draft
            </Stack>
          </MenuItem>,
        ]
      : []),

    ...([DRAFT]?.includes(seqStatus)
      ? [
          <MenuItem
            onClick={async () => {
              handleClose();
              if (type === 'enrollment') onEnrol();
              else {
                const updatedEnrolment = await new Promise((resolve, reject) => {
                  dispatch(submitPersonalizeSequence({}, true, resolve, reject));
                });
                dispatch(setSequenceEnroll(updatedEnrolment.enrolments));
              }
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              Enrol prospect
            </Stack>
          </MenuItem>,
        ]
      : []),
  ];

  const onEmailModalClose = () => setShowEmailModal(false);
  const closeShowBounceForm = () => setShowBounceForm(false);
  const onNextBounceError = ({ email, type }) => {
    setShowEmailModal(false);
    setShowBounceForm(true);
    setBounceEmail(email);
  };

  const onSubmitBounceNext = async (type) => {
    dispatch(
      setReEnrolmentErrors(sequenceEnrollment?.id, {
        reenroll: type,
        email: bounceEmail,
      }),
    );
    setShowBounceForm(false);
  };

  const onSeqStatusClick = (event) => {
    if (!sequenceEnrollment?.tasks?.length) return null;
    setModalType('hover');
    setAnchorEl(event.currentTarget);
  };

  const isUnsubscribed = contact.status === 'unsubscribed';

  return (
    <ProfileWrapper
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <Stack direction={'row'} gap={'16px'}>
        <Left>
          <Avatar src={contact.imageUrl} name={name} size={50} />
          {type === 'enrollment' && (
            <Box
              sx={{ width: '100%', justifyContent: 'center', marginTop: '-14px', display: 'flex' }}
            >
              <IntegrationStatus contact={contact} />
            </Box>
          )}
        </Left>

        <Right>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontSize: 18, fontWeight: 400 }}>
              <Stack direction="row" alignItems="center" gap={1}>
                {name}
                {showEmail && email !== '' && (
                  <Typography color="textSecondary" variant="body2">
                    {`< ${email} >`}
                  </Typography>
                )}
                {type === 'enrollment' && !!crmActiveFieldType && (
                  <Chip
                    sx={{
                      width: 'fit-content',
                      backgroundColor: '#4caf50',
                      color: 'white',
                      fontWeight: 400,
                      fontSize: 12,
                      height: 24,
                      marginLeft: '4px',
                      letterSpacing: '0.8px',
                    }}
                    label={crmActiveFieldType}
                  />
                )}
              </Stack>
            </Typography>

            <Spacer basis={1} />

            {!isUnsubscribed && showEdit && showActions && type !== 'enrollment' && (
              <Box display="flex" pl={0.8}>
                <ButtonBase
                  onClick={() => {
                    props.toggleAddContactForm(contact);
                  }}
                >
                  <EditIcon className="edit-icon" />
                </ButtonBase>
              </Box>
            )}
          </Box>

          <Box>
            <span style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '15px' }}>
              {jobTitle ? jobTitle : null}
              <span style={{ color: 'rgb(128, 171, 205)' }}>
                {jobTitle && employer ? ` at ` : ' '}
              </span>

              {employer}
            </span>
            {showAddress && <JobLocation>{jobLocation || ''}</JobLocation>}

            {/* {type !== 'enrollment' && lastConnectedDate ? (
              <SequenceInfo variant="caption">Last Contacted {lastConnectedDate}</SequenceInfo>
            ) : null} */}
          </Box>

          <Spacer y={2} />

          <Stack direction="row" spacing={1}>
            {type !== 'enrollment' && <IntegrationStatus contact={contact} />}
            <Tooltip
              title={isUnsubscribed ? 'Cannot perform this action on an unsubscribed user.' : ''}
            >
              <Stack direction="row" alignItems="center">
                <Chip
                  label={getSequenceStatus(seqStatus)}
                  disabled={isUnsubscribed}
                  onClick={onSeqStatusClick}
                  sx={{
                    backgroundColor: getSequenceStatusColorCode(seqStatus).background,
                    color: getSequenceStatusColorCode(seqStatus).color,
                    fontSize: '12px',
                    cursor: 'pointer',
                    height: '28px',
                  }}
                />
                {!isUnsubscribed && options?.length !== 0 && (
                  <Button
                    sx={{ padding: 0, minWidth: 'auto' }}
                    onClick={handleClick}
                    variant="text"
                  >
                    <ArrowDropDownIcon />
                  </Button>
                )}
              </Stack>
            </Tooltip>
            <Menu
              open={open && modalType === 'menu'}
              anchorEl={anchorEl}
              iconButton={false}
              onClose={handleClose}
              buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
              disableScrollLock
            >
              {options?.map((item) => item)}
            </Menu>
            <Menu
              open={open && modalType === 'hover'}
              anchorEl={anchorEl}
              iconButton={false}
              onClose={handleClose}
              buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
            >
              <SequenceStages
                seqStag={sequenceEnrollment}
                enrolment={sequenceEnrollment}
                enrolmentLoading={false}
                type="profile"
              />
            </Menu>
          </Stack>
        </Right>
      </Stack>
      <Drawer
        open={showAddToSequence}
        title={<Typography fontSize={20}>Add to sequence</Typography>}
        onClose={() => setShowAddToSequence(false)}
      >
        <AddToSequenceForm
          contact={contact}
          addContactToSequence={props.addContactToSequence}
          fetchContactNotes={props.fetchContactNotes}
          onClose={() => setShowAddToSequence(false)}
        />
      </Drawer>
      <Modal
        open={showEmailModal}
        onClose={onEmailModalClose}
        title={bounceWarningMessage}
        size="xs"
      >
        <ContactDetailsForm
          formType={'email'}
          formData={{ email: bounceEmail, type: '' }}
          onSubmit={onNextBounceError}
          onClose={onEmailModalClose}
          submitButtonText={'Next'}
          showOnlyEmail
        />
      </Modal>
      <Modal
        open={showBounceForm}
        onClose={closeShowBounceForm}
        backdrop={false}
        title="How you would like to proceed?"
      >
        <BounceActions onClick={onSubmitBounceNext} />
      </Modal>
    </ProfileWrapper>
  );
}

Profile.propTypes = {
  showEdit: PropTypes.bool,
  showBullhornButton: PropTypes.bool,
  showAddress: PropTypes.bool,
};

Profile.defaultProps = {
  showEdit: true,
  showBullhornButton: true,
  showAddress: true,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchContactNotes: (contactId, filter, paging) =>
    dispatch(fetchContactNotes(contactId, filter, paging)),

  addContactToSequence: (sequenceId, contactId) => {
    return new Promise((resolve, reject) => {
      dispatch(addContactToSequence(sequenceId, contactId, resolve, reject));
    });
  },
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
