import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const StyledBox = styled(Box)`
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 7px;
  height: 384px;
  overflow-y: auto;
`;

export const StyledTypography = styled(Typography)`
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
`;
