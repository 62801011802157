import React from 'react';
import { Grid } from '@mui/material';
import SeqProspectsCardItem from './Card';
import { useDashboardStore } from 'src/modules/app/utils/dashboardStore';

function Cards() {
  const { sequenceReport, loading } = useDashboardStore();

  return (
    <Grid container spacing={2}>
      <SeqProspectsCardItem
        type="text"
        fill="#1976D2"
        title="Enrolled"
        loading={loading?.sequenceReport}
        value={sequenceReport?.prospectEnrolled?.value || 0}
        toolTipTitle={'This shows the number of enrollments'}
      />
      <SeqProspectsCardItem
        title="Contacts"
        loading={loading?.sequenceReport}
        fill="#1976D2"
        value={sequenceReport?.prospectContacted?.value || 0}
        percentage={sequenceReport?.prospectContacted?.progress || 0}
        total={100}
        toolTipTitle={
          'Based on the number of enrollments, this shows the number of prospects contacted via email.'
        }
      />
      <SeqProspectsCardItem
        title="Opened"
        value={sequenceReport?.prospectsOpened?.value || 0}
        percentage={sequenceReport?.prospectsOpened?.progress || 0}
        total={100}
        loading={loading?.sequenceReport}
        fill="#42C340"
        toolTipTitle={
          'Based on the number contacted, this shows the number of prospects that have opened an email where open tracking has been enabled.'
        }
      />
      <SeqProspectsCardItem
        title="Clicked"
        value={sequenceReport?.prospectsClicked?.value || 0}
        percentage={sequenceReport?.prospectsClicked?.progress || 0}
        total={100}
        loading={loading?.sequenceReport}
        fill="#1864B0"
        toolTipTitle={
          'Based on the number contacted, this shows the number of prospects that have click an email where click tracking has been enabled.'
        }
      />
      <SeqProspectsCardItem
        title="Replied"
        value={sequenceReport?.prospectsReplied?.value || 0}
        percentage={sequenceReport?.prospectsReplied?.progress || 0}
        total={100}
        loading={loading?.sequenceReport}
        fill="#E69904"
        toolTipTitle={
          'Based on the number contacted, this shows the number of prospects that have replited to an email .'
        }
      />
      <SeqProspectsCardItem
        title="Interested"
        value={sequenceReport?.prospectsInterested?.value || 0}
        percentage={sequenceReport?.prospectsInterested?.progress || 0}
        total={100}
        loading={loading?.sequenceReport}
        fill="#033EAC"
        toolTipTitle={
          'Based on the number contacted, this shows the number of prospects that have been marked as interested.'
        }
      />
    </Grid>
  );
}

export default Cards;
