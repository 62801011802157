import React from 'react';
import { MobileStepper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // dots: {
  //   width: '100%',
  // },
  dot: {
    // flex: 1,
    backgroundColor: palette.text.white3,
    width: '84px',
    borderRadius: '8px',
    height: '8px',
  },
  dotActive: {
    backgroundColor: '#E3FDEC !important',
  },
  dotCompleted: {
    backgroundColor: palette.success.light,
  },
}));

const JourneyStepper = ({ currentStep, totalSteps, sx = {} }) => {
  const classes = useStyles();
  return (
    <>
      <MobileStepper
        backButton={<></>}
        nextButton={<></>}
        classes={{
          root: classes.root,
          dot: classes.dot,
          // dots: classes.dots,
          dotActive: classes.dotActive,
        }}
        steps={totalSteps}
        sx={(theme) => ({
          ...sx,
          '& .MuiMobileStepper-dot': {
            backgroundColor: theme.palette.success.light,
          },
          '& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot': {
            backgroundColor: theme.palette.text.white3,
          },
        })}
        position="static"
        activeStep={currentStep}
      />
    </>
  );
};
export default JourneyStepper;
