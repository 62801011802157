import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  flexDirection: 'initial',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  gridRow: '1',
  gridColumn: 'span 3',
}));

export const ExternalLinkWrapper = styled.div`
  border-bottom: 2px solid #ececec;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

export const IntroTitle = styled(Typography).attrs({})`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 116.7%;
  color: #000000;
`;

export const IntroDescription = styled(Typography)`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  display: inline-block;
  max-width: 580px;
`;

export const IncompleteSection = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.12);
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export const ProgressText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px;
`;

export const AccordionMainText = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.34px;
  padding: 5px;
`;

export const AccordionSubText = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.6px;
  padding: 5px;
`;

export const ProgressBar = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.12);
  border-radius: 999px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  margin-left: 6.2px;
  justify-content: center;
`;

export const InfoText = styled.span`
  color: #1976d2;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
`;

export const useStyles = makeStyles({
  stepperIndex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '0px 7px',
    border: '1px solid rgba(0, 0, 0, 0.38)',
    borderRadius: '20px',
    width: '20px',
    height: '20px',
    marginRight: 8,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  stepperCheckIcon: {
    width: 26,
    height: 26,
    marginRight: 8,
    color: '#66BB6A',
  },
  stepperButton: {
    display: 'flex',
    gap: '20px',
  },
  // ACCORDION COMPONENT STYLES : START
  outerAccordionInfoText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 400,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    lineHeight: '150%',
  },
  outerAccordionInfoTextComplete: {
    color: '#66BB6A',
    fontWeight: 400,
    fontSize: 16,
  },
  outerAccordionIconWrap: {
    position: 'relative',
  },
  outerAccordionCompletedCheck: {},
  outerAccordionIcon: {
    marginRight: 10,
    fontSize: 24,
  },
  accordionContainer: {},
  outerAccordionContainer: {
    padding: '0px !important',
    margin: '0px !important',
    background: 'none',
    borderRadius: '5px 5px 0px 0px !important',
    boxShadow: 'none',
    overflow: 'hidden',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:before': {
      background: 'transparent',
    },
  },
  outerAccordionDetails: {
    flexDirection: 'column',
    padding: '20px 24px 20px 24px',
    boxShadow: '0px 2px 3px 0px #00000012 inset',
    background: 'white',
    borderRadius: '0px 5px',
  },
  innerAccordionContainer: {
    background: 'white',
    borderBottom: '1px solid #0000001f',
    '&:before': {
      backgroundColor: 'transparent',
    },
    '&.Mui-expanded': {
      boxSizing: 'border-box',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  completedStep: {},
  innerAccordionDetails: {
    paddingLeft: 90,
  },
  outerAccordionSummary: {
    minHeight: 88,
    maxHeight: 88,
    padding: '0px 24px !important',
    background: 'white',
    overflow: 'hidden',
    '& .MuiAccordionSummary-content': {
      display: 'block',
      margin: '0',
    },
    '&.Mui-expanded': {
      minHeight: 88,
      // borderBottom: '2px solid #0000001f',
      '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(180deg)',
      },
    },
    '& .title': {
      fontSize: '20px',
      fontWeight: '700',
    },
  },
  innerAccordionSummary: {
    alignItems: 'center',
    minHeight: 56,
    maxHeight: 56,
    margin: '0 !important',
    '& .title': {
      fontSize: '16px',
      fontWeight: '600',
    },
    '& .icon': {
      fontSize: 28,
    },
    '& .MuiAccordionSummary-content':{
      alignItems: 'center',
    }
  },
  accordionProgress: {
    position: 'absolute',
    width: '100%',
    height: '3px',
    borderRadius: 4,
    '& .MuiCircularProgress-circle': {
      color: '#66BB6A',
    },
  },
  accordionInfoIcon: {
    width: 20,
    marginRight: 10,
  },
  stepMediaThumbnail: {
    width: '100%',
    cursor: 'pointer',
  },
  videoModal: {
    width: '100%',
    '& .modal-dialog': {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'calc(100vw - 25%)',
      margin: '0 auto',
      '& .modal-content': {
        background: 'transparent',
      },
    },
  },
  // ACCORDION COMPONENT STYLES : END

  // INTRO COMPONENT STYLES : START
  introContainer: {
    width: 'calc(100vw - 50% - 160px)',
    maxWidth: 500,
    minWidth: 440,
    position: 'absolute',
    left: 'calc(100vw - 50% - 200px)',
    top: 140,
  },
  introContent: {
    position: 'relative',
  },
  introManImage: {
    maxWidth: 230,
    position: 'absolute',
    right: -150,
    bottom: -75,
    zIndex: 1,
  },
  introBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start',
    padding: 20,
    border: '1px solid #80ABCD',
    borderRadius: 5,
    zIndex: 2,
    backgroundColor: '#ffffff',
  },
  introFromText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  introSRLogo: {
    marginLeft: 10,
  },
  // INTRO COMPONENT STYLES : END
});
