import { isEmpty } from 'lodash';
import { createContext, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDifferenceBetweenDates, getRelativeDateRange } from 'src/utils/dateUtils';

import usePersistState from 'src/components/hooks/usePersistState';
import { fetchUsersLookup } from '../../users/api/usersApi';
import { fetchSequencesLookup } from '../../sequence/api/sequenceApi';
import { convertObjectToQuerystring } from '../../../utils/helper';
import { fetchActivityDashboards, fetchActivityDashboardsChart } from '../actions/appActions';

const dashContext = createContext({});

const filtersArr = [
  {
    key: 'enrolledby',
    label: 'Enrolled',
    title: 'Enrolled by',
    type: 'dropdown',
    remote: true,
    optLabel: 'name',
    optValue: 'id',
    cancellable: false,
    searchable: true,
    isPrimary: true,
    checkbox: true,
    multiple: true,
    apply: true,
    remoteMethod: async (value) => {
      return fetchUsersLookup(value);
    },
  },
  {
    key: 'sequences',
    label: 'Sequence',
    title: 'All Sequences',
    type: 'dropdown',
    remote: true,
    optLabel: 'name',
    optValue: 'id',
    cancellable: false,
    searchable: true,
    isPrimary: true,
    checkbox: true,
    multiple: true,
    apply: true,
    remoteMethod: async (value) => {
      return fetchSequencesLookup(value);
    },
  },
  {
    key: 'createdAt',
    title: 'Filter By Date',
    custom: true,
    isCloseIcon: false,
    type: 'relativeDateRange',
    options: [
      { id: 'today', name: 'Today' },
      { id: 'yesterday', name: 'Yesterday' },
      { id: 'last7days', name: 'Last 7 days' },
      { id: 'last14days', name: 'Last 14 days' },
      { id: 'last30Days', name: 'Last 30 days' },
      { id: 'last90Days', name: 'Last 90 days' },
      { id: 'last180Days', name: 'Last 180 days' },
      { id: 'last365Days', name: 'Last 365 days' },
      { id: 'thisWeek', name: 'This week' },
      { id: 'thisMonth', name: 'This month' },
      { id: 'thisQuarter', name: 'This quarter' },
      { id: 'thisYear', name: 'This year' },
      { id: 'lastWeek', name: 'Last week' },
      { id: 'lastMonth', name: 'Last month' },
      { id: 'lastQuarter', name: 'Last quarter' },
      { id: 'lastYear', name: 'Last year' },
    ],
    isPrimary: true,
  },
];

export const useActivityDashboardStore = () => {
  const {
    filters,
    setFilters,
    fetchActivityReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    activityReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
  } = useContext(dashContext);
  return {
    filters,
    setFilters,
    fetchActivityReport,
    fetchSequenceStats,
    fetchUserStats,
    fetchTeamStats,
    activityReport,
    loading,
    sequenceStats,
    userStats,
    teamStats,
    setChartType,
    chartType,
    makeChartTypeDisable,
    filtersArr,
  };
};

export const DashboardProvider = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.user);

  const [makeChartTypeDisable, setMakeChartTypeDisable] = useState([]);
  const [filters, setFilters] = usePersistState('activityDashboardFilters', {});
  const [chartType, setChartType] = usePersistState('activityDashboardChartType', 'daily');
  const [loading, setLoading] = useState({
    activityReport: false,
    sequenceStats: false,
    userStats: false,
    teamStats: false,
  });
  const [userStats, setUserStats] = useState({
    paging: {
      pageNo: 0,
      perPage: 10,
      count: 0,
    },
    filters: {},
    data: [],
    sort: {},
  });
  const [activityReport, setActivityReport] = useState({});

  useEffect(() => {
    const persistedData = localStorage.getItem('activityDashboardFilters');
    const data = persistedData ? JSON.parse(persistedData) : {};
    const updatedFilters = { ...data };
    if (!data?.isFirstTime) {
      const enrolledBy = data?.enrolledby ?? [];
      updatedFilters.enrolledby = [...enrolledBy, user];
      updatedFilters.isFirstTime = true;
    }
    if (!data?.createdAt) {
      updatedFilters.createdAt = { id: 'last30Days', name: 'Last 30 days' };
    }
    setFilters(updatedFilters);
  }, []);

  const getFilter = () => {
    let filter = {};

    Object.keys(filters).map((key, index) => {
      if (filters[key] && key === 'createdAt' && !isEmpty(filters[key])) {
        const dateRange = getRelativeDateRange(filters[key]);
        filter = {
          ...filter,
          createdAt_gte: dateRange.start,
          createdAt_lte: dateRange.end,
        };
      } else if (filters[key] && key === 'sequences' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          sequences: filters[key]?.map((sequence) => sequence.id),
        };
      } else if (filters[key] && key === 'enrolledby' && filters[key]?.length > 0) {
        filter = {
          ...filter,
          enrolledby: filters[key]?.map((assignedTo) => assignedTo.id),
        };
      }
    });
    return filter;
  };

  const fetchUserStats = async (newPage, sort) => {
    try {
      setLoading(prevState => ({
        ...prevState,
        userStats: true,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        paging: {
          ...prevState.paging,
          pageNo: newPage.pageNo,
          perPage: newPage.perPage,
        },
        sort,
      }));

      let filter = {
        _from: newPage.pageNo * newPage.perPage,
        _size: newPage.perPage,
        ...getFilter(),
      };
      if (sort && !isEmpty(sort)) {
        filter._sort = `${sort.name}:${sort.direction}`;
      }
      const query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchActivityDashboards(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading(prevState => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: resolvedValue?.users,
            paging: {
              pageNo: newPage?.pageNo,
              perPage: newPage?.perPage,
              count: resolvedValue?.total?.value,
            },
          }));
        })
        .catch((error) => {
          setLoading(prevState => ({
            ...prevState,
            userStats: false,
          }));
          setUserStats((prevState) => ({
            ...prevState,
            data: [],
            paging: {
              ...prevState.paging,
              count: 0,
            },
          }));
        });
    } catch (error) {
      setLoading(prevState => ({
        ...prevState,
        userStats: false,
      }));
      setUserStats((prevState) => ({
        ...prevState,
        data: [],
        paging: {
          ...prevState.paging,
          count: 0,
        },
      }));
    }
  };

  useEffect(() => {
    fetchActivityReport();
  }, [filters]);

  const fetchActivityReport = async () => {
    try {
      setLoading(prevState => ({
        ...prevState,
        activityReport: true,
      }));
      let filter = getFilter();
      if (filter?.createdAt_gte && filter?.createdAt_lte) {
        const diff = getDifferenceBetweenDates(filter?.createdAt_gte, filter?.createdAt_lte);
        let fieldDisables = [];
        if (diff > 730) {
          fieldDisables.push('daily');
          fieldDisables.push('weekly');
          if (chartType === 'daily' || chartType === 'weekly') {
            setChartType('monthly');
          }
        } else if (diff > 360) {
          fieldDisables.push('daily');
          if (chartType === 'daily') {
            setChartType('weekly');
          }
        }
        setMakeChartTypeDisable(fieldDisables);
      } else {
        setMakeChartTypeDisable([]);
      }
      if (chartType) {
        filter['chartType'] = chartType;
      }
      let query = '';
      if (filter && !isEmpty(filter)) query = convertObjectToQuerystring(filter);
      const response = new Promise((resolve, reject) => {
        dispatch(fetchActivityDashboardsChart(query, resolve, reject));
      });
      response
        .then((resolvedValue) => {
          setLoading(prevState => ({
            ...prevState,
            activityReport: false,
          }));
          setActivityReport(resolvedValue);
        })
        .catch((error) => {
          setActivityReport({});
          setLoading(prevState => ({
            ...prevState,
            activityReport: false,
          }));
        });
    } catch (error) {
      setActivityReport({});
      setLoading(prevState => ({
        ...prevState,
        activityReport: false,
      }));
    }
  };

  return (
    <dashContext.Provider
      value={{
        filters,
        setFilters,
        activityReport,
        userStats,
        loading,
        setChartType,
        chartType,
        fetchActivityReport,
        fetchUserStats,
        makeChartTypeDisable,
      }}
    >
      {children}
    </dashContext.Provider>
  );
};

export const withActivityDashboardProvider = (Component) => (props) =>
  (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  );
