import images from 'src/config/images';

export const tenantIntegration = [
  {
    id: '',
    type: 'enrich',
    name: 'Bullhorn',
    status: '',
    src: '/Bullhorn.png',
    integration: {
      clientId: '',
      secrete: '',
      username: '',
      pass: '',
      data_center: '',
    },
    bullhorn: '',
    description:
      'Bullhorn is an industry-leading CRM helping recruiters and sales teams win new business and delight customers around the world.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Vincere',
    status: '',
    src: '/Vincere.jpg',
    integration: {
      clientId: '',
      apikey: '',
      appId: '',
      crmTenantId: '',
    },
    description:
      'Vincere is your new Recruitment Operating System. The modern OS purpose-built for recruitment & staffing agencies worldwide.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Salesforce',
    status: '',
    src: '/Salesforce.png',
    integration: {
      clientId: '',
      apikey: '',
      appId: '',
      crmTenantId: '',
    },
    description:
      'The world’s #1 CRM platform that helps to connect with customers across sales, service, marketing, etc.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Hubspot',
    status: '',
    src: '/Hubspot.png',
    integration: {
      clientId: '',
      apikey: '',
      appId: '',
      crmTenantId: '',
    },
    description:
      'Hubspot lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'JobAdder',
    status: '',
    src: 'https://jobadder.com/wp-content/uploads/2021/11/Blue.svg',
    integration: {
      clientId: '',
      clientSecret: '',
      tenantUrl: '',
    },
    description:
      'Built by recruiters for recruiters, the JobAdder Applicant Tracking System (ATS) and CRM recruiting software adds joy to your job.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Lusha',
    status: '',
    src: '/Lusha.png',
    integration: {
      apikey: '',
    },
    description:
      'Search for emails on LinkedIn, sync with SuperReach and enroll prospects into multichannel sequences.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Hunter',
    status: '',
    src: 'https://global-uploads.webflow.com/63086eb329bf636da59321d7/630889201ee8f43774c7beb0_610abed8ecea931493d06eee_609a307ff9a04730fb20df99_hunter.svg',
    integration: {
      apikey: '',
    },
    description:
      'Hunter lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'ContactOut',
    status: '',
    src: 'https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_f4b0046d731dd5d5205214495f3af9da/contactout.png',
    integration: {
      apikey: '',
    },
    description:
      'ContactOut lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'RocketReach',
    status: '',
    src: 'https://static.rocketreach.co/images/logo/logoWhiteRocketBlueHaloWhiteText.png',
    integration: {
      apikey: '',
    },
    description:
      'RocketReach lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Nymeria',
    status: '',
    src: 'https://www.saashub.com/images/app/service_logos/106/570375e3deec/large.png?1574491248.png',
    integration: {
      apikey: '',
    },
    description:
      'Nymeria lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'PeopleDataLab',
    status: '',
    src: 'https://s4-recruiting.cdn.greenhouse.io/external_greenhouse_job_boards/logos/400/716/700/original/PDL_Logo_-_Purple_Brain.jpg?1638131183.png',
    integration: {
      apikey: '',
    },
    description:
      'Peoples Data Labs lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Uplead',
    status: '',
    src: images.integration.uplead,
    integration: {
      apikey: '',
    },
    description:
      'Uplead lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Anymail',
    status: 'anymail.png',
    src: images.integration.anymail,
    integration: {
      apikey: '',
    },
    description:
      'Anymail lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Kendo',
    status: '',
    src: images.integration.kendo,
    integration: {
      apikey: '',
    },
    description:
      'Kendo lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Apollo',
    status: '',
    src: images.integration.apollo,
    integration: {
      apikey: '',
    },
    description:
      'Apollo lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Getanymail',
    status: '',
    src: images.integration.getanymail,
    integration: {
      apikey: '',
    },
    description:
      'Getanymail lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
  {
    id: '',
    type: 'enrich',
    name: 'Kaspr',
    status: '',
    src: images.integration.kaspr,
    integration: {
      apikey: '',
    },
    description:
      'Kaspr lets you find professional email addresses in seconds and connect with the people that matter for your business.',
  },
];

export const integrationMappingTypes = [
  {
    name: 'Text',
    value: 'text',
  },
  {
    name: 'TextArea',
    value: 'textArea',
  },
  {
    name: 'String',
    value: 'string',
  },
  {
    name: 'Integer',
    value: 'integer',
  },
  {
    name: 'Lookup',
    value: 'lookup',
  },
  {
    name: 'User',
    value: 'user',
  },
  {
    name: 'Time',
    value: 'time',
  },
  {
    name: 'Date',
    value: 'date',
  },
  {
    name: 'Contact',
    value: 'contact',
  },
  {
    name: 'DateTime',
    value: 'datetime',
  },
  {
    name: 'Formula',
    value: 'formula',
  },
];
