import React, { useState, useMemo } from 'react';
import { Typography, Switch } from '@mui/material';
import { Link } from 'react-router-dom';

import { AppGrid, GridToolbar } from 'src/components/App';
import { Drawer } from '../../../../../components/shared';

const TagsGrid = ({
  tags,
  handlePerPageChange,
  handleTableChange,
  handleReFetch,
  loading,
  selectedTagDetail,
  updateTagStatus,
  ...props
}) => {
  const { data, counts } = tags;

  const tagColumns = useMemo(
    () => [
      {
        name: 'tag_name',
        label: 'Tag Name',
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              minWidth: 300,
              width: 300,
              maxWidth: 300,
            },
          }),
          setCellProps: () => ({
            style: {
              minWidth: 300,
              width: 300,
              maxWidth: 300,
            },
          }),
          customBodyRenderLite: (dataIndex) => {
            const tag = data ? data[dataIndex] : {};
            return (
              <Typography
                // className={classes.tagTitle}
                variant="body2"
                // onClick={() => {
                //   setTagItem(tag);
                //   setShowTagForm(true);
                // }}
              >
                {tag.tag_name}
              </Typography>
            );
          },
        },
      },
      {
        name: 'category_name',
        label: 'Tag Category',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const tag = data ? data[dataIndex] : {};
            return <Typography variant="body2">{tag.category_name}</Typography>;
          },
        },
      },
      {
        // name: 'autoTags',
        name: 'tag_type',
        label: 'Auto Tagging',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <Typography variant="body2">{''}</Typography>;
          },
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const tag = data ? data[dataIndex] : {};

            return (
              <Switch
                checked={tag.status === 'true'}
                color="primary"
                // disabled={
                //   tagItemLoading && tagItemLoading == tag.id ? true : false
                // }
                onChange={async (e, val) => {
                  changeTagStatus(tag.id, val);
                }}
              />
            );
          },
        },
      },
    ],
    [data],
  );

  const changeTagStatus = async (tagId, value) => {
    try {
      const payload = {
        status: value === true ? 'true' : 'false',
        tagId: tagId,
      };
      updateTagStatus(payload);
    } catch (error) {}
  };

  const TableToolbar = useMemo(
    () => (props) => {
      return (
        <GridToolbar
          // refetch={handleReFetch}
          myFilterData={[]}
          defaultFilterState={{}}
          filterState={{}}
          visibleFilter={1}
          filterDrawerName={'Filters'}
          showEditCol={false}
          // onColumnChange={(columns) => {
          //   handleColumnChange(columns);
          // }}
          loading={false}
          {...props}
        />
      );
    },
    [],
    [],
  );

  return (
    <>
      <AppGrid
        columns={tagColumns}
        data={data}
        loading={loading}
        options={{
          pagination: Boolean(data?.length),
          selectableRows: 'multiple',
          filterType: 'dropdown',
          responsive: 'simple',
          searchPlaceholder: 'Search Tags',
          selectToolbarPlacement: 'none',
          searchOpen: true,
          search: true,
        }}
        components={{
          TableToolbar: TableToolbar,
        }}
      />

      <Drawer
        title={props.drawerTitle}
        open={props.drawerTitle && props.drawerTitle !== ''}
        onClose={props.handleDrawerClose}
      ></Drawer>
    </>
  );
};

export default TagsGrid;
