import React from 'react';
import PropTypes from 'prop-types';
import { EventCard } from '../eventsStyles';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const CallbackCard = ({ event, ...props }) => {
  let unitValue = event.unit;
  if (Number(event.waitTime) <= 1 && unitValue.endsWith('s')) {
    unitValue = unitValue.slice(0, -1);
  }
  const title = `Wait ${event.waitTime} ${unitValue}`;

  return (
    <EventCard
      icon={<WatchLaterIcon style={{ fill: '#78909C', width: 24, height: 24 }} />}
      title={title}
      showEdit={true}
      showDelete={true}
      event={event}
      {...props}
    />
  );
};

CallbackCard.propTypes = {
  event: PropTypes.object,
};

export default CallbackCard;
