import React from 'react';
import Session from 'src/components/lms/Session';
import Course from 'src/components/lms/Course';
import Slider from 'src/components/lms/Slider';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

import { LMSCard } from 'src/components/lms';
import { Button } from 'src/components/shared';
import { ReactComponent as Saved } from 'src/assets/lms/icons/saved.svg';
import { useLmsHomeProvider } from '../../utils/lmsStore';
import { useHistory } from 'react-router-dom';

function HomeSaved({ title }) {
  const { isMobile } = useMediaQuery();
  const { saved, fetchSavedList } = useLmsHomeProvider();
  const history = useHistory();

  React.useEffect(() => {
    fetchSavedList();
  }, []);

  const sliderProps = {
    slidesToShow: 4,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const viewAll = () => {
    history.push(`lms/saved`);
  };

  if (!saved.length) return null;

  return (
    <LMSCard
      icon={<Saved style={{ width: 24, height: 24 }} />}
      title={title}
      action={
        <Button size="small" color="secondary" onClick={viewAll}>
          View all
        </Button>
      }
    >
      <Slider {...sliderProps}>
        {saved.map((item, index) => {
          if (item?.course && item?.course?.id) {
            return <Course key={`home-saved-course-item-${index}`} course={item.course} />;
          }

          if (item?.session && item?.session?.id) {
            return <Session key={`home-saved-session-item-${index}`} session={item.session} />;
          }
        })}
      </Slider>
    </LMSCard>
  );
}

export default HomeSaved;
