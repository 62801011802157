import React from 'react';
import { Grid } from '@mui/material';

import { useActivityDashboardStore } from '../../../utils/activityDashboardStore';
import ProspectsCardItem from './ProspectsCardItem';

function ProspectsCards() {
  const { activityReport, loading } = useActivityDashboardStore();
  return (
    <Grid container spacing={2}>
      <ProspectsCardItem
        type="text"
        fill="#1976D2"
        title="Enrolled"
        value={activityReport?.prospectEnrolled?.value || 0}
        loading={loading?.activityReport}
        toolTipTitle={'This shows the number of enrollments'}
      />
      <ProspectsCardItem
        title="Open Rate"
        loading={loading?.activityReport}
        fill="#1976D2"
        value={`${activityReport?.prospectOpenRate?.completed ?? 0} / ${
          activityReport?.prospectOpenRate?.totalCount ?? 0
        }`}
        percentage={activityReport?.prospectOpenRate?.progress || 0}
        total={100}
        toolTipTitle={
          'This shows the number of emails sent with email tracking enabled, with the\n' +
          'percentage open rate against those emails.'
        }
      />
      <ProspectsCardItem
        title="All Tasks"
        value={activityReport?.prospectTasksCompleted?.totalCount || 0}
        percentage={activityReport?.prospectTasksCompleted?.progress || 0}
        total={100}
        loading={loading?.activityReport}
        fill="#1864B0"
        toolTipTitle={'This shows the number of tasks scheduled and their % completion rate.'}
      />
      <ProspectsCardItem
        title="Call Backs"
        value={activityReport?.prospectCallBacksTasks?.totalCount || 0}
        percentage={activityReport?.prospectCallBacksTasks?.progress || 0}
        total={100}
        loading={loading?.activityReport}
        fill="#42C340"
        toolTipTitle={
          'This shows the number of call-back tasks scheduled and their % completion rate.'
        }
      />
      <ProspectsCardItem
        title="LinkedIn"
        value={activityReport?.prospectLinkedInTasks?.totalCount || 0}
        percentage={activityReport?.prospectLinkedInTasks?.progress || 0}
        total={100}
        loading={loading?.activityReport}
        fill="#E69904"
        toolTipTitle={
          'This shows the number of LinkedIn tasks scheduled and their % completion rate.'
        }
      />
      <ProspectsCardItem
        title="Interested"
        type="text"
        value={activityReport?.prospectsInterested?.value || 0}
        total={100}
        loading={loading?.activityReport}
        fill="#033EAC"
        toolTipTitle={'This shows the number of contacts marked as interested.'}
      />
    </Grid>
  );
}

export default ProspectsCards;
