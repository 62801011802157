import React, { useMemo } from 'react';
import {
  SequenceSummaryChip,
  SequenceSummaryDataList,
  SequenceSummaryHeader,
  SequenceSummaryHeadTitle,
} from './styles';
import AutoComplete from '../../../../../components/shared/Form/AutoComplete';

const SequenceDetailHeader = ({
  events,
  title,
  remoteMethod,
  optLabel,
  value,
  optValue,
  onChange,
}) => {
  const eventsArr = Object.values(events);

  const duration = useMemo(() => {
    let seconds = 0;
    if (eventsArr && eventsArr.length) {
      const waitEvents = eventsArr.filter((item) => item.eventType === 'wait');
      if (waitEvents.length) {
        for (let i = 0; i < waitEvents.length; i++) {
          const event = waitEvents[i];
          switch (event.unit) {
            case 'minutes':
              seconds += event.waitTime * 60;
              break;

            case 'hours':
              seconds += event.waitTime * 3600;
              break;

            case 'days':
              seconds += event.waitTime * 86400;
              break;

            case 'weeks':
              seconds += event.waitTime * 86400 * 7;
              break;

            case 'months':
              seconds += event.waitTime * 86400 * 30;
              break;

            case 'years':
              seconds += event.waitTime * 86400 * 365;
              break;

            default:
            case 'seconds':
              seconds += event.waitTime;
              break;
          }
        }
      }
      return seconds > 0 ? Math.ceil(seconds / 86400) || 1 : 1;
    }
    return 0;
  }, [events, eventsArr]);

  const totalSteps = useMemo(() => {
    if (Object.keys(events).length === 0) return 0;
    return Object.values(events).filter(
      (item) => item.eventType !== 'wait' && item.eventType !== 'endsquence',
    )?.length;
  }, [events, eventsArr]);

  return (
    <SequenceSummaryHeader>
      <SequenceSummaryDataList>
        <SequenceSummaryHeadTitle>{title}</SequenceSummaryHeadTitle>
        {optLabel ? (
          <AutoComplete
            formSx={{
              margin: 0,
              marginTop: 0,
              marginBottom: 0,
              minWidth: '200px',
              borderRadius: '20px',
              background: 'rgba(25, 118, 210, 0.08)',
              '.MuiOutlinedInput-notchedOutline': { border: '0px !important' },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                border: '0px !important',
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '0px !important',
              },
            }}
            InputProps={{
              sx: {
                color: '#1976D2',
                fontWeight: 500,
                fontSize: '14px',
              },
            }}
            multiple={false}
            value={value}
            fullWidth
            size="small"
            showAvatar={false}
            options={[]}
            remoteMethod={(val) => {
              return remoteMethod(val);
            }}
            onChange={(val) => onChange(val)}
            name="contact"
            variant="outlined"
            optLabel={optLabel}
            optValue={optValue}
          />
        ) : null}
      </SequenceSummaryDataList>
      <SequenceSummaryDataList>
        <SequenceSummaryChip
          size={'small'}
          label={totalSteps + (totalSteps > 1 ? ' steps' : ' step')}
        />
        <SequenceSummaryChip
          size={'small'}
          label={`${duration > 1 ? duration + ' days' : duration + ' day'}`}
        />
        {/*<SequenceSummaryChip size={'small'} label="0% Automation" />*/}
      </SequenceSummaryDataList>
    </SequenceSummaryHeader>
  );
};
export default SequenceDetailHeader;
