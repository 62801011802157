import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, InputAdornment, CircularProgress, Link } from '@mui/material';
import { Form, Button, Spacer } from 'src/components/shared';
import { grey, green, red } from '@mui/material/colors';

import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { registerSetPassword } from '../../actions/authActions';

const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
const numberRegex = new RegExp('^(?=.*[0-9])');
const specialCharRegex = new RegExp(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/);
const eightChatRegex = new RegExp('^(?=.{8,})');

import styled from 'styled-components';

const PasswordLintAlert = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

import _ from 'lodash';

const PasswordLint = ({ isValid, title }) => {
  return (
    <PasswordLintAlert>
      {isValid ? (
        <CheckCircleIcon
          style={{
            color: green[800],
          }}
        />
      ) : (
        <ErrorOutlineIcon
          style={{
            color: grey[400],
          }}
        />
      )}
      <Spacer x={1} y={1} />
      <Typography color={isValid ? green[800] : 'textSecondary'}>{title}</Typography>
    </PasswordLintAlert>
  );
};

const SetPasswordForm = ({ verifying, query, router, isAuthenticated, ...props }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { uid } = query;

  const setPassword = (uid, data) => {
    return new Promise((resolve, reject) => {
      dispatch(registerSetPassword(uid, data, resolve, reject));
    });
  };

  const handleSubmit = async (values) => {
    try {
      values.termsAccepted = values?.terms ?? false;
      values.privacyPolicyAccepted = values?.terms ?? false;
      delete values.terms;
      const res = await setPassword(uid, values);
      props.onNext();
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <div style={{ height: 36 }} />
      <Typography variant="h1" align="center" color="secondary">
        To get started, let’s create your account.
      </Typography>
      <div style={{ height: 24 }} />
      <div style={{ maxWidth: 400, margin: '0 auto' }}>
        <Form
          initialValues={{
            password: '',
            confirmPassword: '',
            terms: false,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Please enter your password!')
              .min(8, 'Password must be at least 8 characters long!')
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
                'Password must contain 8 Characters, One Uppercase, One Lowercase, One Number and one Special Character',
              ),
            confirmPassword: Yup.string()
              .required('Please enter your confirm password!')
              .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            terms: Yup.bool().oneOf([true], 'Please accept Terms of Service.'),
          })}
          onSubmit={async (values, form) => {
            handleSubmit(values);
            form.prevent;
          }}
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  props.submitForm();
                  return false;
                }}
                noValidate
              >
                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  type="password"
                  name="password"
                  placeholder="Password"
                  disabled={loading}
                  sx={{
                    '& .MuiOutlinedInput-root': { background: 'transparent' },
                    '& .MuiOutlinedInput-notchedOutline': { borderRadius: '4px' },
                  }}
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Form.Field.Input
                  fullWidth
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  disabled={loading}
                  autoComplete="current-password"
                  sx={{ '& .MuiOutlinedInput-root': { background: 'transparent' } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{ fill: '#d1d1d1' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Spacer x={2} y={2} />
                <div>
                  <PasswordLint
                    isValid={eightChatRegex.test(props.values.password)}
                    title="At least 8 characters long"
                  />
                  <PasswordLint
                    isValid={lowerCaseRegex.test(props.values.password)}
                    title="One lowercase character"
                  />
                  <PasswordLint
                    isValid={upperCaseRegex.test(props.values.password)}
                    title="One uppercase character"
                  />
                  <PasswordLint
                    isValid={numberRegex.test(props.values.password)}
                    title="One numeric character"
                  />
                  <PasswordLint
                    isValid={specialCharRegex.test(props.values.password)}
                    title="One special character"
                  />
                </div>

                <Box mt={2}>
                  <Form.Field.Checkbox
                    name="terms"
                    label={
                      <Typography>
                        I agree to SuperReach’s{' '}
                        <Link
                          color="secondary"
                          target="_blank"
                          href="https://superreach.com/privacy-policy/"
                          sx={{ textDecoration: 'none' }}
                        >
                          Privacy Policy
                        </Link>{' '}
                        &{' '}
                        <Link
                          color="secondary"
                          target="_blank"
                          href="https://superreach.com/terms-of-service/"
                          sx={{ textDecoration: 'none' }}
                        >
                          Terms of Service
                        </Link>
                      </Typography>
                    }
                  />
                </Box>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading || verifying}
                    fullWidth
                    size="large"
                    endIcon={<ArrowForwardIcon />}
                    // disabled={!props.values.terms}
                  >
                    Next
                  </Button>
                </Box>
              </form>
            );
          }}
        </Form>
      </div>
    </React.Fragment>
  );
};

export default SetPasswordForm;
