import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import AppLoader from 'src/layouts/AppLoader';
import { loginWithGoogle } from '../../actions/authActions';

const GoogleAuthCallBack = ({ loginWithGoogleByTempId }) => {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const tempID = params.get('tempID');
  const history = useHistory();

  useEffect(() => {
    if (!tempID) {
      history.push('/auth/login');
    }
    fetchUserData();
  }, [tempID]);

  const fetchUserData = async () => {
    try {
      const res = await loginWithGoogleByTempId(tempID);
      if (res?.user?.id) {
        history.push('/tasks');
      }
    } catch (e) {
      history.push('/auth/login');
    }
  };

  return (
    <>
      <AppLoader />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loginWithGoogleByTempId: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(loginWithGoogle(data, resolve, reject));
    });
  },
});

export default connect(null, mapDispatchToProps)(GoogleAuthCallBack);
