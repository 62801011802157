import React from 'react';
import {
  Wrapper,
  Content,
  ProgressWrapper,
  CourseWrap,
  Gradient,
  GradientTop,
  Title,
  SessionImage,
  AssignedIcon,
} from './styles';
import ProgressBar from '../ProgressBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Icon from 'src/components/lms/Icon';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';
import { palette } from 'src/config/darkTheme';

const Long = ({
  session,
  title,
  courseName,
  totalVideos,
  isAssigned,
  videosWatched,
  handleClick,
  summary,
  isCompleted,
}) => {
  const thumbnailImage = session?.longTileImage ?? '';

  const renderCourseAssignedText = () => {
    if (summary?.summary?.course && summary?.summary?.course !== '') {
      return (
        <Typography component="span" variant="body2" color="textSecondary" textAlign="left" noWrap>
          From{' '}
          <Typography component="span" variant="body2" fontWeight="600" color="textPrimary">
            {summary?.summary?.course}
          </Typography>
        </Typography>
      );
    } else if (summary?.summary?.expertFname && summary?.summary?.expertFname !== '') {
      return (
        <Typography component="span" variant="body2" color="textSecondary" textAlign="left" noWrap>
          By{' '}
          <Typography component="span" variant="body2" fontWeight="600" color="textPrimary">
            {summary.summary?.expertFname} {summary?.summary?.expertLname}
          </Typography>
        </Typography>
      );
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <Stack className="background-wrapper" height={'100%'}>
        <ProgressWrapper>
          <ProgressBar total={totalVideos} completed={videosWatched} />
          <Icon
            name="tick"
            size={24}
            color={isCompleted ? palette.secondary.main : 'rgba(255, 212, 126, 0.30)'}
          />
        </ProgressWrapper>

        <Content hasCourse={courseName !== ''}>
          <Gradient />
          <GradientTop />
          {thumbnailImage ? (
            thumbnailImage !== '' && <SessionImage src={thumbnailImage} />
          ) : (
            <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
              <SessionPlaceholder style={{ width: '40%', color: '#808080' }} />
            </Stack>
          )}

          <Title>{title}</Title>
        </Content>

        <CourseWrap>
          {renderCourseAssignedText()}

          {isAssigned && <AssignedIcon />}
        </CourseWrap>
      </Stack>
    </Wrapper>
  );
};

export default Long;
