import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Avatar } from 'src/components/shared';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { ButtonBase, ListItemText } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { usePopupState, bindMenu, bindHover, bindToggle } from 'material-ui-popup-state/hooks';

import { logout } from 'src/modules/auth/actions/authActions';
import storage from 'src/utils/storageUtils';

function UserMenu() {
  const user = useSelector((state) => state.auth.user);
  const name = `${user?.fname} ${user?.lname}`;
  const dispatch = useDispatch();
  const history = useHistory();

  const popupState = usePopupState({ variant: 'popover', popupId: 'user-menu' });

  const handleLogout = (event) => {
    dispatch(logout(event));
  };

  const hasAdminAccess = ['teamlead', 'admin', 'manager']?.includes(
    user?.acl?.accessLevel?.toLowerCase(),
  );

  const onClose = () => {};

  return (
    <>
      <ButtonBase {...bindToggle(popupState)}>
        <Avatar name={name} size={30} />
      </ButtonBase>
      <Menu
        {...bindMenu(popupState)}
        id={'user-menu'}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            history.push('/lms');
            onClose();
          }}
        >
          <ListItemIcon>
            <AppsIcon fontSize="small" />
          </ListItemIcon>
          <Typography>SuperReach</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push('/profile/details');
            onClose();
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Profile</Typography>
        </MenuItem>
        {hasAdminAccess && (
          <MenuItem
            to={'/admin/users'}
            onClick={() => {
              const route = history.location.pathname;
              if (route && route !== '') {
                storage.set('REFERRAL_ROUTE', route);
              }
              window.open('/admin/users', '_blank');
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Administration" />
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
