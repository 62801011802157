import React from 'react';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import { EventCard } from '../eventsStyles';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const EmailCard = ({ event, ...props }) => {
  return (
    <EventCard
      title="Select email template"
      // subTitle="Choose email at the time of enrolling"
      icon={<ContactMailIcon style={{ color: '#FFA726' }} />}
      showEdit={false}
      showDelete={true}
      event={event}
      {...props}
    />
  );
};

EmailCard.propTypes = {
  event: PropTypes.object,
};

export default EmailCard;
