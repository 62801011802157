const cookieUtils = {
  get: function (name) {
    let cookieName = encodeURIComponent(name) + '=',
      cookieStart = document.cookie.indexOf(cookieName),
      cookieValue = null,
      cookieEnd = undefined;

    if (cookieStart > -1) {
      cookieEnd = document.cookie.indexOf(';', cookieStart);
      if (cookieEnd == -1) {
        cookieEnd = document.cookie.length;
      }
      cookieValue = decodeURIComponent(
        document.cookie.substring(cookieStart + cookieName.length, cookieEnd),
      );
    }
    return cookieValue;
  },

  set: (name, value, days) => {
    var expires = '';
    if (days && days > 0) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    } else {
      var dateExp = new Date(0);
      expires = '; expires=' + dateExp.toUTCString();
    }
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      `; domain=${process.env.REACT_APP_COOKIE_DOMAIN}; path=/`;
  },

  unset: function (name) {
    this.set(name, '', 0);
  },
};

export default cookieUtils;
