import styled from 'styled-components';
import { Chip, Paper } from '@mui/material';

const DayChip = styled(Chip).attrs({})`
  border-radius: 4px;
  width: 78px;
  height: 30px;
  align-items: center;
`;

const Item = styled(Paper)`
  background-color: #fff;
  padding: 20px;
  flex-direction: initial;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  grid-row: 1;
  grid-column: span3;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export { DayChip, Item };
