import React from 'react';
import { Form } from 'src/components/shared';

function SequenceStatusFormSwitch({ sequence, ...props }) {
  return (
    <Form
      initialValues={{
        status: sequence.status == 'published' ? true : false,
      }}
      onSubmit={(values) => {}}
      enableReinitialize={true}
    >
      <Form.Field.Switch
        name="status"
        label={sequence?.status === 'unpublished' ? 'Inactive' : 'Active'}
        onChange={(value) => {
          if (value === false) {
            props.putSequence(sequence.id, { status: 'unpublished' });
          } else {
            props.putSequence(sequence.id, { status: 'published' });
          }
        }}
      />
    </Form>
  );
}

export default SequenceStatusFormSwitch;
