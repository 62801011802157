import React, { useEffect } from 'react';
import CompetitionGrid from '../../components/Competitions/CompetitionGrid';
import useStyles from './style';
import CompetitionSettings from './CompetitionSettings';
import CompetitionTemplate from './CompetitionTemplate';
import CompetitionTemplatePreview from './CompetitionTemplatePreview';
import { themeColors } from 'src/modules/lms/config';
import Restricted from 'src/components/hooks/Restricted';

function CompetitionsView({ fields, sequenceTab, competitions, ...props }) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [templateModal, setTemplateModal] = React.useState(false);
  const [templatePreviewModal, setTemplatePreviewModal] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [result, setResult] = React.useState(false);
  const [invitePreview, setInvitePreview] = React.useState(false);
  const [updatePreview, setUpdatePreview] = React.useState(false);
  const [resultPreview, setResultPreview] = React.useState(false);
  const [liveCompetitionsLength, setLiveCompetitionsLength] = React.useState(
    competitions?.data?.filter((k) => k.status === 'live').length,
  );

  const fetchCompetitions = (paging, filter, sort = undefined) => {
    props.fetchCompetitions(paging, filter, sequenceTab, sort);
  };
  useEffect(() => {
    setLiveCompetitionsLength(competitions?.data?.filter((k) => k.status === 'live').length);
  }, [competitions]);

  const onEdit = (session) => {};
  const onDelete = (session) => {};

  const totalLiveCompetitions = competitions?.paging?.live || 0;
  return (
    <>
      <CompetitionGrid
        fields={fields}
        competitions={competitions}
        onEdit={onEdit}
        onDelete={onDelete}
        {...props}
        fetchCompetitions={fetchCompetitions}
        setSettingsModalOpen={setModalOpen}
      />
      <CompetitionSettings
        open={modalOpen}
        handleInviteModal={() => {
          setTemplateModal(true);
          setInvite(true);
          setUpdate(false);
          setResult(false);
        }}
        handleInvitePreviewModal={() => {
          setTemplatePreviewModal(true);
          setInvitePreview(true);
          setUpdatePreview(false);
          setResultPreview(false);
        }}
        handleUpdateModal={() => {
          setTemplateModal(true);
          setInvite(false);
          setUpdate(true);
          setResult(false);
        }}
        handleUpdatePreviewModal={() => {
          setTemplatePreviewModal(true);
          setInvitePreview(false);
          setUpdatePreview(true);
          setResultPreview(false);
        }}
        handleResultModal={() => {
          // setModalOpen(false);
          setTemplateModal(true);
          setInvite(false);
          setUpdate(false);
          setResult(true);
        }}
        handleResultPreviewModal={() => {
          setTemplatePreviewModal(true);
          setInvitePreview(false);
          setUpdatePreview(false);
          setResultPreview(true);
        }}
        handleClose={() => setModalOpen(false)}
        handleOpen={() => setModalOpen(true)}
      />
      <CompetitionTemplate
        open={templateModal}
        invite={invite}
        update={update}
        result={result}
        handleClose={() => setTemplateModal(false)}
        handleOpen={() => setTemplateModal(true)}
      />
      <CompetitionTemplatePreview
        open={templatePreviewModal}
        invite={invitePreview}
        update={updatePreview}
        result={resultPreview}
        handleClose={() => setTemplatePreviewModal(false)}
        handleOpen={() => setTemplatePreviewModal(true)}
      />

      {/* <CompetitionForm /> */}
    </>
  );
}

export default CompetitionsView;
