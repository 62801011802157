import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const SequenceSummaryChip = styled(Box)`
  border-radius: 50%;
  margin: 0;
  outline: 3px solid rgba(0, 0, 0, 0.12);
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PreviewContent = styled(Box)`
  height: 250px;
  padding: 20px;
  overflow-y: auto;
`;

export const EmailSubject = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 0px;
  margin: 0px;
`;

export const EmailContent = styled(Typography)`
  padding: 0px;
  margin: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export const WaitContent = styled(Typography)`
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.17px;
  margin-right: 16px;
`;
