import { createMigrate, persistReducer, persistStore } from 'redux-persist';

const migrations = {
  0: (state) => {
    return {
      ...state,
    };
  },
  1: (state) => {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        loading: {
          ...state.tasks.loading,
          outbox: false,
        },
        outbox: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          sort: {},
          filters: {},
          data: [],
          columns: [],
          columnsOrder: [],
        },
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      lms: {
        loading: {
          sessions: false,
          homePage: false,
          session: false,
          notes: false,
          templates: false,
          list: false,
        },
        sessions: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          sort: {},
          data: [],
        },
        list: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          sort: {},
          data: [],
        },
        templates: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          sort: {},
          data: [],
        },
        notes: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          sort: {},
          data: [],
        },
        competitions: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          filters: {},
          sort: {},
          data: [],
        },
        session: {},
        homePage: {},
        pillar: {},
        course: {},
      },
    };
  },
  3: (state) => {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        tasksQueue: [],
        enrolmentDrafts: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          sort: {},
          filters: {},
          data: [],
        },
      },
    };
  },
  4: (state) => {
    return {
      ...state,
      tasks: {
        ...state.tasks,
        loading: {
          ...state.tasks.loading,
          inbox: false,
          sent: false,
        },
        inbox: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          sort: {},
          filters: {},
          data: [],
        },
        sent: {
          paging: {
            pageNo: 0,
            perPage: 10,
            count: 0,
          },
          sort: {},
          filters: {},
          data: [],
        },
      },
    };
  },
  5: (state) => {
    return {
      ...state,
      lms: {
        ...state.lms,
        score: {},
      },
    };
  },
  6: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        showNotificationsDrawer: false,
      },
    };
  },
  7: (state) => {
    return {
      ...state,
      lms: {
        ...state.lms,
        homePageFilters: {},
      },
    };
  },
  8: (state) => {
    return {
      ...state,
      lms: {
        ...state.lms,
        history: {
          paging: {
            pageNo: 0,
            perPage: 12,
            count: 0,
          },
          filters: {},
          data: [],
          featured: [],
        },
      },
    };
  },
  9: (state) => {
    return {
      ...state,
      lms: {
        ...state.lms,
        summary: {
          inProgress: 0,
          assignedToMe: 0,
          saved: 0,
          competitions: 0,
          completed: 0,
        },
      },
    };
  },
};

export default migrations;
