import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { AppGrid } from '../../../../../components/App';
import { useStyles } from './styles';
import { useActivityDashboardStore } from '../../../utils/activityDashboardStore';

const UserGrid = () => {
  const classes = useStyles();
  const { fetchUserStats, userStats, loading, filters } = useActivityDashboardStore();
  const { data, paging, sort } = userStats;

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: 'User',
        options: {
          sort: true,
          setCellHeaderProps: () => ({
            style: { width: 10 },
          }),
          setCellProps: () => ({
            style: { width: 10 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Typography color="secondary" variant="body2">
                {row?.fname || ''} {row?.lname || ''}
              </Typography>
            );
          },
        },
      },
      {
        name: 'prospectsEnrolled',
        label: 'Enrolled',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsEnrolled;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'totalOpenRate',
        label: 'Open Rate',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box display="flex" alignItems="flex-end" justifyContent="center" gap={0.5}>
                <Typography color="#000000DE" sx={{ lineHeight: '1.05' }}>
                  {row?.openRateProgress || '0'}%
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  ({row?.totalTrackingOntasks ?? '0'})
                </Typography>
              </Box>
            );
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'totalTaskCompleted',
        label: 'Tasks Completed',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box display="flex" alignItems="flex-end" justifyContent="center" gap={0.5}>
                <Typography color="#000000DE" sx={{ lineHeight: '1.05' }}>
                  {row?.taskCompletedProgress || '0'}%
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  ({row?.totalTaskCompleted ?? '0'})
                </Typography>
              </Box>
            );
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'totalCallTasks',
        label: 'Call Backs',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box display="flex" alignItems="flex-end" justifyContent="center" gap={0.5}>
                <Typography color="#000000DE" sx={{ lineHeight: '1.05' }}>
                  {row?.callProgress || '0'}%
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  ({row?.totalCallTasks ?? '0'})
                </Typography>
              </Box>
            );
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'totalLinkedinTasks',
        label: 'LinkedIn',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return (
              <Box display="flex" alignItems="flex-end" justifyContent="center" gap={0.5}>
                <Typography color="#000000DE" sx={{ lineHeight: '1.05' }}>
                  {row?.linkedinProgress || '0'}%
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  ({row?.totalLinkedinTasks ?? '0'})
                </Typography>
              </Box>
            );
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
      {
        name: 'prospectsInterested',
        label: 'Interested',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const row = data[dataIndex];
            return row?.prospectsInterested;
          },
          setCellHeaderProps: () => ({ className: classes.centeredHeader }),
          setCellProps: () => ({ className: classes.centeredCell }),
        },
      },
    ],
    [data],
  );

  useEffect(() => {
    fetchUserStats({ perPage: paging?.perPage, pageNo: 0 }, sort);
  }, [filters]);

  const onSort = (sort) => {
    fetchUserStats(paging, sort);
  };

  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchUserStats(
        {
          pageNo,
          perPage,
        },
        sort,
      );
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <AppGrid
      columns={columns.map((col, index) => ({
        ...col,
        options: columns?.columns?.length
          ? { ...col.options, ...columns?.columns[index] }
          : col.options,
      }))}
      skeletonLoading={true}
      onSort={onSort}
      onTableChange={handleTableChange}
      data={data}
      loading={loading.userStats}
      options={{
        sortOrder: sort,
        serverSide: true,
        pagination: true,
        page: paging.pageNo,
        rowsPerPage: paging.perPage,
        count: paging.count,
      }}
    />
  );
};
export default UserGrid;
