import moment from 'moment';
import api from 'src/api';
import { convertObjectToQuerystring } from 'src/utils/helper';

export const fetchAutomations = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/automation/${filter}`, null, 'get');
};

export const createAutomation = (payload) => {
  return api(`/automation`, payload, 'post');
};

export const updateAutomation = (automationId, payload) => {
  return api(`/automation/${automationId}`, payload, 'put');
};

export const deleteAutomation = (automationId) => {
  return api(`/automation/${automationId}`, null, 'delete');
};

export const fetchResellerReport = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/report/${filter}`, null, 'get');
};

export const fetchResellerTenantReport = (tenantId, filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/${tenantId}/report/${filter}`, null, 'get');
};

export const fetchResellerTenantUserReport = (tenantId, userId, filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/${tenantId}/user/${userId}/report/${filter}`, null, 'get');
};

export const fetchResellerReportSummary = (resellerFilters) => {
  let filter = '';
  if (!_.isEmpty(resellerFilters)) {
    filter = `?${convertObjectToQuerystring(resellerFilters)}`;
  }
  return api(`/reseller/report/summary${filter}`, null, 'get');
};

export const fetchResellerTenantReportSummary = (tenantId, resellerFilters) => {
  let filter = '';
  if (!_.isEmpty(resellerFilters)) {
    filter = `?${convertObjectToQuerystring(resellerFilters)}`;
  }
  return api(`/reseller/${tenantId}/report/summary${filter}`, null, 'get');
};

export const fetchResellerTenantUserReportSummary = (tenantId, userId, resellerFilters) => {
  let filter = '';
  if (!_.isEmpty(resellerFilters)) {
    filter = `?${convertObjectToQuerystring(resellerFilters)}`;
  }
  return api(`/reseller/${tenantId}/user/${userId}/report/summary${filter}`, null, 'get');
};

export const tenantResellersLookup = async (tenantId, search, type) => {
  let res = [];
  const filters = {
    // _from: 0,
    // _size: 10,
    ...(search &&
      search !== '' && {
        _search: search,
      }),
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  // filter = '';
  try {
    const response = await api(`/reseller/${filter}`, null, 'get');
    res = response.resellers;
    return res;
  } catch (error) {
    return res;
  }
};

export const tenantPartnersLookup = async (tenantId, search, type) => {
  let res = [];
  const filters = {
    // _from: 0,
    // _size: 10,
    ...(search &&
      search !== '' && {
        _search: search,
      }),
  };
  let filter = `?${convertObjectToQuerystring(filters)}`;
  // filter = '';
  try {
    const response = await api(`/partner${filter}`, null, 'get');
    res = response.partners;
    return res;
  } catch (error) {
    return res;
  }
};

export const fetchTenantResellers = (tenantId, filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/${tenantId}/${filter}`, null, 'get');
};

export const getResellerSummary = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/summary/${filter}`, null, 'get');
};

export const getResellerSummaryStats = (filters) => {
  let filter = '';
  if (!_.isEmpty(filters)) {
    filter = `?${convertObjectToQuerystring(filters)}`;
  }
  return api(`/reseller/summary/stats/${filter}`, null, 'get');
};
