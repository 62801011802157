import React from 'react';
import { Box, Grid } from '@mui/material';
import { Form } from 'src/components/shared';
import { EmailContent, PreviewContent } from './style';

function LinkedIn({ event, onlyViewable }) {
  return (
    <>
      {onlyViewable ? (
        <Grid key={`sequence-preview=email`} item xs={12}>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <PreviewContent>
              <EmailContent
                component="div"
                dangerouslySetInnerHTML={{
                  __html: event?.contactPreviewObj?.description || event?.description,
                }}
              />
            </PreviewContent>
          </Box>
        </Grid>
      ) : (
        <Box sx={{ marginTop: '-8px' }}>
          <Form.Field.TextEditor
            name={`${event.id}.description`}
            showFontStyle={false}
            showFontEditor={false}
            showAlignProps={false}
            showListProps={false}
            showAttachments={false}
            toolbarLocation="bottom"
          />
        </Box>
      )}
    </>
  );
}

export default LinkedIn;
