import React from 'react';
import { Card, CardContent, Skeleton, Grid, Stack, Box } from '@mui/material';
import { Spacer } from 'src/components/shared';

export const Skeleton1 = () => {
  return (
    <Card>
      <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
        <Skeleton animation="wave" height={24} width={200} />
        <Spacer x={2} y={2} />
        <Grid container>
          <Grid item xs={12} md={6} sx={{ height: '559px' }}>
            <Skeleton animation="wave" height={'100%'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box pl={2}>
              <Skeleton
                animation="wave"
                height={'248px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Box>
            <Stack direction="column" spacing={2} pl={2} mt={2}>
              <Stack direction="row" spacing={2}>
                <Skeleton
                  animation="wave"
                  height={'296px'}
                  width={'100%'}
                  sx={{ transform: 'none' }}
                />
                <Skeleton
                  animation="wave"
                  height={'296px'}
                  width={'100%'}
                  sx={{ transform: 'none' }}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const Skeleton2 = () => {
  return (
    <Card>
      <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
        <Skeleton animation="wave" height={24} width={200} />
        <Spacer x={2} y={2} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton animation="wave" height={'250px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton animation="wave" height={'250px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const Skeleton3 = () => {
  return (
    <Card>
      <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
        <Skeleton animation="wave" height={24} width={200} />
        <Spacer x={2} y={2} />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton animation="wave" height={'180px'} width={'100%'} sx={{ transform: 'none' }} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

function HomeSkeleton() {
  return (
    <>
      <Card>
        <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
          <Skeleton animation="wave" height={24} width={200} />
          <Spacer x={2} y={2} />
          <Grid container>
            <Grid item xs={12} md={6} sx={{ height: '559px' }}>
              <Skeleton
                animation="wave"
                height={'100%'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box pl={2}>
                <Skeleton
                  animation="wave"
                  height={'248px'}
                  width={'100%'}
                  sx={{ transform: 'none' }}
                />
              </Box>
              <Stack direction="column" spacing={2} pl={2} mt={2}>
                <Stack direction="row" spacing={2}>
                  <Skeleton
                    animation="wave"
                    height={'296px'}
                    width={'100%'}
                    sx={{ transform: 'none' }}
                  />
                  <Skeleton
                    animation="wave"
                    height={'296px'}
                    width={'100%'}
                    sx={{ transform: 'none' }}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Spacer x={3} y={3} />
      <Card>
        <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
          <Skeleton animation="wave" height={24} width={200} />
          <Spacer x={2} y={2} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                height={'250px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                animation="wave"
                height={'250px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Spacer x={3} y={3} />
      <Card>
        <CardContent sx={{ backgroundColor: '#0C1721', padding: '24px' }}>
          <Skeleton animation="wave" height={24} width={200} />
          <Spacer x={2} y={2} />
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
            <Grid item xs={3}>
              <Skeleton
                animation="wave"
                height={'180px'}
                width={'100%'}
                sx={{ transform: 'none' }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default HomeSkeleton;
