import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import { toggleContactDrawer } from 'src/modules/app/actions/appActions';
import styled from 'styled-components';
import { green, red } from '@mui/material/colors';
import { ContactStatus, ContactStatusLabel } from './styles';
import {
  getEnrollmentStatusLabel,
  getStatusIcon,
  getStepIcon,
  getStepTitle,
} from '../../utils/sequenceHelper';
import { Avatar, Button, Menu } from '../../../../components/shared';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router-dom';

export const StatsText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${(props) =>
    props.count == 0 ? 'rgba(0, 0, 0, 0.87)' : props.count > 50 ? green[500] : red[500]} !important;
`;

export function useSequenceContactsColumns(sequencesContacts, onEdit, onDelete) {
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setColumns([
      {
        name: 'view',
        label: ' ',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            const isUnsubscribed = rData?.contactObj?.status === 'unsubscribed';
            return (
              <Box justifyContent="center" display="flex">
                <Button
                  iconButton={true}
                  disabled={isUnsubscribed}
                  onClick={() => {
                    dispatch(toggleContactDrawer(rData.contactObj.id));
                  }}
                >
                  <VisibilityIcon fontSize={'small'} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </Button>
              </Box>
            );
          },
        },
      },
      {
        label: 'Name',
        name: 'contactName',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            let userEmail = '';
            if (rData?.emails?.length > 0) {
              const findPersonalEmail = rData?.emails.find(
                (email) => email.type === 'personal' && email?.isPrimary,
              )?.email;
              if (!findPersonalEmail) {
                userEmail = rData?.emails.find(
                  (email) => email.type === 'work' && email?.isPrimary,
                )?.email;
                if (!userEmail) {
                  userEmail = rData?.emails[0]?.email;
                }
              } else {
                userEmail = findPersonalEmail;
              }
            }
            return (
              <>
                <ButtonBase
                  onClick={() => {
                    history.push(`/contact/${rData.contactObj.id}`);
                  }}
                >
                  <Typography variant="body2" color="secondary">
                    {`${rData.contactObj.fname} ${rData.contactObj.lname}`}
                  </Typography>
                </ButtonBase>
                {userEmail && <Typography variant="body2">{userEmail}</Typography>}
              </>
            );
          },
        },
      },
      {
        label: 'Company',
        name: 'employer',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            return <Typography variant="body2">{`${rData.contactObj?.employer}`}</Typography>;
          },
        },
      },
      {
        label: 'Completed step',
        name: 'stepsCompleted',
        options: {
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'left', minWidth: 150 },
          }),
          setCellProps: () => ({
            style: { textAlign: 'left', minWidth: 150 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            if (rData?.stats?.stepsCompleted > 0 || rData?.status === 'bounced') {
              const completedStepTaskType = rData?.tasks?.find(
                (task) => task?.taskOrderInSequence == rData?.stats?.stepsCompleted - 1,
              )?.taskType;
              const completeTaskName =
                rData?.status !== 'bounced'
                  ? getStepIcon(completedStepTaskType)
                  : getStepIcon('email');
              return (
                <Box display="flex" alignItems="center" justifyContent={'flex-start'} gap="10px">
                  {completeTaskName}
                  <Typography
                    variant="body2"
                    sx={{ color: '#000000DE', textAlign: 'start', textWrap: 'nowrap' }}
                  >
                    {rData?.status === 'bounced'
                      ? getStepTitle('email')
                      : getStepTitle(completedStepTaskType)}
                    ({rData?.stats?.stepsCompleted}/{rData?.stats?.totalSteps})
                  </Typography>
                </Box>
              );
            } else {
              return (
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  ({rData?.stats?.stepsCompleted}/{rData?.stats?.totalSteps})
                </Typography>
              );
            }
          },
        },
      },
      {
        label: 'Opened',
        name: 'openrate',
        options: {
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const opens = rData?.stats?.uniqueOpens || 0;
            const rate = rData?.stats?.uniqueOpenRate;
            const rateStr = `${rData?.stats?.uniqueOpenRate || 0}%`;
            return (
              <StatsText count={rate}>
                {opens}
                {/*{rateStr}*/}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Clicked',
        name: 'clicked',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const clicks = rData?.stats?.uniqueClicks || 0;
            const rate = rData?.stats?.uniqueClickRate;
            const rateStr = `${rData?.stats?.uniqueClickRate || 0}%`;
            return (
              <StatsText count={rate}>
                {clicks}
                {/*{rateStr}*/}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Replied',
        name: 'replied',
        options: {
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            const clicks = rData?.stats?.uniqueReplies || 0;
            const rateStr = `${rData?.stats?.uniqueReplyRate || 0}%`;
            return (
              <StatsText count={0}>
                {clicks}
                {/*{rateStr}*/}
              </StatsText>
            );
          },
        },
      },
      {
        label: 'Status',
        name: 'status',
        options: {
          setCellHeaderProps: (value) => ({
            className: 'center-status',
          }),
          setCellProps: () => ({
            style: { textAlign: 'center' },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            return (
              <Stack alignItems="center">
                <ContactStatus status={rData?.status}>
                  <img src={getStatusIcon(rData?.status, rData?.statusObj?.description)} />
                  <ContactStatusLabel status={rData?.status}>
                    {getEnrollmentStatusLabel(rData.status)}
                  </ContactStatusLabel>
                </ContactStatus>
              </Stack>
            );
          },
        },
      },
      {
        label: 'Enrolled by',
        name: 'enrolledBy',
        options: {
          sort: false,
          align: 'center',
          textAlign: 'center',
          setCellHeaderProps: (value) => ({
            style: { textAlign: 'center', minWidth: 100 },
          }),
          setCellProps: () => ({
            style: { textAlign: 'center', minWidth: 100 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            return (
              <Stack alignItems={'center'}>
                <Avatar
                  sx={{ backgroundColor: '#BDBDBD !important' }}
                  name={`${rData?.createdBy?.fname} ${rData?.createdBy?.lname}`}
                  src={
                    rData?.createdBy?.userImage ||
                    rData?.createdBy?.imageUrl ||
                    rData?.createdBy?.profileImageUrl
                  }
                  size={32}
                />
              </Stack>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = sequencesContacts[dataIndex];
            if (!rData?.contactObj?.id) return null;
            const isUnsubscribed = rData?.contactObj?.status === 'unsubscribed';
            const contact = rData?.contactObj;
            return (
              <Menu
                disabled={isUnsubscribed}
                options={[
                  {
                    label: 'Edit',
                    icon: <EditIcon />,
                    onClick: () => {
                      onEdit?.(contact);
                    },
                  },
                  {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    confirm: true,
                    confirmMessage: 'Are you sure you want to delete this contact?',
                    onClick: () => {
                      onDelete?.(contact);
                    },
                    popUpTitle: 'Delete Contact',
                  },
                ]}
              />
            );
          },
        },
      },
    ]);
  }, [sequencesContacts]);

  return columns;
}
