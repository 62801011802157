import { StepConnector } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

const connectorStyles = (props) => (theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: !props?.seqNav && theme.palette.secondary.main,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: !props?.seqNav && theme.palette.secondary.main,
    },
  },
  line: {
    border: 0,
    borderRadius: 1,
    marginLeft: 10,
    marginRight: 10,
    height: props?.seqNav ? '1px' : 2,
    width: props?.seqNav && '41px',
    backgroundColor: props?.seqNav ? '#E0E0E0' : theme.palette.divider,
  },
});

export const ConnectorStyles = (props) => {
  const StyledComponent = withStyles(connectorStyles(props))(StepConnector);
  return <StyledComponent />;
};

export const IconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    zIndex: 1,
    color: '#fff',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const StepperStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    padding: 0,
    '& .MuiSvgIcon-root': { color: '#0000001F' },
    '& .MuiSvgIcon-root.Mui-active': {
      color: (props) => (props?.seqNav ? '#1976D2' : theme.palette.primary.main),
    },
    '& .MuiSvgIcon-root.Mui-completed': {
      color: (props) => (props?.seqNav ? '#1976D2' : theme.palette.primary.main),
    },
  },
}));
