import React, { useState } from 'react';
import { Box, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';

import SummaryReportCards from './SummaryReportCards';
import GridRatings from './Grid';
import { Spacer } from '../../../../components/shared';
import DashboardLineChart from '../../../app/components/dashboard/Sequences/LineChart';
import { useSeqSummaryStore, withSeqSummaryProvider } from './useSeqSummury';
import { FilterBar, Tabs } from '../../../../components/App';
import UserGrid from '../../../app/components/dashboard/Sequences/UserGrid';
import { fetchUsersLookup } from '../../../users/api/usersApi';

function SequenceSummary({ enrollmentsStats, ...props }) {
  const { sequencesContacts, fetchSequenceContacts, sequence } = props;
  const { loading, filters, setFilters, setChartType, sequenceReport } = useSeqSummaryStore();
  const [currentTab, setCurrentTab] = useState('step');

  const uniqueOpen = 0;

  const handleTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const filtersArr = [
    {
      key: 'enrolledby',
      label: 'Enrolled',
      title: 'Enrolled by',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: false,
      searchable: true,
      isPrimary: true,
      checkbox: true,
      multiple: true,
      apply: true,
      remoteMethod: async (value) => {
        return fetchUsersLookup(value);
      },
    },
    {
      key: 'createdAt',
      title: 'Filter By Date',
      custom: true,
      isCloseIcon: false,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'last7days', name: 'Last 7 days' },
        { id: 'last14days', name: 'Last 14 days' },
        { id: 'last30Days', name: 'Last 30 days' },
        { id: 'last90Days', name: 'Last 90 days' },
        { id: 'last180Days', name: 'Last 180 days' },
        { id: 'last365Days', name: 'Last 365 days' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'thisQuarter', name: 'This quarter' },
        { id: 'thisYear', name: 'This year' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'lastQuarter', name: 'Last quarter' },
        { id: 'lastYear', name: 'Last year' },
      ],
      isPrimary: true,
    },
  ];

  const handleFilterChange = (key, value) => {
    const daily = [
      'today',
      'yesterday',
      'last7days',
      'last14days',
      'last30Days',
      'thisWeek',
      'thisMonth',
      'lastWeek',
      'lastMonth',
    ];
    const weekly = ['last90Days', 'last180Days', 'thisQuarter', 'lastQuarter'];
    const monthly = ['last365Days', 'thisYear', 'lastYear'];
    if (value && typeof value !== 'undefined') {
      if (daily.includes(value.id)) {
        setChartType('daily');
      } else if (weekly.includes(value.id)) {
        setChartType('weekly');
      } else if (monthly.includes(value.id)) {
        setChartType('monthly');
      } else {
        setChartType('weekly');
      }
      setFilters({
        ...filters,
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };

  return (
    <Container>
      <FilterBar
        filters={filtersArr}
        appliedFilters={filters}
        onChange={handleFilterChange}
        withClearButton
      />
      <Spacer x={1} y={1} />
      <SummaryReportCards loading={loading} sequence={sequenceReport} />
      <Spacer x={2} y={3} />
      <Box sx={{ maxWidth: 'calc(100vw - 300px)' }}>
        <DashboardLineChart isFromSeqDetailPage />
      </Box>
      <Spacer x={2} y={2} />
      <Box mb={2}>
        <Tabs
          tabs={[
            {
              id: 'step',
              name: 'Steps',
              icon: <SendIcon />,
            },
            {
              id: 'users',
              name: 'Enrolled By',
              icon: <PersonIcon />,
            },
          ]}
          value={currentTab}
          onChange={handleTabChange}
          sx={{ zIndex: 100 }}
        />
      </Box>
      {currentTab === 'step' ? (
        <GridRatings
          sequence={props.sequence}
          seqId={props.seqId}
          openRate={uniqueOpen}
          fetchSequenceContacts={fetchSequenceContacts}
          sequencesContacts={sequencesContacts}
          contactsLoading={props.contactsLoading}
          {...props}
        />
      ) : null}
      {currentTab === 'users' ? <UserGrid isFromSeqDetailPage /> : null}
    </Container>
  );
}

export default withSeqSummaryProvider(SequenceSummary);
