import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import VoicesView from './VoicesView';
import { fetchVoices, fetchVoicesTabs, saveVoice, updateVoiceStatus } from '../../actions/authActions';

export const VoicesContainer = (props) => {
  useEffect(() => {
    if (!props.voices?.length) {
      props.fetchVoices(props.voices.paging, props.voices.filters, props.voices.sort);
    }
  }, []);
  return <VoicesView {...props} />;
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    voicesTabs: state.auth.voices?.voiceTabs,
    voices:  state.auth?.voices,
    total:  state.auth.voices?.paging.count || 10 ,
    globals: state.app.globals,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchVoicesTabs: () => dispatch(fetchVoicesTabs()),
  fetchVoices: (paging, filters, sort) => dispatch(fetchVoices(paging, filters, sort)),
  saveVoice: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveVoice(data, resolve, reject));
    });
  },
  updateVoiceStatus: (rowData) => dispatch(updateVoiceStatus(rowData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoicesContainer);
