import React, { useEffect, useState } from 'react';
import { AppGrid } from 'src/components/App';
import { useColumns } from './useColumns';

import { useDataProviders } from '../../utils/billingStore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from 'src/components/shared';
import { Box } from '@mui/material';

function Invoices() {
  const {
    invoices,
    fetchInvoices,
    fetchInvoice,
    payNowInvoice,
    loading,
    appliedFilters,
    setAppliedFilters,
  } = useDataProviders();
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const columns = useColumns(invoices, fetchInvoice, payNowInvoice);

  const filtersAvailable = [
    {
      key: 'createdAtRange',
      title: 'Select Date',
      custom: true,
      type: 'relativeDateRange',
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
    {
      key: 'status',
      title: 'Status',
      type: 'dropdown',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      options: [
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
      ],
    },
  ];

  const handleTableChange = ({ pageNo, perPage }) => {
    setAppliedFilters((prevProps) => {
      return {
        paging: { pageNo, perPage },
        filters: prevProps.filters,
      };
    });
  };

  const onFilterChange = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setAppliedFilters((prevProps) => {
        const { paging, filters } = prevProps;
        return {
          paging: {
            ...paging,
            pageNo: 0,
          },
          filters: { ...filters, [key]: value },
        };
      });
    } else {
      setAppliedFilters((prevProps) => {
        const { paging, filters } = prevProps;
        delete filters[key];
        return { paging, filters };
      });
    }
  };
  const enhancedColumns = [
    ...columns,
    {
      label: ' ',
      name: ' ',
      options: {
        filter: true,
        sort: true,
        draggable: true,
        setCellHeaderProps: (value) => ({
          style: { maxWidth: '16px', minWidth: '16px' },
        }),
        setCellProps: () => ({
          style: { maxWidth: '16px', minWidth: '16px' },
        }),
        customBodyRenderLite: (dataIndex) => {
          const invoice = invoices[dataIndex];
          return (
            hoveredRowIndex === dataIndex && (
              <Box>
                <Button
                  iconButton
                  onClick={async () => {
                    try {
                      const res = await fetchInvoice(invoice.id);
                      const url = res.invoice.download_url;
                      window.open(url, '_blank');
                    } catch (error) {}
                  }}
                >
                  <FileDownloadIcon sx={{ color: '#4CAF50' }} />
                </Button>
              </Box>
            )
          );
        },
      },
    },
  ];
  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const handleRowHoverLeave = () => {
    setHoveredRowIndex(null);
  };

  const onSort = (sort) => {
    setAppliedFilters((prevState) => ({ ...prevState, filters: { ...prevState.filters, sort } }));
  };

  return (
    <>
      <AppGrid
        selectableRows="none"
        columns={enhancedColumns}
        data={invoices}
        onSort={onSort}
        filters={filtersAvailable}
        appliedFilters={appliedFilters?.filters}
        onTableChange={handleTableChange}
        onFilterChange={onFilterChange}
        loading={loading.invoices}
        options={{
          sortOrder: appliedFilters?.filters?.sort,
          pagination: false,
          page: appliedFilters?.paging?.pageNo,
          rowsPerPage: appliedFilters?.paging?.perPage,
          serverSide: true,
          searchPlaceholder: 'Search',
          searchText: appliedFilters?.filters?._search ? appliedFilters?.filters._search : '',
          searchOpen: true,
          search: true,
          setRowProps: (row, dataIndex) => ({
            onMouseEnter: () => handleRowHover(dataIndex),
            onMouseLeave: handleRowHoverLeave,
          }),
          // page: paging.pageNo,
          // rowsPerPage: paging.perPage,
          // count: paging.count,
        }}
      />
    </>
  );
}

export default Invoices;
