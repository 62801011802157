import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Modal } from 'src/components/shared';
import { Stack, Button } from '@mui/material';
import {
  AmountTextWrapper,
  ContentWrapper,
  MainWrapper,
  SubTextWrapper,
  TextWrapper,
  SaveBadge,
} from './style';

const plans = [
  {
    id: 'OneMembership-Discount-Pricing-GBP-Monthly',
    title: 'Premium',
    subtitle: 'Monthly',
    price: '$20',
    description: '/ user per month',
  },
  {
    id: 'OneMembership---Annual-GBP-Yearly',
    title: 'Premium',
    subtitle: 'Annual',
    price: '$15',
    description: '/ user per month',
  },
];

function SelectPlanModal({ open, onClose, handleUpdate, selectPlan }) {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const getValue = (id) => {
    if (!selectPlan?.plans || !Array.isArray(selectPlan.plans)) {
      return 0; // Fallback if selectPlan.plan is not an array
    }
    if (id === 0 && selectPlan?.plans[1]?.planName === "oneMembershipMonthly") {
      return selectPlan?.plans[1]?.price;
    } else if (id === 1 && selectPlan?.plans[0]?.planName ==="oneMembershipAnnual") {
      const monthselectPlanValue = +selectPlan?.plans[0]?.price / 12;
      return monthselectPlanValue;
    } else return 0;
  };

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '860px !important',
        },
        '& .MuiDialogTitle-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '32px 48px 24px 48px !important',
          padding: '0px 0px 24px 0px !important',
        },
        '& .MuiTypography-root-MuiDialogTitle-root': {
          padding: '0px !important',
        },
        '& .MuiDialogContent-root': {
          padding: '32px 48px 24px 48px !important',
        },
        '& .MuiDialogActions-root': { padding: '0px !important' },
      }}
      title={
        <Stack gap="6px">
          <Typography fontSize="18px" fontWeight="500">
            Select Plan
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Simple and flexible per-user pricing.
          </Typography>
        </Stack>
      }
      onClose={() => {
        onClose();
      }}
    >
      <Box display={'flex'} gap={'16px'} flexDirection={'column'}>
        <Box display={'flex'} gap={'16px'} sx={{ width: '100%' }}>
          {plans.map((plan, index) => (
            <MainWrapper
              key={plan.id}
              selected={selectedPlan === plan.id}
              isDefault={selectedPlan === null} // If no card is selected (default state)
              onClick={() => setSelectedPlan(plan.id)}
            >
              {plan.badge && <SaveBadge>{plan.badge}</SaveBadge>}
              <Box sx={{ paddding: '8px 0' }}>
                <TextWrapper selected={selectedPlan === plan.id} isDefault={selectedPlan === null}>
                  {plan?.title}
                </TextWrapper>
                <SubTextWrapper
                  selected={selectedPlan === plan.id}
                  isDefault={selectedPlan === null}
                >
                  {plan?.subtitle}
                </SubTextWrapper>
              </Box>
              <Box display={'flex'} gap={'8px'} alignItems={'end'}>
                <AmountTextWrapper
                  selected={selectedPlan === plan.id}
                  isDefault={selectedPlan === null}
                >
                  {getValue(index)}
                </AmountTextWrapper>
                <ContentWrapper
                  selected={selectedPlan === plan.id}
                  isDefault={selectedPlan === null}
                >
                  {plan.description}
                </ContentWrapper>
              </Box>
            </MainWrapper>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            gap: '16px',
          }}
        >
          <Button
            sx={{
              width: 'fitContent',
              backgroundColor: '#f5f5f5',
              color: '#333',
              flex: 1,
              padding: '8px 24px',
              textTransform: 'none',
              maxWidth: '101px',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
            onClick={() => setSelectedPlan(null)}
            disabled={!selectedPlan}
          >
            Cancel
          </Button>
          <Box
            sx={{
              '& .Mui-disabled': {
                color: '#fff !important',
                backgroundColor: 'rgba(31,63,63,0.7)',
              },
            }}
          >
            <Button
              sx={{
                backgroundColor: '#2F4F4F',
                color: '#fff',
                flex: 1,
                maxWidth: '152px',
                padding: '8px 24px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#1f3f3f',
                },
              }}
              disabled={!selectedPlan}
              onClick={() => {
                handleUpdate(selectedPlan);
                setSelectedPlan(null);
              }}
            >
              Upgrade plan
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SelectPlanModal;
