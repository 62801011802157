import React from "react";
import { connect } from "react-redux";
import { Button, Stack, Typography, useTheme, Box } from "@mui/material";
import { Spacer } from "src/components/shared";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { registerReVerification } from "../../actions/authActions";
import styled from "styled-components";
import images from "src/config/images";

const AlertContentBox = styled(Box)`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 7px;
  background: #e4feed;
`;

const AlertContent = styled(Typography)`
  color: #2e7d32;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

function RegisterVerification(props) {
  const email = props.router.location?.query?.email || "";
  const uid = props.router.location?.query?.id || "";
  const theme = useTheme();

  return (
    <div style={{ maxWidth: 442, margin: "0 auto" }}>
      <Typography variant="h1" align="center" sx={{ mb: "36px" }}>
        Email Verification
      </Typography>
      <AlertContentBox>
        <img src={images.icons.checkSuccessIcon} alt={""} />
        <AlertContent>
          We've sent an email to <>{email}</>.
          <br />
          Click the link in the email to verify your account.
        </AlertContent>
      </AlertContentBox>
      <Typography variant="body2" color="textSecondary" sx={{ mt: "16px", color: "#252627" }}>
        If you have not received an email, please check your spam folder.
      </Typography>
      <Typography variant="body2" sx={{ mt: "16px", color: "#252627" }}>
        Or try resend or edit your email
      </Typography>
      <Stack
        mb={1}
        mt={2}
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          borderRadius: "7px",
          background: theme.palette.text.white1,
          padding: "8px 8px 8px 16px",
          boxShadow:
            "0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 2px 0px rgba(0, 0, 0, 0.05) inset",
        }}
      >
        <Stack direction="row" gap={"12px"} alignItems={"center"}>
          <EmailOutlinedIcon sx={(theme) => ({ color: theme.palette.text.grey6 })} />
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.grey,
              fontSize: "16px",
              maxWidth: "240px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            })}
          >
            {email}
          </Typography>
        </Stack>
        <Button
          onClick={() => props.registerReVerification(uid)}
          sx={{
            borderRadius: "4px",
            textTransform: "none",
            background: theme.palette.background.darkBlue,
            color: theme.palette.text.darkSkyBlue,
            letterSpacing: "0.4px",
          }}
        >
          Resend link
        </Button>
      </Stack>
    </div>
  );
}

const mapStateToProps = (state) => ({
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  registerReVerification: (id) => {
    return new Promise((resolve, reject) => {
      dispatch(registerReVerification(id, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerification);
