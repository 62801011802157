import React from 'react';
import { Box, Stack } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export const helperTextValue = ({helperText, sx = {}}) => (
  <Stack direction="row" alignItems="center" gap="8px" sx={sx}>
    <ErrorOutlineOutlinedIcon
      sx={(theme) => ({
        fontSize: '24px',
        color: `${theme.palette.error.main} !important`,
        fill: `${theme.palette.error.main} !important`,
      })}
    />
    <Box
      sx={(theme) => ({
        lineHeight: 'normal',
        color: `${theme.palette.error.main} !important`,
        fontSize: '14px',
      })}
    >
      {helperText}
    </Box>
  </Stack>
);
