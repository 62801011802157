import { Button, Grid, Typography, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useStyles from '../CoverButtonSection/style';

export const CoverButtonSection = ({ text, tags = [] }) => {
  const classes = useStyles();
  if (!tags?.length) return null;

  return (
    <Grid className={classes.container}>
      <Box className={classes.title}>
        <Typography className="title" variant="p">
          {text}
        </Typography>
      </Box>
      <Grid className="tags-container">
        {tags.map((k) =>
          k.name ? (
            <Button
              key={k.id}
              style={{
                textTransform: 'none',
                height: '28px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'left',
                justifyContent: 'flex-start',
                maxWidth: '160px',
              }}
              title={k.name}
            >
              {k.name}
            </Button>
          ) : (
            <Button key={k.id}>No name</Button>
          ),
        )}
      </Grid>
    </Grid>
  );
};
export default CoverButtonSection;
