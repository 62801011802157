import React, { useState } from 'react';
import { addEmailBulk, addPhonesBulk, fetchEnrich } from '../../api/contactApis';
import { checkEmailUnique, checkPhoneUnique } from 'src/utils/validation';
import toast from 'src/utils/toast';
import { Box, Link, Typography } from '@mui/material';
import FindMoreMenu from 'src/modules/sequence/components/EnrolmentPersonalize/FindMoreDetails';
import { fetchContact, postContactDetails } from '../../actions/contactActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function SearchContactDetails({ contact, type = '', setDataCreditError, disabled, ...props }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const addContactDetails = async (contactId, type, data) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(contactId, type, data, resolve, reject));
    });
  };

  const findDetails = async (type) => {
    // TODO : THIS SHOULD BE MOVED TO SAGA IN FUTURE
    try {
      setLoading(true);
      let data = [];
      let refetch = false;
      if (contact?.socialLinks?.length) {
        contact.socialLinks.map((item) => {
          if (item?.type === 'linkedin') {
            const newLinkdinUrl = !item?.link?.includes('/talent/search/profile')
              ? (item.link?.split('/') || [])[item.link?.includes('/talent/profile/') ? 5 : 4]
              : '';
            data.push({
              link: newLinkdinUrl,
              type: type,
              id: contact?.id,
              source: item?.source || 'manual',
            });
          }
        });
      }

      if (!data?.length) data.push({ type: type, id: contact?.id });
      const enrichResult = await fetchEnrich(data);
      const enrichContact = enrichResult?.contacts?.[0] || undefined;
      const enrichErrorMessage =
        enrichResult?.contacts?.[0]?.message ||
        enrichResult?.error?.message ||
        enrichResult?.contacts?.error?.message;

      let emailFound = false;
      if (enrichContact || enrichResult) {
        // HANDLE ADDING CONTACT EMAIL ADDRESSES
        if (enrichContact?.emails && enrichContact?.emails.length) {
          const promises = [];
          for (let i = 0; i < enrichContact?.emails.length; i++) {
            promises.push(checkEmailUnique(enrichContact?.emails[i].email, {}));
          }
          const res = await Promise.all(promises);

          const addEmailList = [];
          if (res && res.length) {
            res.map(async (isDup, index) => {
              if (!isDup) {
                const email = enrichContact?.emails[index];
                let payload = {
                  email: email.email,
                  type: email.type,
                  contact: contact.id,
                  source: email.source,
                };
                addEmailList.push(payload);
              } else
                setDataCreditError({
                  [isDup?.email]: (
                    <Typography>
                      Duplicate contact info was found.{' '}
                      <Link sx={{ cursor: 'pointer' }} color="secondary">
                        {isDup?.email}
                      </Link>{' '}
                      is already present in contact{' '}
                      <Link
                        sx={{ cursor: 'pointer' }}
                        color="secondary"
                        href={`/contact/${isDup?.contact.id}`}
                      >
                        {isDup?.contact?.fname + ' ' + isDup?.contact?.lname}
                      </Link>
                    </Typography>
                  ),
                });
            });
          }
          if (addEmailList.length) {
            emailFound = true;
            refetch = true;
            const response = await addEmailBulk(addEmailList);
          }
        }
        if (
          ['workEmail', 'personalEmail']?.includes(type) &&
          !['userLimitExceed', 'tenantLimitExceed']?.includes(enrichResult?.error?.type) &&
          !emailFound
        ) {
          toast.error('No new contact emails found', 'tc');
        }

        // HANDLE ADDING CONTACT PHONE NUMBERS
        let mobileFound = false;
        if (enrichContact?.phones && enrichContact?.phones.length) {
          const promises = [];
          for (let i = 0; i < enrichContact?.phones.length; i++) {
            promises.push(checkPhoneUnique(enrichContact?.phones[i], contact?.id, {}));
          }
          const res = await Promise.all(promises);
          const addPhoneList = [];
          if (res && res.length) {
            res.map(async (allow, index) => {
              const phone = enrichContact?.phones[index];
              let payload = {
                phone: phone.phone,
                type: 'mobile',
                contact: contact.id,
                source: phone.source,
              };
              if (allow && phone.phone) {
                addPhoneList.push(payload);
              }
            });
          }
          if (addPhoneList.length) {
            refetch = true;
            mobileFound = true;
            const response = await addPhonesBulk(addPhoneList);
          }
        }
        if (
          ['mobile']?.includes(type) &&
          !['userLimitExceed', 'tenantLimitExceed']?.includes(enrichResult?.error?.type) &&
          !mobileFound
        ) {
          toast.error('No new contact phone numbers found', 'tc');
        }
        if (refetch) {
          dispatch(fetchContact(contact.id));
        }
      } else {
        toast.error(
          `No new contact ${
            ['workEmail', 'personalEmail']?.includes(type) ? 'emails' : 'phone numbers'
          } found`,
          'tc',
        );
      }
      setLoading(false);
      setDataCreditError({ mainError: enrichErrorMessage });
    } catch (error) {
      setLoading(false);
      toast.error('Error occurred! Please try again.');
    }
  };

  return (
    <Box display="flex" mt={'16px'}>
      <FindMoreMenu
        findDetails={findDetails}
        disabled={disabled}
        loading={type === 'dropdown' && loading}
        isLoading={loading}
      />
    </Box>
  );
}

export default SearchContactDetails;
