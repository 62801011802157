import React from 'react';
import { Stack } from '@mui/material';
import {
  ContentDescription,
  ContentHeader,
  ExperienceButton,
  SubContentDescription,
} from './styles';
import { BottomButton } from './utils';

const ExperienceView = ({ userJourney, setUserJourney, handleUpdateUserJourney, loading }) => {
  const [experience, setExperience] = React.useState(userJourney?.experience || '');

  const handleOnClick = (value) => {
    if (experience === value) {
      setExperience('');
    } else {
      setExperience(value);
    }
  };

  return (
    <>
      <Stack gap={'36px'}>
        <ContentHeader>Your experience</ContentHeader>
        <SubContentDescription>
          What is your experience using an outreach platform?
        </SubContentDescription>
      </Stack>
      <Stack direction={'row'} mt={'24px'} gap={'16px'}>
        <ExperienceButton
          blackButton={experience === 'none'}
          onClick={() => handleOnClick('none')}
          size={'large'}
          variant="contained"
          style={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          None
        </ExperienceButton>
        <ExperienceButton
          blackButton={experience === 'little'}
          onClick={() => handleOnClick('little')}
          size={'large'}
          variant="contained"
          style={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          Little
        </ExperienceButton>
        <ExperienceButton
          blackButton={experience === 'expert'}
          onClick={() => handleOnClick('expert')}
          size={'large'}
          variant="contained"
          style={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          Expert
        </ExperienceButton>
      </Stack>
      <BottomButton
        loading={loading}
        nextDisabled={!experience}
        onSkip={() => {
          setUserJourney((prevState) => ({ ...prevState, experience: experience }));
          handleUpdateUserJourney({ ...userJourney, experience: experience }, 'skipped', 'goals');
        }}
        onNext={() => {
          setUserJourney((prevState) => ({ ...prevState, experience: experience }));
          handleUpdateUserJourney({ ...userJourney, experience: experience }, 'completed', 'goals');
        }}
      />
    </>
  );
};
export default ExperienceView;
