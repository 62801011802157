import React from 'react';
import images from 'src/config/images';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DashboardIcon from '@mui/icons-material/Dashboard';
import OutboxIcon from '@mui/icons-material/Outbox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import HubIcon from '@mui/icons-material/Hub';
import LockIcon from '@mui/icons-material/Lock';
import InboxIcon from '@mui/icons-material/Inbox';
import SendIcon from '@mui/icons-material/Send';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import learningIcon from '../assets/images/learningIcon.svg';
import outreachIcon from '../assets/images/outreachIcon.svg';
import rolePlayIcon from '../assets/images/RolePlay.svg';

const getMenuItems = (
    path = '',
    sideBar = '',
    isReseller,
    isPartner,
    role,
    hasLmsAccess,
    hasOutreachAccess,
) => {
  let menuItems = [];

  switch (sideBar) {
    case 'admin': {
      menuItems = [
        // ...(hasOutreachAccess
        //   ? [
        //     {
        //       id: 'back',
        //       title: 'Back',
        //       menuIcon: <KeyboardBackspaceIcon />,
        //       expand: false,
        //       link: '/',
        //     },
        //   ]
        //   : []),

        ...(['teamlead', 'admin', 'manager']?.includes(role)
            ? [
              {
                id: 'Organisation',
                title: 'Organisation',
                menuIcon: <AccountBalanceIcon />,
                expand: true,
                link: '/admin/users',
                in: [
                  '/admin/users',
                  '/admin/entities',
                  '/admin/teams',
                  '/admin/offices',
                  '/admin/templates',
                ].includes(path),
                child: [
                  {
                    id: 'Users',
                    title: 'Users',
                    activeClass: path.startsWith('/admin/users') ? 'active' : '',
                    link: '/admin/users',
                  },
                  {
                    id: 'Teams',
                    title: 'Teams',
                    activeClass: path.startsWith('/admin/teams') ? 'active' : '',
                    link: '/admin/teams',
                  },
                  {
                    id: 'Offices',
                    title: 'Offices',
                    activeClass: path.startsWith('/admin/offices') ? 'active' : '',
                    link: '/admin/offices',
                  },
                ],
              },
              {
                type: 'divider',
              },
            ]
            : []),
        ...(['admin']?.includes(role)
            ? [
              {
                id: 'billing',
                title: 'Billing',
                menuIcon: <PaymentIcon />,
                link: '/admin/billing/details',
                activeClass: path.startsWith('/admin/billing') ? 'active' : '',
                in: ['/admin/billing'].includes(path),
                // expand: true,
                // child: [
                //   {
                //     id: 'billingPlan',
                //     title: 'Plan',
                //     link: '/admin/billing/details',
                //     activeClass: path.startsWith('/admin/billing') ? 'active' : '',
                //   },
                // ],
              },
              {
                type: 'divider',
              },
            ]
            : []),
        ...(isPartner
            ? [
              {
                id: 'Resellers',
                title: 'Resellers',
                link: '/admin/resellers/dashboard',
                activeClass: path.startsWith('/admin/resellers/dashboard') ? 'active' : '',
                // menuIcon: <SupervisorAccountIcon />,
                menuIcon: <img src={images.app.resellerIcon} style={{ width: '24px' }} />,
              },
              {
                id: 'Automations',
                title: 'Automations',
                link: '/admin/resellers/automation',
                activeClass: path.startsWith('/admin/resellers/automation') ? 'active' : '',
                menuIcon: <PaymentsIcon />,
              },
              {
                type: 'divider',
              },
            ]
            : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && (hasOutreachAccess || hasLmsAccess)
            ? [
              {
                type: 'mainHelper',
                title: 'Products',
              },
            ]
            : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && hasOutreachAccess
            ? [
              {
                id: 'outreachSettings',
                title: 'Outreach',
                expand: true,
                menuIcon: <img src={outreachIcon} alt="outreachIcon" />,
                link: '/admin/settings',
                in: [
                  '/admin/fields',
                  '/admin/fields/lookups',
                  '/admin/settings',
                  '/admin/integrations',
                  '/admin/data/summary',
                ].includes(path),
                child: [
                  // {
                  //   type: 'helper',
                  //   id: 'outreach-customisation',
                  //   title: 'Customisation',
                  // },
                  {
                    id: 'outreachSettings',
                    title: 'Outreach',
                    link: '/admin/settings',
                    activeClass: path.startsWith('/admin/settings') ? 'active' : '',
                  },
                  {
                    id: 'manage-fields',
                    title: 'Field management',
                    activeClass: path.startsWith('/admin/fields') ? 'active' : '',
                    link: '/admin/fields',
                  },
                  {
                    id: 'central-lookup',
                    title: 'Central lookup',
                    activeClass: path.startsWith('/admin/fields/lookups') ? 'active' : '',
                    link: '/admin/fields/lookups',
                  },
                  {
                    id: 'integrations',
                    title: 'Integrations',
                    link: '/admin/integrations',
                    activeClass: path.startsWith('/admin/integrations') ? 'active' : '',
                  },
                  // {
                  //   type: 'helper',
                  //   id: 'dataEnrichment',
                  //   title: 'Data enrichment',
                  // },
                  {
                    id: 'data',
                    title: 'Manage data',
                    link: '/admin/data/waterfall',
                    activeClass: path.startsWith('/admin/data/summary') ? 'active' : '',
                  },
                ],
              },
            ]
            : []),
        ...(['teamlead', 'admin', 'manager']?.includes(role) && hasLmsAccess
            ? [
              {
                id: 'aiLearningSettings',
                title: 'Learning',
                activeClass: path.startsWith('/admin/roleplay') ? 'active' : '',
                expand: true,
                link: '/admin/roleplay/setting',
                in: [
                  '/admin/notes',
                  '/admin/course',
                  '/admin/course/create',
                  '/admin/roleplay/setting',
                  // '/admin/competitions',
                ].includes(path),
                menuIcon: <img src={learningIcon} alt="learningIcon" width={25} />,
                child: [
                  // {
                  //   type: 'helper',
                  //   id: 'learning-customisation',
                  //   title: 'Customisation',
                  // },
                  {
                    id: 'roleplaySettings',
                    title: 'AI Coaching Settings',
                    activeClass: path.startsWith('admin/roleplay') ? 'active' : '',
                    link: '/admin/roleplay/setting',
                  },
                  // {
                  //   id: 'course',
                  //   title: 'Course',
                  //   activeClass: path.startsWith('admin/course') ? 'active' : '',
                  //   link: '/admin/course',
                  // },
                  // {
                  //   id: 'create-course',
                  //   title: 'Create course',
                  //   activeClass: path.startsWith('/admin/course/create') ? 'active' : '',
                  //   link: '/admin/course/create',
                  // },
                  // {
                  //   id: 'competitions',
                  //   title: 'Competitions',
                  //   activeClass: path.startsWith('/admin/competitions') ? 'active' : '',
                  //   link: '/admin/competitions',
                  // },
                  // {
                  //   id: 'Notes',
                  //   title: 'Notes',
                  //   activeClass: path.startsWith('/admin/notes') ? 'active' : '',
                  //   link: '/admin/notes',
                  // },
                ],
              },
            ]
            : []),
        ...(['admin']?.includes(role) && hasLmsAccess
            ? [
              {
                id: 'rolePlay',
                title: 'AI Coaching',
                activeClass: path.startsWith('/admin/roleplay-minutes') ? 'active' : '',
                expand: true,
                link: '/admin/roleplay-minutes',
                in: ['/admin/roleplay-minutes'].includes(path),
                menuIcon: <img src={rolePlayIcon} alt="rolePlayIcon" width={25} />,
                child: [
                  {
                    id: 'roleplay',
                    title: 'AI Coaching Minutes',
                    link: '/admin/roleplay-minutes',
                    activeClass: path.startsWith('/admin/roleplay-minutes') ? 'active' : '',
                    in: ['/admin/roleplay-minutes'].includes(path),
                  },
                ],
              },
            ]
            : []),
        ...(hasLmsAccess
            ? [
              {
                id: 'learning',
                title: 'LMS',
                // link: '/lms',
                link: process.env.REACT_APP_LMS_URL,
                menuIcon: <img src={learningIcon} alt="learningIcon" width={25} />,
              },
            ]
            : []),
      ];
      break;
    }

    case 'profile':
    default: {
      menuItems = [
        {
          id: 'dashboard',
          title: 'Dashboard',
          menuIcon: <DashboardIcon />,
          expand: false,
          link: '/boards',
          activeClass: path.startsWith('/boards') ? 'active' : '',
        },
        {
          id: 'sequences',
          title: 'Sequences',
          activeClass: path.startsWith('/sequence') ? 'active' : '',
          link: '/sequences',
          menuIcon: <AccessTimeIcon />,
        },
        {
          id: 'conversations',
          title: 'Conversations',
          activeClass: ['/inbox', '/outbox', '/sent'].includes(path) ? 'active' : '',
          // in: ['/inbox', '/outbox', '/sent'].includes(path),
          link: '/inbox',
          menuIcon: <ChatIcon />,
          // expand: true,
          // dropdown: true,
          // type: 'dropdown',
          // child: [
          //   {
          //     id: 'inbox',
          //     title: 'Inbox',
          //     menuIcon: <InboxIcon />,
          //     expand: false,
          //     link: '/inbox',
          //     activeClass: path.startsWith('/inbox') ? 'active' : '',
          //   },
          //   {
          //     id: 'scheduled',
          //     title: 'Scheduled',
          //     activeClass: path.startsWith('/outbox') ? 'active' : '',
          //     link: '/outbox',
          //     menuIcon: <OutboxIcon />,
          //   },
          //   {
          //     id: 'sent',
          //     title: 'Sent',
          //     menuIcon: <MarkEmailReadIcon />,
          //     expand: false,
          //     link: '/sent',
          //     activeClass: path.startsWith('/sent') ? 'active' : '',
          //   },
          // ],
        },

        {
          id: 'tasks',
          title: 'Tasks',
          expand: false,
          link: '/tasks',
          menuIcon: <img src={images.nav.task} />,
          activeClass: path.startsWith('/tasks') ? 'active' : '',
        },
        {
          id: 'contacts',
          title: 'People',
          activeClass: path.startsWith('/contacts') ? 'active' : '',
          link: '/contacts',
          menuIcon: <img src={images.nav.contacts} />,
        },
        ...(hasLmsAccess
            ? [
              {
                id: 'learning',
                title: 'LMS',
                // link: '/lms',
                link: process.env.REACT_APP_LMS_URL,
                menuIcon: <img src={learningIcon} alt="learningIcon" width={25} />,
              },
            ]
            : []),

        // {
        //   id: 'notes',
        //   title: 'Notes',
        //   activeClass: path.startsWith('/notes') ? 'active' : '',
        //   link: '/notes',
        //   menuIcon: <OutboxIcon />,
        // },
        /* {
          id: 'templates',
          title: 'Templates',
          menuIcon: <img src={images.nav.template} />,
          link: '/templates/email',
          activeClass: ['templates', '/templates/email', '/templates/linkedin'].includes(path),
          in: ['templates', '/templates/email', '/templates/linkedin'].includes(path),
          child: [
            {
              id: 'email-templates',
              title: 'Email',
              activeClass: path.startsWith('/templates/email') ? 'active' : '',
              link: '/templates/email',
            },
            {
              id: 'linkedIn-template',
              title: 'LinkedIn',
              activeClass: path.startsWith('/templates/linkedin') ? 'active' : '',
              link: '/templates/linkedin',
            },
          ],
        }, */
        // {
        //   id: 'reports',
        //   title: 'Reports',
        //   expand: true,
        //   menuIcon: <img src={images.nav.report} />,
        //   link: '/activity-report',
        //   in: ['/activity-report', '/prospect/candidates'].includes(path),
        //   child: [
        //     {
        //       id: 'activity-report',
        //       title: 'Activity Report',
        //       activeClass: path.startsWith('/activity-report') ? 'active' : '',
        //       link: '/activity-report',
        //     },
        //     {
        //       id: 'my-candidates',
        //       title: 'Sequence Contacts',
        //       activeClass: path.startsWith('/prospect/candidates') ? 'active' : '',
        //       link: '/prospect/candidates',
        //     },
        //   ],
        // },
      ];
    }
  }

  return menuItems;
};

export default getMenuItems;
