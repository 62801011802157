import styled from 'styled-components';
import { Typography } from '@mui/material';

export const ModalContainer = styled.div`
  '& .muidialogcontent-root' : {
    padding: 30px !important;
  }
`;

export const ModalContentTitle = styled(Typography)`
  color: #1976d2;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
`;

export const ModalAIContentTitle = styled(Typography)`
  color: #009980;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Inter', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
`;

export const ModalContentSubTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;

  /* Typography/Body 2 */
  font-family: 'Inter', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.17px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
