import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import { useHistory } from 'react-router-dom';

import { ChildMenu, MenuItem, ChildMenuItem } from './styles';

import { Stack, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLocation } from 'react-router-dom';

import { stripTrailingSlash } from 'src/utils/helper';
import { useSelector } from 'react-redux';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

function Item({ menuItem, collapsed, ...props }) {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const { isDesktop } = useMediaQuery();
  const summary = useSelector((state) => state.lms.summary);

  const handleClick = () => {
    // if (!showMenu) setShowMenu(true);
    setShowMenu(!showMenu);
    if (menuItem.link) {
      history.push(menuItem.link ?? '/');
      // setShowMenu(false);
    }
    if (!isDesktop && !menuItem.children) {
      props.handleDrawerClose();
    }
  };

  const handleClickSub = (item) => {
    history.push(item?.link ?? '/');
    if (!isDesktop) {
      props.handleDrawerClose();
    }
  };

  const path = stripTrailingSlash(location.pathname);

  const isChildActive =
    menuItem?.children &&
    menuItem?.children.length &&
    menuItem?.children.filter((child) => path === child.link).length;

  useEffect(() => {
    if (isChildActive) {
      setShowMenu(true);
    }
    if (path === menuItem.link) {
      setShowMenu(true);
    }
  }, [path, isChildActive]);

  const className = (menu) => (path === menu.link || isChildActive ? 'active' : '');

  const countData = { ...summary };

  if (
    menuItem.key === 'team' &&
    ['standard', 'user']?.includes(user?.acl?.accessLevel?.toLowerCase())
  )
    return null;

  return (
    <>
      <MenuItem className={className(menuItem)}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{menuItem.icon}</ListItemIcon>
          <ListItemText
            primary={
              <Stack direction="row" justifyContent="space-between">
                <Typography>{menuItem.title}</Typography>
                {menuItem.children && menuItem.children.length ? (
                  <>{showMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
                ) : null}
              </Stack>
            }
          />
        </ListItemButton>
      </MenuItem>
      {menuItem.children && menuItem.children.length ? (
        <Collapse in={showMenu}>
          <ChildMenu collapsed={collapsed || undefined} dense disablePadding>
            {menuItem.children.map((childItem, index) => (
              <ChildMenuItem className={path === childItem.link ? 'active' : ''}>
                <ListItemButton
                  disabled={!countData?.[childItem?.countKey]}
                  onClick={() => handleClickSub(childItem)}
                  key={`${menuItem.link}-${index}`}
                >
                  {collapsed && <ListItemIcon>{childItem.icon}</ListItemIcon>}
                  {!collapsed && (
                    <ListItemText
                      primary={
                        <Typography component="span" color="textSecondary">
                          {childItem.title} ({countData[childItem.countKey]})
                        </Typography>
                      }
                    />
                  )}
                </ListItemButton>
              </ChildMenuItem>
            ))}
          </ChildMenu>
        </Collapse>
      ) : null}
    </>
  );
}

export default Item;
