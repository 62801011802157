import styled from 'styled-components';
import { Box, Typography } from '@mui/material';

export const OptionWrapper = styled(Box)`
  display: flex;
  align-items: center;
  //background: lightblue isSelected;
`;

export const OptionEle = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const FlexContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledBox = styled(Box)`
  background: #f0f7ff;
  padding: 9px 12px;
  justify-content: space-between;
  display: flex;
`;
export const StyledLabel = styled(Typography)`
  color: #989494;
  font-size: 14px;
`;
export const StyledValue = styled(Typography)`
  color: #000000;
  font-size: 14px;
`;
