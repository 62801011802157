import { all, fork, put, call, select, takeLatest } from 'redux-saga/effects';
import * as LMSTypes from '../actions/LMSTypes';
import * as LMSApis from '../api/LMSApis';
import * as LMSActions from '../actions/LMSActions';
import toast from 'src/utils/toast';

import { getRelativeDateRange } from 'src/utils/dateUtils';
import { isEmpty } from 'lodash';

export const getSessions = (state) => state.contacts.contacts;
export const getHome = (state) => state.lms.homePage;
export const getHistory = (state) => state.lms.history;
export const getList = (state) => state.lms.list;

function* fetchSessions({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    const response = yield call(LMSApis.fetchSessions, filter);
    yield put(LMSActions.setSessions(response.sessions));
  } catch (error) {}
}
function* fetchCourses() {
  try {
    const response = yield call(LMSApis.fetchCourses);
    yield put(LMSActions.setCourses(response.courses));
  } catch (error) {}
}
function* fetchList({ paging, filters }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...(filters?.search && filters?.search != '' ? { _search: filters?.search } : {}),
    };
    const response = yield call(LMSApis.fetchList, filter);
    yield put(LMSActions.setList(response));
  } catch (error) {}
}

function* fetchLeftNavigation({ filters }) {
  try {
    const response = yield call(LMSApis.getLeftNav, filters);
    yield put(LMSActions.setHomePage(response));
  } catch (error) {}
}

function* fetchComp1({ filters }) {
  try {
    const response = yield call(LMSApis.getCompo1, filters);
    let home = yield select(getHome);
    home = JSON.parse(JSON.stringify(home));
    if (home && home.length) {
      for (let i = 0; i < home.length; i++) {
        home[i] = {
          ...home[i],
          mainSections: {
            ...(home[i]?.mainSections || {}),
            ...(response.homepage[i]?.mainSections || {}),
          },
        };
      }
    }
    yield put(LMSActions.setHomePage(home));
  } catch (error) {}
}

function* fetchComp2({ filters }) {
  try {
    const response = yield call(LMSApis.getCompo2, filters);
    let home = yield select(getHome);
    home = JSON.parse(JSON.stringify(home));
    if (home && home.length) {
      for (let i = 0; i < home.length; i++) {
        home[i] = {
          ...home[i],
          mainSections: {
            ...(home[i]?.mainSections || {}),
            ...(response.homepage[i]?.mainSections || {}),
          },
        };
      }
    }
    yield put(LMSActions.setHomePage(home));
  } catch (error) {}
}

function* fetchComp3({ filters }) {
  try {
    const response = yield call(LMSApis.getCompo3, filters);
    let home = yield select(getHome);
    home = JSON.parse(JSON.stringify(home));
    if (home && home.length) {
      for (let i = 0; i < home.length; i++) {
        home[i] = {
          ...home[i],
          mainSections: {
            ...(home[i]?.mainSections || {}),
            ...(response.homepage[i]?.mainSections || {}),
          },
        };
      }
    }
    yield put(LMSActions.setHomePage(home));
  } catch (error) {}
}

function* fetchHomePage({ filters }) {
  const filter = {
    ...(filters?.experts && filters?.experts.length
      ? { experts: filters?.experts.map((item) => item.id) }
      : {}),
    ...(filters?.tags && filters?.tags.length
      ? { tags: filters?.tags.map((item) => item.id) }
      : {}),
  };

  // yield call(fetchLeftNavigation, filters);
  // yield fork(fetchComp1, filters);
  // yield fork(fetchComp2, filters);
  // yield fork(fetchComp3, filters);
}

function* fetchHomePage_back({ filters }) {
  try {
    const filter = {
      ...(filters?.experts && filters?.experts.length
        ? { experts: filters?.experts.map((item) => item.id) }
        : {}),
      ...(filters?.tags && filters?.tags.length
        ? { tags: filters?.tags.map((item) => item.id) }
        : {}),
    };
    const res = yield call(LMSApis.getHomePage, filter);
    if (res.homepage) {
      yield put(LMSActions.setHomePage(res.homepage.slice(0, 2)));
    } else {
      yield put(LMSActions.setHomePage({}));
    }
  } catch (error) {
    yield put(LMSActions.setHomePage({}));
  }
}

function* fetchSession({ sessionId }) {
  try {
    const res = yield call(LMSApis.getSession, sessionId);
    if (res.session) {
      yield put(LMSActions.setSession(res.session));
    } else {
      yield put(LMSActions.setSession({}));
    }
  } catch (error) {
    yield put(LMSActions.setSession({}));
  }
}
function* fetchSessionScore({ userId, sessionId }) {
  try {
    const res = yield call(LMSApis.getSessionScore, userId, sessionId);
    if (res) {
      yield put(LMSActions.setSessionScore(res));
    } else {
      yield put(LMSActions.setSessionScore({}));
    }
  } catch (error) {
    yield put(LMSActions.setSessionScore({}));
  }
}

function* fetchPillar({ pillarId }) {
  try {
    const res = yield call(LMSApis.getPillar, pillarId);
    if (res.pillar) {
      yield put(LMSActions.setPillar(res.pillar));
    } else {
      yield put(LMSActions.setPillar({}));
    }
  } catch (error) {
    yield put(LMSActions.setPillar({}));
  }
}

function* fetchCourse({ courseId }) {
  try {
    const res = yield call(LMSApis.getCourse, courseId);
    if (res.course) {
      yield put(LMSActions.setCourse(res.course));
    } else {
      yield put(LMSActions.setCourse({}));
    }
  } catch (error) {
    yield put(LMSActions.setCourse({}));
  }
}

function* fetchHistory({ paging, filters }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      // ...(filters?.search && filters?.search != '' ? { _search: filters?.search } : {}),
    };

    if (filters?.updatedAt && !isEmpty(filters?.updatedAt)) {
      const dateRange = getRelativeDateRange(filters?.updatedAt);
      filter = {
        ...filter,
        updatedAt_gte: dateRange.start,
        updatedAt_lte: dateRange.end,
      };
    }

    const res = yield call(LMSApis.fetchHistory, filter);
    let history = res.history;
    let featured = [];
    if (paging.pageNo === 0 && !filters?.updatedAt) {
      featured = history.splice(0, 4);
    }

    if (res.history) {
      yield put(
        LMSActions.setHistory({
          ...res,
          history,
          featured,
        }),
      );
    } else {
      yield put(LMSActions.setHistory({}));
    }
  } catch (error) {
    yield put(LMSActions.setHistory({}));
  }
}
function* fetchNotes({ paging = {}, filters, sort }) {
  try {
    let filter = {};
    if (filters && Object.keys(filters)?.length !== 0)
      filter = {
        _from: paging.length ? paging.pageNo * paging.perPage : 0,
        _size: paging.length ? paging.perPage : 20,
        ...filters,
      };
    const res = yield call(LMSApis.fetchNotes, filter);
    yield put(LMSActions.setNotes(res));
  } catch (error) {}
}
function* deleteNote({ noteId, resolve, reject }) {
  try {
    const response = yield call(LMSApis.deleteNote, noteId);
    // yield put(replace('/contact'));
    if (response?.error && response?.error !== '') {
      yield put(LMSActions.fetchNotes({}, {}, {}));
    } else {
      toast.success('Note Deleted');
      yield put(LMSActions.fetchNotes({}, {}, {}));
    }
  } catch (error) {}
}

function* fetchTemplates({ paging, filters, sort }) {
  try {
    let filter = {};
    if (filters && Object.keys(filters)?.length !== 0)
      filter = {
        _from: paging.pageNo * paging.perPage,
        _size: paging.perPage,
        ...filters,
      };
    const res = yield call(LMSApis.fetchTemplates, filter);
    yield put(LMSActions.setTemplates(res));
  } catch (error) {}
}

function* deleteTemplate({ templateId, resolve, reject }) {
  try {
    const response = yield call(LMSApis.deleteTemplate, templateId);
    // yield put(replace('/contact'));
    if (response?.error && response?.error !== '') {
      yield put(LMSActions.fetchTemplates());
    } else {
      toast.success('Template Deleted');
      yield put(LMSActions.fetchTemplates());
    }
  } catch (error) {}
}
function* editTemplate({ payload, templateId, resolve, reject }) {
  try {
    const response = yield call(LMSApis.editTemplate, payload, templateId);
    if (response?.error && response?.error !== '') {
      yield put(LMSActions.fetchTemplates());
    } else {
      if (Object.keys(response).length) {
        toast.success('Template Edited');
      } else {
        toast.error('Note is not edited');
      }

      yield put(LMSActions.fetchTemplates());
    }
  } catch (error) {}
}
function* editNote({ payload, noteId, resolve, reject }) {
  try {
    const response = yield call(LMSApis.editNote, payload, noteId);
    if (response?.error && response?.error !== '') {
      yield put(LMSActions.fetchNotes());
    } else {
      if (Object.keys(response).length) {
        toast.success('Note Edited');
      } else {
        toast.error('Note is not edited');
      }
      yield put(LMSActions.fetchNotes());
    }
  } catch (error) {}
}

function* deleteHistory({ id, resolve, reject }) {
  try {
    const historyData = yield select(getHistory);
    const res = yield call(LMSApis.deleteHistory, id);
    yield put(
      LMSActions.setHistory({
        history: historyData.data.filter((item) => item.id !== id),
        featured: [],
        total: { value: historyData.paging.count - 1 },
      }),
    );
    toast.success('History item deleted successfully!');
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! please try again.');
  }
}

function* deleteList({ id, resolve, reject }) {
  try {
    const res = yield call(LMSApis.deleteList, id);
    const listData = yield select(getList);
    yield put(
      LMSActions.setList({
        favourite: listData.data.filter((item) => item.id !== id),
        total: { value: listData.paging.count - 1 },
      }),
    );
    toast.success('Item removed from saved list!');
    resolve(true);
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! please try again.');
    reject(false);
  }
}

function* handleReportSummary({ filters, resolve, reject }) {
  try {
    const res = yield call(LMSApis.fetchRoleplaySummary, filters);
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* handleReportCards({ filters, resolve, reject }) {
  try {
    const res = yield call(LMSApis.getReportSummary, filters);
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* handleReportCharts({ filters, resolve, reject }) {
  try {
    const res = yield call(LMSApis.getReportSummaryGraph, filters);
    resolve(res.result || []);
  } catch (error) {
    reject(error);
  }
}

function* handleComp1({ filters, resolve, reject }) {
  try {
    let res = yield call(LMSApis.getCompo1, filters);
    res = res.homepage;
    const salesRes = res.filter((item) => item.categoryType === 'sales' && item.isPublished)[0];
    const recRes = res.filter((item) => item.categoryType === 'recruitment' && item.isPublished)[0];

    resolve({
      sales: salesRes?.mainSections?.component1.filter((k) => k.published) || [],
      recruitment: recRes?.mainSections?.component1.filter((k) => k.published) || [],
    });
  } catch (error) {
    reject(error);
  }
}
function* handleComp2({ filters, resolve, reject }) {
  try {
    let res = yield call(LMSApis.getCompo2, filters);
    res = res.homepage;
    const salesRes = res.filter((item) => item.categoryType === 'sales' && item.isPublished)[0];
    const recRes = res.filter((item) => item.categoryType === 'recruitment' && item.isPublished)[0];
    resolve({
      sales: salesRes?.mainSections?.component2.filter((k) => k.published) || [],
      recruitment: recRes?.mainSections?.component2.filter((k) => k.published) || [],
    });
  } catch (error) {
    reject(error);
  }
}
function* handleComp3({ filters, resolve, reject }) {
  try {
    let res = yield call(LMSApis.getCompo3, filters);
    res = res.homepage;
    const salesRes = res.filter((item) => item.categoryType === 'sales' && item.isPublished)[0];
    const recRes = res.filter((item) => item.categoryType === 'recruitment' && item.isPublished)[0];
    resolve({
      sales:
        salesRes?.mainSections?.component3.filter(
          (k) => k.published && k?.categoryType === 'sales',
        ) || [],
      recruitment:
        recRes?.mainSections?.component3.filter(
          (k) => k.published && k?.categoryType === 'recruitment',
        ) || [],
    });
  } catch (error) {
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(LMSTypes.FETCH_SESSIONS, fetchSessions);
  yield takeLatest(LMSTypes.FETCH_COURSES, fetchCourses);
  yield takeLatest(LMSTypes.FETCH_HOMEPAGE, fetchHomePage);
  yield takeLatest(LMSTypes.FETCH_SESSION, fetchSession);
  yield takeLatest(LMSTypes.FETCH_SESSION_SCORE, fetchSessionScore);
  yield takeLatest(LMSTypes.FETCH_PILLAR, fetchPillar);
  yield takeLatest(LMSTypes.FETCH_COURSE, fetchCourse);
  yield takeLatest(LMSTypes.FETCH_HISTORY, fetchHistory);
  yield takeLatest(LMSTypes.FETCH_TEMPLATES, fetchTemplates);
  yield takeLatest(LMSTypes.FETCH_NOTES, fetchNotes);
  yield takeLatest(LMSTypes.DELETE_NOTES, deleteNote);
  yield takeLatest(LMSTypes.DELETE_TEMPLATE, deleteTemplate);
  yield takeLatest(LMSTypes.EDIT_TEMPLATE, editTemplate);
  yield takeLatest(LMSTypes.EDIT_NOTE, editNote);
  yield takeLatest(LMSTypes.FETCH_LIST, fetchList);
  yield takeLatest(LMSTypes.DELETE_LIST, deleteList);

  yield takeLatest(LMSTypes.DELETE_HISTORY, deleteHistory);

  yield takeLatest(LMSTypes.FETCH_LMS_REPORT_SUMMARY, handleReportSummary);
  yield takeLatest(LMSTypes.FETCH_LMS_REPORT_CARDS, handleReportCards);
  yield takeLatest(LMSTypes.FETCH_LMS_REPORT_CHARTS, handleReportCharts);

  yield takeLatest(LMSTypes.FETCH_LMS_COMPONENT1, handleComp1);
  yield takeLatest(LMSTypes.FETCH_LMS_COMPONENT2, handleComp2);
  yield takeLatest(LMSTypes.FETCH_LMS_COMPONENT3, handleComp3);
}
export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
