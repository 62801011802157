import React from 'react';
import CourseBannerSection from './CourseBannerSection';
import CourseBanner from './CourseBanner';
import CourseDetailsSection from './CourseDetailSection';
import Spacer from 'src/components/shared/Spacer';
import Stack from '@mui/material/Stack';
import CardMedia from 'src/modules/lms/shared/cardMedia';

export const CourseDetail = (props) => {
  return (
    <CourseDetailsSection
      courseBanner={
        !props.loading ? (
          props?.course?.source === 'superadmin' ? (
            <CardMedia
              src={props.course?.headerImage}
              alt={props.course?.title}
              id={props.course?.id}
              style={{ objectFit: 'contain', background: '#0c1721', borderRadius: '8px' }}
              contentType={props.course?.contentType}
              data={props.course}
              loading={props.loading}
              maxSize={'xl'}
              dontShowAction
              isClickable={false}
              makeImageResponsive={true}
              type={'headerImage'}
            />
          ) : (
            <CourseBanner course={props.course} loading={props.loading} />
          )
        ) : (
          <></>
        )
      }
      courseData={props.course}
      loading={props.loading}
    />
  );
};

export default CourseDetail;
