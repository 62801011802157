import React, { useState, useEffect } from 'react';
import { AppGrid } from 'src/components/App';
import { useSequenceContactsColumns } from './useColumns';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { useSeqSummaryStore } from './useSeqSummury';

const SequenceContactsGrid = () => {
  const { seqStepsStats, fetchStepsStats, filters, loading } = useSeqSummaryStore();
  const { data, paging, sort } = seqStepsStats;
  const contactColumns = useSequenceContactsColumns(data);

  useEffect(() => {
    fetchStepsStats({ perPage: paging?.perPage, pageNo: 0 }, sort);
  }, [filters]);

  const onSort = (sort) => {
    fetchStepsStats(paging, sort);
  };

  const handleTableChange = async ({ pageNo, perPage }) => {
    try {
      fetchStepsStats(
        {
          pageNo,
          perPage,
        },
        sort,
      );
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <>
      <AppGrid
        columns={contactColumns.map((col, index) => ({
          ...col,
          options: contactColumns?.columns?.length
            ? { ...col.options, ...contactColumns?.columns[index] }
            : col.options,
        }))}
        data={data}
        onTableChange={handleTableChange}
        // onFilterChange={onFilterChange}
        onSort={onSort}
        loading={loading?.sequenceStats}
        // filters={contactFilters} // hiding Enrolled by filter for now
        appliedFilters={filters}
        options={{
          sortOrder: sort,
          serverSide: true,
          pagination: true,
          page: paging.pageNo,
          rowsPerPage: paging.perPage,
          count: paging.count,
        }}
      />
    </>
  );
};

export default SequenceContactsGrid;
