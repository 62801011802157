import { Modal } from '../../../../components/shared';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Link, Typography } from '@mui/material';
import images from '../../../../config/images';
import { useHistory } from 'react-router-dom';

const WarningMessage = styled('div')(({ theme }) => ({
  backgroundColor: '#FFF3E0', // Light orange background
  padding: '16px 8px',
  borderRadius: '5px',
  color: '#F57C00', // Orange text
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '14px',
}));

const TextWrapper = styled('div')`
  color: #e65100;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
`;

const LinkTextWrapper = styled(Link)`
  color: #1976d2 !important;
  font-family: Roboto, serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important; /* 24px */
  letter-spacing: 0.15px !important;
  display: flex;
  gap: 8px;
  text-decoration: none;
`;

const TitleWrapper = styled('div')`
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter, serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.34px;
`;

export const UserConfirmModal = ({ open, onClose, onCancle }) => {
  const history = useHistory();

  return (
    <Modal
      size="sm"
      title={<TitleWrapper>Add user</TitleWrapper>}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
        },
        '& .MuiDialogTitle-root': {
          padding: '30px !important',
          boxSizing: 'border-box',
          '& > div': {
            alignItems: 'center',
          },
        },
        '& .MuiButton-root': {
          height: '42px',
          textTransform: 'none',
        },
      }}
    >
      <WarningMessage>
        <TextWrapper>You do not have enough licenses.</TextWrapper>
        <LinkTextWrapper href="/admin/billing/subscriptions">
          Add more licenses <img src={images.icons.addLicencesIcon} />
        </LinkTextWrapper>
      </WarningMessage>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: ' 20px' }}>
        <Button variant="outlined" sx={{ padding: '8px 22px' }} color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          sx={{ padding: '8px 22px' }}
          onClick={() => history.push('/admin/billing/subscriptions')}
        >
          Go to License page
        </Button>
      </div>{' '}
    </Modal>
  );
};
