import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Chip } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import Typography from '@mui/material/Typography';
import { fetchAdminDomainLookup, fetchAdminIndustryLookup } from '../../api/authApis';
import { isArray } from 'lodash';
import { id } from 'date-fns/locale';

function AISettings({ editUser, putUser }) {
  const [loading, setLoading] = useState(false);
  const [domainOptions, setDomainOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);

  const {
    domain = '', // default value if domain is null or undefined
    industryExperience = '', // default value if industryExperience is null or undefined
  } = editUser?.roleplaySettings ?? {};
  
  const [domains, setDomain] = useState()
  const [industryExperiences, setIndustryExperiece] = useState()
  useEffect(()=>{
    const initValues = async() => {
      setLoading(true)
      if(typeof domain==="string") {
        const opts = await fetchAdminDomainLookup("");
        const label = opts.find((opt) => opt.value === domain)?.label || domain
        setDomain({"label":label, "value":domain})
      } else {
        setDomain(domain)
      }
      if(typeof industryExperience==="string"){
        const opts = await fetchAdminIndustryLookup("")
        const label = opts.find((opt) => opt.value === industryExperience)?.label || industryExperience
        setIndustryExperiece({"label":label,"value":industryExperience})
      } else {
        setIndustryExperiece(industryExperience)
      }
      setLoading(false)
    }
    initValues()
  },[domain, industryExperience])
  
  const handleSubmit = async (values, form) => {
    try {
      setLoading(true);
      const data = { roleplaySettings: { ...values } };
      if (editUser && editUser?.id) {
        const updatedRow = { id: editUser?.id, data };
        await putUser(updatedRow);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{
        domain: (isArray(domains) ? {"domain": domains[0]} : domains) ?? {},
        industryExperience:
        (isArray(industryExperiences) ? {"industryExperience": industryExperiences[0]} : industryExperiences) ?? {},
      }}
      validationSchema={Yup.object().shape({
        domain: Yup.mixed().test('domain', 'Please choose a domain.', function (item) {
          if (typeof item === 'object') {
            return !!item?.value;
          } else if (typeof item === 'string') {
            return !!item.trim();
          }
          return false;
        }),
        industryExperience: Yup.mixed().test(
          'industryExperience',
          'Please choose an industry.',
          function (item) {
            if (typeof item === 'object') {
              return !!item?.value;
            } else if (typeof item === 'string') {
              return !!item.trim();
            }
            return false;
          }
        ),
      })}
      
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box display="flex" flexDirection="column" height="100%">
              <Box>
                <Typography variant="h4" gutterBottom>
                  AI Learning Settings
                </Typography>
                <Spacer x={1} y={1} />
                <Typography variant="body2" color="textSecondary">
                  Choose your domain and industry specialism to ensure every ai coach is
                  personalised to your exact needs.
                </Typography>
              </Box>
              <Spacer x={2} y={2} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Form.Field.AutoComplete
                    name="domain"
                    options={domainOptions}
                    fullWidth={true}
                    variant="outlined"
                    remoteMethod={(val) => {
                      return fetchAdminDomainLookup(val, setDomainOptions);
                    }}
                    optLabel="label"
                    optValue="value"
                    label="Domain"
                    hideInputSearchOnOpen
                  />
                </Grid>
                <Grid item xs={6}>
                  <Form.Field.AutoComplete
                    name="industryExperience"
                    options={industryOptions}
                    fullWidth={true}
                    variant="outlined"
                    label="Industry expertise"
                    remoteMethod={(val) => {
                      return fetchAdminIndustryLookup(val, setIndustryOptions);
                    }}
                    optLabel="label"
                    optValue="value"
                    hideInputSearchOnOpen
                  />
                </Grid>
              </Grid>

              <Box mt={2} display="flex">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default AISettings;
