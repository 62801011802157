import { ListItemIcon, MenuItem, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useStyles } from '../../components/ICP/style';

const AutocompleteField = ({
  isDynamicFiled = false,
  item,
  optionList,
  defaultValue,
  handleAutocomplete,
  setFieldValue,
  errors,
  getRenderIcon = null,
}) => {
  const classes = useStyles();

  const isSingle = isDynamicFiled ? item.type === 'single' : item.lookupType === 'single';
  const placeholderText = `Select ${item?.label}`;

  const renderInput = (params) => (
    <TextField
      {...params}
      placeholder={placeholderText}
      error={!!errors[item.fieldName] && !isDynamicFiled}
      InputProps={{
        ...params.InputProps,
        startAdornment:
          isSingle && !isDynamicFiled && params.inputProps.value && getRenderIcon?.icon ? (
            <>
              <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                {getRenderIcon.icon}
              </div>
              {params.InputProps.startAdornment}
            </>
          ) : (
            params.InputProps.startAdornment
          ),
      }}
    />
  );

  const renderOption = (props, option) => (
    <MenuItem
      {...props}
      disabled={option?.disabled || false}
      key={option?.value}
      value={option?.label}
    >
      {option?.icon ? (
        <Box display="flex" alignItems="center">
          <ListItemIcon sx={{ minWidth: '44px' }}>{option.icon}</ListItemIcon>
          {option?.label}
        </Box>
      ) : (
        option?.label
      )}
    </MenuItem>
  );

  const handleChange = (event, newValue) => {
    if (isDynamicFiled) {
      handleAutocomplete(event, newValue, item.value);
    } else {
      handleAutocomplete(event, newValue, item.fieldName);
      setFieldValue(
        item.fieldName,
        isSingle
          ? newValue?.value
          : { value: newValue?.map((item) => item.value), type: 'multiple' },
      );
    }
  };
  return (
    <Box width="100%" sx={{ '& .MuiFormControl-root': { margin: '0px !important' } }}>
      <Autocomplete
        options={optionList || item?.fieldOptions || []}
        fullWidth
        name={item.fieldName}
        multiple={!isSingle}
        filterSelectedOptions={!isSingle}
        onChange={handleChange}
        defaultValue={isSingle ? defaultValue : Array.isArray(defaultValue) ? defaultValue : []}
        limitTags={!isSingle ? 2 : undefined}
        placeholder={placeholderText}
        getOptionLabel={(option) => option?.label || ''}
        renderInput={renderInput}
        renderOption={renderOption}
        className={classes.selectFieldContainer}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            backgroundColor: '#F9F9F9',
          },
          '& .Mui-focused': {
            backgroundColor: '#F9F9F9',
          },
        }}
        size="small"
        variant="outlined"
        optLabel="label"
        optValue="value"
      />
    </Box>
  );
};

export default AutocompleteField;
