import React, { useEffect } from 'react';
import Session from 'src/components/lms/Session';
import Slider from 'src/components/lms/Slider';
import useMediaQuery from 'src/components/hooks/useMediaQuery';

import { LMSCard } from 'src/components/lms';
import { ReactComponent as Progress } from 'src/assets/lms/icons/progress.svg';
import { useLmsHomeProvider } from '../../utils/lmsStore';

import { Button } from 'src/components/shared';
import { useHistory } from 'react-router-dom';

import HomeSkeleton from './HomeSkeleton';

function HomeInProgress({ title }) {
  const { isMobile } = useMediaQuery();
  const { progressSessions, fetchInProgressSessions, loading } = useLmsHomeProvider();

  const history = useHistory();

  useEffect(() => {
    fetchInProgressSessions();
  }, []);

  const sliderProps = {
    slidesToShow: 4,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const viewAll = () => {
    history.push(`lms/progress`);
  };

  if (loading?.progress === true) return <HomeSkeleton />;

  if (!progressSessions?.length) return null;

  return (
    <>
      <LMSCard
        icon={<Progress style={{ width: 24, height: 24 }} />}
        title={title}
        action={
          <Button size="small" color="secondary" onClick={viewAll}>
            View all
          </Button>
        }
      >
        <Slider {...sliderProps}>
          {progressSessions.map((session, index) => (
            <Session key={`session-progress-${index}`} session={session} />
          ))}
        </Slider>
      </LMSCard>
    </>
  );
}

export default HomeInProgress;
