import React, { useState, useEffect } from 'react';
import { Typography, Box, Link, Tooltip, Stack } from '@mui/material';
import { Form, Menu } from 'src/components/shared';
import { HtmlTooltip } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import InfoIcon from '@mui/icons-material/Info';

export function useColumns(
  emailTemplates,
  event,
  changeEventTemplateStatus,
  deleteTemplate,
  editTemplate,
  cloneTemplate,
  setDrawerOpen = () => {},
  setAnchorEl = () => {},
  anchorEl
) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { width: 40 },
          }),
          setCellProps: () => ({
            style: { width: 40 },
          }),
          customBodyRenderLite: (index) => {
            const data = emailTemplates[index];

            const checked =
              data &&
              data?.id &&
              event?.templateProps &&
              typeof event?.templateProps !== 'undefined' &&
              typeof event?.templateProps[data.id] !== 'undefined' &&
              event.templateProps[data.id] &&
              event.templateProps[data.id].status !== 'undefined'
                ? event.templateProps[data.id].status || false
                : true;
            return (
              <Box ml={2} mt={-2}>
                <Form
                  initialValues={{
                    status: checked,
                  }}
                  enableReinitialize={true}
                >
                  <Form.Field.Switch
                    name="status"
                    onChange={(val) => {
                      changeEventTemplateStatus(data.id, val);
                    }}
                  />
                </Form>
              </Box>
            );
          },
        },
      },
      // {
      //   name: 'templateName',
      //   label: 'Template name',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     draggable: false,
      //     setCellHeaderProps: (value) => ({
      //       style: { maxWidth: 1500 },
      //     }),
      //     setCellProps: () => ({
      //       style: { maxWidth: 1500 },
      //     }),
      //     customBodyRenderLite: (dataIndex) => {
      //       const data = emailTemplates[dataIndex];
      //       return (
      //         <Typography
      //           variant="body2"
      //           noWrap
      //           onClick={() => editTemplate(data)}
      //           sx={{
      //             color: '#082037',
      //             cursor: 'pointer',
      //             overflow: 'hidden',
      //           }}
      //           component="div"
      //         >
      //           {data?.name}
      //         </Typography>
      //       );
      //     },
      //   },
      // },
      {
        name: 'subject',
        label: 'Subject',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { maxWidth: '360px', minWidth: '200px' },
          }),
          setCellProps: () => ({
            style: { maxWidth: '360px', minWidth: '200px' },
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            var subject = data?.subject ? data?.subject.replace(/<img[^>]*>/g, '') : '';
            return (
              <HtmlTooltip title={<div dangerouslySetInnerHTML={{ __html: subject }} />}>
                <Typography
                  variant="body2"
                  noWrap
                  onClick={() => editTemplate(data)}
                  sx={{
                    color: '#1976d2',
                    cursor: 'pointer',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                  }}
                  component="div"
                >
                  <div dangerouslySetInnerHTML={{ __html: subject }} />
                </Typography>
              </HtmlTooltip>
            );
          },
        },
      },
      {
        name: 'sent',
        label: 'Sent',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { width: 60 },
          }),
          setCellProps: () => ({
            style: { width: 60 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            const sent = data?.stats?.totalEmailsSent || 0;
            return (
              <Typography variant="body2" color="textSecondary">
                {sent}
              </Typography>
            );
          },
        },
      },
      {
        name: 'opened',
        label: 'Opened',
        // label: (
        //   <Tooltip
        //     title={
        //       <React.Fragment>
        //         <Typography variant="body2" textAlign="center">
        //           Tracking the number of contacts that opened sent emails
        //         </Typography>
        //       </React.Fragment>
        //     }
        //   >
        //     <Stack direction="row" spacing={1}>
        //       <Typography variant="body2" fontWeight="500">
        //         Opened
        //       </Typography>
        //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
        //     </Stack>
        //   </Tooltip>
        // ),
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { width: 90 },
          }),
          setCellProps: () => ({
            style: { width: 90 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            const uniqueOpens = data?.stats?.uniqueOpens || 0;
            const uniqueOpenRate = data?.stats?.uniqueOpenRate || 0;
            return (
              <Typography variant="body2" color="textSecondary">
                {/*{uniqueOpens} (*/}
                {uniqueOpenRate}%{/*%)*/}
              </Typography>
            );
          },
        },
      },
      {
        name: 'clicked',
        label: 'Clicked',
        // label: (
        //   <Tooltip
        //     title={
        //       <React.Fragment>
        //         <Typography variant="body2" textAlign="center">
        //           Tracks the number of times your emails were clicked on. Please note that keep this
        //           tracking enabled could affect the deliverability of emails.
        //         </Typography>
        //       </React.Fragment>
        //     }
        //   >
        //     <Stack direction="row" spacing={1}>
        //       <Typography variant="body2" fontWeight="500">
        //         Clicked
        //       </Typography>
        //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
        //     </Stack>
        //   </Tooltip>
        // ),
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { width: 90 },
          }),
          setCellProps: () => ({
            style: { width: 90 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            const uniqueClicks = data?.stats?.uniqueClicks || 0;
            const uniqueClickRate = data?.stats?.uniqueClickRate || 0;
            return (
              <Typography variant="body2" color="textSecondary">
                {/*{uniqueClicks} (*/}
                {uniqueClickRate}%{/*%)*/}
              </Typography>
            );
          },
        },
      },
      {
        name: 'replied',
        label: 'Replied',
        options: {
          filter: false,
          sort: false,
          draggable: false,
          setCellHeaderProps: (value) => ({
            style: { width: 75 },
          }),
          setCellProps: () => ({
            style: { width: 75 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            const uniqueReplies = data?.stats?.uniqueReplies || 0;
            const uniqueReplyRate = data?.stats?.uniqueReplyRate || 0;
            return (
              <Typography variant="body2" color="textSecondary">
                {/*{uniqueReplies} (*/}
                {uniqueReplyRate}%{/*%)*/}
              </Typography>
            );
          },
        },
      },
      {
        name: ' ',
        label: ' ',
        options: {
          setCellHeaderProps: (value) => ({
            style: { width: 40 },
          }),
          setCellProps: () => ({
            className: 'no-hover',
            style: { width: 40 },
          }),
          filter: false,
          sort: false,
          draggable: false,
          customBodyRenderLite: (dataIndex) => {
            const data = emailTemplates[dataIndex];
            return (
              <Menu
                sx={{
                  zIndex: 9999,
                  '& .MuiMenu-paper': {
                    zIndex: 9999,
                  },
                }}
                anchorElProps={anchorEl}
                removeParentAnchorlEl={setAnchorEl}
                options={[
                  {
                    label: 'Edit',
                    icon: <EditIcon />,
                    onClick: () => {
                      setAnchorEl(null);
                      editTemplate(data);
                    },
                  },
                  {
                    label: 'Clone template',
                    icon: <CopyAllIcon />,
                    confirm: true,
                    confirmMessage: 'Are you sure you want to clone this template?',
                    onClick: () => {
                      setDrawerOpen({
                        edit: { ...data, id: null, name: `${data.name} (copy)` },
                        open: true,
                        type: data.type === 'clonedEmail' ? 'email' : data.type,
                        templateType: data.type === 'clonedEmail' ? 'email' : data.type,
                      });
                    },
                  },
                  ...(emailTemplates && emailTemplates.length > 1
                    ? [
                        {
                          label: 'Delete',
                          icon: <DeleteIcon />,
                          confirm: true,
                          confirmMessage: 'Are you sure you want to delete this template?',
                          onClick: () => {
                            setAnchorEl(null);
                            deleteTemplate(data);
                          },
                        },
                      ]
                    : []),
                ]}
              />
            );
          },
        },
      },
    ]);
  }, [emailTemplates]);

  return columns;
}
