import React, { useState } from 'react';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import { FilterBar, Tabs } from 'src/components/App';
import { Spacer } from 'src/components/shared';
import { useActivityDashboardStore } from '../../../utils/activityDashboardStore';
import ProspectsCards from './ProspectsCards';
import ActivityLineChart from './ActivityLineChart';
import UserGrid from './UserGrid';

function Activity() {
  const { filters, setFilters, setChartType, filtersArr } = useActivityDashboardStore();
  const [currentTab, setCurrentTab] = useState('users');

  const handleTabChange = (tab) => {
    setCurrentTab(tab.id);
  };

  const handleFilterChange = (key, value) => {
    const daily = [
      'today',
      'yesterday',
      'last7days',
      'last14days',
      'last30Days',
      'thisWeek',
      'thisMonth',
      'lastWeek',
      'lastMonth',
    ];
    const weekly = ['last90Days', 'last180Days', 'thisQuarter', 'lastQuarter'];
    const monthly = ['last365Days', 'thisYear', 'lastYear'];
    if (value && typeof value !== 'undefined') {
      if (daily.includes(value.id)) {
        setChartType('daily');
      } else if (weekly.includes(value.id)) {
        setChartType('weekly');
      } else if (monthly.includes(value.id)) {
        setChartType('monthly');
      } else {
        setChartType('weekly');
      }
      setFilters({
        ...filters,
        [key]: value,
      });
    } else {
      const newFilter = JSON.parse(JSON.stringify(filters));
      delete newFilter[key];
      setFilters(newFilter);
    }
  };

  return (
    <>
      <FilterBar
        filters={filtersArr}
        appliedFilters={filters}
        onChange={handleFilterChange}
        withClearButton
      />
      <Spacer x={1} y={1} />
      <ProspectsCards />
      <Spacer x={2} y={3} />
      <ActivityLineChart />
      <Spacer x={2} y={2} />
      <Box mb={2}>
        <Tabs
          tabs={[
            {
              id: 'users',
              name: 'User',
              icon: <PersonIcon />,
            },
          ]}
          value={currentTab}
          onChange={handleTabChange}
          sx={{ zIndex: 100 }}
        />
      </Box>
      {currentTab === 'users' && <UserGrid />}
    </>
  );
}

export default Activity;
