import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, Alert, FormHelperText, MenuItem, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';

import { fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { saveTemplate, fetchTemplateDetails, updateTemplate } from 'src/modules/admin/api/adminApi';
import { isNull } from 'lodash';

import { getInputReplacementVariables, getIntegrations } from 'src/modules/app/actions/appSelector';
import SeqTypeTab from '../SequenceFormTabBar/SeqTypeTab';
import SequenceFormTabBar from '../SequenceFormTabBar';
import AIContentView from '../SequenceFormTabBar/AIContentView';
import { SavingTemplateButton } from '../StyleComponent';
import { bhCandidateSearch } from '../../../../contacts/api/contactApis';
import { fetchTeamLookup, fetchUsersLookup } from '../../../../users/api/usersApi';
import AdvanceSetting from '../SequenceFormTabBar/AdvanceSetting';

const editorProps = {
  fullWidth: true,
  rows: 4,
  variant: 'outlined',
  name: 'description',
  label: 'Description',
  showPlaceholder: true,
  showFontStyle: false,
  showFontEditor: false,
  showAlignProps: false,
  showListProps: false,
  showAttachments: false,
};

const charLimit = {
  linkedinMessage: 8000,
  linkedinMail: 1900,
  linkedinConnection: 300,
};

const linkedinTypeList = [
  {
    label: 'Connection',
    value: 'linkedinConnection',
  },
  {
    label: 'Message',
    value: 'linkedinMessage',
  },
  {
    label: 'InMail',
    value: 'linkedinMail',
  },
  {
    label: 'View',
    value: 'linkedinViewProfile',
  },
];

const tenantHasAIAccess = {
  linkedinConnection: 'generateLinkedinConnectionTemplateWithAI',
  linkedinMessage: 'generateLinkedinMessageTemplateWithAI',
  linkedinMail: 'generateLinkedinInMailTemplateWithAI',
};

const LinkedInForm = ({
  onSubmit,
  loading,
  event,
  handleClose,
  openAIWriter,
  setOpenAIWriter,
  saveAsNew,
  setSaveAsNew,
  selectedAiResponse,
  setSelectedAiResponse,
  ...props
}) => {
  const loginUser = useSelector((state) => state.auth.user);
  const [template, setTemplate] = useState({});
  const [fetchingTemplates, setFetchingTemplates] = useState(false);
  const [description, setDescription] = useState('');
  const [linkedinType, setLinkedinType] = useState(props?.linkedinType || 'linkedinMessage');
  const [templateAutomationType, setTemplateAutomationType] = useState(event.mode || 'manual');
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const tenant = useSelector((state) => state.app.tenant);
  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));
  const integrations = useSelector((state) => getIntegrations(state));
  const sequenceAccessTypeList = useSelector((state) => state.app.globals?.sequenceAccessTypeList);

  const popoverRef = useRef(null);
  const descriptionRef = useRef('');

  useEffect(() => {
    if (props?.linkedinType) {
      setLinkedinType(props?.linkedinType);
    }
  }, [props?.linkedinType]);

  const options = useMemo(() => {
    if (templateCategoryList && templateCategoryList.length) {
      return templateCategoryList;
    }
    return [];
  }, [integrations, templateCategoryList]);

  useEffect(() => {
    setDescription(descriptionRef.current);
  }, [linkedinType]);

  const descriptionOnChange = (val) => {
    descriptionRef.current = val;
  };

  useEffect(() => {
    if (template && template?.id && template?.id !== '') {
      setDescription(template.content);
    }
  }, [template]);

  useEffect(() => {
    if (event && event?.id && event?.id !== '' && event?.templates && event?.templates !== '') {
      if (event?.templates && event?.templates?.id && event?.templates?.id !== '') {
        setTemplate(event?.templates);
      } else {
        setFetchingTemplates(true);
        fetchTemplateDetails(event?.templates)
          .then((res) => {
            setTemplate(res.template);
            setFetchingTemplates(false);
          })
          .catch((err) => {
            setTemplate({});
            setFetchingTemplates(false);
          });
      }
    } else if (event?.description && !isNull(event?.description) && event?.description !== '') {
      setDescription(event.description);
    }
  }, []);

  useEffect(() => {
    if (selectedAiResponse?.body) {
      const formattedContent = selectedAiResponse?.body?.replace(/\n/g, '<br/>');
      setTemplate((prevState) => ({
        ...prevState,
        subject: selectedAiResponse?.subject,
        content: formattedContent,
      }));
      setDescription(formattedContent);
    }
  }, [selectedAiResponse]);

  const handleSubmit = async (values) => {
    const data = JSON.parse(JSON.stringify(values));
    data.templates = data.templates?.id || '';

    let saveAsNew = data.saveAsNew;
    if (
      data.eventType !== 'linkedinViewProfile' ||
      (data.eventType === 'linkedinMail' && data.templates === '')
    ) {
      saveAsNew = true;
    }

    if (data.eventType !== 'linkedinMail') {
      delete data.subject;
    }

    if (data.templates && data.templates !== '') {
      if (template?.id && template?.id !== '' && template?.id == data.templates) {
        await updateTemplate(template?.id, {
          name: data.name,
          subject: data.subject,
          content: data.description,
          category: data.category,
          isPublished: true,
        });
      } else {
        saveAsNew = true;
      }
    } else {
      saveAsNew = true;
    }
    data.mode = templateAutomationType;
    delete data.emailTemplates;
    if (saveAsNew === true) {
      let newTemplate = await saveTemplate({
        type: linkedinType || 'linkedin',
        name: data.name,
        subject: data.subject,
        content: data.description,
        category: data.category,
        mode: templateAutomationType,
        ...((!data.saveAsNew || data.saveAsNew === false) && { type: 'personalisedMessage' }),
        isPublished: true,
        sharedWithTeams: data.sharedWithTeams.map((team) => team.id),
        sharedWithUsers: data.sharedWithUsers.map((user) => user.id),
      });
      newTemplate = newTemplate.template;
      data.templates = newTemplate.id;
      delete data.category;
      delete data.subject;
      onSubmit(data);
    } else {
      delete data.category;
      delete data.subject;
      // delete data.templates;
      onSubmit(data);
    }
  };

  const fetchTemplatesDetails = async (val) => {
    const newTemplates = await fetchTemplatesLookup(val, linkedinType);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : 'Organisation';

          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  return (
    <>
      {saveAsNew ? null : (
        <Box sx={{ mt: '24px', mb: '8px' }}>
          <SeqTypeTab
            onClick={(value) => {
              setOpenAIWriter(false);
              setLinkedinType(value);
            }}
            seqTypeList={linkedinTypeList}
            value={linkedinType}
            buttonStyle={{ padding: '8px !important', fontSize: '14px', flex: '1 1 0' }}
            // formProps={formProps}
          />
        </Box>
      )}
      {tenant?.featureAccess && tenant?.featureAccess?.linkedinAutomation && (
        <SequenceFormTabBar
          showAiWriter={
            tenant?.featureAccess &&
            tenantHasAIAccess.hasOwnProperty(linkedinType) &&
            Object.keys(tenant?.featureAccess)?.length > 0
              ? tenant?.featureAccess[tenantHasAIAccess[linkedinType]]
              : false
          }
          templateAutomationType={templateAutomationType}
          setTemplateAutomationType={setTemplateAutomationType}
        />
      )}
      <Form
        initialValues={{
          eventType: linkedinType || 'linkedin',
          name: template?.name || '',
          subject: template?.subject || '',
          templates: template,
          emailTemplates: template,
          description: description || template?.content,
          category: template?.category || '',
          saveAsNew: false,
          isAutoClose: event?.isAutoClose || false,
          autoCloseDuration: event?.autoCloseDuration ? parseInt(event?.autoCloseDuration) : 0,
          sendFromAnotherUser: event?.sendFromAnotherUser || false,
          startNewConversation: event?.startNewConversation || false,
          assignedto: event?.assignedto || [],
          sharedWithUsers: [],
          sharedWithTeams: [],
        }}
        validationSchema={Yup.object().shape({
          ...(linkedinType !== 'linkedinViewProfile'
            ? { description: Yup.string().required('Please enter description!') }
            : {}),
          name: Yup.string().test({
            message: () => 'Please enter template name!',
            test: (values, { options }) => {
              if (!values && options.parent.saveAsNew === false) return true;
              return values && typeof values !== 'undefined' && values !== '';
            },
          }),
          autoCloseDuration: Yup.number()
            .integer()
            .test({
              message: () => 'Please choose Auto-Close duration!',
              test: (values, { options }) => {
                if (!values && options.parent.isAutoClose === false) return true;
                return values && values > 0;
              },
            }),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              style={{ height: '100%' }}
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                height="100%"
              >
                <Box>
                  {linkedinType !== 'linkedinViewProfile' ? (
                    <Box>
                      {saveAsNew ? (
                        <>
                          <Form.Field.Input
                            size={'small'}
                            fullWidth
                            variant="outlined"
                            name="name"
                            label="Template name"
                            isSmall
                            sx={{ marginBottom: 0 }}
                            onChange={(e) =>
                              setTemplate((prevState) => ({ ...prevState, name: e }))
                            }
                          />
                          <Form.Field.Select
                            size={'small'}
                            options={options}
                            fullWidth
                            variant="outlined"
                            name="category"
                            isSmall
                            label="Template category"
                            sx={{ marginBottom: 0 }}
                            onChange={(e) =>
                              setTemplate((prevState) => ({ ...prevState, category: e }))
                            }
                          />
                        </>
                      ) : (
                        <Form.Field.AutoComplete
                          options={[template]}
                          fullWidth
                          clearOnEscape={true}
                          name="emailTemplates"
                          variant={'outlined'}
                          isSmall
                          placeholder="Select template"
                          noOptionsText="No template found"
                          groupBy={(option) => option?.accessType || ''}
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(val) => {
                            if (val) {
                              setTemplate(val);
                              setDescription(val.content);
                            }
                          }}
                          remoteMethod={(val) => {
                            return fetchTemplatesDetails(val, 'email');
                          }}
                          loading={fetchingTemplates}
                          optLabel="name"
                          optValue="id"
                          multiple={false}
                          disabled={saveAsNew}
                        />
                      )}
                      <AIContentView
                        hideSubject={linkedinType !== 'linkedinMail'}
                        options={replacementVariables}
                        editorProps={{
                          ...editorProps,
                          showAiWriter:
                            tenant?.featureAccess &&
                            tenantHasAIAccess.hasOwnProperty(linkedinType) &&
                            Object.keys(tenant?.featureAccess)?.length > 0
                              ? tenant?.featureAccess[tenantHasAIAccess[linkedinType]]
                              : false,
                          characterLimit: charLimit[linkedinType],
                          onChange: descriptionOnChange,
                          pasteAsText: true,
                        }}
                        formProps={formProps}
                        placeHolder={
                          linkedinType !== 'linkedinMail' ? 'Your message…' : 'Your email…'
                        }
                        linkedinType={linkedinType}
                        setOpenAIWriter={setOpenAIWriter}
                        height={'462px'}
                      />

                      {saveAsNew ? (
                        <>
                          <Form.Field.Select
                            options={sequenceAccessTypeList || []}
                            fullWidth
                            variant="outlined"
                            size={'small'}
                            name="permissionType"
                            label="Permission Type"
                            showNone={false}
                            optLabel="label"
                            optValue="value"
                            isSmall
                            sx={{ marginBottom: 0 }}
                            // tip="Select who has access to this template."
                            onChange={(val) => {
                              switch (val) {
                                case 'private':
                                  formProps.setFieldValue('sharedWithUsers', [
                                    { ...loginUser, name: loginUser.fname + ' ' + loginUser.lname },
                                  ]);
                                  // formProps.setFieldValue('sharedWithUsers', []);
                                  break;
                                case 'team':
                                  formProps.setFieldValue('sharedWithTeams', []);
                                  break;
                                case 'organisation':
                                default:
                                  formProps.setFieldValue('sharedWithUsers', []);
                                  formProps.setFieldValue('sharedWithTeams', []);
                                  break;
                              }
                            }}
                          />
                          {values.category === 'cv-spec' && integrations.isBullhornActive && (
                            <Form.Field.AutoComplete
                              multiple={false}
                              options={[]}
                              fullWidth
                              formSx={{ marginBottom: 0 }}
                              InputLabelProps={{
                                sx: {
                                  fontSize: '14px',
                                },
                              }}
                              size={'small'}
                              variant="outlined"
                              name="bhCandidate"
                              label="Select Candidate"
                              remoteMethod={async (val) => {
                                const res = await bhCandidateSearch(val);
                                return res.candidates;
                              }}
                              renderOption={(option, props) => {
                                return (
                                  <MenuItem {...props}>
                                    <Box>
                                      <Typography color="textPrimary">{option.name}</Typography>
                                      <Stack direction="row" gap={0.5}>
                                        <Typography color="textSecondary" variant="body2">
                                          {option.jobTitle}
                                        </Typography>
                                        <Typography color="secondary" variant="body2">
                                          at
                                        </Typography>
                                        <Typography color="textSecondary" variant="body2">
                                          {option.employer}
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  </MenuItem>
                                );
                              }}
                              optLabel="name"
                              optValue="bhCandidateId"
                            />
                          )}
                          {values.permissionType === 'private' ? (
                            <Form.Field.AutoComplete
                              multiple={true}
                              options={values?.sharedWithUsers || users}
                              fullWidth
                              formSx={{ marginBottom: 0 }}
                              variant="outlined"
                              InputLabelProps={{
                                sx: {
                                  fontSize: '14px',
                                },
                              }}
                              size={'small'}
                              name="sharedWithUsers"
                              label="Select users"
                              remoteMethod={(val) => {
                                return fetchUsersLookup(val);
                              }}
                              checkboxes={true}
                              optLabel="name"
                              optValue="id"
                            />
                          ) : null}

                          {values.permissionType === 'team' ? (
                            <Form.Field.AutoComplete
                              multiple={true}
                              options={values?.sharedWithTeams || teams}
                              fullWidth
                              formSx={{ marginBottom: 0 }}
                              variant="outlined"
                              InputLabelProps={{
                                sx: {
                                  fontSize: '14px',
                                },
                              }}
                              size={'small'}
                              name="sharedWithTeams"
                              checkboxes={true}
                              label="Select Teams"
                              remoteMethod={(val) => {
                                return fetchTeamLookup(val);
                              }}
                              optLabel="name"
                              optValue="id"
                            />
                          ) : null}
                        </>
                      ) : null}
                    </Box>
                  ) : (
                    <Box sx={{ mt: '24px' }}>
                      <Typography variant="body2" color="textSecondary">
                        This step is just a view profile.
                        <br />
                        <br /> Engaging with profiles signals interest and potentially gives you
                        some brand recognition when doing your outreach.
                      </Typography>
                      {linkedinType !== 'linkedinViewProfile' && (
                        <Form.Field.TextEditor
                          editorProps={{
                            ...editorProps,
                            showAiWriter:
                              tenant?.featureAccess &&
                              tenantHasAIAccess.hasOwnProperty(linkedinType) &&
                              Object.keys(tenant?.featureAccess)?.length > 0
                                ? tenant?.featureAccess[tenantHasAIAccess[linkedinType]]
                                : false,
                            // characterLimit: charLimit[linkedinType],
                            onChange: descriptionOnChange,
                            pasteAsText: true,
                          }}
                        />
                      )}
                    </Box>
                  )}

                  {/*advance setting*/}
                  <AdvanceSetting
                    values={values}
                    name={'isAutoClose'}
                    formProps={formProps}
                    selectName={'autoCloseDuration'}
                    anotherUser={'sendFromAnotherUser'}
                    anotherUsersList={'assignedto'}
                    startNewConversation={'startNewConversation'}
                  />

                  <Spacer x={2} y={2} />
                  <Alert severity="info" style={{ visibility: 'hidden' }}>
                    To ensure that this task, if not complete, does not stop your sequence from
                    progressing, enable the above setting.
                  </Alert>
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" width="100%">
                  <Box mt={2} display="flex" justifyContent="end" width="100%">
                    {linkedinType === 'linkedinViewProfile' || saveAsNew ? (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          loading={loading || fetchingTemplates}
                          disabled={loading}
                        >
                          Save
                        </Button>
                      </>
                    ) : (
                      <SavingTemplateButton
                        setSaveAsNew={setSaveAsNew}
                        setFieldValue={formProps.setFieldValue}
                        template={template}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export default LinkedInForm;
