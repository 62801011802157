import React from 'react';
import { Box, Card, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ResponsiveContainer, RadialBarChart, RadialBar } from 'recharts';
import { StyledCardContent, StyledTitle } from './styles';
import images from '../../../../../config/images';

const ProspectsCardItem = ({
  total,
  title,
  value,
  percentage,
  fill,
  type = 'graph',
  loading,
  toolTipTitle,
  hidePercentageSymbol,
}) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '125px',
  };
  const textStyles = {
    fontSize: '28px',
  };

  const CustomizedLabel = () => {
    return (
      <g>
        <foreignObject x={0} y={0} width={125} height={125}>
          <div style={style}>
            <Typography sx={{ ...textStyles, color: fill }}>
              {percentage}
              {hidePercentageSymbol ? '' : '%'}
            </Typography>
          </div>
        </foreignObject>
      </g>
    );
  };

  if (loading)
    return (
      <Grid item xs={6} md={4} lg={2}>
        <Card
          sx={{
            display: 'flex',
            width: '100%',
            height: '177px',
            background: '#ffffff',
            boxSizing: 'border-box',
            border: '1.04px solid #0000001f',
            borderRadius: '5px',
            boxShadow: 'none',
          }}
        >
          <Stack gap={1} alignItems="center" justifyContent="center" width="100%">
            <Skeleton animation="wave" height={40} width={'20%'} />
            <Skeleton animation="wave" height={20} width={'60%'} />
          </Stack>
        </Card>
      </Grid>
    );

  if (type === 'text') {
    return (
      <Grid item xs={6} md={4} lg={2}>
        <Card sx={{ border: '1.04px solid #0000001f', borderRadius: '5px', boxShadow: 'none' }}>
          <StyledCardContent>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingX: '8px' }}
              >
                <StyledTitle variant="body2" color="#535353">
                  {title}
                </StyledTitle>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '107px' }}
              >
                <Typography sx={{ fontSize: '28px', fontWeight: 600, color: '#535353' }}>
                  {value}
                </Typography>
              </Box>
              <Tooltip title={toolTipTitle}>
                <Box display="flex" justifyContent="end" alignItems="center">
                  <img src={images.sequence.InfoIcon} alt="info" />
                </Box>
              </Tooltip>
            </Box>
          </StyledCardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={6} md={4} lg={2}>
      <Card sx={{ border: '1.04px solid #0000001f', borderRadius: '5px', boxShadow: 'none' }}>
        <StyledCardContent>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ paddingX: '8px' }}
            >
              <StyledTitle variant="body2" color="#535353">
                {title}
              </StyledTitle>
              <StyledTitle variant="body2" color="#1976D2">
                {value}
              </StyledTitle>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ResponsiveContainer width={125} height={125}>
                <RadialBarChart
                  cx="50%"
                  cy="50%"
                  innerRadius={48}
                  outerRadius={60}
                  barSize={5}
                  startAngle={90}
                  endAngle={450}
                  data={[
                    {
                      name: 'Demo',
                      value: total,
                      fill: '#ffffff',
                    },
                    {
                      name: 'Demo 1',
                      value: percentage,
                      fill: fill,
                      background: '#000000',
                    },
                  ]}
                >
                  <RadialBar
                    minAngle={125}
                    label={<CustomizedLabel />}
                    background
                    clockWise
                    dataKey="value"
                    isAnimationActive={false}
                  />
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
            <Tooltip title={toolTipTitle}>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                sx={{ position: 'absolute', right: '10px', bottom: '10px' }}
              >
                <img src={images.sequence.InfoIcon} alt="info" />
              </Box>
            </Tooltip>
          </Box>
        </StyledCardContent>
      </Card>
    </Grid>
  );
};

export default ProspectsCardItem;
