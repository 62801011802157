import { makeStyles } from '@mui/styles';
import theme from 'src/config/theme';

const useStyles = makeStyles(() => ({
    RoleplayCardRoot: {
        border: "1px solid rgba(0,0,0,0.23)",
        borderRadius: "16px",
        display: "flex",
        width: "48%",
        "& .card-left": {
            height: "162px",
            width: "30%",
            objectFit: "cover",
            background: "rgba(0,0,0,0.04)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "& .roleplay-img": {
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "16px 0 0 16px"
            }
        },
        "& .card-right": {
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            width: "70%",
            position: "relative",
            "& .MuiButton-root": {
                width: "fit-content",
                position: "absolute",
                bottom: "1rem"
            }
        },
    },
    RoleplayProperty: {
        background: "rgba(0,0,0,0.04)",
        borderRadius: "16px",
        border: "1px solid rgba(0,0,0,0.23)",
        boxShadow: "none",
        "& .property-header": {
            background: "rgba(255,255,255,1)",
            padding: "1.5rem 2.5rem",
            borderRadius: "16px 16px 0 0"
        },
        "& .property-content": {
            padding: "32px",
            "& .property-filter-section": {
                "& .MuiOutlinedInput-root ": {
                    width: "100%",
                    background: "#fff"
                }
            },
            "& .property-items": {
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                "& .property-item": {
                    padding: "1rem",
                    borderRadius: "8px",
                    border: "2px solid rgba(0,0,0,0.23)",
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }
            },
            "& .add-new-btn": {
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(0,0,0,0.04)",
                padding: "2rem"
            }
        }
    },
    globalSearchNoBorder: {
        border: 'none',
    },
    globalSearchPopper: {
        // width: '70% !important',
        borderRadius: "24px",
        overflowX: 'hidden !important',
        boxShadow:
            '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    },
    globalSearchListBox: {
        maxHeight: '70vh',
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "24px 40px",
        '& .MuiAutocomplete-option': {
            backgroundColor: '#ffffff',
        },
        '& li': {
            padding: '10px 30px',
            borderRadius: "8px",
            border: "2px solid rgba(0,0,0,0.23)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    },
}));
export default useStyles;