import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box, Typography, Modal } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import toast from 'src/utils/toast';

import { fetchEventsDetails, updateEventsDetails } from './api';
import EditEvent from './EditEvent';
import { StyleWrapper, style } from './styles';

export default function App() {
  const [values, setValues] = useState({
    calendarWeekends: true,
    event: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const fetchEvent = async () => {
    try {
      const filters = {
        startTime_gteq: startDate?.toISOString(),
        startTime_lteq: endDate?.toISOString(),
      };
      if (startDate && endDate) {
        const res = await fetchEventsDetails(filters);
        const event = res.calendarEvent.map((data) => ({
          id: data.id,
          title: data.title,
          start: data.startTime,
          end: data.endTime,
          description: data.description,
          contact: data.contact,
        }));
        setEvents(event);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 1)));
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)));
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [startDate, endDate]);

  const toggle = () => {
    setValues({ modal: !values.modal });
    setShowModal((prev) => !prev);
  };

  const handleEventClick = ({ event, el }) => {
    toggle();
    if (event?._def) {
      const newEvents = {
        title: event?._def || event._def?.title || '',
        start: moment(event._instance.range.start).format('YYYY-MM-DD HH:MM'),
        end: moment(event._instance.range.end).format('YYYY-MM-DD HH:MM'),
      };
      newEvents.id = events.find((e) => e.title === newEvents.title.title).id;
      newEvents.description = events.find((e) => e.title === newEvents.title.title).description;

      setValues((prevState) => ({
        ...prevState,
        event: newEvents,
      }));
    }
  };

  const setDates = async (start, end) => {
    setEndDate(end);
    setStartDate(start);
  };

  return (
    <div className="App">
      <StyleWrapper>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          defaultView="timeGridDay"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth',
          }}
          contentHeight={'auto'}
          initialView="timeGridDay"
          initialDate={new Date()}
          initialEvents={[]}
          events={events}
          eventClick={handleEventClick}
          datesSet={(ev) => {
            let endDate = ev.end;
            if (ev.view.type === 'timeGridDay') {
              // add one day if clicked on day tab
              var dateString = ev.endStr;
              var startDate = new Date(dateString);
              var day = 60 * 60 * 24 * 1000;
              endDate = new Date(startDate.getTime() + day);
            }
            if (ev.startStr && ev.endStr) {
              setDates(ev.start, endDate);
            }
          }}
          select={handleEventClick}
          dateClick={async (ev) => {
            if (ev.dateStr) {
              const newEvents = {
                title: '',
                start: ev.date.toISOString(),
                end: moment(ev.date.toISOString()).add(1, 'day').add(30, 'm').toDate(),
                contact: {},
              };

              setValues((prevState) => ({
                ...prevState,
                event: newEvents,
              }));
            }
          }}
          eventDrop={async (info) => {
            const id = info.event?._def?.publicId;
            const { start, end } = info.oldEvent._instance.range;
            const { start: newStart, end: newEnd } = info.event._instance.range;
            if (new Date(start).getDate() === new Date(newStart).getDate()) {
              info.revert();
            }
            await updateEventsDetails(id, {
              startTime: info.event.start.toISOString(),
              endTime: info.event.end.toISOString(),
            });
            await fetchEvent();
            toast.success('Event Updated!', 'tc');
          }}
        />
      </StyleWrapper>
      <Modal
        open={showModal}
        onClose={handleEventClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h2" component="h2">
            {values.event?.id ? 'Update Calender Event' : 'Add Calender Events'}
          </Typography>
          <EditEvent
            setShowModal={setShowModal}
            value={values}
            editEvent={values.event}
            fetchEvent={fetchEvent}
          />
        </Box>
      </Modal>
    </div>
  );
}
