import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

// Material Design
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Custom Component
import Button from 'src/components/shared/Button';
import Icon from 'src/components/lms/Icon';
import CoverButtonSection from 'src/modules/lms/components/CoverButtonSection/CoverButtonSection';
import PageDetailSkeleton from 'src/modules/lms/shared/pageDetailSkeleton/PageDetailSkeleton';
import ProgressBar from 'src/components/lms/ProgressBar';
import Session from 'src/components/lms/Session';
import TwoImageSideSection from 'src/modules/lms/components/TwoImageSideSection';

import { addList, deleteList, getEmbedURL } from 'src/modules/lms/api/LMSApis';

// Constants
import { viewMoreString } from 'src/modules/lms/utils/helper';

// Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ReactComponent as AssignThisIcon } from 'src/assets/images/LMS/assignThisIcon.svg';
import { ReactComponent as SaveIcon } from 'src/assets/lms/icons/saved.svg';
import { ReactComponent as PlayIcon } from 'src/assets/images/LMS/playButton.svg';
import { ReactComponent as TickIcon } from 'src/assets/lms/icons/tick.svg';
import { ReactComponent as UserIcon } from 'src/assets/images/LMS/userIcon.svg';
import { ReactComponent as VideosIcon } from 'src/assets/images/LMS/videosIcon.svg';

// Styles
import useStyles from './style';

// Apis
import { getCoursesCompletionStatus } from 'src/modules/lms/api/LMSApis';
import AssignThis from '../../../AssignThisModal';
import { useSelector } from 'react-redux';

import { useLmsHomeProvider } from 'src/modules/lms/utils/lmsStore';
import { setCourse } from 'src/modules/lms/actions/LMSActions';
import { useDispatch } from 'react-redux';
import toast from 'src/utils/toast';

const CourseDetailsSection = ({ courseData, loading, courseBanner = null, ...props }) => {
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const currentUrl = props?.location?.pathname;

  const { fetchSummary } = useLmsHomeProvider();

  const [activeExpert, setActiveExpert] = React.useState(0);
  const [sessionStatus, setSessionStatus] = React.useState({});
  const [assignCourseModal, setAssignCourseModal] = React.useState(false);

  const breadcrumbs = React.useMemo(
    () => [
      <Link underline="hover" key="1" className={classes.links} to="/lms">
        SuperReach Learning
      </Link>,
      <Link
        underline="hover"
        key="2"
        className={classes.links}
        to={currentUrl ? currentUrl : '/lms'}
      >
        {courseData?.title}
      </Link>,
    ],
    [currentUrl, courseData],
  );
  const otherFilteredCourses = React.useMemo(
    () => (courseData?.otherCourses ?? [])?.filter((k) => k.published),
    [courseData],
  );
  const filteredExperts = React.useMemo(
    () => (courseData?.expert ?? [])?.filter((k) => k.published),
    [courseData],
  );
  const completedSessions = React.useMemo(
    () =>
      Object.keys(sessionStatus)?.filter((sessionId) => sessionStatus[sessionId] === 'completed'),
    [sessionStatus],
  );
  const filteredSessions = React.useMemo(
    () => (courseData?.sessions ?? [])?.filter((k) => k.published),
    // ?.sort((a, b) => {
    //   const titleA = a?.title?.toLowerCase();
    //   const titleB = b?.title?.toLowerCase();
    //   if (titleA < titleB) return -1;
    //   if (titleA > titleB) return 1;
    //   return 0;
    // }),
    [courseData, completedSessions, sessionStatus],
  );
  const isSessionCompleted = !filteredSessions.find(
    (session) => sessionStatus?.[session.id] !== 'completed',
  )?.id;

  React.useEffect(() => {
    if (courseData?.id)
      getCoursesCompletionStatus(courseData?.id).then((res) =>
        setSessionStatus(
          res?.sessionProgress?.reduce((acc, { session, status }) => {
            acc[session] = status;
            return acc;
          }, {}) ?? {},
        ),
      );
  }, [courseData?.id]);

  const handleSave = async () => {
    try {
      if (courseData.isFavourite === true) {
        await deleteList(courseData.favouriteId);
        fetchSummary();
        dispatch(
          setCourse({
            ...courseData,
            isFavourite: false,
            favouriteId: undefined,
          }),
        );
        toast.info('Item removed from My list');
      } else {
        const res = await addList({ course: courseData.id });
        fetchSummary();
        dispatch(
          setCourse({
            ...courseData,
            isFavourite: res?.favourite?.id && res?.favourite?.isDeleted == false ? true : false,
            favouriteId: res?.favourite?.id,
          }),
        );
        if (res?.message) {
          toast.info('Already added to My List');
        } else {
          toast.success('Item added to My list');
        }
      }
    } catch (error) {
      toast.error('Error ocurred! Please try again.');
      console.error('Error: ', error);
    }
  };

  if (loading) return <PageDetailSkeleton />;

  const activeCourse = Object.values(filteredSessions)?.find(
    (session) => sessionStatus?.[session.id] !== 'completed',
  );
  const activeCourseIndex = Object.values(filteredSessions)?.findIndex(
    (session) => sessionStatus?.[session.id] !== 'completed',
  );

  const isAuthorize = !['standard']?.includes(user?.acl?.accessLevel?.toLowerCase());

  return (
    <Stack gap={4} className={classes.courseDetailWrapper}>
      {/* Course Detail */}
      <Stack className="course-detail-container">
        {/* Banner */}
        {courseBanner}

        {/* Course Title */}
        <Stack gap={1}>
          <Breadcrumbs
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>

          <Typography className={classes.courseTitle}>{courseData?.title}</Typography>
        </Stack>

        {/* Course Description */}
        <Grid container gap={4}>
          <Grid item md={12} lg={8} className={classes.descriptionBox}>
            {typeof courseData?.synopsis === 'string' ? parse(courseData?.synopsis) : ''}
          </Grid>

          <Grid item md={12} lg={4}>
            <Stack gap={2}>
              {isAuthorize && (
                <Button
                  size="large"
                  onClick={() => setAssignCourseModal(true)}
                  color="secondary"
                  variant="outlined"
                  startIcon={<Icon name="assign" size={24} />}
                  sx={{ height: 56, fontSize: 18, borderWidth: 2 }}
                >
                  Assign this course
                </Button>
              )}
              <Button
                size="large"
                color="secondary"
                variant={courseData.isFavourite ? 'contained' : 'outlined'}
                startIcon={<SaveIcon width={24} height={24} />}
                onClick={handleSave}
                sx={{ height: 56, fontSize: 18, borderWidth: 2 }}
              >
                {courseData.isFavourite ? 'Saved' : 'Save'}
              </Button>
              {!isSessionCompleted && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(`/lms/session/${activeCourse?.id}?autoplay=video`)}
                  sx={{ height: 76 }}
                >
                  <Stack gap={2} alignItems={'center'} direction={'row'}>
                    <PlayIcon />
                    <Stack gap={0.5}>
                      <Typography>Continue Session {activeCourseIndex + 1}:</Typography>
                      <Typography fontSize={'20px'} fontWeight={600}>
                        {viewMoreString({
                          input: activeCourse?.title || '',
                          limit: 16,
                        })}
                      </Typography>
                    </Stack>
                  </Stack>
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>

        {/* Course Tags */}
        <Stack gap={2} direction={'row'}>
          {courseData?.tags?.length ? (
            <Box className={classes.courseTagSection}>
              <CoverButtonSection text="Course covers" tags={courseData?.tags} />
            </Box>
          ) : (
            <React.Fragment />
          )}
        </Stack>

        {/* Course Sessions */}
        {filteredSessions?.length !== 0 && (
          <Stack gap={3} className={classes.sessionsContainer}>
            <Stack alignItems={'center'} direction="row" gap={1}>
              <VideosIcon />
              <Typography className="title">Sessions</Typography>
            </Stack>

            <Stack gap={1} className="progress-bar-container">
              <Typography className="progress-title">
                Progress {completedSessions.length}/{filteredSessions.length}
              </Typography>
              <Stack direction={'flex'} gap={2} alignItems={'center'}>
                <Stack className="progress-bar" flex={1}>
                  <ProgressBar
                    completed={completedSessions.length}
                    total={filteredSessions.length}
                  />
                </Stack>
                <TickIcon className={`tick-icon ${isSessionCompleted ? 'active' : ''}`} />
              </Stack>
            </Stack>

            <Stack gap={3}>
              {filteredSessions.map((session, index) => (
                <Stack
                  key={index}
                  className="session-wrapper"
                  gap={3}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <Stack
                    className={`list-number ${
                      activeCourse?.id === session?.id
                        ? 'current'
                        : sessionStatus?.[session.id] === 'completed'
                        ? 'active'
                        : 'inactive'
                    }`}
                  >
                    {index > 0 && (
                      <span
                        className={`before ${
                          activeCourse?.id === session?.id
                            ? 'current'
                            : sessionStatus?.[session.id] === 'completed'
                            ? 'active'
                            : 'inactive'
                        }`}
                      />
                    )}
                    <span className="sessionCount">{index + 1}</span>
                  </Stack>
                  <Session
                    type="list"
                    key={`assigned-session-${session.id}`}
                    session={session}
                    showAssigned={false}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>

      {/* Other Section */}
      <Grid container gap={4}>
        {/* Expert Section */}
        <Grid item md={12} className="expert-section-container">
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <UserIcon />
            <Typography className="title">Meet the Experts</Typography>
          </Stack>

          <Stack direction={'row'} gap={2} className="expert-avatar-container">
            {filteredExperts.length ? (
              filteredExperts.map((expert, index) => (
                <ButtonBase key={`expert-${index}`} onClick={() => setActiveExpert(index)}>
                  <Avatar
                    key={expert.profileImage}
                    className={`avatar ${activeExpert === index ? 'active' : ''}`}
                    alt="avatar"
                    src={expert.profileImage}
                  />
                </ButtonBase>
              ))
            ) : (
              <React.Fragment />
            )}
          </Stack>

          <Stack gap={0.5} className="expert-details">
            <Typography className="name">
              {filteredExperts?.[activeExpert]?.fname} {filteredExperts?.[activeExpert]?.lname}
            </Typography>

            <Typography className="job-title">
              {filteredExperts?.[activeExpert]?.jobTitle
                ? filteredExperts?.[activeExpert]?.jobTitle + ', '
                : ''}
              {filteredExperts?.[activeExpert]?.companyName}
            </Typography>

            <Typography className="description">
              {parse(filteredExperts?.[activeExpert]?.description ?? '')}
            </Typography>
          </Stack>
        </Grid>

        {/* Other Courses */}
        <Grid item md={12} lg={4} className="other-course-container">
          {otherFilteredCourses.length ? (
            <TwoImageSideSection data={otherFilteredCourses} text={'Explore other courses'} />
          ) : (
            <React.Fragment />
          )}
        </Grid>
      </Grid>

      {/* Assign Course Modal */}
      {assignCourseModal && (
        <AssignThis
          type={'course'}
          id={courseData?.id}
          open={assignCourseModal}
          onClose={() => setAssignCourseModal(false)}
        />
      )}
    </Stack>
  );
};

export default CourseDetailsSection;
