import mixpanel from 'mixpanel-browser';
const mixPanelToken = '4e71910311e8c74be46184f26625850c';
mixpanel.init(mixPanelToken, {
  // debug: true,
  // loaded: function (mixpanel) {
  //   const distinct_id = mixpanel.get_distinct_id();
  // },
});

// setTimeout(async () => {
//   const config = await mixpanel.get_config();

//   mixpanel.track(
//     'Sign Up',
//     {
//       source: "Pat's affiliate site",
//       'Opted out of email': true,
//     },
//     {},
//     true,
//     true,
//     (response) => {
//     },
//   );
// }, 1000);

let actions = {
  identify: (id) => {
    return mixpanel.identify(id);
  },
  alias: (id) => {
    return mixpanel.alias(id);
  },
  track: (name, props) => {
    return mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      return mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
