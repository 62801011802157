import React from 'react';
import { Box, Dialog } from '@mui/material';
import images from 'src/config/images';
import { FlexColumnBox, MainBox, Title, Content } from './styles';
import { Button } from '../../../../../components/shared';

const CheckWithAppDialog = ({ open, onResend, onSMSClick }) => {
  return (
    <>
      <Dialog open={open}>
        <MainBox>
          <FlexColumnBox gap={'16px'}>
            <Box>
              <img src={images.user.linkedinMobileCheck} width={'100%'} alt={''} />
            </Box>
            <FlexColumnBox gap={'8px'}>
              <Title>Check your LinkedIn app</Title>
              <Content>
                A notification has been sent to your signed in device. Please open your LinkedIn app
                and tap <span style={{ fontWeight: 700 }}>Yes</span> to confirm your sign-in
                attempt.
              </Content>
            </FlexColumnBox>
          </FlexColumnBox>
          <FlexColumnBox gap={'8px'}>
            <Button variant={'outlined'} fullWidth color={'secondary'} onClick={() => onResend()}>
              Resend
            </Button>
            <Button
              variant={'text'}
              fullWidth
              color={'secondary'}
              sx={{ fontWeight: 700 }}
              onClick={() => onSMSClick()}
            >
              Verify using SMS
            </Button>
          </FlexColumnBox>
        </MainBox>
      </Dialog>
    </>
  );
};
export default CheckWithAppDialog;
