import React, { useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { Grid, Box, Typography, MenuItem, Stack } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import { fetchTeamLookup } from 'src/modules/users/api/usersApi';
import { fetchUsersLookup, fetchSingleUser } from 'src/modules/users/api/usersApi';
import { bhCandidateSearch } from 'src/modules/contacts/api/contactApis';
import { fetchTeam } from 'src/modules/admin/api/adminApi';
import { templateStatuses } from './config';

import { getIntegrations } from 'src/modules/app/actions/appSelector';
import { fetchFile } from 'src/modules/app/api/appApis';
import AIContentView from '../../../sequence/components/SequenceEvents/SequenceFormTabBar/AIContentView';
import AIWriterForm from '../../../sequence/components/SequenceEvents/SequenceFormTabBar/AIWriterForm';

const editorProps = {
  fullWidth: true,
  rows: 4,
  variant: 'outlined',
  name: 'description',
  label: 'Content',
  showPlaceholder: true,
  showFontStyle: false,
  showFontEditor: false,
  showAlignProps: false,
  showListProps: false,
  showAttachments: false,
};

const tenantHasAIAccess = {
  linkedinConnection: 'generateLinkedinConnectionTemplateWithAI',
  linkedinMessage: 'generateLinkedinMessageTemplateWithAI',
  linkedinMail: 'generateLinkedinInMailTemplateWithAI',
};

const charLimit = {
  linkedinMessage: 300,
  linkedinMail: 1900,
  linkedinConnection: 300,
};

const charLimitForSubject = {
  linkedinMail: 200,
};

const showEmailTypeSelection = [];

function TemplateForm({
  drawerToggle,
  templateType,
  editUser,
  formData,
  linkedinType,
  sequenceAccessTypeList,
  loading,
  isOpen,
  ...props
}) {
  const integrations = useSelector((state) => getIntegrations(state));
  const tenant = useSelector((state) => state.app.tenant);

  const [openAIWriter, setOpenAIWriter] = useState(false);
  const [template, setTemplate] = useState(editUser || {});
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [selectedAiResponse, setSelectedAiResponse] = useState(null);
  const [description, setDescription] = useState('');
  const [subjectValue, setSubjectValue] = useState(template.subject || '');

  useEffect(() => {
    setTemplate(editUser || {});
    setDescription(editUser?.content);
  }, [editUser]);

  const fetchAttachments = async (attachments) => {
    const promises = [];
    for (let i = 0; i < attachments?.length; i++) {
      const attachmentId = attachments[i];
      promises.push(fetchFile(attachmentId));
    }

    try {
      let response = await Promise.all(promises);
      if (response && response?.length) {
        const attaches = response.map((file) => ({
          ...file.upload,
        }));
        setAttachments(attaches);
      }
    } catch (error) {
      setAttachments([]);
    }
  };

  useEffect(() => {
    if (editUser?.attachments && editUser?.attachments?.length) {
      fetchAttachments(editUser?.attachments);
    }
  }, [editUser?.attachments?.length]);

  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const templateCategoryList = useSelector((state) => state.app.globals.templateCategoryList);

  const options = useMemo(() => {
    if (templateCategoryList && templateCategoryList?.length) {
      return templateCategoryList;
      // if (integrations.isBullhornActive) {
      //   return templateCategoryList;
      // }
      // return templateCategoryList.filter((item) => item.value !== 'cv-spec');
    }
    return [];
  }, [integrations, templateCategoryList]);

  useEffect(() => {
    if (
      template?.sharedWithUsers &&
      template?.sharedWithUsers?.length &&
      template?.permissionType === 'private'
    ) {
      fetchDefUsers();
    } else if (
      template?.sharedWithTeams &&
      template?.sharedWithTeams?.length &&
      template?.permissionType === 'team'
    ) {
      fetchDefTeams();
    } else if (props.user) {
      setUsers([{ ...props.user, name: `${props.user.fname} ${props.user.lname}` }]);
    }
  }, [template]);

  const fetchDefTeams = async () => {
    const promises = [];
    for (let i = 0; i < template?.sharedWithTeams?.length; i++) {
      const tid = template?.sharedWithTeams[i];
      promises.push(fetchTeam(tid));
    }
    let response = await Promise.all(promises);

    if (response && response?.length) {
      const teams = response.map((team) => ({
        ...team.team,
        value: team?.name || '',
      }));
      setTeams(teams);
    }
  };

  const handleClose = () => {
    setOpenAIWriter(false);
    setSelectedAiResponse(null);
    setDescription('');
  };

  const fetchDefUsers = async () => {
    const promises = [];
    for (let i = 0; i < template?.sharedWithUsers?.length; i++) {
      const uid = template?.sharedWithUsers[i];
      promises.push(fetchSingleUser(uid));
    }
    let response = await Promise.all(promises);

    if (response && response?.length) {
      const sharedUsers = response.map((user) => ({
        ...user.user,
        name: `${user.user?.title} ${user.user?.fname} ${user.user?.lname}`,
      }));
      setUsers(sharedUsers);
    }
  };

  const handleSubmit = async (values, form) => {
    const data = { ...values };
    // data.category = addCategoryPayload(values.category.value);
    const bhCandidate = data.bhCandidate;
    delete data.bhCandidate;
    data.type = templateType || 'email';
    data.content = data.description;
    data.sharedWithTeams = values.sharedWithTeams.map((team) => team.id);
    data.sharedWithUsers = values.sharedWithUsers.map((team) => team.id);
    if (templateType === 'email') {
      const filesArr = data?.files && data?.files?.length ? data?.files.map((file) => file.id) : [];
      data.attachments = filesArr;
      data.files = filesArr;
    } else {
      delete data.files;
      delete data.attachments;
    }

    if (templateType === 'linkedin') {
      delete data.category;
      delete data.files;
      data.type = linkedinType || 'linkedinMessage';
    }
    if (linkedinType !== 'linkedinMail' && templateType !== 'email') {
      delete data.subject;
    }
    delete data.upload;

    if (data.category === 'cv-spec' && bhCandidate?.bhCandidateId !== '') {
      data.bhCandidateId = bhCandidate.bhCandidateId;
      data.bhCandidateUrl = bhCandidate.url;
      data.bhCandidateName = bhCandidate.name;
    }

    if (editUser && editUser?.id) {
      await props.putTemplates(editUser?.id, data);
      drawerToggle({ edit: null, open: false });
    } else {
      const res = await props.saveTemplates(data);
      if (props.putSequenceEventOnTemplateCreation) {
        // cloning through the sequence steps,
        // need to update the sequence with the new template
        props.putSequenceEventOnTemplateCreation(res.template);
      }

      if (props.showEmailForm) {
        props.toggleCreateEmailForm();
      }
      handleClose();
      drawerToggle({ edit: null, open: false });
    }
  };

  useEffect(() => {
    if (selectedAiResponse?.body) {
      const formattedContent = selectedAiResponse?.body?.replace(/\n/g, '<br/>');
      setTemplate((prevState) => ({
        ...prevState,
        subject: selectedAiResponse?.subject,
        content: formattedContent,
      }));
      setDescription(formattedContent);
    }
  }, [selectedAiResponse]);

  useEffect(() => {
    if (!isOpen) {
      setDescription('');
    }
  }, [isOpen]);

  return (
    <>
      <Form
        initialValues={{
          name: template.name || '',
          subject: template.subject || '',
          category: template?.category,
          description: description,
          status: template?.status || 'active',
          permissionType: template?.permissionType || 'private',
          files: attachments,
          attachments: attachments,
          audienceEmailType: template?.audienceEmailType || '',
          sharedWithUsers: users,
          sharedWithTeams: teams,
          bhCandidate:
            template?.bhCandidateId !== ''
              ? {
                  bhCandidateId: template?.bhCandidateId,
                  name: template?.bhCandidateName,
                  url: template?.bhCandidateUrl,
                }
              : {},
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Please enter name!'),
          subject: Yup.string().test('len', `Subject must be less then 200 characters`, (val) => {
            if (linkedinType === 'linkedinMail') {
              return val?.toString()?.length < 200;
            } else {
              return true;
            }
          }),
          description: Yup.string().required('Please enter content'),
          permissionType: Yup.string().required('Select who has access to this template.'),
          sharedWithUsers: Yup.array().test({
            message: () => 'Please choose at least one user.',
            test: (value, values) => {
              const permissionType = values.parent.permissionType;
              if (permissionType === 'private') {
                return value?.length > 0;
              }
              return true;
            },
          }),
          sharedWithTeams: Yup.array().test({
            message: () => 'Please choose at least one team.',
            test: (value, values) => {
              const permissionType = values.parent.permissionType;
              if (permissionType === 'team') {
                return value?.length > 0;
              }
              return true;
            },
          }),
        })}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();
                return false;
              }}
              noValidate
            >
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                alignItems="flex-end"
                height="100%"
              >
                <Box>
                  <Grid container>
                    <Form.Field.Input
                      fullWidth
                      rows={4}
                      variant="outlined"
                      name="name"
                      label="Template name *"
                    />

                    {templateType === 'email' && (
                      <>
                        <Form.Field.Select
                          fullWidth
                          options={options}
                          variant="outlined"
                          name="category"
                          label="Template Category"
                          optLabel="label"
                          optValue="value"
                          showNone={false}
                        />
                        {values.category === 'cv-spec' && integrations.isBullhornActive && (
                          <Form.Field.AutoComplete
                            multiple={false}
                            options={[]}
                            fullWidth
                            variant="outlined"
                            name="bhCandidate"
                            label="Select Candidate"
                            remoteMethod={async (val) => {
                              const res = await bhCandidateSearch(val);
                              return res.candidates;
                            }}
                            renderOption={(option, props) => {
                              return (
                                <MenuItem {...props}>
                                  <Box>
                                    <Typography color="textPrimary">{option.name}</Typography>
                                    <Stack direction="row" gap={0.5}>
                                      <Typography color="textSecondary" variant="body2">
                                        {option.jobTitle}
                                      </Typography>
                                      <Typography color="secondary" variant="body2">
                                        at
                                      </Typography>
                                      <Typography color="textSecondary" variant="body2">
                                        {option.employer}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </MenuItem>
                              );
                            }}
                            optLabel="name"
                            optValue="bhCandidateId"
                          />
                        )}
                      </>
                    )}

                    {linkedinType === 'linkedinMail' && (
                      <Grid item xs={12}>
                        <Form.Field.Input
                          fullWidth
                          rows={3}
                          variant="outlined"
                          name="subject"
                          label="Template Subject"
                          showPicker={true}
                          options={replacementVariables}
                          optValue="value"
                          optLabel="text"
                          emojiPicker={true}
                          value={subjectValue}
                          onChange={(value) => {
                            if (
                              value.length > charLimitForSubject[linkedinType]
                            ) {
                              const newValue = value.substring(
                                0,
                                charLimitForSubject[linkedinType],
                              );
                              setSubjectValue(newValue);
                              formProps?.setFieldValue('subject', newValue);
                            } else {
                              setSubjectValue(value);
                              formProps?.setFieldValue('subject', value);
                            }
                          }}
                        />
                      </Grid>
                    )}
                    {templateType === 'email' && (
                      <Grid item xs={12}>
                        <Form.Field.Input
                          fullWidth
                          rows={3}
                          variant="outlined"
                          name="subject"
                          label="Template Subject"
                          showPicker={true}
                          options={replacementVariables}
                          optValue="value"
                          optLabel="text"
                          emojiPicker={true}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} mt={0.8}>
                      <Grid>
                        {templateType === 'email' ? (
                          <>
                            <AIContentView
                              editorProps={editorProps}
                              hideSubject={true}
                              formType="templateEmail"
                              showAiWriter={
                                tenant?.featureAccess &&
                                tenant?.featureAccess?.generateEmailTemplateWithAI
                              }
                              withUpload
                              showTemplateList={values.saveAsNew === true}
                              options={replacementVariables}
                              setOpenAIWriter={setOpenAIWriter}
                            />
                          </>
                        ) : (
                          <>
                            <AIContentView
                              hideSubject={true}
                              options={replacementVariables}
                              editorProps={{
                                ...editorProps,
                                showAiWriter:
                                  tenant?.featureAccess &&
                                  tenantHasAIAccess.hasOwnProperty(linkedinType) &&
                                  Object.keys(tenant?.featureAccess)?.length > 0
                                    ? tenant?.featureAccess[tenantHasAIAccess[linkedinType]]
                                    : false,
                                characterLimit: charLimit[linkedinType],
                                // onChange: descriptionOnChange,
                                pasteAsText: true,
                              }}
                              linkedinType={linkedinType}
                              formProps={formProps}
                              setOpenAIWriter={setOpenAIWriter}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Form.Field.Select
                          options={sequenceAccessTypeList || []}
                          fullWidth
                          variant="outlined"
                          name="permissionType"
                          label="Permission Type"
                          optLabel="label"
                          optValue="value"
                          tip="Select who has access to this template."
                          showNone={false}
                          onChange={(val) => {
                            switch (val) {
                              case 'private':
                                formProps.setFieldValue('sharedWithUsers', []);
                                break;
                              case 'team':
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                              case 'organisation':
                              default:
                                formProps.setFieldValue('sharedWithUsers', []);
                                formProps.setFieldValue('sharedWithTeams', []);
                                break;
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Form.Field.Select
                          fullWidth
                          options={templateStatuses}
                          variant="outlined"
                          name="status"
                          label="Status"
                          optLabel="label"
                          optValue="value"
                          showNone={false}
                        />
                      </Grid>
                    </Grid>

                    {values.permissionType === 'private' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={
                          values?.sharedWithUsers?.length > 0 ? values?.sharedWithUsers : users
                        }
                        fullWidth
                        variant="outlined"
                        name="sharedWithUsers"
                        label="Select users"
                        checkboxes={true}
                        remoteMethod={(val) => {
                          return fetchUsersLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}

                    {values.permissionType === 'team' ? (
                      <Form.Field.AutoComplete
                        multiple={true}
                        options={values?.sharedWithTeams}
                        fullWidth
                        variant="outlined"
                        name="sharedWithTeams"
                        checkboxes={true}
                        label="Select Teams"
                        remoteMethod={(val) => {
                          return fetchTeamLookup(val);
                        }}
                        optLabel="name"
                        optValue="id"
                      />
                    ) : null}

                    {templateType === 'email' && (
                      <Grid item xs={12}>
                        <Form.Field.File
                          size="small"
                          fullWidth
                          rows={4}
                          variant="outlined"
                          name="files"
                          maxSize={8}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  my={2}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => drawerToggle({ edit: null, open: false })}
                    loading={loading?.templates}
                    disabled={loading?.templates}
                  >
                    Cancel
                  </Button>
                  <Spacer x={1} y={1} />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={loading?.templates}
                    disabled={loading?.templates}
                  >
                    {!template.id ? 'Save' : 'Update'}
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </Form>
      <AIWriterForm
        formType={templateType}
        showSelect={showEmailTypeSelection?.includes(templateType)}
        open={openAIWriter}
        setOpenAIWriter={setOpenAIWriter}
        selectedAiResponse={selectedAiResponse}
        setSelectedAiResponse={setSelectedAiResponse}
      />
    </>
  );
}
export default TemplateForm;
