import React from 'react';
import { Wrapper, ProgressWrapper, Gradient, SessionImage, AssignedIcon, Content } from './styles';
import ProgressBar from '../ProgressBar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Spacer } from 'src/components/shared';
import { ReactComponent as SessionPlaceholder } from 'src/assets/lms/icons/SessionPlaceholder.svg';
import ReadMore from 'src/components/lms/ReadMore';

import Icon from 'src/components/lms/Icon';
import { palette } from 'src/config/darkTheme';
import { Grid } from '@mui/material';

const Wide = ({
  session,
  title,
  courseName,
  totalVideos,
  videosWatched,
  handleClick,
  summary,
  showAssigned,
  showCourse,
  action,
  assignedBy,
  isCompleted,
}) => {
  const thumbnailImage = session?.thumbnailImage || '';

  const renderCourseAssignedText = () => {
    if (summary?.summary?.course && summary?.summary?.course !== '') {
      return (
        <Typography component="span" variant="body2" color="textSecondary" textAlign="left" noWrap>
          From{' '}
          <Typography component="span" variant="body2" fontWeight="600" color="textPrimary">
            {summary?.summary?.course}
          </Typography>
        </Typography>
      );
    } else if (summary?.summary?.expertFname && summary?.summary?.expertFname !== '') {
      return (
        <Typography component="span" variant="body2" color="textSecondary" textAlign="left" noWrap>
          Session by{' '}
          <Typography component="span" variant="body2" fontWeight="600" color="textPrimary">
            {summary.summary?.expertFname} {summary?.summary?.expertLname}
          </Typography>
        </Typography>
      );
    }
  };

  const assignedByName = `${assignedBy?.fname || ''} ${assignedBy?.lname || ''}`;

  return (
    <Wrapper className="list-wrapper" onClick={handleClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack className="background-wrapper" height={'100%'}>
            <ProgressWrapper>
              <ProgressBar total={totalVideos} completed={videosWatched} />
              <Icon
                name="tick"
                size={24}
                color={isCompleted ? palette.secondary.main : 'rgba(255, 212, 126, 0.30)'}
              />
            </ProgressWrapper>

            <Content sx={{ height: '100%' }}>
              <Gradient />
              {thumbnailImage ? (
                thumbnailImage !== '' && <SessionImage src={thumbnailImage} />
              ) : (
                <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
                  <SessionPlaceholder style={{ width: '20%', color: '#808080' }} />
                </Stack>
              )}
            </Content>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Stack className="content-wrapper">
            {showAssigned && (
              <Stack className="header">
                <AssignedIcon />
                <Typography component="span" color="textSecondary">
                  Assigned by&nbsp;
                  <Typography color="textPrimary" component="span" fontWeight={500}>
                    {assignedByName}
                  </Typography>
                </Typography>
              </Stack>
            )}

            <Stack className="content">
              <Typography color="textPrimary" sx={{ fontSize: '24px !important' }} textAlign="left">
                {title}
              </Typography>
              {showCourse && (
                <>
                  <Spacer y={0.6} />
                  <Stack direction="row">{renderCourseAssignedText()}</Stack>
                </>
              )}

              <Spacer y={1} />

              <Typography variant="body2" color="textSecondary" component="span">
                {/* {parse(session?.overviewText ?? '')} */}
                <ReadMore text={session?.overviewText || ''} maxChars={200} isHtml={true} />
              </Typography>
            </Stack>
          </Stack>
          {action && <Stack className="content">{action}</Stack>}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Wide;
