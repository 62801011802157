import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Box, InputAdornment, Typography } from '@mui/material';
import { Form, Button, Spacer, Modal } from 'src/components/shared';
import { isEmailExist, linkedinRegex, phoneRegex } from 'src/config';
import api from 'src/api';
import { checkEmailInEnrollment, fetchTemplatesLookup } from 'src/modules/app/api/appApis';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setReEnrolmentErrors } from 'src/modules/sequence/actions/enrolmentActions';
import BounceActions from 'src/modules/sequence/actions/BounceActions';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

function ContactDetailsForm({
  formType,
  onClose,
  onSubmit,
  formData,
  findErrors = () => {},
  loader,
  submitButtonText = 'Save',
  showOnlyEmail = false,
  contact,
  formRef = undefined,
  ...props
}) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState('');
  const [confirmPhone, setConfirmPhone] = useState({
    flag: false,
    phoneError: false,
  });
  const [template, setTemplate] = useState({});
  const [description, setDescription] = useState('');
  const history = useHistory();
  const phoneTypes = useSelector((state) => state.app.globals.contactPhoneTypesList);
  const emailTypes = useSelector((state) => state.app.globals.contactEmailTypesList);
  const [showBounceForm, setShowBounceForm] = useState(false);
  const [buttonText, setButtonText] = useState(submitButtonText);
  const [enrolment, setEnrolment] = useState(null);
  const [currentEmailInput, setCurrentEmailInput] = useState('');
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    email: '',
    phone: '',
    type: '',
    template: '',
    linkedin: '',
  });
  const showSequenceMsg = !location?.pathname?.includes('contact');
  useEffect(() => {
    if (formData) {
      if (formData?.email) checkEmailIsEnrolled(formData.email);
      let data = { ...formData };
      setInitialValues({
        email: data.email || '',
        phone: data.phone || '',
        type:
          data.type === 'workEmail' || data.type === 'work'
            ? 'work'
            : data.type === 'phone'
            ? 'mobile'
            : 'personal',
        template: data.template || '',
        linkedin: data.linkedin || '',
        isPrimary: isPrimary,
      });
    }
  }, [formData, contact]);

  const isPrimary = useMemo(() => {
    let isPrimaryVal = false;
    if (!contact) return isPrimaryVal;
    switch (true) {
      case formType === 'email' && contact?.emails?.length === 0:
        isPrimaryVal = true;
        break;
      case formType === 'phone' && contact?.phones?.length === 0:
        isPrimaryVal = true;
        break;
      case formData?.isPrimary:
      default:
        isPrimaryVal = formData?.isPrimary || false;
        break;
    }
    return isPrimaryVal;
  }, [formData, contact]);

  const isPrimaryDisabled = useMemo(() => {
    let disabled = false;
    if (!contact) return disabled;
    switch (true) {
      case formType === 'email' && contact?.emails?.length === 0:
        disabled = true;
        break;
      case formType === 'phone' && contact?.phones?.length === 0:
        disabled = true;
        break;
    }
    return disabled;
  }, [formData, contact]);

  /**
   * check if the email is enrolled
   * and show error accordingly
   * @param {*} email
   */
  const checkEmailIsEnrolled = async (email) => {
    try {
      let response = await checkEmailInEnrollment(email);
      if (response?.data?.emailUsed && response?.data?.emailBounced) {
        setEnrolment(response?.data?.enrollment);
        setButtonText('Next');
      } else if (response?.data?.emailUsed) {
        setEnrollmentError(response?.data?.message);
      } else {
        setEnrollmentError('');
      }
    } catch (error) {}
  };

  const fetchPhone = async (value) => {
    let response = await await api(`/contact?phones.phone=${value}`, null, 'get');
    // tech debt: rely on the yup validator instead of the state below
    if (response?.contacts.length > 0 && response?.contacts[0].phones.length > 0) {
      const phoneDispatch = response?.contacts[0].phones;
      const matches = await phoneDispatch.find((o) => o.phone === value);

      if (matches) {
        setConfirmPhone({
          flag: false,
          phoneError: true,
        });
      } else {
        setConfirmPhone({
          flag: true,
          phoneError: false,
        });
      }
    } else {
      setConfirmPhone({
        flag: true,
        phoneError: false,
      });
    }
  };

  const emailExistDebounce = useCallback(debounce(isEmailExist, 500), []);
  const searchPhoneDebounce = useCallback(debounce(fetchPhone, 500), []);

  const fetchTemplatesDetails = async (val, type) => {
    const newTemplates = await fetchTemplatesLookup(val, type);
    if (newTemplates && newTemplates.length > 0) {
      const sequenceResults = newTemplates
        .sort(
          (a, b) =>
            (a.permissionType === '') - (b.permissionType === '') ||
            a.permissionType - b.permissionType,
        )
        .map((option) => {
          var permissionType =
            option.permissionType === 'team'
              ? 'Team'
              : option.permissionType === 'private'
              ? 'Private'
              : 'Organisation';

          return {
            accessType: permissionType,
            ...option,
          };
        });
      return await sequenceResults;
    }
  };

  const onSubmitBounceNext = async (type) => {
    dispatch(
      setReEnrolmentErrors(enrolment.id, {
        reenroll: type,
        email: currentEmailInput,
      }),
    );
    await setShowBounceForm(false);
    onClose();
    const contactId = props?.match?.params?.id;
    if (contactId) {
      props.fetchContact(contactId);
    } else history.push(`/tasks/errors`);
  };

  const closeShowBounceForm = () => {
    setShowBounceForm(false);
  };

  return (
    <>
      <Form
        initialValues={{
          email: formData?.email || '',
          phone: formData?.phone || '',
          type:
            formData?.type && formData.type !== ''
              ? formData.type
              : formType === 'email'
              ? 'work'
              : 'mobile',
          template: formData?.template || '',
          linkedin: formData?.linkedin || '',
          isPrimary,
        }}
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          ...(formType === 'email' && {
            email: Yup.string()
              .email('Invalid email format')
              .required('Please enter email!')
              .test({
                message: () => 'Email already exists',
                test: (values) => emailExistDebounce(values, formData?.email),
              }),
          }),
          ...(formType === 'phone' && {
            phone: Yup.string()
              .matches(phoneRegex, 'Phone number is not valid')
              .required('Please enter phone number!')
              .test({
                message: () => '',
                test: async (values) => {
                  if (formData?.id) {
                    return true;
                  } else {
                    try {
                      await searchPhoneDebounce(values);
                      return confirmPhone.flag;
                    } catch (error) {}
                  }
                },
              }),
          }),
          ...(formType === 'linkedin' && {
            linkedin: Yup.string()
              .matches(linkedinRegex, 'linkedin Url is not valid')
              .required('Please enter linkedin Url!'),
          }),
        })}
        onSubmit={async (values, form) => {
          if (enrolment) {
            setCurrentEmailInput(values.email);
            setShowBounceForm(true);
          } else {
            setLoading(true);
            await onSubmit(values, formType, form);
            setLoading(false);
          }
        }}
      >
        {({ values, ...formProps }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formProps.submitForm();

                return false;
              }}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {enrollmentError && (
                    <Typography
                      component="span"
                      sx={{
                        color: '#FFA726',
                        '&:hover': {
                          backgroundColor: '#DCDCDC',
                        },
                      }}
                    >
                      {enrollmentError}
                    </Typography>
                  )}
                  {formType === 'email' &&
                    !enrollmentError &&
                    !showOnlyEmail &&
                    showSequenceMsg && (
                      <Typography component="span">
                        To continue the sequence please add work email
                      </Typography>
                    )}
                  {formType === 'personal_email' &&
                    !enrollmentError &&
                    !showOnlyEmail &&
                    showSequenceMsg && (
                      <Typography component="span">
                        To continue the sequence please add personal email
                      </Typography>
                    )}
                  {formType === 'phone' && showSequenceMsg && (
                    <Typography component="span">
                      To continue the sequence please add mobile
                    </Typography>
                  )}
                  {formType === 'linkedin' && showSequenceMsg && (
                    <Typography component="span">
                      To continue the sequence please add linkedin url
                    </Typography>
                  )}
                  {formType === 'template' && showSequenceMsg && (
                    <Typography component="span">
                      To continue the sequence please select template
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={
                        formType === 'template' || formType === 'linkedin' || showOnlyEmail ? 12 : 8
                      }
                    >
                      {formType === 'email' && (
                        <Form.Field.Input fullWidth variant="outlined" name="email" label="Email" />
                      )}

                      {formType === 'personal_email' && (
                        <Form.Field.Input fullWidth variant="outlined" name="email" label="Email" />
                      )}

                      {formType === 'phone' && (
                        <Form.Field.Phone
                          sx={{ '& .react-tel-input': { marginTop: '16px !important' } }}
                          fullWidth
                          variant="outlined"
                          name="phone"
                          label="Phone"
                        />
                      )}

                      {confirmPhone.phoneError && (
                        <Typography variant="body2" color=" #d32f2f" mr={1}>
                          Phone number already exists
                        </Typography>
                      )}

                      {formType === 'template' && (
                        <Form.Field.AutoComplete
                          fullWidth
                          variant="outlined"
                          options={[]}
                          name="template"
                          label="Select template"
                          groupBy={(option) => option?.accessType || ''}
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(val) => {
                            if (val) {
                              setTemplate(val);
                              setDescription(val.content);
                            }
                          }}
                          remoteMethod={(val) => {
                            return fetchTemplatesDetails(val, 'email');
                          }}
                          optLabel="name"
                          optValue="id"
                        />
                      )}

                      {formType === 'linkedin' && (
                        <Form.Field.Input
                          fullWidth
                          variant="outlined"
                          name="linkedin"
                          label="linkedin Url"
                        />
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      {formType !== 'template' && formType !== 'linkedin' && !showOnlyEmail && (
                        <Form.Field.Select
                          options={formType === 'phone' ? phoneTypes : emailTypes}
                          fullWidth
                          variant="outlined"
                          name="type"
                          label="Type"
                          showNone={false}
                          optLabel="label"
                          optValue="value"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {(formType === 'phone' || formType === 'email' || formType === 'personal_email') && (
                <Form.Field.Checkbox
                  name="isPrimary"
                  label={`Make this the primary ${
                    values.type === 'workEmail' || values.type === 'work'
                      ? 'work'
                      : values.type === 'phone'
                      ? 'mobile'
                      : 'personal'
                  } ${formType === 'phone' ? 'phone' : 'email'}`}
                  disabled={isPrimaryDisabled}
                />
              )}

              {/* <Spacer y={1.25} />
              {!showOnlyEmail && (
                <Button color="secondary" onClick={() => findErrors(formType)} loading={loader}>
                  {formType === 'email' && 'Find Work Email'}
                  {formType === 'personal_email' && 'Find Personal Email'}
                  {formType === 'phone' && 'Find Mobile Number'}
                  {formType === 'linkedin' && 'Find LinkedIn Profile'}
                </Button>
              )} */}

              <Box alignItems="flex-end" display="flex" justifyContent="flex-end">
                <Button
                  size="medium"
                  onClick={onClose}
                  variant="outlined"
                  disabled={loading}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Spacer basis={2} />
                <Button
                  size="medium"
                  variant="contained"
                  loading={loading}
                  type="submit"
                  color="secondary"
                >
                  {buttonText}
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
      <Modal
        open={showBounceForm}
        onClose={closeShowBounceForm}
        backdrop={false}
        title={'How you would like to proceed?'}
      >
        <BounceActions onClick={onSubmitBounceNext} />
      </Modal>
    </>
  );
}

export default ContactDetailsForm;
