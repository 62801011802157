import * as types from '../actions/appTypes';

const initialState = {
  loader: {
    dashboards: false,
    panels: false,
  },
  taskCount: {},
  appLoading: true,
  sideBarIsOpen: true,
  status: false,
  showContactForm: false,
  showSequenceForm: false,
  showNotificationsDrawer: false,
  showTaskForm: false,
  showEmailForm: false,
  showContactDrawer: false,
  drawerContactId: undefined,
  editTask: {},
  editContact: {},
  replacementVariables: [],
  globals: {},
  tenant: {},
  attributes: [],
  modules: {},
  unassignedFields: {},
  crmFields: {},
  crmTenantData: {},
  onboarding: {
    overview: {
      mailbox: true,
      downloadExtension: false,
    },
    outReach: {
      howToCreateASequence: false,
      gettingToKnowExtension: false,
      addContactToSequenceExtension: false,
      understandingTasks: false,
    },
    successTips: {
      subjectLines: false,
      buildingKillerSequence: false,
      personalizeAtScale: false,
    },
  },
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ONBOARDING_STATUS:
      return {
        ...state,
        onboarding: action.data,
      };

    case types.SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.attributes,
        modules: action.modules,
        unassignedFields: action.unassignedFields,
      };

    case types.SET_TENANT_FIELDTYPES:
      return {
        ...state,
        crmFields: action.data,
      };

    case types.SET_CRM_NOTES:
      return {
        ...state,
        crmNotes: action?.notes || [],
      };

    case types.SET_SR_NOTES:
      return {
        ...state,
        srNotes: action.data?.noteTypes || [],
      };

    case types.SET_TENANT_DATA:
      return {
        ...state,
        crmTenantData: action.data,
      };

    case types.SET_TENANT_CONFIG:
      return {
        ...state,
        tenant: action.data,
      };

    case types.TOGGLE_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        showNotificationsDrawer: !state.showNotificationsDrawer,
      };

    case types.TOGGLE_CONTACT_DRAWER:
      return {
        ...state,
        showContactDrawer: !state.showContactDrawer,
        drawerContactId: !state.showContactDrawer ? action.contactId : undefined,
      };

    case types.SET_GLOBALS:
      return {
        ...state,
        globals: action.data,
      };
    case types.SET_TASK_COUNTS:
      return {
        ...state,
        taskCount: action.data,
      };
    case types.SET_LOGIN_STATUS:
      return {
        ...state,
        status: action.status,
      };

    case types.SET_APP_LOADING:
      return {
        ...state,
        appLoading: action.loading,
      };

    case types.SET_APP_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.loading,
      };

    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sideBarIsOpen: !state.sideBarIsOpen,
      };

    case types.TOGGLE_CONTACT_FORM:
      return {
        ...state,
        showContactForm: !state.showContactForm,
        editContact: action?.contact ? action?.contact : {},
      };

    case types.TOGGLE_SEQUENCE_FORM:
      return {
        ...state,
        showSequenceForm: !state.showSequenceForm,
      };

    case types.TOGGLE_EMAIL_FORM:
      return {
        ...state,
        // showEmailForm: !state.showEmailForm,
        showEmailForm: action.actionType && action.actionType !== '' ? action.actionType : false,
      };

    case types.TOGGLE_TASK_FORM:
      return {
        ...state,
        showTaskForm: !state.showTaskForm,
        editTask: action.task,
      };

    case types.SET_REPLACEMENT_VARIABLES:
      return {
        ...state,
        replacementVariables: action.data,
      };

    case types.RESET_STORE:
      return {
        ...initialState,
        appLoading: false,
      };

    default:
      return state;
  }
};
export default AppReducer;
