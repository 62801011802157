import React, { useState } from 'react';
import { Box, Chip, Typography, CardContent, Card } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useLmsReportsProvider } from '../../utils/reportsStore';
import randomColor from 'randomcolor';

function Chart() {
  const { graphData } = useLmsReportsProvider();
  const [hiddenKeys, setHiddenKeys] = useState([]);

  const toggleKey = (e) => {
    if (hiddenKeys.includes(e.dataKey)) {
      setHiddenKeys((prev) => prev.filter((item) => item !== e.dataKey));
    } else {
      setHiddenKeys((prev) => [...prev, e.dataKey]);
    }
  };
  return (
    <Card>
      <CardContent sx={{ height: 'calc(100% - 32px)' }}>
        <Box display="flex" width="100%" height="300px" mt={2}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width="100%" height={300} data={graphData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend onClick={toggleKey} />

              <Line
                type="monotone"
                dataKey="allUsers"
                name="All users"
                stroke={randomColor({ luminosity: 'light', seed: 'allUsers' })}
                strokeOpacity={hiddenKeys.includes('allUsers') ? 0 : 1}
              />

              <Line
                type="monotone"
                dataKey="averageScore"
                name="Average score"
                stroke={randomColor({ luminosity: 'light', seed: 'score' })}
                strokeOpacity={hiddenKeys.includes('averageScore') ? 0 : 1}
              />
              <Line
                type="monotone"
                dataKey="roleplays"
                name="Role-plays"
                stroke={randomColor({ luminosity: 'light', seed: 'roleplay' })}
                strokeOpacity={hiddenKeys.includes('roleplays') ? 0 : 1}
              />
              <Line
                type="monotone"
                dataKey="sessionsViewed"
                name="Sessions viewed"
                stroke={randomColor({ luminosity: 'light', seed: 'views' })}
                strokeOpacity={hiddenKeys.includes('sessionsViewed') ? 0 : 1}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Chart;
