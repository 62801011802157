import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'src/components/App';
import { Typography, Box } from '@mui/material';

function LookupTabs({ lookups, activeLookup }) {
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    setCurrentTab(activeLookup);
  }, [activeLookup]);

  const tabs = useMemo(() => {
    if (lookups && lookups.length) {
      return lookups
        .filter((lookup) => (lookup?.hidden ? lookup?.hidden !== true : true))
        .map((item) => {
          item.pluralName =
            item?.pluralName && item?.pluralName !== '' ? item.pluralName : item.name;
          return item;
        });
    }
    return [];
  }, [lookups]);

  const history = useHistory();

  const onTabChange = (tab) => {
    // setCurrentTab(tab.id);
    history.replace(`/admin/fields/lookups/${tab.id}`);
  };

  return (
    <>
      <Tabs
        tabs={tabs}
        orientation="vertical"
        variant="scrollable"
        onChange={onTabChange}
        value={currentTab}
        tabLabel={'pluralName'}
        sx={{ width: '100%', height: 'calc(100vh - 190px)' }}
      />
    </>
  );
}

export default LookupTabs;
