import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Tooltip, Typography, Box, Stack } from '@mui/material';
import { Spacer, Menu, Button, Form } from 'src/components/shared';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { green, red, amber } from '@mui/material/colors';
import sequenceGridHeader from './SequenceGridHeader';

import InfoIcon from '@mui/icons-material/Info';
import { getPercentage } from '../../utils/sequenceUtils';

export const StatsText = styled(Typography).attrs({
  variant: 'body2',
})`
  color: ${(props) => getColorPercent(props.count)} !important;
`;

const getColorPercent = (count) => {
  if (count <= 10) return red[500];
  else if (count > 10 && count < 25) return amber[500];
  else if (count >= 25) return green[500];
  else return 'rgba(0, 0, 0, 0.87)'; // fallback
};

const calculatePercentage = (value, total) => {
  let progress = 0;
  if (parseInt(value) > 0 && total > 0) {
    progress = Math.floor((100 * value) / total);
    return ` (${progress}%)`;
  } else {
    return ` (${0}%)`;
  }
};

export function useSequenceColumns(
  sequences,
  fields,
  onEdit,
  onDelete,
  toggleContactDrawer,
  handleCloneSequence,
  handleSequenceStatusChange,
) {
  const [columns, setColumns] = useState([]);

  const enabledColumns = useMemo(() => {
    return fields.filter(
      (field) => field?.fieldOptions?.showOnGrid && field?.fieldOptions?.showOnGrid === true,
    );
  }, [fields]);

  const history = useHistory();

  const openSequenceDetails = (id) => {
    if (id && id !== '') {
      let path = `/sequence/${id}/steps`;
      history.push(path);
    }
  };

  useEffect(() => {
    let data = sequenceGridHeader.map((item) => {
      return {
        name: item.fieldName,
        label: item.label,
        ...(item.fieldName === 'opened' && {
          label: 'Opened',
          // label: (
          //   <Tooltip
          //     title={
          //       <React.Fragment>
          //         <Typography variant="body2" textAlign="center">
          //           Tracking the number of contacts that opened sent emails
          //         </Typography>
          //       </React.Fragment>
          //     }
          //   >
          //     <Stack direction="row" spacing={1}>
          //       <Typography variant="body2" fontWeight="500">
          //         Opened
          //       </Typography>
          //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
          //     </Stack>
          //   </Tooltip>
          // ),
        }),
        ...(item.fieldName === 'clicked' && {
          label: 'Clicked',
          // label: (
          //   <Tooltip
          //     title={
          //       <React.Fragment>
          //         <Typography variant="body2" textAlign="center">
          //           Tracks the number of times your emails were clicked on. Please note that keep
          //           this tracking enabled could affect the deliverability of emails.
          //         </Typography>
          //       </React.Fragment>
          //     }
          //   >
          //     <Stack direction="row" spacing={1}>
          //       <Typography variant="body2" fontWeight="500">
          //         Clicked
          //       </Typography>
          //       <InfoIcon sx={{ fontSize: '1.12rem', color: 'rgba(0,0,0,0.4)' }} />
          //     </Stack>
          //   </Tooltip>
          // ),
        }),
        options: {
          ...(item.gridParams &&
            item.gridParams?.sequences && {
              ...item.gridParams.sequences,
            }),
          ...(item.type === 'default' && {
            customBodyRenderLite: (index) => {
              const sequence = sequences[index];
              const sent = sequence?.stats?.sent || 0;

              const totalEnrollments = sequence?.stats?.totalEnrolments || 0;

              if (!sequence?.id) return '';
              switch (item.fieldName) {
                case 'name':
                  return (
                    <Tooltip title={sequence?.name || ''} arrow>
                      <Link
                        to={`/sequence/${sequence.id}`}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        <Typography variant="body2" color="secondary">
                          {sequence?.name}
                        </Typography>
                      </Link>
                    </Tooltip>
                  );
                  break;

                case 'steps':
                  return sequence?.events ? sequence?.events.length : 0;
                  break;

                case 'contacts':
                  return totalEnrollments || 0;
                  break;

                case 'Unsubscribe':
                  const unsubscribes = sequence.stats?.unsubscribes || 0;
                  return (
                    <StatsText count={getPercentage(unsubscribes, totalEnrollments)}>
                      {unsubscribes}
                      {calculatePercentage(unsubscribes, totalEnrollments)}
                    </StatsText>
                  );
                  break;

                case 'interested':
                  const interested = sequence.stats?.interested || 0;
                  const interestedRate = sequence.stats?.interestedRate || 0;
                  return (
                    <StatsText count={interestedRate}>
                      {interested} ({interestedRate}%)
                    </StatsText>
                  );
                  break;

                case 'inProgress':
                  const inProgressEnrollments = sequence.stats?.inProgressEnrollments || 0;
                  const inProgressRate = sequence.stats?.inProgressRate || 0;
                  return (
                    <StatsText count={inProgressRate}>
                      {inProgressEnrollments} ({inProgressRate}%)
                    </StatsText>
                  );
                  break;

                case 'accessType3b9d46':
                  return (
                    sequence?.accessType.charAt(0).toUpperCase() + sequence.accessType.slice(1) ||
                    ''
                  );
                  break;

                case 'opened':
                  const uniqueOpens = sequence?.stats?.uniqueOpens || 0;
                  const uniqueOpenRate = sequence?.stats?.uniqueOpenRate || 0;
                  return (
                    <StatsText count={uniqueOpenRate}>
                      {uniqueOpens} ({uniqueOpenRate}%)
                    </StatsText>
                  );
                  break;

                case 'clicked':
                  const uniqueClicks = sequence.stats?.uniqueClicks || 0;
                  const uniqueClickRate = sequence.stats?.uniqueClickRate || 0;
                  return (
                    <StatsText count={uniqueClickRate}>
                      {uniqueClicks} ({uniqueClickRate}%)
                    </StatsText>
                  );
                  break;

                case 'bounces':
                  const bounce = sequence.stats?.bounce || 0;
                  return (
                    <StatsText count={getPercentage(bounce, sent)}>
                      {bounce}
                      {calculatePercentage(bounce, sent)}
                    </StatsText>
                  );
                  break;

                case 'replied':
                  const uniqueReplies = sequence.stats?.uniqueReplies || 0;
                  const uniqueReplyRate = sequence.stats?.uniqueReplyRate || 0;
                  return (
                    <StatsText count={uniqueReplyRate}>
                      {uniqueReplies} ({uniqueReplyRate}%)
                    </StatsText>
                  );
                  break;

                default:
                  return typeof sequence[item.fieldName] !== 'undefined' && sequence[item.fieldName]
                    ? sequence[item.fieldName]
                    : '';
                  break;
              }
            },
          }),
        },
      };
    });

    data = [
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
          setCellHeaderProps: (value) => ({
            style: { minWidth: 40, width: 40, maxWidth: 50, padding: 0 },
          }),
          setCellProps: () => ({
            style: { minWidth: 40, width: 40, maxWidth: 50, padding: 0 },
          }),
          customBodyRenderLite: (index) => {
            const sequence = sequences[index];
            return (
              <Box ml={1} mt={-1}>
                <Form
                  initialValues={{ status: sequence?.status === 'published' }}
                  enableReinitialize={true}
                >
                  <Form.Field.Switch
                    name="status"
                    onChange={(value) => {
                      handleSequenceStatusChange(
                        sequence.id,
                        value === true ? 'published' : 'unpublished',
                      );
                    }}
                    color="success"
                  />
                </Form>
              </Box>
            );
          },
        },
      },
      ...data,
    ];

    data.push({
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        draggable: false,
        allowToggle: false,
        viewColumns: false,
        setCellHeaderProps: (value) => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        setCellProps: () => ({
          style: { minWidth: 35, width: 35, maxWidth: 35, padding: 0 },
        }),
        customBodyRender: (value) => {
          const sequence = sequences.find((tData) => tData.id === value);
          return (
            <Menu
              options={[
                {
                  label: 'Edit steps',
                  icon: <EditIcon />,
                  // onClick: () => {
                  //   onEdit(sequence);
                  // },
                  onClick: () => openSequenceDetails(sequence.id),
                },
                {
                  label: 'Clone',
                  icon: <ContentCopyIcon />,
                  onClick: () => {
                    handleCloneSequence(sequence);
                  },
                },
              ]}
            />
          );
        },
      },
    });
    setColumns(data);
  }, [enabledColumns, sequenceGridHeader, sequences]);

  return columns;
}
