import React, { useEffect, useRef, useState } from 'react';
import { BarItem, BarFill } from './styles';
import { Modal } from 'src/components/shared';
import toast from 'src/utils/toast';

function Bar({ score, size, session }) {
  const [showDetails, setShowDetails] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(undefined);

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current === null) {
      ref.current = window.addEventListener('message', eventFunction, false);
    }
    return () => {
      window.removeEventListener('message', eventFunction);
      ref.current = null;
    };
  }, [iframeUrl]);

  function eventFunction(event) {
    if (event.origin === `${process.env.REACT_APP_BOT_URL}`) {
      try {
        if (event?.data) {
          let data = JSON.parse(event?.data);
          if (data.type === 'close') {
            toggleDetails();
          }
          if (data.type === 'home') {
            toggleDetails();
            // history.replace('/lms');
          }
        }
      } catch (err) {}
    }
    return;
  }

  const scoreVal = score.score;
  let height = (100 * score.score) / 5;
  let color = '#FF4040';
  switch (true) {
    case scoreVal > 3:
      color = '#60FF40';
      break;

    case scoreVal > 2 && scoreVal <= 3:
      color = '#FF8F40';
      break;
  }

  const html = score?.feedback.replace(/\n/g, '<br />') || '';

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    setIframeUrl(undefined);
  };

  const openDetails = () => {
    if (score?.conversationId && score?.conversationId !== '') {
      setShowDetails(!showDetails);
      setIframeUrl(`${process.env.REACT_APP_BOT_URL}/?feedbackId=${score?.conversationId}`);
    } else {
      toast.error('Feedback not found!');
      setIframeUrl(undefined);
    }
  };

  return (
    <>
      <BarItem size={size} onClick={openDetails}>
        <BarFill size={size} color={color} height={height} />
      </BarItem>

      <Modal
        fullScreen={true}
        open={showDetails && iframeUrl}
        onClose={toggleDetails}
        padding={false}
        sx={{
          '& .MuiDialogTitle-root': {
            display: 'none',
          },
        }}
      >
        {iframeUrl && (
          <iframe src={iframeUrl} width="100%" height="100%" style={{ border: 'none' }} />
        )}
      </Modal>
    </>
  );
}

export default Bar;
