import { makeStyles } from '@mui/styles';
import theme from 'src/config/theme';

const useStyles = makeStyles(() => ({
  autocomplete: {
    '& .MuiChip-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiChip-deleteIcon': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  RoleplayFilterBar: {
    "& .MuiBox-root": {
      "& .MuiOutlinedInput-root": { width: "100%" },
    }
  },

}));
export default useStyles;
