import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import createRootReducer from './reducer';
import rootSaga from './saga';
import migrations from './migrations';

export const history = createBrowserHistory();

const logger = createLogger({
  level: 'info',
  collapsed: true,
});

const persistConfig = {
  key: 'root',
  blacklist: [
    'app',
    'auth',
    'router',
    'enrolment',
    'reseller',
    'fields',
    'notes',
    'reports',
    'app',
  ],
  // blacklist: ['app', 'auth', 'router', 'reseller'],
  storage,
  version: 9,
  migrate: createMigrate(migrations, { debug: true }),
};

let middlewares;
const sagaMiddleware = createSagaMiddleware();
if (process.env.NODE_ENV === 'development') {
  middlewares = [sagaMiddleware, logger, thunk, routerMiddleware(history)];
} else {
  middlewares = [sagaMiddleware, routerMiddleware(history)];
}

const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(persistedReducer, bindMiddleware(middlewares));
store.sagaTask = sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
