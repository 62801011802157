import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip, Typography } from '@mui/material';
import { Spacer, Button } from 'src/components/shared';

import MenuAction from './MenuAction';
import { enqueueSequencePersonalize } from './../../../../sequence/actions/enrolmentActions';
import { SeqInfo, ContactStatus } from './styles';

import moment from 'moment/moment';
import { getSequenceStatusLabel } from 'src/utils/helper';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { capitalizeFirstLetter } from 'src/utils/helper';

function SequenceItem({
  contact,
  sequence,
  putEnrollment,
  isUnsubscribedUser,
  sequenceEnrollment,
}) {
  const dispatch = useDispatch();
  const sequenceEnrollments = useSelector((state) => state?.contacts?.sequenceEnrollment);
  const status = getSequenceStatusLabel(sequence?.status);
  const setActiveEnrolment = () => {
    const enrolments = Object.assign(
      {},
      ...sequenceEnrollments
        .filter((enrollment) => enrollment?.id === sequence?.id)
        .map((enrollment) => ({ [enrollment.id]: enrollment })),
    );
    dispatch(enqueueSequencePersonalize(enrolments, 'edit'));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography
          variant="body2"
          onClick={() => contact?.status !== 'unsubscribed' && setActiveEnrolment()}
          style={{
            cursor: contact?.status !== 'unsubscribed' ? 'pointer' : 'text',
            fontWeight: 500,
          }}
          color="secondary"
        >
          {sequence?.sequence?.name}
        </Typography>
        <Box display="flex" gap={0.5} alignItems="center">
          <ContactStatus status={sequence?.status}>{capitalizeFirstLetter(status)}</ContactStatus>
          <Tooltip title={'Cannot perform this action on an unsubscribed user.'}>
            <MenuAction
              disabled={isUnsubscribedUser}
              sequence={sequence}
              putEnrollment={putEnrollment}
            />
          </Tooltip>
        </Box>
      </Box>
      <SeqInfo variant="body2">
        Enrolled by {sequence?.createdBy?.fname} {sequence?.createdBy?.lname} on{' '}
        {moment(sequence?.createdAt).format('ll')}
      </SeqInfo>
      <SeqInfo variant="body2">
        Steps{' '}
        {sequence?.tasks?.filter((task) => ['replied', 'completed'].includes(task?.status)).length}{' '}
        of {sequence?.tasks?.length} is completed
      </SeqInfo>
      <Spacer x={1} y={1} />
      <Tooltip
        title={isUnsubscribedUser ? 'Cannot perform this action on an unsubscribed user.' : ''}
      >
        <Button
          size="small"
          color="secondary"
          disabled={isUnsubscribedUser}
          sx={isUnsubscribedUser ? { opacity: 0.7, color: '#1976D2 !important' } : {}}
          startIcon={<OpenInNewIcon />}
          onClick={setActiveEnrolment}
        >
          Personalise your Outreach
        </Button>
      </Tooltip>
    </Box>
  );
}

export default SequenceItem;
