import React from 'react';
import PropTypes from 'prop-types';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import SmsIcon from '@mui/icons-material/Sms';
import { EventCard } from '../eventsStyles';

const CallbackCard = ({ event, ...props }) => {
  return (
    <EventCard
      title="SMS"
      icon={<SmsIcon style={{ color: '#66BABC' }} />}
      showEdit={true}
      showDelete={true}
      event={event}
      {...props}
    />
  );
};

CallbackCard.propTypes = {
  event: PropTypes.object,
};

export default CallbackCard;
