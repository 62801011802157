import React, { useState } from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Icon from 'src/components/lms/Icon';
import Session from 'src/components/lms/Session';
import Course from 'src/components/lms/Course';
import FilterBar from 'src/components/lms/Filterbar';
import Empty from 'src/components/lms/Empty';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useRequest from 'src/components/hooks/useRequest';
import Spacer from 'src/components/shared/Spacer';
import { Box } from '@mui/material';
import { scrollToRef } from 'src/utils/helper';
import { useSelector } from 'react-redux';
import { fetchAssigned, getTagsLookup } from '../../api/LMSApis';

import DataSkeleton from './Skeleton';

function AssignedToMe() {
  const [applied, setApplied] = useState({});
  const [paging, setPaging] = useState({
    perPage: 10,
    page: 0,
  });

  const user = useSelector((state) => state.auth.user);
  const wrapperRef = React.useRef();

  const filters = [
    {
      key: 'sort',
      title: 'Sort',
      type: 'dropdown',
      remote: false,
      cancellable: true,
      icon: <Icon name="sort" />,
      options: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'createdAt',
        },
      ],
    },
    {
      key: 'type',
      title: 'Type',
      type: 'dropdown',
      remote: false,
      // optLabel: 'name',
      // optValue: 'id',
      cancellable: true,
      isPrimary: true,
      options: [
        { label: 'Sessions', value: 'session' },
        { label: 'Courses', value: 'course' },
      ],
      icon: <Icon name="layers" />,
    },
    {
      key: 'tags',
      title: 'Tag',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      icon: <Icon name="tags" />,
      remoteMethod: getTagsLookup,
    },
  ];

  const dir = applied?.sort && applied?.sort?.value == 'name' ? ':asc' : ':desc';

  const payload = {
    user_eq: user.id,
    ...applied,
    _from: paging.page * paging.perPage,
    _size: paging.perPage,
    ...(applied.sort ? { _sort: applied.sort?.value + dir ?? '' } : { _sort: 'createdAt:desc' }),
    ...(applied._search ? { _search: applied._search } : {}),
    ...(applied.type ? { _type: applied.type?.id } : {}),
    ...(applied?.tags ? { tags: applied?.tags.id } : {}),
    ...(applied?.type ? { type: applied?.type.value } : {}),
  };
  delete payload.sort;

  const [data, { loading }] = useRequest(fetchAssigned, payload);

  const handleFilter = (key, value) => {
    if (value && typeof value !== 'undefined') {
      setApplied({
        ...applied,
        [key]: value,
      });
    } else {
      delete applied[key];
      setApplied({
        ...applied,
      });
    }
  };

  const assignedToMe = data?.tasks ?? [];

  const handlePageChange = (e, page) => {
    setPaging((prevState) => {
      return {
        ...prevState,
        page: page - 1,
      };
    });
    scrollToRef(wrapperRef);
  };
  const total = data?.total?.value ?? assignedToMe?.length ?? 0;

  return (
    <LMSCard
      icon={<Icon name="flag" style={{ width: '24px', height: '24px' }} />}
      title="Assigned to Me"
      ref={wrapperRef}
    >
      <FilterBar
        showSearch={true}
        filters={filters}
        key="assigned-filters"
        value={applied}
        onChange={handleFilter}
      />

      <Spacer y={4} />

      {loading ? (
        <DataSkeleton />
      ) : assignedToMe.length ? (
        <Stack gap={4}>
          {assignedToMe.flatMap((item) => {
            return item?.session.id ? (
              <Session
                type="list"
                key={`assigned-session-${item?.session.id}`}
                session={item?.session}
                assignedBy={item?.createdBy || {}}
              />
            ) : item?.course.id ? (
              <Course
                type="list"
                key={`home-assigned-${item?.course.id}`}
                course={item?.course}
                assignedBy={item?.createdBy || {}}
              />
            ) : (
              []
            );
          })}

          <Box
            display={assignedToMe.length ? 'flex' : 'none'}
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Pagination
              count={total ? Math.ceil(total / paging.perPage) : 0}
              page={paging.page + 1}
              defaultPage={1}
              onChange={handlePageChange}
            />
          </Box>
        </Stack>
      ) : (
        <Empty
          title="Nothing to see here."
          desc="Check out some of our Courses and Sessions to get started!"
        />
      )}
    </LMSCard>
  );
}

export default AssignedToMe;
