export const openRateOptions = () => {
  return [...Array(100).keys()]
    .filter((i) => i % 10 == 0)
    .map((i) => ({
      value: `${i}-${i + 10}`,
      label: `${i}% to ${i + 10}%`,
    }));
};

export const enrolmentStatusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'In progress', value: 'inProgress' },
  { label: 'Completed', value: 'completed' },
  { label: 'Completed interested', value: 'interested' },
  { label: 'Completed not interested', value: 'notInterested' },
  { label: 'Replied', value: 'replied' },
  { label: 'Pause', value: 'hold' },
  { label: 'On hold', value: 'hold' },
  { label: 'Error', value: 'error' },
  { label: 'Bounced', value: 'bounced' },
  { label: 'Unsubscribe', value: 'unsubscribed' },
];

export const taskAutoCloseOptions = [
  { value: 72, label: '3 Days' },
  { value: 120, label: '5 Days' },
  { value: 168, label: '7 Days' },
];

export const sequenceCategories = [
  { value: 'introduction', label: 'Introduction' },
  { value: 'sales', label: 'Sales' },
  { value: 'followUp', label: 'Follow Up' },
];

export const sequenceBulkActions = [
  {
    title: 'Change Status',
    key: 'changeStatus',
  },
  // {
  //   title: 'Pause',
  //   key: 'pause',
  // },
  {
    title: 'Delete',
    key: 'delete',
  },
];

export const sequenceTabs = [
  {
    id: 'all',
    name: 'All',
  },
  {
    id: 'published',
    name: 'Active',
  },
];

export const sequenceWaitUnitOptions = [
  { value: 'seconds', label: 'Seconds' },
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: 'weeks', label: 'Weeks' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' },
];

export const sequenceDetailsTabs = [
  {
    id: 'people',
    name: 'People',
  },
  {
    id: 'steps',
    name: 'Steps',
  },
  {
    id: 'settings',
    name: 'Settings',
  },
  {
    id: 'preview',
    name: 'Preview',
  },
  {
    id: 'summary',
    name: 'Stats',
  },
];

export const bhContactTypeOptions = [
  {
    id: 'contact',
    name: 'Contact',
  },
  {
    id: 'candidate',
    name: 'Candidate',
  },
  {
    id: 'lead',
    name: 'Lead',
  },
];

export const bhSyncRuleOptions = [
  {
    id: 'manual',
    name: 'Manual',
  },
  {
    id: 'newContactEnrolled',
    name: 'On Enrollment',
  },
  {
    id: 'onReply',
    name: 'On Reply',
  },
  {
    id: 'onReplyInterested',
    name: 'On Reply Interested',
  },
];

export const sequenceStepTypes = {
  email: { label: 'Automated email', value: 'email', stats: {} },
  selectEmailTemplate: { label: 'Manual email', value: 'selectEmailTemplate', stats: {} },
  // wait: { label: 'Wait', value: 'wait', stats: {} },
  call: { label: 'Call', value: 'call', stats: {} },
  // endsequence: { label: 'End Sequence', value: 'endsequence', stats: {} },
  general: { label: 'General', value: 'general', stats: {} },
  linkedinMessage: { label: 'linkedin message', value: 'linkedinMessage', stats: {} },
  linkedinViewProfile: { label: 'linkedin View Profile', value: 'linkedinViewProfile', stats: {} },
  linkedinMail: { label: 'linkedin Mail', value: 'linkedinMail', stats: {} },
  linkedinConnection: { label: 'linkedin Connection', value: 'linkedinConnection', stats: {} },
};
