import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { drawerWidth } from 'src/config/theme';
import { SwipeableDrawer, Box, Typography } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  drawerMedium: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 530,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerMid: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 430,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerSmall: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 340,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerLarge: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 800,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  title: {
    fontSize: '16px !important',
  },
  subtitle: {
    // fontSize: '16px !important',
  },
}));

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  padding: ${(props) => (props.disabledPadding ? '0px' : '0px 30px')};
  height: calc(100vh - 68px);
  margin-bottom: 6px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3: {
    font-size: 16px !important;
  }
`;

export const Footer = styled.div`
  padding: 0px 30px;
  justify-content: flex-end;
  height: 54px;
  text-align: right;
`;
