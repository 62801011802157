export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const SET_SESSIONS = 'SET_SESSIONS';

export const FETCH_LIST = 'FETCH_LIST';
export const SET_LIST = 'SET_LIST';
export const DELETE_LIST = 'DELETE_LIST';

export const FETCH_HOMEPAGE = 'FETCH_HOMEPAGE';
export const SET_HOMEPAGE = 'SET_HOMEPAGE';

export const FETCH_SESSION = 'FETCH_SESSION';
export const SET_SESSION = 'SET_SESSION';

export const FETCH_SESSION_SCORE = 'FETCH_SESSION_SCORE';
export const SET_SESSION_SCORE = 'SET_SESSION_SCORE';

export const FETCH_COURSE = 'FETCH_COURSE';
export const SET_COURSE = 'SET_COURSE';

export const FETCH_COURSES = 'FETCH_COURSES';
export const SET_COURSES = 'SET_COURSES';

export const FETCH_HISTORY = 'FETCH_HISTORY';
export const SET_HISTORY = 'SET_HISTORY';

export const FETCH_SCORE = 'FETCH_SCORE';
export const SET_SCORE = 'SET_SCORE';

export const FETCH_PILLAR = 'FETCH_PILLAR';
export const SET_PILLAR = 'SET_PILLAR';

export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATES';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';

export const FETCH_NOTES = 'FETCH_NOTES';
export const SET_NOTES = 'SET_NOTES';
export const DELETE_NOTES = 'DELETE_NOTES';
export const EDIT_NOTE = 'EDIT_NOTE';

export const DELETE_HISTORY = 'DELETE_HISTORY';

export const FETCH_LMS_REPORT_SUMMARY = 'FETCH_LMS_REPORT_SUMMARY';
export const FETCH_LMS_REPORT_CARDS = 'FETCH_LMS_REPORT_CARDS';
export const FETCH_LMS_REPORT_CHARTS = 'FETCH_LMS_REPORT_CHARTS';

export const FETCH_LMS_COMPONENT1 = 'FETCH_LMS_COMPONENT1';
export const FETCH_LMS_COMPONENT2 = 'FETCH_LMS_COMPONENT2';
export const FETCH_LMS_COMPONENT3 = 'FETCH_LMS_COMPONENT3';

export const SET_LMS_SUMMARY = 'SET_LMS_SUMMARY';
export const SET_LMS_PROGRESS = 'SET_LMS_PROGRESS';
