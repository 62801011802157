import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    borderRadius: '7px',
    '&:disabled': {
      background: theme.palette.background.navyBlue,
      color: theme.palette.text.white,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
    },
    '&:enabled': {
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.00) 100%), #0C1721',
    },
  },
}));
