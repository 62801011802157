import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Stack, Typography, Tooltip } from '@mui/material';
import images from 'src/config/images';
import { taskAutoCloseOptions } from '../../../config';
import { Form } from '../../../../../components/shared';
import { getAssignerUser } from '../../../../auth/api/authApis';
import { useSelector } from 'react-redux';
import { fetchTeamLookup } from '../../../../users/api/usersApi';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const AdvanceSetting = ({
  name,
  selectName,
  values,
  formProps,
  startNewConversation,
  anotherUser,
  anotherUsersList,
}) => {
  const user = useSelector((state) => state.auth.user);
  const [showSetting, setShowSetting] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const response = await getAssignerUser(user?.id);
    setUserList(response);
  };

  return (
    <>
      <Stack
        direction={'row'}
        gap={'8px'}
        sx={{ cursor: 'pointer', mt: '24px', mb: '16px' }}
        alignItems={'center'}
        onClick={() => setShowSetting(!showSetting)}
      >
        <Typography
          sx={{
            color: '#1976D2',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
          }}
        >{`${showSetting ? 'Hide ' : ''}Advanced setting`}</Typography>
        <img src={showSetting ? images.icons.upArrowIcon : images.icons.downArrowIcon} alt={''} />
      </Stack>
      {showSetting ? (
        <>
          <Stack gap={'18px'}>
            <Stack>
              <Form.Field.Checkbox
                label={
                  <>
                    <Stack direction={'row'} sx={{ width: '215px' }} alignItems={'flex-start'}>
                      <Typography
                        variant={'caption'}
                        sx={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '20.02px',
                          letterSpacing: '0.17px',
                        }}
                      >
                        Automatically skip to next step if this step not completed.
                      </Typography>
                      <Tooltip
                        title={
                          <Stack padding={'14px 38px'} maxWidth={'372px'}>
                            <Stack gap="20px">
                              <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                                <ErrorOutlineOutlinedIcon sx={{ fontSize: '20px' }} />
                                <Typography fontSize={'14px'} fontWeight={700}>
                                  Skip step
                                </Typography>
                              </Box>
                              <Typography fontSize={'12px'} fontWeight={500}>
                                Enable this option to automatically move to the next step if the
                                current one isn't completed within the set timeframe. This helps
                                keep your workflow on track without manual intervention.
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                        arrow
                        placement={'bottom'}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#082037',
                              '& .MuiTooltip-arrow': {
                                color: '#082037',
                              },
                            },
                          },
                        }}
                      >
                        <img src={images.icons.questionMarkIcon} alt={''} />
                      </Tooltip>
                    </Stack>
                  </>
                }
                checked={values[name]}
                value={values[name]}
                name={name}
                size={'small'}
                // sx={{ padding: '0px' }}
              />
              {values[name] ? (
                <Form.Field.Select
                  sx={{ mt: '16px' }}
                  size={'small'}
                  isSmall
                  options={taskAutoCloseOptions}
                  fullWidth={true}
                  variant="outlined"
                  value={values[selectName]}
                  name={selectName}
                  optLabel="label"
                  optValue="value"
                  showNone={false}
                />
              ) : null}
              {formProps?.errors?.autoCloseDuration &&
                formProps?.errors?.autoCloseDuration !== '' && (
                  <Box>
                    <FormHelperText error={true}>
                      {formProps?.errors?.autoCloseDuration}
                    </FormHelperText>
                  </Box>
                )}
            </Stack>

            {/*<Stack>*/}
            {/*  <Form.Field.Checkbox*/}
            {/*    label={*/}
            {/*      <>*/}
            {/*        <Stack direction={'row'} sx={{ width: '215px' }} alignItems={'flex-start'}>*/}
            {/*          <Typography*/}
            {/*            variant={'caption'}*/}
            {/*            sx={{*/}
            {/*              color: 'rgba(0, 0, 0, 0.87)',*/}
            {/*              fontSize: '14px',*/}
            {/*              fontWeight: 400,*/}
            {/*              lineHeight: '20.02px',*/}
            {/*              letterSpacing: '0.17px',*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            Send this message from another user*/}
            {/*          </Typography>*/}
            {/*          <Tooltip*/}
            {/*            title={*/}
            {/*              <Stack padding={'14px 38px'} maxWidth={'372px'}>*/}
            {/*                <Stack gap="20px">*/}
            {/*                  <Box display={'flex'} gap={'10px'} alignItems={'center'}>*/}
            {/*                    <ErrorOutlineOutlinedIcon sx={{ fontSize: '20px' }} />*/}
            {/*                    <Typography fontSize={'14px'} fontWeight={700}>*/}
            {/*                      Skip Step*/}
            {/*                    </Typography>*/}
            {/*                  </Box>*/}
            {/*                  <Typography fontSize={'12px'} fontWeight={500}>*/}
            {/*                    Enable this option to automatically move to the next step if the*/}
            {/*                    current one isn't completed within the set timeframe. This helps*/}
            {/*                    keep your workflow on track without manual intervention.*/}
            {/*                  </Typography>*/}
            {/*                </Stack>*/}
            {/*              </Stack>*/}
            {/*            }*/}
            {/*            arrow*/}
            {/*            placement={'bottom'}*/}
            {/*            componentsProps={{*/}
            {/*              tooltip: {*/}
            {/*                sx: {*/}
            {/*                  bgcolor: '#082037',*/}
            {/*                  '& .MuiTooltip-arrow': {*/}
            {/*                    color: '#082037',*/}
            {/*                  },*/}
            {/*                },*/}
            {/*              },*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <img src={images.icons.questionMarkIcon} alt={''} />*/}
            {/*          </Tooltip>*/}
            {/*        </Stack>*/}
            {/*      </>*/}
            {/*    }*/}
            {/*    checked={values[anotherUser]}*/}
            {/*    value={values[anotherUser]}*/}
            {/*    name={anotherUser}*/}
            {/*    size={'small'}*/}
            {/*  />*/}
            {/*  {values[anotherUser] ? (*/}
            {/*    <Form.Field.AutoComplete*/}
            {/*      fullWidth*/}
            {/*      clearOnEscape={true}*/}
            {/*      name={anotherUsersList}*/}
            {/*      isSmall*/}
            {/*      variant={'outlined'}*/}
            {/*      placeholder="Select the users"*/}
            {/*      groupBy={(option) => option?.accessType || ''}*/}
            {/*      getOptionLabel={(option) => option.name || ''}*/}
            {/*      remoteMethod={(val) => {*/}
            {/*        return getAssignerUser(user?.id, val);*/}
            {/*      }}*/}
            {/*      optLabel="name"*/}
            {/*      optValue="id"*/}
            {/*      multiple={true}*/}
            {/*      options={*/}
            {/*        values?.[anotherUsersList]?.length > 0*/}
            {/*          ? values?.[anotherUsersList]*/}
            {/*          : userList || []*/}
            {/*      }*/}
            {/*      formSx={{ marginBottom: 0 }}*/}
            {/*      InputLabelProps={{*/}
            {/*        sx: {*/}
            {/*          fontSize: '14px',*/}
            {/*        },*/}
            {/*      }}*/}
            {/*      size={'small'}*/}
            {/*      checkboxes={true}*/}
            {/*    />*/}
            {/*  ) : null}*/}
            {/*  {formProps?.errors?.anotherUser && formProps?.errors?.anotherUser !== '' && (*/}
            {/*    <Box>*/}
            {/*      <FormHelperText error={true}>{formProps?.errors?.anotherUser}</FormHelperText>*/}
            {/*    </Box>*/}
            {/*  )}*/}
            {/*</Stack>*/}
            {/*<Form.Field.Checkbox*/}
            {/*  label={*/}
            {/*    <>*/}
            {/*      <Stack direction={'row'} sx={{ width: '215px' }} alignItems={'flex-start'}>*/}
            {/*        <Typography*/}
            {/*          variant={'caption'}*/}
            {/*          sx={{*/}
            {/*            color: 'rgba(0, 0, 0, 0.87)',*/}
            {/*            fontSize: '14px',*/}
            {/*            fontWeight: 400,*/}
            {/*            lineHeight: '20.02px',*/}
            {/*            letterSpacing: '0.17px',*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          Start a new email with this message.*/}
            {/*        </Typography>*/}
            {/*        <img src={images.icons.questionMarkIcon} alt={''} />*/}
            {/*      </Stack>*/}
            {/*    </>*/}
            {/*  }*/}
            {/*  checked={values[startNewConversation]}*/}
            {/*  value={values[startNewConversation]}*/}
            {/*  name={startNewConversation}*/}
            {/*  size={'small'}*/}
            {/*  // sx={{ padding: '0px' }}*/}
            {/*/>*/}
          </Stack>
        </>
      ) : null}
    </>
  );
};
export default AdvanceSetting;
