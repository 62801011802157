import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { Menu, Drawer } from 'src/components/shared';
import { Tabs } from 'src/components/App';

import { templateTabs } from '../../config';
import { useStyles } from '../../containers/integrations/styles';
import TemplatesGrid from '../../components/Templates/TemplatesGrid';
import TemplateForm from '../../components/Templates/TemplateForm';

function TemplatesView({
  templates,
  templateType,
  sequenceAccessTypeList,
  sequenceEmailTypeList,
  total,
  ...props
}) {
  const classes = useStyles();
  // const currentTab = useRef({});
  const [currentTab, setCurrentTab] = useState(templateType);
  const [formType, setFormType] = useState(undefined);
  const [activeCount, setActiveCount] = useState(0);
  const [linkedinType, setLinkedinType] = useState('linkedinMessage');
  const [drawerOpen, setDrawerOpen] = useState({ edit: null, open: false });

  useEffect(() => {
    if (drawerOpen?.edit?.id) setLinkedinType(drawerOpen?.edit?.type);
  }, [drawerOpen]);

  useEffect(() => {
    setCurrentTab(templateType);
  }, [templateType]);

  const onTabChange = (tab) => {
    // setCurrentTab(tab.id);
    props.history.push(`/templates/${tab.id}`);
  };

  const menuTitle = useMemo(() => {
    switch (linkedinType) {
      case 'linkedinConnection':
        return 'Connection';
        break;

      case 'linkedinMail':
        return 'InMail';
        break;

      default:
      case 'linkedinMessage':
        return 'Message';
        break;
    }
  }, [linkedinType]);

  return (
    <>
      <Tabs tabs={templateTabs} onChange={onTabChange} value={currentTab} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Drawer
          title={
            drawerOpen.edit
              ? drawerOpen?.clone
                ? 'Clone template'
                : 'Edit template'
              : 'Create template'
          }
          showCloseConfirmation={true}
          open={drawerOpen.open}
          disableEnforceFocus
          onClose={() => {
            setDrawerOpen({ edit: null, open: false });
          }}
          renderHeaderFunction={() => {
            if (templateType === 'email') return null;
            return (
              <Menu
                iconButton={false}
                title={menuTitle}
                buttonStyles={{ padding: 0, textTransform: 'none', fontWeight: 'normal' }}
                options={[
                  {
                    label: 'Message',
                    onClick: () => {
                      setLinkedinType('linkedinMessage');
                    },
                  },
                  {
                    label: 'Connection',
                    onClick: () => {
                      setLinkedinType('linkedinConnection');
                    },
                  },
                  {
                    label: 'Inmail',
                    onClick: () => {
                      setLinkedinType('linkedinMail');
                    },
                  },
                ]}
              />
            );
          }}
        >
          <TemplateForm
            isOpen={drawerOpen.open}
            editUser={drawerOpen.edit}
            templateType={templateType}
            drawerToggle={setDrawerOpen}
            linkedinType={linkedinType}
            loading={props.loading}
            sequenceAccessTypeList={sequenceAccessTypeList}
            sequenceEmailTypeList={sequenceEmailTypeList}
            user={props.user}
            {...props}
          />
        </Drawer>
      </Box>
      <TemplatesGrid
        drawerToggle={setDrawerOpen}
        templateType={templateType}
        templates={templates}
        linkedinType={linkedinType}
        templateBulkUpdate={props.templateBulkUpdate}
        globalConfig={props.globalConfig}
        sequenceAccessTypeList={sequenceAccessTypeList}
        sequenceEmailTypeList={sequenceEmailTypeList}
        {...props}
      />
    </>
  );
}

export default TemplatesView;
