import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';
import { Card, CardContent, Grid, LinearProgress, Stack, Typography, Box } from '@mui/material';
import styled from 'styled-components';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import EjectIcon from '@mui/icons-material/Eject';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { VideoModal } from 'src/components/App';
import api from 'src/api';
import qs from 'qs';
import { useLmsHomeProvider, withLmsStore } from 'src/modules/lms/utils/lmsStore';
import { isEmpty } from 'lodash';

function SessionVideo({ session, user }) {
  const searchParams = useLocation();
  const { videos, setProgress, progress } = useSessionProvider();
  const { fetchSummary, fetchProgress } = useLmsHomeProvider();
  const [video, setVideo] = useState(undefined);
  const timeupdateRef = useRef(null);
  const totalTimeRef = useRef(null);

  const openVideo = (video) => {
    if (
      video?.history?.isFullyWatched ||
      (video?.history?.watchedDuration &&
        video?.history?.watchedDuration == video?.history?.totalDuration)
    ) {
      setVideo({
        ...video,
        videoUrl: video.ogVideoUrl,
      });
    } else {
      setVideo(video);
    }
  };

  const addToWatchList = async (video) => {
    const payload = {
      session: session.id,
      user,
      videoId: video.id,
      thumbnail: video.thumbnailImageUrl,
      watchedDuration: timeupdateRef?.current || 0,
      totalDuration: totalTimeRef?.current || 0,
      isFullyWatched: timeupdateRef?.current === totalTimeRef?.current ? true : false,
    };
    setProgress({
      ...progress,
      [video.id]: {
        ...progress[video.id],
        watchedDuration: timeupdateRef?.current,
        totalDuration: totalTimeRef?.current || 0,
        progress: (timeupdateRef?.current * 100) / totalTimeRef?.current,
        isFullyWatched: timeupdateRef?.current === totalTimeRef?.current ? true : false,
      },
    });
    await api('/lms/history', payload, 'POST');
    setTimeout(() => {
      fetchProgress();
      fetchSummary();
    }, 1000);
  };

  const handleClose = () => {
    let thisVideo = video;
    addToWatchList(thisVideo);
    setVideo(undefined);
  };

  // this is set a event to get the duration and video ended evvent from vvideo player
  function eventFunction(event) {
    if (event.origin === `https://srvplr.superreach.com`) {
      if (event?.data) {
        if (event?.data?.event === 'timeupdate') {
          timeupdateRef.current = event?.data?.time || 0;
          totalTimeRef.current = event?.data?.totalDuration || 0;
        }
        if (event?.data?.event === 'videoEnded') {
          handleClose();
        }
      }
    }
  }

  React.useEffect(() => {
    if (video) {
      window.addEventListener('message', eventFunction, false);
    }
    return () => {
      window.removeEventListener('message', eventFunction);
    };
  }, [video]);
  // added the logic to play video if  search param is here

  React.useEffect(() => {
    const search = searchParams ? qs.parse(searchParams.search, { ignoreQueryPrefix: true }) : {};
    if (search && search.autoplay == 'video') {
      let video = videos.find((item) => item.history && item.history.isFullyWatched === false);
      if (video && video.id) {
        setVideo(video);
      } else {
        let video1 = videos.find((item) => !item.history || isEmpty(item.history));
        setVideo(video1);
      }
    }
  }, [searchParams, videos]);
  if (!videos || !videos.length) return null;

  return (
    <>
      <>
        <Box ml={2} width={'calc(100% - 16px)'} mt={2}>
          <Grid container spacing={2} sx={{ borderRadius: '4px' }}>
            {videos.map((video, index) => {
              const videoProgress = progress[video.id] || undefined;

              return (
                <Grid
                  item
                  xs={4}
                  key={`session-video-${index}`}
                  sx={{
                    background: '#0E202E',
                    paddingRight: '16px',
                    paddingBottom: '16px',
                  }}
                >
                  <VideoThumbContainer onClick={() => openVideo(video)}>
                    <div className="backdrop" />
                    {/* <PlayCircleIcon className="playIcon" /> */}
                    {(videoProgress && videoProgress.isFullyWatched) ||
                    progress[video.id]?.progress === 100 ? (
                      <div className="refreshButton">
                        <RestartAltIcon />
                      </div>
                    ) : videoProgress && videoProgress.watchedDuration ? (
                      <NotStartedIcon className="playIcon" />
                    ) : (
                      <PlayCircleIcon className="playIcon" />
                    )}
                    {video.thumbnailImageUrl ? (
                      <img className="thumbnail" src={video.thumbnailImageUrl} />
                    ) : (
                      <VideoThumb>
                        {(videoProgress && videoProgress.isFullyWatched) ||
                        progress[video.id]?.progress === 100 ? (
                          <div className="refreshButton">
                            <RestartAltIcon />
                          </div>
                        ) : videoProgress && videoProgress.watchedDuration ? (
                          <NotStartedIcon className="playPlaceholder" />
                        ) : (
                          <PlayCircleIcon className="playPlaceholder" />
                        )}
                      </VideoThumb>
                    )}
                    {progress[video.id] && progress[video.id]?.progress > 0 && (
                      <LinearProgress
                        color="secondary"
                        value={progress[video.id].progress}
                        variant="determinate"
                        sx={{
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#13DEA1',
                          },
                        }}
                      />
                    )}
                  </VideoThumbContainer>

                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ marginTop: '10px', wordBreak: 'break-all' }}
                  >
                    {video.title}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </>
      <VideoModal video={video} onClose={handleClose} />
    </>
  );
}

export default withLmsStore(SessionVideo);

const VideoThumbContainer = styled.div`
  position: relative;
  /* height: 126px; */
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  aspect-ratio: 256/144;
  height: auto;
  width: 100%;
  .refreshButton {
    /* display: flex; */
    display: none;
    width: 44px;
    height: 44px;
    background-color: #ffffff;
    border-radius: 44px;
    z-index: 999;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      fill: #000000;
    }
  }
  & .MuiLinearProgress-root {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & .playIcon {
    display: none;
    position: absolute;
    top: 0;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 10;
    width: 48px;
    height: 48px;
    z-index: 999;
    color: #ffffff;
  }
  & .backdrop {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 23, 33, 60);
    border-radius: 4px;
    z-index: 10;
    opacity: 0.4;
    border-radius: 0;
    /* z-index: 9; */
  }
  &:hover .backdrop,
  &:hover .playIcon {
    display: block;
  }
  &:hover .refreshButton {
    display: flex;
  }
  & .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const VideoThumb = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #193247;
  border-radius: 4px;
  & .playPlaceholder {
    position: absolute;
    top: 0;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    z-index: 10;
    width: 48px;
    height: 48px;
    fill: #466b92;
    color: #466b92;
  }
`;
