import React, { useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import images from 'src/config/images';

import { ReactComponent as Camera } from 'src/assets/icons/camera.svg';
import { ReactComponent as Robot } from 'src/assets/icons/Robot.svg';
import { useSessionProvider } from 'src/modules/lms/utils/sessionStore';
import api from 'src/api';
import { addList, deleteList, getEmbedURL } from 'src/modules/lms/api/LMSApis';
import toast from 'src/utils/toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { themeColors } from 'src/modules/lms/config';
import CompetitionProgress from '../../LMSCompetition/CompetitionProgress';
import SessionRoleplay from './SessionRoleplay';
import { isEmpty } from 'lodash';

import { ReactComponent as Guide } from 'src/assets/icons/guide.svg';
import { ReactComponent as ProgressIcon } from 'src/assets/icons/ProgressIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'src/components/App';
import { useLmsHomeProvider, withLmsStore } from 'src/modules/lms/utils/lmsStore';

import { setSession } from 'src/modules/lms/actions/LMSActions';

import qs from 'qs';

function SessionActionBar({ session, handleViewChange, fetchScore }) {
  const appHistory = useHistory();
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.app.tenant);
  const globalConfig = useSelector((state) => state.app.globals);
  const tenantSettings = useSelector((state) => state.admin.admin.tenantSettings);
  const progress = useSelector((state) => state.lms.progress);
  const { competition, reports, view, openRoleplay } = useSessionProvider();
  const { fetchSummary } = useLmsHomeProvider();

  const [loading, setLoading] = useState(false);
  const [embedURL, setEmbedURL] = useState('');
  const [rolePlayModal, setRolePlayModal] = useState(false);
  const [isFav, setIsFav] = useState(false);

  const [roleplayMinModal, setRoleplayMinModal] = useState(false);

  useEffect(() => {
    setIsFav(session?.isFavourite);
  }, [session]);

  const statusKey = session.showRoleplay ? 'progressStatus' : 'status';

  const status =
    progress?.sessions?.[session.id]?.[statusKey] === 'completed'
      ? 'Completed'
      : progress?.sessions?.[session.id]?.[statusKey] === 'inProgress'
      ? 'In progress'
      : 'Session not started';
  const totalVideos = session?.videos?.length;

  const minimumRoleplayScore =
    tenantSettings?.data?.[0]?.roleplaySettings?.minimumRoleplayScore ||
    globalConfig?.minimumRoleplayScore ||
    0;

  const onClickRolePlay = async () => {
    if (tenant?.roleplayMinutes && tenant?.roleplayMinutes > 0) {
      openRoleplay();
    } else {
      setRoleplayMinModal(true);
    }
  };

  //Fav list
  const addInFavList = async (id) => {
    try {
      let payload = {
        session: id,
      };
      let res = await addList(payload);
      dispatch(
        setSession({
          ...session,
          isFavourite: res?.favourite?.id && res?.favourite?.isDeleted == false ? true : false,
          favouriteId: res?.favourite?.id,
        }),
      );
      fetchSummary();
      if (res) {
        setIsFav(true);
        if (res?.message) {
          toast.info('Already added to My List');
        } else {
          toast.success('Item added to My list');
        }
      } else {
        toast.error('Item not deleted');
      }
    } catch (error) {
      toast.error('Item not added to the My list');
    }
  };

  const removeFromList = async (id) => {
    try {
      let res = await deleteList(id);
      await fetchSummary();
      if (res) {
        toast.info('Item removed from My list');
        setIsFav(false);
        dispatch(
          setSession({
            ...session,
            isFavourite: false,
            favouriteId: undefined,
          }),
        );
      } else {
        toast.error('Item not removed from My list');
      }
    } catch (error) {
      toast.error('Error ocurred! Please try again.');
    }
  };
  //Fav list end

  useEffect(() => {
    if (embedURL) setRolePlayModal(true);
  }, [embedURL]);

  useEffect(() => {
    const search = appHistory?.location
      ? qs.parse(appHistory?.location?.search, { ignoreQueryPrefix: true })
      : {};
    if (search && search.autoplay == 'roleplay') {
      onClickRolePlay();
    }
  }, [appHistory]);

  return (
    <>
      <Stack
        gap={2}
        direction="row"
        sx={{
          '& button': {
            height: 'fit-content',
          },
        }}
      >
        {minimumRoleplayScore > 0 && (
          <Stack
            direction={'row'}
            divider={<Stack sx={{ width: '1px', height: '100%', background: '#FFFFFF1F' }} />}
            alignItems={'center'}
            sx={{ padding: '8px 16px', background: '#0C1721', borderRadius: '8px', gap: '12px' }}
          >
            <Stack alignItems={'center'}>
              <Typography sx={{ color: '#FFFFFFB2', fontSize: '14px', whiteSpace: 'pre' }}>
                Minimum Score
              </Typography>
              <Typography sx={{ color: '#FFFFFF', fontSize: '32px', fontWeight: '300' }}>
                {minimumRoleplayScore}
              </Typography>
            </Stack>
            <Stack width={240}>
              <Typography sx={{ color: '#FFFFFFB2', fontSize: '13px' }}>
                To complete the session, you must watch at least one video and get the minimum score
                from the AI Coach.
              </Typography>
            </Stack>
          </Stack>
        )}
        {status ? (
          <Button
            variant="contained"
            size="small"
            startIcon={
              status === 'Completed' ? (
                <CheckCircleIcon style={{ color: themeColors.greenText }} />
              ) : status === 'In progress' ? (
                <ProgressIcon />
              ) : (
                ''
              )
            }
            sx={{
              padding: '4px 10px',
              background: '#0C1721',
              color:
                status === 'Completed' || status === 'In progress'
                  ? themeColors.greenText
                  : 'white',
            }}
          >
            {status}
          </Button>
        ) : null}

        {isFav === true ? (
          <Button
            size="small"
            variant="contained"
            startIcon={<StarIcon style={{ color: themeColors.yellow }} />}
            sx={{ padding: '4px 10px', background: '#0C1721', color: 'white' }}
            onClick={() => {
              if (isFav) {
                removeFromList(session?.favouriteId);
              } else {
                addInFavList(session.id);
              }
            }}
          >
            Added to list
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            startIcon={<StarBorderIcon />}
            sx={{ padding: '4px 10px', background: '#0C1721', color: 'white' }}
            onClick={() => {
              addInFavList(session.id);
            }}
          >
            Add to list
          </Button>
        )}

        {/* {competition ? <CompetitionProgress report={reports} competition={competition} /> : null} */}
      </Stack>
      <Stack gap={2} direction="row" mt={3.5} flexWrap="wrap">
        {totalVideos >= 1 ? (
          <Button
            startIcon={<Camera style={{}} />}
            onClick={() => {
              handleViewChange('desc');
            }}
            variant={view === 'desc' ? 'outlined' : 'contained'}
            color={view === 'desc' ? 'secondary' : 'primary'}
            sx={{ backgroundColor: '#0C1721', color: '#ffffff', flex: '0 0 auto' }}
          >
            {totalVideos > 1 ? 'Expert Videos' : 'Expert Video'}
          </Button>
        ) : null}
        {session?.howToGuidePdf && session?.howToGuidePdf !== '' && (
          <Button
            startIcon={<Guide />}
            onClick={() => {
              handleViewChange('guide');
            }}
            variant={view === 'guide' ? 'outlined' : 'contained'}
            color={view === 'guide' ? 'secondary' : 'primary'}
            sx={{ backgroundColor: '#0C1721', color: '#ffffff', flex: '0 0 auto' }}
          >
            How to Guide
          </Button>
        )}
        {session.showRoleplay ? (
          <Button
            startIcon={<Robot style={{ color: '#ffffff' }} />}
            onClick={onClickRolePlay}
            loading={loading}
            variant="contained"
            sx={{ backgroundColor: '#0C1721', color: '#ffffff', flex: '0 0 auto' }}
          >
            AI Coach
          </Button>
        ) : null}

        {/* {session?.showNote ? (
          <Button
            onClick={() => {
              appHistory.push({ pathname: '/lms/createNotes', state: session });
            }}
            variant="contained"
            startIcon={<img src={images?.LMS?.squareAdd} />}
            sx={{ backgroundColor: '#0C1721', color: '#ffffff', flex: '0 0 auto' }}
          >
            Add Notes
          </Button>
        ) : null} */}

        <ConfirmDialog
          title="AI Coach Credits"
          open={roleplayMinModal}
          onClose={() => {
            setRoleplayMinModal(false);
          }}
          showCancelButton={false}
          showConfirmButton={false}
        >
          You have run out of AI Coach credits. Please ask your Manager to purchase more credits to
          use this feature.
        </ConfirmDialog>
      </Stack>
    </>
  );
}

export default withLmsStore(SessionActionBar);
