import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RolePlayView from './RolePlayView';
import { fetchTenantSettings, updateSettings } from 'src/modules/admin/actions/adminActions';
import { Tabs } from 'src/components/App';
import { RoleplayTabs } from './config';
import AvailableRoleplays from './AvailableRoleplays';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';



export const RolePlayContainer = (props) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("generalSettings")
  useEffect(() => {
    if (!props.tenantSettings?.data?.length) {
      props.fetchTenantSettings();
    }
  }, []);

  const onTabChange = (tab) => {
    setCurrentTab(tab.id)
    if (tab.id === "availableRoleplays") {
      history.push("manage")
    }
  }


  return (
    <>
      <Tabs
        tabs={RoleplayTabs}
        onChange={onTabChange}
        value={currentTab}
      />
      <RolePlayView {...props} />
    </>
  )
};

const mapStateToProps = (state) => {
  const tenantSettings = state.admin.admin.tenantSettings?.data?.length
    ? state.admin.admin.tenantSettings?.data[0]
    : {};
  return {
    tenantSettings,
    tenant: state.app.tenant,
    settingValue: state.admin.admin.tenantSettings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTenantSettings: () => dispatch(fetchTenantSettings()),
  updateSettings: (data, id) => dispatch(updateSettings(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolePlayContainer);
