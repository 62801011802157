import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ContactsView from './ContactsView';
import {
  fetchContactTabs,
  fetchContacts,
  fetchContactOptionsFilters,
  deleteContact,
  toggleViewContactProfile,
  addContacts,
  putContact,
  contactBulkAction,
} from 'src/modules/contacts/actions/contactActions';
import {
  toggleAddTaskForm,
  toggleAddContactForm,
  toggleContactDrawer,
} from 'src/modules/app/actions/appActions';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import { PageTitle } from 'src/components/App';

export const ContactsContainer = (props) => {
  useEffect(() => {
    // props.fetchContacts(props.contacts.paging, props.contacts.filters, props.contacts.sort);
  }, []);

  return (
    <>
      <PageTitle title="People" />
      <ContactsView {...props} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    contactsOptions: state.contacts.contactsOptions,
    integrations: state.admin.admin.integrations.data || [],
    contactsTabs: state.contacts.contactTabs,
    contacts: state.contacts.contacts,
    loading: state.contacts.loading.contacts,
    fields: getContactFields(state),
    currentUser: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleContactDrawer: (contactId) => dispatch(toggleContactDrawer(contactId)),
  toggleViewContactProfile: (contactId) => dispatch(toggleViewContactProfile(contactId)),
  toggleAddContactForm: (contact) => dispatch(toggleAddContactForm(contact)),
  fetchContactOptionsFilters: (tab) => dispatch(fetchContactOptionsFilters(tab)),
  fetchContactTabs: () => dispatch(fetchContactTabs()),
  fetchContacts: (paging, filters, sort) => dispatch(fetchContacts(paging, filters, sort)),
  addContacts: (contacts) => dispatch(addContacts(contacts)),
  putContact: (id, contacts) => dispatch(putContact(id, contacts)),
  toggleAddTaskForm: (task = {}) => dispatch(toggleAddTaskForm(task)),
  deleteContact: (contactId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteContact(contactId, resolve, reject));
    });
  },
  contactBulkAction: (action, contacts, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(contactBulkAction(action, contacts, payload, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer);
