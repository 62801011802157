import { all, fork, put, call, select, takeLatest, delay } from 'redux-saga/effects';
import * as authTypes from '../actions/authTypes';
import * as authApis from '../api/authApis';
import * as authActions from '../actions/authActions';
import {
  setAppLoading,
  fetchGlobals,
  fetchTaskCount,
  fetchTenantConfig,
  setLoginStatus,
  resetStore,
} from 'src/modules/app/actions/appActions';
import { fetchBoards } from 'src/modules/app/actions/boardActions';
import toast from 'src/utils/toast';
import { push, replace } from 'connected-react-router';
import storage from 'src/utils/storageUtils';
import { authPages, isPathMatched, standAlonePages } from 'src/config';
import { Mixpanel } from 'src/utils/mixpanel';

import ReactGA from 'react-ga4';
import cookieUtils from 'src/utils/cookieUtils';
import { isEmpty } from 'lodash';
import {
  checkUserCompleteJourney,
  checkUserHaveLmsJourney,
  redirectAfterLogin,
} from 'src/utils/helper';

import { syncUser } from 'src/modules/auth/api/aiServicesApi';

export const getRouter = (state) => state.router;
export const getCurrentUser = (state) => state.auth.user;

const userAgentPayload = {
  userAgent: navigator.userAgent,
  screenResolution: {
    width: window.screen.width,
    height: window.screen.height,
    colorDepth: window.screen.colorDepth,
    pixelDepth: window.screen.pixelDepth,
    availWidth: window.screen.availWidth,
    availHeight: window.screen.availHeight,
  },
};

function* loginUser({ data, resolve, reject }) {
  try {
    const router = yield select(getRouter);
    const res = yield call(authApis.loginUser, data);
    const { user } = res;
    let tenant = user.tenants[0];

    if (!user?.id) {
      toast.error('You have entered an invalid email or password', 'tc');
      reject('You have entered an invalid email or password');
    }
    const isNonAdmin = user?.acl?.accessLevel?.toLowerCase() !== 'admin';
    const isNotActive = ['inactive', 'paused', 'cancelled'].includes(user?.subscriptionStatus);
    const isSubscriptionFailed = ['failure', 'needs_attention'].includes(user?.subscriptionStatus);
    const hasNotAccess =
      !user?.lmsmonthly && !user?.lmsannual && !user?.outreachAnnual && !user?.outreachMonthly;

    if (isNonAdmin && (isNotActive || hasNotAccess || isSubscriptionFailed)) {
      yield put(
        authActions.userLoginError(
          isSubscriptionFailed
            ? 'There is an issue with your company subscription. Please speak to your administrator.'
            : 'We can’t find an active subscription. Please speak to your administrator.',
        ),
      );
    } else {
      // storage.set('TOKEN', res.jwt);
      cookieUtils.set('AUTH_TOKEN', res.jwt, 30);
      const tenantHash = res.tenants[0];
      // storage.set('TENANT_HASH', tenantHash);
      cookieUtils.set('AUTH_TENANT_HASH', tenantHash, 30);
      if (router?.location?.query?.redirect && router?.location?.query?.redirect == 'lms') {
        window.location.replace(process.env.REACT_APP_LMS_URL);
        return false;
      } else {
        yield put(authActions.restoreSession());
      }
      // window.gtag('event', 'login', {
      //   event_category: 'access',
      //   event_label: 'login',
      // });
      ReactGA.event({
        category: 'access',
        action: 'login',
        label: 'login',
      });
      // if (
      //   (res?.user?.lmsmonthly || res?.user?.lmsannual) &&
      //   (res?.user?.outreachAnnual || res?.user?.outreachMonthly)
      // ) {
      //   yield put(authActions.showWelcomeModal(true));
      //   toast.success('Login successful!', 'tc');
      // }
      toast.success('Login successful!', 'tc');
    }
    const payload = {
      username: user.email,
      email: user.email,
      password: data?.password,
      jobTitle: '',
      firstName: user.fname,
      lastName: user.fname,
      organisation: {
        name: tenant?.name || '',
        domain: user.email.split('@')[1],
      },
    };
    try {
      const aiServiceUser = yield call(syncUser, payload);
      cookieUtils.set('AIBRAIN_TOKEN', aiServiceUser.data.token, 30);

      yield call(authApis.updateUser, {
        id: user.id,
        data: {
          aiServiceToken: aiServiceUser.data.token,
          ...userAgentPayload,
        },
      });
    } catch (aiError) {
      // toast.error('Failed to sync user with AI service.', 'tc');
    }
    resolve(res);
  } catch (error) {
    toast.error(error?.error?.message || 'You have entered an invalid email or password', 'tc');
    reject(error);
  }
}

function* restoreSession({ newUser, redirect = true }) {
  const router = yield select(getRouter);
  const currentRoute = router.location.pathname;

  try {
    // const token = yield call(storage.get, 'TOKEN');
    // const tenantHash = yield call(storage.get, 'TENANT_HASH');

    const token = cookieUtils.get('AUTH_TOKEN');
    const tenantHash = cookieUtils.get('AUTH_TENANT_HASH');

    if (token && tenantHash && token !== '' && tenantHash !== '') {
      if (redirect === true) {
        yield put(setAppLoading(true));
      }
      const profile = yield call(authApis.getProfile);
      const userJourney = yield call(authApis.signUpJourneyGetData, profile?.user?.email);
      window.DD_RUM.setUser({
        id: profile.user.id,
        name: `${profile.user.fname} ${profile.user.lname}`,
        email: profile.user.email,
      });

      yield put(authActions.setAuth(profile.user));
      yield put(setLoginStatus(true));
      yield put(fetchGlobals());
      // yield put(fetchTaskCount());

      yield put(fetchTenantConfig());
      yield put(fetchBoards('lg'));

      const hasOutreachAccess =
        profile.user?.outreachAnnual === true || profile.user?.outreachMonthly === true;
      const hasLmsAccess = profile.user?.lmsannual === true || profile.user?.lmsmonthly === true;
      const isStepCompleted = checkUserCompleteJourney(userJourney);

      const showLMSJourney = checkUserHaveLmsJourney(userJourney);
      const isNotActive = ['inactive', 'paused', 'cancelled'].includes(
        profile?.user?.subscriptionStatus,
      );

      const isAdmin = profile.user?.acl?.accessLevel?.toLowerCase() === 'admin';
      if (!profile.user?.outreachAnnual && !profile.user?.outreachMonthly) {
        if (!isAdmin && !currentRoute.startsWith('/profile')) {
          window.location.replace(process.env.REACT_APP_LMS_URL);
        }
      }

      if (redirect === true) {
        yield put(setAppLoading(false));
      }
      if (redirect === true) {
        if (router?.location?.query?.refferal) {
          yield put(replace(router?.location?.query?.refferal));
        } else if (currentRoute === '/auth/forgot-password') {
          yield put(replace('/profile/security'));
        } else if (authPages.includes(currentRoute)) {
          if (
            (profile.user?.lmsannual === true || profile.user?.lmsmonthly === true) &&
            !hasOutreachAccess
          ) {
            yield put(replace('/lms'));
          } else {
            if (isStepCompleted) {
              if (showLMSJourney) {
                window.location.replace(process.env.REACT_APP_LMS_URL + 'lms-journey');
              } else if (profile?.user?.onboardingCompleted === true) {
                // if (newUser === true) {
                // yield put(replace('/onboarding'));
                // yield put(replace('/landing'));
                yield put(replace(redirectAfterLogin(hasOutreachAccess, hasLmsAccess)));
                // } else {
                //   yield put(replace('/landing'));
                // }
              } else {
                // yield put(replace('/landing'));
                yield put(replace(redirectAfterLogin(hasOutreachAccess, hasLmsAccess)));
                // yield put(replace('/onboarding'));
              }
            } else {
              yield put(setAppLoading(false));
              if (showLMSJourney) {
                window.location.replace(process.env.REACT_APP_LMS_URL + 'lms-journey');
              } else if (hasOutreachAccess) {
                yield put(replace(`/outreach-journey`));
              } else {
                // yield put(replace('/landing'));
                yield put(replace(redirectAfterLogin(hasOutreachAccess, hasLmsAccess)));
              }
            }
          }
        } else {
          yield put(setAppLoading(false));
          if (!isNotActive) {
            if (!isStepCompleted && hasOutreachAccess) {
              yield put(replace(`/outreach-journey?refferal=${currentRoute}`));
            } else if (showLMSJourney) {
              window.location.replace(process.env.REACT_APP_LMS_URL + 'lms-journey');
            }
          }
        }
      }
    } else {
      if (
        !authPages.includes(currentRoute) &&
        !standAlonePages.some((path) => isPathMatched(currentRoute, path))
      ) {
        storage.del('TOKEN');
        storage.del('TENANT_HASH');
        yield put(
          replace(
            currentRoute && currentRoute != '/'
              ? `/auth/login?refferal=${currentRoute}`
              : '/auth/login',
          ),
        );
      }
      if (
        isPathMatched(currentRoute, '/outreach-journey') &&
        (!router?.location?.search || !Object.keys(router?.location?.query)?.length)
      ) {
        yield put(replace('/auth/login'));
      }
      yield put(setAppLoading(false));
    }
  } catch (error) {
    if (!authPages.includes(currentRoute)) {
      yield put(replace('/auth/login'));
    }
    yield put(setAppLoading(false));
    storage.del('TOKEN');
    storage.del('TENANT_HASH');
  }
}

function* logoutUser() {
  try {
    cookieUtils.unset('');
    cookieUtils.unset('AUTH_TOKEN');
    cookieUtils.unset('AUTH_TENANT_HASH');
    yield put(resetStore());
    yield put(replace('/auth/login'));
    localStorage.clear();
  } catch (error) {}
}

function* forgotPassword({ payload, resolve, reject }) {
  try {
    const res = yield call(authApis.forgotPassword, payload);
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* resetPassword({ payload, resolve, reject }) {
  try {
    const token = yield call(storage.get, 'TOKEN');
    const res = yield call(authApis.resetPassword, payload, token);
    toast.success('Password Updated!', 'tc');
    resolve(res);
  } catch (error) {
    reject(error);
  }
}

function* updateUser({ payload, resolve = () => {}, reject = () => {} }) {
  try {
    const curUser = yield select(getCurrentUser);
    const updatedPayload = { ...payload };
    if (payload?.hideToaster) delete updatedPayload.hideToaster;
    const userData = yield call(authApis.updateUser, updatedPayload);
    if (curUser.id === updatedPayload.id) {
      yield put(authActions.setCurrentUser({ userData }));
      !payload.hideToaster && toast.success('Profile Updated!', 'tc');
    } else {
      !payload?.hideToaster && toast.success('User Updated!', 'tc');
    }
    resolve(userData);
  } catch (error) {
    const genericErrorMsg = 'Profile update failed!';
    const errorMsg = error?.error?.message ? error.error.message : genericErrorMsg;
    !payload.hideToaster && toast.error(errorMsg, 'tc');
    reject(error);
  }
}

function* getUserById({ id, resolve, reject }) {
  try {
    const userData = yield call(authApis.getUserById, id);
    yield put(authActions.setUser({ userData }));
    yield put(setLoginStatus(false));
    resolve(userData);
  } catch (error) {
    reject(error);
  }
}

function* setNlToken({ code, userid, resolve, reject }) {
  try {
    const res = yield call(authApis.setNlToken, code, userid);
    const userData = { nlAccessToken: res.upUser[0].nlAccessToken, status: res.upUser[0].status };
    yield put(authActions.setEmailNLToken({ userData }));
    resolve(res);
    yield put(replace('/profile/outreachSettings'));
    toast.success('Account Connected!', 'tc');
  } catch (error) {
    reject(error);
  }
}

function* disconnectIntegration({ id, status, resolve, reject }) {
  try {
    const res = yield call(authApis.disconnectIntegration, id, status);
    const userData = { nlAccessToken: res.upUser[0].nlAccessToken, status: res.upUser[0].status };
    yield put(authActions.setEmailNLToken({ userData }));
    resolve(res);
    toast.success('Account Disconnected!', 'tc');
  } catch (error) {
    reject(error);
  }
}

function* uploadImage({ file, resolve, reject }) {
  try {
    const imageData = yield call(authApis.uploadImage, file);
    yield put(authActions.setUploadImage({ imageData }));
    resolve(imageData);
    toast.success('Image Uploaded', 'tc');
  } catch (error) {
    reject(error);
  }
}

function* registerUser({ data, resolve, reject }) {
  try {
    if (data?.step === 3) {
      const payload = {
        email: data?.email,
        fname: data?.firstName,
        lname: data?.lastName,
        tenant: data?.companyName,
        password: data?.password || '',
        emailVerified: data?.emailVerified || false,
      };
      const userData = yield call(authApis.registerUser, payload);
      const updatePayload = { ...data, user: userData?.user?.id };
      const res = yield call(authApis.signupjourney, updatePayload);
      if (userData?.error && userData?.error?.message) {
        toast.error(userData?.error?.error?.message || 'Error ocurred! Please try again.');
        reject(userData?.error);
      } else {
        if (!userData?.user?.emailVerified) {
          yield put(replace(`/auth/verification?email=${payload?.email}&id=${userData?.user?.id}`));
        } else {
          if (userData?.urlLink || res?.redirectUrl) {
            window.open(userData?.urlLink || res?.redirectUrl, '_self');
          }
        }
      }
    } else {
      const payload = JSON.parse(JSON.stringify(data));
      const res = yield call(authApis.signupjourney, payload);
      if (res?.error?.message) {
        toast.error(res?.error?.message || 'Error ocurred! Please try again.');
        reject(res);
      } else {
        if (res?.redirectUrl) {
          window.open(res?.redirectUrl, '_self');
        }
        resolve(res);
      }
    }
  } catch (error) {
    toast.error(error?.error?.message || 'Error ocurred! Please try again.');
    reject(error);
  }
}

function* registerUserWithThirdPartyService({ data, resolve, reject }) {
  const router = yield select(getRouter);
  try {
    const res = yield call(authApis.registerUserWithThirdPartyServices, data);
    const { user } = res;
    if (!user?.id) {
      toast.error('Registration failed', 'tc');
      reject('Registration failed');
    }
    const isNonAdmin = user?.acl?.accessLevel?.toLowerCase() !== 'admin';
    const isNotActive = ['inactive', 'paused', 'cancelled'].includes(user?.subscriptionStatus);
    const isSubscriptionFailed = ['failure', 'needs_attention'].includes(user?.subscriptionStatus);

    const hasNotAccess =
      !user?.lmsmonthly && !user?.lmsannual && !user?.outreachAnnual && !user?.outreachMonthly;

    if (isNonAdmin && (isNotActive || hasNotAccess || isSubscriptionFailed)) {
      yield put(
        authActions.userLoginError(
          isSubscriptionFailed
            ? 'There is an issue with your company subscription. Please speak to your administrator.'
            : 'We can’t find an active subscription. Please speak to your administrator.',
        ),
      );
    } else {
      // storage.set('TOKEN', res.jwt);
      cookieUtils.set('AUTH_TOKEN', res.jwt, 30);
      const tenantHash = res.tenants[0];
      // storage.set('TENANT_HASH', tenantHash);
      cookieUtils.set('AUTH_TENANT_HASH', tenantHash, 30);
      if (router?.location?.query?.redirect && router?.location?.query?.redirect == 'lms') {
        window.location.replace(process.env.REACT_APP_LMS_URL);
        return false;
      } else {
        yield put(authActions.restoreSession());
      }

      // window.gtag('event', 'login', {
      //   event_category: 'access',
      //   event_label: 'login',
      // });
      ReactGA.event({
        category: 'access',
        action: 'login',
        label: 'login',
      });

      const payload = {
        username: user.email,
        email: user.email,
        password: user.password,
        jobTitle: '',
        firstName: user.fname,
        lastName: user.fname,
        organisation: {
          name: user?.tenants[0]?.name || '',
          domain: user.email.split('@')[1],
        },
      };

      try {
        const aiServiceUser = yield call(syncUser, payload);
        cookieUtils.set('AIBRAIN_TOKEN', aiServiceUser.data.token, 30);

        yield call(authApis.updateUser, {
          id: user.id,
          data: {
            aiServiceToken: aiServiceUser.data.token,
            ...userAgentPayload,
          },
        });
      } catch (aiError) {
        toast.error('Failed to sync user with AI service.', 'tc');
      }
      if (
        (res?.user?.lmsmonthly || res?.user?.lmsannual) &&
        (res?.user?.outreachAnnual || res?.user?.outreachMonthly)
      )
        yield put(authActions.showWelcomeModal(true));
      toast.success('Registration successful!', 'tc');
    }
    resolve(res);
  } catch (error) {
    toast.error(error?.error?.message || 'Error occurred! Please try again.');
    reject(error);
  }
}

function* registerSetPassword({ uid, data, resolve, reject }) {
  try {
    const { redirect = false, ...dataToSave } = data;
    const res = yield call(authApis.registerUserSetPassword, uid, dataToSave);
    // storage.set('TOKEN', res.jwt);
    // storage.set('TENANT_HASH', tenantHash);
    const tenantHash = res.tenants[0];
    cookieUtils.set('AUTH_TOKEN', res.jwt, 30);
    cookieUtils.set('AUTH_TENANT_HASH', tenantHash, 30);

    yield put(authActions.restoreSession(true, redirect));
    // yield put(authActions.showWelcomeModal(true, true));
    const user = res.user;
    yield call(Mixpanel.identify, user.id);
    yield call(Mixpanel.track, 'User Signed Up');
    yield call(Mixpanel.people.set, {
      $first_name: user.fname,
      $last_name: user.lname,
      // $name: `${user.fname} ${user.lname}`,
      $email: user.email,
      $tenant: user.tenants.length ? user.tenants[0]?.name : '',
    });

    const payload = {
      username: user.email,
      email: user.email,
      password: user.password,
      jobTitle: '',
      firstName: user.fname,
      lastName: user.fname,
      organisation: {
        name: user?.tenants[0]?.name || '',
        domain: user.email.split('@')[1],
      },
    };
    try {
      const aiServiceUser = yield call(syncUser, payload);
      cookieUtils.set('AIBRAIN_TOKEN', aiServiceUser.data.token, 30);
      yield call(authApis.updateUser, {
        id: user.id,
        data: {
          aiServiceToken: aiServiceUser.data.token,
          ...userAgentPayload,
        },
      });
    } catch (aiError) {
      toast.error('Failed to sync user with AI service.', 'tc');
    }

    toast.success('Registration successful!');
    resolve(true);
  } catch (error) {
    toast.error('Error occurred. Please try again!');
    reject(false);
  }
}

function* loginUserWithGoogle({ data, resolve, reject }) {
  try {
    const router = yield select(getRouter);
    const res = yield call(authApis.getUserDataViaGoogleLogin, data);
    const { user } = res;
    if (!user?.id) {
      // toast.error('User not found.', 'tc');
      yield put(authActions.userLoginError('User not found.'));
      reject('User not found.');
    }
    const isNonAdmin = user?.acl?.accessLevel?.toLowerCase() !== 'admin';
    const isNotActive = ['inactive', 'paused', 'cancelled'].includes(user?.subscriptionStatus);
    const isSubscriptionFailed = ['failure', 'needs_attention'].includes(user?.subscriptionStatus);

    const hasNotAccess =
      !user?.lmsmonthly && !user?.lmsannual && !user?.outreachAnnual && !user?.outreachMonthly;

    if (isNonAdmin && (isNotActive || hasNotAccess || isSubscriptionFailed)) {
      yield put(
        authActions.userLoginError(
          isSubscriptionFailed
            ? 'There is an issue with your company subscription. Please speak to your administrator.'
            : 'We can’t find an active subscription. Please speak to your administrator.',
        ),
      );
      yield put(replace('/auth/login'));
    } else {
      // storage.set('TOKEN', res.jwt);
      cookieUtils.set('AUTH_TOKEN', res.jwt, 30);
      const tenantHash = res.tenants[0];
      // storage.set('TENANT_HASH', tenantHash);
      cookieUtils.set('AUTH_TENANT_HASH', tenantHash, 30);
      if (router?.location?.query?.redirect && router?.location?.query?.redirect == 'lms') {
        window.location.replace(process.env.REACT_APP_LMS_URL);
        return false;
      } else {
        yield put(authActions.restoreSession());
      }

      // window.gtag('event', 'login', {
      //   event_category: 'access',
      //   event_label: 'login',
      // });
      ReactGA.event({
        category: 'access',
        action: 'login',
        label: 'login',
      });

      try {
        const payload = {
          username: user.email,
          email: user.email,
          password: data.password,
          jobTitle: '',
          firstName: user.fname,
          lastName: user.fname,
          organisation: {
            name: user?.tenants[0]?.name || '',
            domain: user.email.split('@')[1],
          },
        };
        const aiServiceUser = yield call(syncUser, payload);
        cookieUtils.set('AIBRAIN_TOKEN', aiServiceUser.data.token, 30);

        yield call(authApis.updateUser, {
          id: user.id,
          data: {
            aiServiceToken: aiServiceUser.data.token,
            ...userAgentPayload,
          },
        });
      } catch (e) {}
      const hasOutreachAccess = res?.user?.outreachAnnual || res?.user?.outreachMonthly;
      const hasLmsAccess = res?.user?.lmsmonthly || res?.user?.lmsannual;
      if (
        (res?.user?.lmsmonthly || res?.user?.lmsannual) &&
        (res?.user?.outreachAnnual || res?.user?.outreachMonthly)
      ) {
        // yield put(authActions.showWelcomeModal(true));
        // yield put(replace('/landing'));
        yield put(replace(redirectAfterLogin(hasOutreachAccess, hasLmsAccess)));
      } else {
        yield put(authActions.userLoginError());
        toast.success('Login successful!', 'tc');
      }
    }
    // window.location.replace('/tasks');
    resolve(res);
  } catch (error) {
    const errorMessage =
      typeof error === 'string' ? error : error?.error?.message || 'User not found';
    yield put(authActions.userLoginError(errorMessage));
    // toast.error(errorMessage);
    reject(error);
  }
}

function* resendVerifictionEmail({ id, resolve, reject }) {
  try {
    const res = yield call(authApis.resendVerifictionEmail, id);
    if (res?.error?.message) {
      toast.error(res?.error?.message || 'Verification email sent failed', 'tc');
    } else toast.success(res?.message || 'Verification email sent successfully', 'tc');
    resolve(res);
  } catch (error) {
    toast.error(error?.message || 'Verification email sent failed!', 'tc');
    reject(error);
  }
}

function* verifyUser({ tid, uid }) {
  try {
    const res = yield call(authApis.verifyUserAndGetData, tid, uid);
    if (res?.msg === 'invalid token') {
      yield put(replace('/auth/login'));
    }
    if (res?.itsExpired) {
      toast.error(res?.error?.message);
      yield put(
        authActions.registerReVerification(
          uid,
          () => {},
          () => {},
        ),
      );
      yield put(replace(`/auth/verification?email=${res?.email}&id=${uid}`));
    }
    // storage.set('TOKEN', res.jwt);
    // storage.set('TENANT_HASH', tenantHash);
    const tenantHash = res.tenants[0];
    cookieUtils.set('AUTH_TOKEN', res.jwt, 30);
    cookieUtils.set('AUTH_TENANT_HASH', tenantHash, 30);

    // yield put(authActions.showWelcomeModal(true, true));
    const user = res.user;
    yield call(Mixpanel.identify, user.id);
    yield call(Mixpanel.track, 'User Signed Up');
    yield call(Mixpanel.people.set, {
      $first_name: user.fname,
      $last_name: user.lname,
      // $name: `${user.fname} ${user.lname}`,
      $email: user.email,
      $tenant: user.tenants.length ? user.tenants[0]?.name : '',
    });

    yield put(authActions.setAuth(user));

    try {
      const payload = {
        username: user.email,
        email: user.email,
        password: user.password,
        jobTitle: '',
        firstName: user.fname,
        lastName: user.fname,
        organisation: {
          name: user?.tenants[0]?.name || '',
          domain: user.email.split('@')[1],
        },
      };
      const aiServiceUser = yield call(syncUser, payload);

      cookieUtils.set('AIBRAIN_TOKEN', aiServiceUser.data.token, 30);

      yield call(authApis.updateUser, {
        id: user.id,
        data: {
          aiServiceToken: aiServiceUser.data.token,
          ...userAgentPayload,
        },
      });
    } catch (e) {}
    // yield put(authActions.restoreSession(true));
    return res;
  } catch (error) {
    toast.error(error?.error?.message || 'Error occurred. Please try again!');
    if (error?.itsExpired) {
      yield put(
        authActions.registerReVerification(
          uid,
          () => {},
          () => {},
        ),
      );
      yield put(replace(`/auth/verification?email=${error?.email}&id=${uid}`));
    }
  }
}

function* syncUsers() {
  try {
    const data = yield call(authApis.getVoiceTabs);
    yield put(authActions.setVoiceTabs(data));
  } catch (error) {
    yield put(authActions.setVoiceTabs(error));
  }
}

function* fetchVoicesTabs() {
  try {
    const data = yield call(authApis.getVoiceTabs);
    yield put(authActions.setVoiceTabs(data));
  } catch (error) {
    yield put(authActions.setVoiceTabs(error));
  }
}

function* fetchVoiceById({ id, resolve, reject }) {
  try {
    const data = yield call(authApis.fetchVoiceById, id);
    // yield put(authActions.fetchVoiceById(data));
    resolve(data);
  } catch (error) {
    toast.error('Failed to fetch data by ID', 'tc');
    reject(error);
  }
}

function* fetchVoices({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }

    const voices = yield call(authApis.fetchVoices, filter);
    let columns = voices.voice ? JSON.parse(voices.voice) : [];
    yield put(
      authActions.setVoices({
        ...voices,
        // ...columns,
      }),
    );
  } catch (error) {
    yield put(authActions.setVoices(error));
  }
}

function* saveVoice({ data, resolve, reject }) {
  try {
    const voices = yield call(authApis.saveVoiceData, data);
    // if (voices.error?.message) return toast.error(voices.error?.message, 'tc');
    // toast.success('Voice Created!', 'tc');
    resolve(voices);
  } catch (error) {
    toast.error(error?.error?.message, 'tc');
    return reject(error);
  }
}

function* updateVoice({ rowData, resolve, reject }) {
  try {
    const updatedRow = yield call(authApis.updateVoiceData, rowData);
    resolve(updatedRow);
    // toast.success('Voice updated!', 'tc');
  } catch (error) {
    toast.error(error?.error?.message || 'Something went wrong! Please try again.');
    reject(error);
  }
}

function* updateVoiceStatus({ rowData }) {
  try {
    const updatedRow = yield call(authApis.updateVoiceStatus, rowData);
    // yield put(authActions.setVoiceStatus({ updatedRow, id: rowData.id }));
    // toast.success('Status Updated!', 'tc');
  } catch (error) {
    toast.error('Failed to update status!', error);
  }
}

function* fetchICP({ paging, filters, sort }) {
  try {
    let filter = {
      _from: paging.pageNo * paging.perPage,
      _size: paging.perPage,
      ...filters,
    };
    if (sort && !isEmpty(sort)) {
      filter._sort = `${sort.name}:${sort.direction}`;
    }

    const ICPData = yield call(authApis.fetchICP, filter);
    yield put(
      authActions.setICP({
        icp: ICPData.Icps, // Set ICP list data
        total: ICPData.total.value, // Set total value in the Redux state
      }),
    );
  } catch (error) {
    yield put(authActions.setICP(error));
  }
}

function* saveICP({ data, resolve, reject }) {
  try {
    const ICP = yield call(authApis.saveICPData, data);
    resolve(ICP);
  } catch (error) {
    toast.error(error?.error?.message, 'tc');
    return reject(error);
  }
}

function* fetchICPId({ id, resolve, reject }) {
  try {
    const data = yield call(authApis.fetchICPById, id);
    resolve(data);
  } catch (error) {
    toast.error('Failed to fetch data by ID', 'tc');
    reject(error);
  }
}
function* updateICP({ data, resolve, reject }) {
  try {
    const updatedRow = yield call(authApis.updateICPData, data);
    resolve(updatedRow);
  } catch (error) {
    toast.error(error?.error?.message || 'Something went wrong! Please try again.');
    reject(error);
  }
}

function* deleteICP({ id, resolve, reject }) {
  try {
    const data = yield call(authApis.deleteICPData, id);
    resolve(data);
  } catch (error) {
    toast.error('Failed to delete data by ID', 'tc');
    reject(error);
  }
}

export function* watchSagas() {
  yield takeLatest(authTypes.LOGIN, loginUser);
  yield takeLatest(authTypes.VERIFY_USER, verifyUser);
  yield takeLatest(authTypes.LOGIN_WITH_GOOGLE, loginUserWithGoogle);
  yield takeLatest(authTypes.REGISTER, registerUser);
  yield takeLatest(authTypes.REGISTER_WITH_THIRD_PARTY, registerUserWithThirdPartyService);
  yield takeLatest(authTypes.REGISTER_RESEND_VERIFICATION_LINK, resendVerifictionEmail);
  yield takeLatest(authTypes.REGISTER_SET_PASSWORD, registerSetPassword);
  yield takeLatest(authTypes.RESTORE_SESSION, restoreSession);
  yield takeLatest(authTypes.LOGOUT, logoutUser);
  yield takeLatest(authTypes.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(authTypes.RESET_PASSWORD, resetPassword);
  yield takeLatest(authTypes.UPDATE_CURRENT_USER, updateUser);
  yield takeLatest(authTypes.GET_CURRENT_USER, getUserById);
  yield takeLatest(authTypes.SET_NL_TOKEN, setNlToken);
  yield takeLatest(authTypes.UPLOAD_IMAGE, uploadImage);
  yield takeLatest(authTypes.DISCONNECT_EMAIL_INTEGRATION, disconnectIntegration);

  yield takeLatest(authTypes.FETCH_VOICES_TABS, fetchVoicesTabs);
  yield takeLatest(authTypes.FETCH_VOICES, fetchVoices);
  yield takeLatest(authTypes.FETCH_BY_ID, fetchVoiceById);
  yield takeLatest(authTypes.SAVE_VOICES, saveVoice);
  yield takeLatest(authTypes.UPDATE_VOICES_STATUS, updateVoiceStatus);
  yield takeLatest(authTypes.UPDATE_VOICE, updateVoice);

  yield takeLatest(authTypes.FETCH_ICP, fetchICP);
  yield takeLatest(authTypes.SAVE_ICP, saveICP);
  yield takeLatest(authTypes.FETCH_ICP_BY_ID, fetchICPId);
  yield takeLatest(authTypes.UPDATE_ICP, updateICP);
  yield takeLatest(authTypes.DELETE_ICP, deleteICP);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}
