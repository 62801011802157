import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { Spacer } from 'src/components/shared';
import BillingDetailsCard from '../../components/Billing/BillingDetailsCard';
import PaymentMethods from '../../components/Billing/PaymentMethods';

import { useDataProviders } from '../../utils/billingStore';

function BillingDetails() {
  const { customer, cards, getCustomer, managePayments, primaryCard } = useDataProviders();

  const tenant = useSelector((state) => state.app.tenant);
  const chargeBeeId = tenant?.id || '';
  const updatePaymentMethod = () => getCustomer(tenant?.id);

  useEffect(() => {
    if (tenant && tenant?.id !== '') {
      getCustomer(tenant?.id);
    }
  }, [tenant]);

  return (
    <Container maxWidth="md" sx={{ margin: '0 !important', padding: '0 !important' }}>
      <BillingDetailsCard customer={customer} chargeBeeId={chargeBeeId} />
      <Spacer x={3.2} y={3.2} />
      <PaymentMethods
        updatePaymentMethod={updatePaymentMethod}
        primaryCard={primaryCard}
        cards={cards}
        managePayments={managePayments}
      />
    </Container>
  );
}

export default BillingDetails;
