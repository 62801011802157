import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';
import { getSequenceStatusColorCode } from '../../utils/contactUtils';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import images from '../../../../config/images';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const StatusChip = styled.div`
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 6px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
`;

export const ContactStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 30px;
  padding: 4px 16px;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  background-color: ${(props) => getSequenceStatusColorCode(props.status).background};
  color: ${(props) => getSequenceStatusColorCode(props.status).color};
`;

export const useStyles = makeStyles(({ custom }) => ({
  chip: {
    borderRadius: 4,
    '&.Inactive': {
      background: '#EF5350',
      color: '#ffffff',
    },
    '&.Active': {
      background: '#66BB6A',
      color: '#ffffff',
    },
    '&.Enrollmenterror, &.Hold/Stop, &.Systemhold': {
      backgroundColor: '#EF5350',
      color: '#ffffff',
    },
    '&.Notinanysequence': {
      backgroundColor: '#2196F3',
      color: '#ffffff',
    },
    '&.Spokentoasreasonssequencehold': {
      backgroundColor: '#CDDC39',
      color: '#ffffff',
    },
    '&.In-Progress': {},
  },
}));

export const Accordion = MuiStyled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme?.palette?.divider}`,
  borderTop: `1px solid ${theme?.palette?.divider}`,
  '&:first-child': {
    borderTop: 0,
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

export const AccordionSummary = MuiStyled((props) => (
  <MuiAccordionSummary
    expandIcon={props?.hideIcon ? null : <img src={images.people.squarePeopleAdd} alt="" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#F9F9F9',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = MuiStyled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
}));
