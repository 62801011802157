import styled from 'styled-components';
import { ButtonBase, Chip, Typography } from '@mui/material';
import { TimelineDot } from '@mui/lab';
import images from 'src/config/images';
import AddIcon from '@mui/icons-material/Add';

import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent } from '@mui/lab';

export const RoundButton = styled(ButtonBase)`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background: #42A5F5;
`;

export const MuiTimelineDot = styled(TimelineDot).attrs({
  color: 'secondary',
})`
  margin: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

export const TimelineButton = ({ onClick }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ backgroundColor: '#E0E0E0' }} />
        <RoundButton onClick={onClick}>
          <MuiTimelineDot>
            <AddIcon />
          </MuiTimelineDot>
        </RoundButton>
        <TimelineConnector sx={{ backgroundColor: '#E0E0E0' }} />
      </TimelineSeparator>
      <TimelineContent></TimelineContent>
    </TimelineItem>
  );
};

export const TimelineFiller = ({ onClick }) => {
  return <div style={{ height: 50 }} />;
};

export const EmptyIcon = styled.img.attrs({
  src: images.sequence.SequenceEmpty,
})`
  width: 160px;
`;

export const EventContainer = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  margin: 0 auto;
`;

export const SequenceSummaryHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  background: #fff;
  margin: 0px 0px 28px 0px;
`;

export const SequenceSummaryHeadTitle = styled(Typography)`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-wrap: nowrap;
`;

export const SequenceSummaryDataList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const SequenceSummaryChip = styled(Chip)`
  background-color: #e8f5e9;
  color: #2e7d32;
  font-size: 12px;
`;
