import React from 'react';
import {
  Wrapper,
  HeadWide,
  Gradient,
  Background,
  AssignedIcon,
  SessionImage,
  Content,
} from './styles';
import { Spacer } from 'src/components/shared';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ProgressBar from '../ProgressBar';
import { ReactComponent as CoursePlaceholder } from 'src/assets/lms/icons/CoursePlaceholder.svg';
import ReadMore from 'src/components/lms/ReadMore';
import Icon from 'src/components/lms/Icon';
import { palette } from 'src/config/darkTheme';
import { isEmpty } from 'lodash';

function Wide({
  course,
  image,
  leftSessions,
  handleClick,
  completedSessions,
  totalSessions,
  isListType,
  assignedBy,
  isCompleted,
}) {
  const thumbnail = course?.thumbnailImage || '';

  const assignedByName =
    assignedBy && !isEmpty(assignedBy) ? `${assignedBy?.fname} ${assignedBy?.lname}` : undefined;

  return (
    <Wrapper className={isListType ? 'list-wrapper' : ''} onClick={handleClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Content height={'100%'}>
            <Background className="backgroundImage" image={thumbnail}>
              <Gradient />
              <HeadWide>
                <ProgressBar total={totalSessions} completed={completedSessions} />
                <Stack
                  direction="row"
                  px={2}
                  justifyContent={leftSessions > 0 ? 'space-between' : 'flex-end'}
                >
                  {leftSessions > 0 && (
                    <Typography variant="body2" color="textSecondary" textAlign="left">
                      {leftSessions} SESSION{leftSessions === 1 ? '' : 'S'} LEFT
                    </Typography>
                  )}
                  <Icon
                    name="tick"
                    size={20}
                    color={isCompleted ? palette.secondary.main : 'rgba(255, 212, 126, 0.30)'}
                  />
                </Stack>
              </HeadWide>
              {thumbnail ? (
                thumbnail !== '' && <SessionImage src={thumbnail} />
              ) : (
                <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
                  <CoursePlaceholder style={{ width: '20%' }} />
                </Stack>
              )}
            </Background>
          </Content>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack className="content-wrapper">
            {assignedByName && (
              <Stack className="header">
                <AssignedIcon />
                <Typography component="span" color="textSecondary">
                  Assigned by&nbsp;
                  <Typography component="span" color="textPrimary" fontWeight={500}>
                    {assignedByName}
                  </Typography>
                </Typography>
              </Stack>
            )}

            <Stack className="content">
              <Spacer y={1} />
              <Typography color="textPrimary" sx={{ fontSize: '24px !important' }} textAlign="left">
                {course?.title}
              </Typography>
              <Spacer y={0.6} />
              <Stack direction="row">
                <Typography variant="body2" color="textSecondary" component="span">
                  Course by{' '}
                  <Typography variant="body2" color="textPrimary" component="span">
                    <b>SuperReach</b>
                  </Typography>
                </Typography>
              </Stack>
              <Spacer y={2} />
              <Typography variant="body2" color="textSecondary" component="span">
                <ReadMore text={course?.synopsis || ''} maxChars={200} isHtml={true} />
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Wide;
