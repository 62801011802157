import React from 'react';
import * as lmsApi from '../api/LMSApis';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLmsProgress, fetchLmsSummary } from '../actions/LMSActions';

const lmsHomeContext = React.createContext({});

export const useLmsHomeProvider = () => {
  const {
    competitions,
    fetchCompetitions,
    slidesDataByKey,
    completedSlides,
    fetchSlidesData,
    fetchAssigned,
    fetchSavedList,
    assignedSessions,
    assignedCourses,
    fetchSummary,
    getTaskById,
    isTaskFetching,
    assignTaskTo,
    removeTaskFrom,
    courseAssignedTo,
    progressSessions,
    fetchLibrary,
    libraryData,
    libraryListLoading,
    saved,
    summary,
    progress,
    homePage,
    assigned,
    fetchInProgressSessions,
    fetchProgress,
    loading,
  } = React.useContext(lmsHomeContext);
  return {
    competitions,
    fetchCompetitions,
    slidesDataByKey,
    completedSlides,
    fetchSlidesData,
    fetchSavedList,
    assignedSessions,
    fetchSummary,
    fetchAssigned,
    assignedCourses,
    getTaskById,
    isTaskFetching,
    assignTaskTo,
    removeTaskFrom,
    courseAssignedTo,
    progressSessions,
    fetchLibrary,
    libraryData,
    libraryListLoading,
    saved,
    summary,
    progress,
    homePage,
    assigned,
    fetchInProgressSessions,
    fetchProgress,
    loading,
  };
};

export const LmsStore = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState({
    home: true,
  });
  const [competitions, setCompetitions] = React.useState([]);
  const [slidesDataByKey, setSlidesDataByKey] = React.useState({});
  const [completedSlides, setCompletedSlides] = React.useState(0);
  const [assigned, setAssigned] = React.useState({ sessions: [], courses: [] });
  const [assignedSessions, setAssignedSessions] = React.useState([]);
  const [assignedCourses, setAssignedCourses] = React.useState([]);
  const [courseAssignedTo, setCourseAssignTo] = React.useState([]);
  const [progressSessions, setProgressSessions] = React.useState([]);
  const [isTaskFetching, setIsTaskFetching] = React.useState(false);
  const [saved, setSaved] = React.useState([]);
  const [libraryData, setLibraryData] = React.useState({ total: 0, libraries: [] });
  const [libraryListLoading, setLibraryListLoading] = React.useState(false);
  const [homePage, setHomePage] = React.useState({});
  const [progress, setProgress] = React.useState({ sessions: {}, courses: {} });
  const [summary, setSummary] = React.useState({
    inProgress: 0,
    assignedToMe: 0,
    saved: 0,
    competitions: 0,
    completed: 0,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      await fetchSlidesData();
      // fetchCompetitions();
      // fetchAssigned();
      // fetchSavedList();
      fetchSummary();
      fetchProgress();
      // fetchInProgressSessions();
      fetchHomepage();
    };
    fetchData();
  }, []);

  const getTaskById = async (id, type) => {
    setIsTaskFetching(true);
    const tasksById = await lmsApi.getCourseAssignedTo(id, type);
    const filteredTasks = (tasksById?.task ?? [])?.flatMap((task) => {
      if (
        (type === 'course' && task?.course?.id === id) ||
        (type === 'session' && task?.session?.id === id)
      )
        return {
          ...task,
          id: task?._id ?? task?.id,
          assignedTo: task?.team?.id
            ? task?.team?.name
            : task?.user?.id || task?.user?._id
            ? `${task?.user?.fname} ${task?.user?.lname}`
            : '',
        };
      else return [];
    });
    setCourseAssignTo(filteredTasks);
    setIsTaskFetching(false);
    return filteredTasks;
  };

  const removeTaskFrom = async (id, type) => {
    return await lmsApi.removeCourseAssignTo(id);
  };

  const assignTaskTo = async (payload) => {
    return await lmsApi.setCourseAssignTo(payload);
  };

  const fetchHomepage = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        home: true,
      }));
      const res = await lmsApi.fetchPages();
      setHomePage(res.pages.filter((page) => page.name === 'homepage')[0]);
      setLoading((prev) => ({
        ...prev,
        home: false,
      }));
    } catch (error) {
      setLoading((prev) => ({
        ...prev,
        home: false,
      }));
    }
  };

  const fetchInProgressSessions = async () => {
    ``;
    try {
      setLoading((prev) => ({
        ...prev,
        progress: true,
      }));
      const res = await lmsApi.getInprogressSessions({
        page: 0,
        perPage: 4,
        _sort: 'createdAt:desc',
      });
      setProgressSessions(res.progress);
      setLoading((prev) => ({
        ...prev,
        progress: false,
      }));
    } catch (error) {
      console.error('Error: ', error);
      setProgressSessions([]);
    }
  };

  const fetchProgress = async () => {
    try {
      const res = await lmsApi.fetchAllProgress();
      const progressObj = res.progress.reduce(
        (acc, item) => {
          if (item.session && item.session !== '') {
            acc.sessions[item.session] = {
              stats: item.stats,
              sessionVideos: item.sessionVideos,
              status: item.status,
              progressStatus: item?.progressStatus || '',
            };
          }
          if (item.course && item.course !== '') {
            acc.courses[item.course] = {
              stats: item.stats,
              sessionVideos: item.sessionVideos,
              status: item.status,
              progressStatus: item?.progressStatus || '',
            };
          }
          return acc;
        },
        { sessions: {}, courses: {} },
      );
      dispatch(fetchLmsProgress(progressObj));
      setProgress(progressObj);
    } catch (error) {}
  };

  const fetchSummary = async () => {
    try {
      const res = await lmsApi.getLmsSummaryCount();
      setSummary(res.count);
      dispatch(fetchLmsSummary(res.count));
    } catch (error) {}
  };

  const fetchSavedList = async () => {
    try {
      const payload = {
        _from: 0,
        _size: 4,
        _sort: 'createdAt:desc',
      };
      const res = await lmsApi.fetchList(payload);
      setSaved(res.favourite);
    } catch (error) {
      setSaved([]);
    }
  };

  const fetchLibrary = async (payload) => {
    try {
      setLibraryListLoading(true);
      const res = await lmsApi.fetchLibrary(payload);
      setLibraryData(
        res
          ? { total: res?.total?.value || 0, libraries: res?.library || [] }
          : { total: 0, libraries: [] },
      );
    } catch (error) {
      setLibraryData({ total: 0, libraries: [] });
    } finally {
      setLibraryListLoading(false);
    }
  };

  const fetchInprogressList = async () => {
    try {
      const payload = {
        _from: 0,
        _size: 4,
      };
      ``;
      const res = await lmsApi.getInprogressSessions(payload);
      setProgressSessions(res.progress);
    } catch (error) {
      ``;
      setProgressSessions([]);
    }
  };

  const fetchAssigned = async () => {
    try {
      const payload = {
        user_eq: user.id,
        _sort: 'createdAt:desc',
      };
      const res = await lmsApi.fetchAssigned(payload);
      let assignedObj = {
        sessions: [],
        courses: [],
      };
      let sessions = [],
        courses = [];

      for (const i in res.task) {
        if (res.task[i].session && res.task[i].session?.id) {
          sessions.push(res.task[i].session);
          assignedObj.sessions.push(res.task[i].session?.id);
        }
        if (res.task[i].course && res.task[i].course?.id) {
          courses.push(res.task[i].course);
          assignedObj.courses.push(res.task[i].course?.id);
        }
      }
      setAssignedSessions(sessions);
      setAssignedCourses(courses);
      setAssigned(assignedObj);
    } catch (error) {}
  };

  const fetchCompetitions = async () => {
    try {
      const res = await lmsApi.getCompetitions();
      setCompetitions(res?.favourite ?? []);
    } catch (error) {}
  };

  const fetchSlidesData = async (updatedUser) => {
    const updatedUserData = updatedUser ?? user;

    try {
      const response = await lmsApi.fetchGetStartedData();
      const slidesDataByKey = response?.onboarding?.reduce((acc, slide) => {
        if (slide.id) acc[slide.id] = slide;
        return acc;
      }, {});
      setSlidesDataByKey(slidesDataByKey);
      setCompletedSlides(
        Object.keys({ ...updatedUserData?.lmsOnboarding } ?? {})?.filter(
          (key) => slidesDataByKey[key] && updatedUserData?.lmsOnboarding[key],
        ).length ?? 0,
      );
    } catch (error) {}
  };

  return (
    <lmsHomeContext.Provider
      value={{
        competitions,
        slidesDataByKey,
        completedSlides,
        fetchCompetitions,
        fetchSlidesData,
        fetchSavedList,
        fetchAssigned,
        fetchSummary,
        getTaskById,
        isTaskFetching,
        assignTaskTo,
        removeTaskFrom,
        fetchLibrary,
        libraryData,
        libraryListLoading,
        courseAssignedTo,
        assignedSessions,
        assignedCourses,
        progressSessions,
        saved,
        summary,
        progress,
        homePage,
        assigned,
        fetchInProgressSessions,
        fetchProgress,
        loading,
      }}
    >
      {children}
    </lmsHomeContext.Provider>
  );
};

export const withLmsStore = (Component) => (props) =>
  (
    <LmsStore>
      <Component {...props} />
    </LmsStore>
  );
