import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Drawer } from '../../../../components/shared';
import AccordionFilters from '../../../../components/App/AccordionFilters';
import Switch from '../../../../components/shared/Form/Switch';
import { AccordionSummary, Accordion, AccordionDetails } from './styles';
import { useContactFilterStore } from './useContactFilter';
import { countSelectedAdvanceFilters, tempAccordionList } from '../../utils/contactUtils';

const ContactFilterDrawer = ({ onClose }) => {
  const {
    options,
    loading,
    fetchOption,
    updateOptionFilterData,
    handleContactFilters,
    updateFilterValue,
    onClearFilters,
    setHandleContactFilters,
  } = useContactFilterStore();

  const appliedFilterCount = countSelectedAdvanceFilters(handleContactFilters);
  const isDrawerOpen = handleContactFilters?.open;
  const [expanded, setExpanded] = useState();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => (prevState === panel ? null : panel));
  };

  const RenderElements = React.memo((item) => {
    if (options[item?.optionKey]?.isHidden) return <></>;
    const filterValue = handleContactFilters?.filters[item?.name];
    const selectedListValue = Array.isArray(filterValue?.selectedList)
      ? filterValue?.selectedList
      : [];
    const tempExpanded =
      item?.subType === 'calendar'
        ? filterValue?.value?.startDate && filterValue?.value?.endDate
        : selectedListValue?.length > 0;

    switch (item?.type) {
      case 'accordion':
        return (
          <Accordion
            expanded={tempExpanded || expanded === item?.id}
            onChange={handleChange(item?.id)}
          >
            <AccordionSummary
              aria-controls={`panel1d-content-${item?.id}`}
              id={`panel1d-header-${item?.id}`}
            >
              <Typography>{item?.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionFilters
                options={options[item?.optionKey]?.data}
                optionFilterData={options[item?.optionKey]}
                fetchOption={fetchOption[item?.optionKey]}
                loading={loading[item?.optionKey]}
                filterValue={handleContactFilters?.filters[item?.name]}
                updateFilterValue={updateFilterValue}
                updateOptionFilterData={updateOptionFilterData}
                isExpanded={expanded === item?.id}
                {...item}
              />
            </AccordionDetails>
          </Accordion>
        );
      case 'switch':
        return (
          <Accordion sx={{ cursor: 'default !important' }}>
            <AccordionSummary
              hideIcon
              sx={{
                '.MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'default !important',
                },
                cursor: 'default !important',
              }}
            >
              <Typography>{item?.label}</Typography>
              <Switch
                sx={{
                  mt: 0,
                  ml: 0,
                  '.MuiSwitch-root': {
                    m: 0,
                  },
                  '.MuiFormControlLabel-root': {
                    m: 0,
                  },
                }}
                value={handleContactFilters?.filters[item?.name]?.value}
                onChange={(updatedValue) =>
                  updateFilterValue({ key: item?.name, value: { value: updatedValue } })
                }
              />
            </AccordionSummary>
          </Accordion>
        );
      default:
        return <></>;
    }
  });

  return (
    <>
      <Drawer
        title={'Advanced filters'}
        showClose
        open={isDrawerOpen}
        onClose={() => setHandleContactFilters({ ...handleContactFilters, open: false })}
        sx={{
          '.drawer-header': {
            padding: '30px',
            paddingBottom: '24px',
          },
        }}
        size="mid"
        disabledPadding
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: '30px',
            paddingTop: '0px',
            paddingBottom: '10px',
          }}
        >
          <Typography color="#333333" variant="body2">
            {appliedFilterCount} filter applied
          </Typography>
          <Button
            variant="text"
            sx={{ textTransform: 'initial', color: appliedFilterCount ? '#1976D2' : '#00000061' }}
            onClick={() => onClearFilters()}
          >
            Clear all
          </Button>
        </Box>
        {tempAccordionList?.map((item) => (
          <RenderElements key={item?.id} {...item} />
        ))}
      </Drawer>
    </>
  );
};
const areEqual = (prevProps, nextProps) => true;
export default React.memo(ContactFilterDrawer, areEqual);
