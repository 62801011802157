import React, { useState, useMemo, useEffect } from 'react';
import { Form } from 'src/components/shared';
import Card from './Card';

import { updateTemplate } from 'src/modules/admin/api/adminApi';

import toast from 'src/utils/toast';
import { editSequenceStep } from 'src/modules/sequence/api/sequenceApi';
import { fetchFile } from 'src/modules/app/api/appApis';
import SequenceDetailHeader from '../SequenceSteps/SequenceDetailHeader';
import { fetchPopulateSequencePreviewTemplate } from '../../../actions/enrolmentActions';
import { useDispatch } from 'react-redux';
import AddNew from '../SequenceSteps/AddNew';
import { fetchContactLookup } from '../../../../users/api/usersApi';

const readonlyTypes = ['linkedinViewProfile', 'selectEmailTemplate', 'endsquence'];

function SequencePreview({
  sequence,
  sequenceEvents,
  onClose,
  seqId,
  sequencesContacts,
  ...props
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [waitTimes, setWaitTimes] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [action, setAction] = useState('');
  const [editIndex, setEditIndex] = useState('');
  const [actionEvent, setActionEvent] = useState({});
  const [actionTemplate, setActionTemplate] = useState({});
  const [updatedEvents, setUpdatedEvents] = useState([]);

  const events = useMemo(() => {
    return Object.values(sequenceEvents);
  }, [sequenceEvents]);

  const hasEndSeq = useMemo(
    () => Object.values(events).filter((event) => event.eventType === 'endsquence').length > 0,
    [events],
  );

  const liTypes = [
    'linkedin',
    'linkedinConnection',
    'linkedinMessage',
    'linkedinMail',
    'linkedinViewProfile',
  ];

  useEffect(() => {
    fetchInitialValues();
    calculateWaitTimes();
  }, [events, selectedContact, sequenceEvents]);

  const populateTemplate = (contactId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchPopulateSequencePreviewTemplate(contactId, subject, content, resolve, reject));
    });
  };

  const updateEmailTemplates = async (emailTemplates, contactId) => {
    return await Promise.all(
      emailTemplates.map(async (template) => {
        let contactPreviewObj = {};
        if (contactId) {
          try {
            await populateTemplate(contactId, template.subject, template.content)
              .then((res) => {
                contactPreviewObj = res;
              })
              .catch((error) => {
                console.error('Error: ', error);
              });
          } catch (error) {
            console.error('Error: ', error);
          }
        }
        return { ...template, contactPreviewObj };
      }),
    );
  };

  const updateEvent = async (event, contactId) => {
    let contactPreviewObj = {};
    if (contactId) {
      try {
        await populateTemplate(contactId, event.subject, event.content || event.description)
          .then((res) => {
            contactPreviewObj = res;
          })
          .catch((error) => {
            console.error('Error: ', error);
          });
      } catch (error) {
        console.error('Error: ', error);
      }
    }
    return { ...event, contactPreviewObj };
  };

  useEffect(() => {
    const updateEvents = async () => {
      const updated = await Promise.all(
        events.map(async (event) => {
          if (readonlyTypes.includes(event.eventType)) {
            return { ...event };
          } else if (event.eventType === 'email') {
            const updatedEmailTemplates = await updateEmailTemplates(
              event.emailTemplates,
              selectedContact?.id,
            );
            return { ...event, emailTemplates: updatedEmailTemplates };
          } else {
            return await updateEvent(event, selectedContact?.id);
          }
        }),
      );
      setUpdatedEvents(updated);
    };
    updateEvents();
  }, [events, selectedContact, sequenceEvents]);

  const fetchSequenceContacts = async (search = '') => {
    // remoteMethod: async (value) => {
    return fetchContactLookup(search);
    // },
    // if (seqId) {
    //   const res = await props.fetchSequenceContactsResolve(seqId, search);
    //   return res?.enrolments?.map((item) => item?.contact) || [];
    // }
  };

  const calculateWaitTimes = () => {
    const data = Object.values(sequenceEvents);

    const res = [];
    let waitTime = 0;
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        const event = data[i];
        if (event.eventType === 'wait') {
          switch (event.unit) {
            case 'seconds':
              waitTime += event.waitTime;
              break;
            case 'minutes':
              waitTime += event.waitTime * 60;
              break;
            case 'hours':
              waitTime += event.waitTime * 60 * 60;
              break;
            case 'days':
              waitTime += event.waitTime * 60 * 60 * 24;
              break;
            case 'weeks':
              waitTime += event.waitTime * 60 * 60 * 24 * 7;
              break;
            case 'months':
              waitTime += event.waitTime * 60 * 60 * 24 * 30;
              break;
            case 'years':
              waitTime += event.waitTime * 60 * 60 * 24 * 365;
              break;
          }
        }
        res.push(waitTime);
      }
      setWaitTimes(res);
    }
  };

  const fetchInitialValues = async () => {
    try {
      const data = Object.values(sequenceEvents);
      let res = {};
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          switch (true) {
            case item.eventType === 'general' ||
              item.eventType === 'sms' ||
              item.eventType === 'call':
              res[item.id] = {
                taskId: item.id,
                description: item.description,
                eventType: item.eventType,
              };
              break;

            case liTypes.includes(item.eventType):
              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                templateId: item?.templates?.id || '',
                template: item?.templates || {},
                subject: item?.templates?.subject || '',
                content: item?.templates?.content || '',
              };
              break;

            case item.eventType === 'wait':
              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                waitTime: item.waitTime,
                unit: item.unit,
              };
              break;

            case item.eventType === 'email':
              let tempData = {};
              for (let x = 0; x < item.emailTemplates.length; x++) {
                const templateItem = item.emailTemplates[x];
                let attachments = [];
                if (templateItem.attachments && templateItem.attachments.length) {
                  const promises = [];
                  for (let k = 0; k < templateItem.attachments.length; k++) {
                    promises.push(fetchFile(templateItem.attachments[k]));
                  }
                  attachments = await Promise.all(promises);
                  attachments = attachments.map((item) => item.upload);
                }
                tempData[templateItem.id] = {
                  template: templateItem,
                  templateId: templateItem.id,
                  content: templateItem.content,
                  subject: templateItem.subject,
                  attachments: attachments,
                };
              }

              res[item.id] = {
                eventType: item.eventType,
                taskId: item.id,
                emailTemplates: tempData,
              };
              break;

            default:
              break;
          }
        }
        setInitialValues(res);
      }
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    try {
      const data = Object.values(values);
      const promises = [];
      if (data && data.length) {
        setLoading(true);
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          switch (true) {
            case item.eventType === 'wait':
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  waitTime: item.waitTime,
                  unit: item.unit,
                }),
              );
              break;

            case item.eventType === 'email':
              const emailTemplates = Object.values(item.emailTemplates);
              for (let j = 0; j < emailTemplates.length; j++) {
                const emailTemplate = emailTemplates[j];
                promises.push(
                  updateTemplate(emailTemplate.template.id, {
                    content: emailTemplate.content,
                    subject: emailTemplate.subject,
                    ...(emailTemplate.attachments && emailTemplate.attachments.length
                      ? {
                          attachments: emailTemplate.attachments.map((item) => item.id),
                        }
                      : {}),
                  }),
                );
              }
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  emailTemplates: emailTemplates.map((item) => item.template.id),
                }),
              );
              break;

            case ['linkedin', 'linkedinConnection', 'linkedinMessage', 'linkedinMail'].includes(
              item.eventType,
            ):
              promises.push(
                updateTemplate(item.template.id, {
                  content: item.content,
                  subject: item.subject,
                }),
              );
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  templates: item.template.id,
                }),
              );
              break;

            case item.eventType === 'general' ||
              item.eventType === 'sms' ||
              item.eventType === 'call':
              promises.push(
                editSequenceStep(sequence.id, item.taskId, {
                  description: item.description,
                }),
              );
              break;

            default:
              break;
          }
        }
        await Promise.all(promises);
        toast.success('Sequence steps updated.');
        setLoading(false);
        onClose();
        props.fetchSequenceEvents(sequence.id);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.error?.message || 'Error occurred! Please try again.');
      props.fetchSequenceEvents(sequence.id);
    }
  };

  const onEdit = (event, template = {}) => {
    setEditIndex(event?.eventOrder);
    setAction('edit');
    setActionEvent(event);
    setActionTemplate(template);
  };

  const toggleForm = () => {
    setEditIndex('');
    setAction('');
    setActionEvent({});
    setActionTemplate({});
  };

  const handleEditSubmit = (data) => {
    const payload = JSON.parse(JSON.stringify(data));

    if (action === 'addTemplate' && actionEvent.id && actionEvent.id !== '') {
      const payloadData = {
        emailTemplates: [
          ...actionEvent.emailTemplates.map((item) => item.id),
          ...payload.emailTemplates,
        ],
        templateProps: {
          ...actionEvent.templateProps,
          [payload.emailTemplates[0]]: {
            status: true,
          },
        },
      };
      return props.putSequenceEvent(sequence.id, actionEvent?.id, payloadData);
    } else if (action === 'edit' && actionEvent.id && actionEvent.id !== '') {
      return props.putSequenceEvent(sequence.id, actionEvent?.id, payload);
    } else {
      payload.eventOrder = editIndex;
      return props.postSequenceEvent(sequence.id, payload);
    }
  };

  return (
    <>
      <SequenceDetailHeader
        events={events}
        title={'Select a contact'}
        remoteMethod={fetchSequenceContacts}
        optLabel={'name'}
        optValue={'id'}
        onChange={setSelectedContact}
        value={selectedContact}
      />
      <Form initialValues={initialValues} enableReinitialize={true} onSubmit={handleSubmit}>
        {(props) => {
          return (
            <>
              {updatedEvents && updatedEvents.length
                ? updatedEvents.map((event, index) => {
                    return (
                      <Card
                        onEdit={onEdit}
                        onlyViewable
                        event={event}
                        index={index}
                        key={`card-${index}`}
                        formProps={props}
                        waitTime={waitTimes[index]}
                      />
                    );
                  })
                : null}
            </>
          );
        }}
      </Form>
      <AddNew
        open={action === 'edit' && actionEvent?.id && actionEvent?.id !== ''}
        addStepIndex={editIndex}
        events={events}
        onClose={toggleForm}
        onSubmit={handleEditSubmit}
        hasEndSeq={hasEndSeq}
        // action={action === 'edit' ? 'edit' : 'add'}
        action={action}
        event={actionEvent}
        template={actionTemplate}
      />
    </>
  );
}

export default SequencePreview;
