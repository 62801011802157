import { Box, Typography } from '@mui/material'
import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SearchList = ({ props, data, selectedItems, setSelectedItems, handleRemove }) => {
    return (
        <li {...props}>
            <Typography>{data.name}</Typography>
            <Box display={"flex"} alignItems={"center"}>
                <Box display={"flex"} sx={{ background: "rgba(0,0,0,0.23)", padding: "2px", borderRadius: "50%" }} onClick={() => handleRemove(data.id)}>
                    <DeleteOutlineIcon />
                </Box>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selectedItems.filter((item) => item.id === data.id).length > 0}
                    onClick={() => setSelectedItems((prev) => prev.filter((item) => item.id === data.id).length > 0 ? prev.filter((item) => item.id !== data.id) : [...prev, data])}
                />
            </Box>
        </li>
    )
}

SearchList.propTypes = {
    props: PropTypes.object,
    data: PropTypes.object,
    selectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func,
    handleRemove: PropTypes.func
};

SearchList.defaultProps = {
    props: {},
    data: {},
    selectedItems: [],
    setSelectedItems: () => { },
    handleRemove: () => { }
};

export default SearchList