import React, { useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { Grid, Box, Typography, Tooltip } from '@mui/material';
import { Button, Form, Spacer } from 'src/components/shared';
import moment from 'moment';

function TaskForm({ task, onClose, ...props }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, form) => {
    setLoading(true);
    // values.dueOn = moment(values.dueOn).format('YYYY-MM-DD');
    const data = JSON.parse(JSON.stringify(values));
    data.scheduledOn = moment(data.dueOn).format('YYYY-MM-DD');
    if (data?.dueTime && data?.dueTime !== 'Invalid Date') {
      data.scheduledOn = new Date(data.scheduledOn + ' ' + data.dueTime).toISOString();
    } else {
      data.scheduledOn = new Date(data.scheduledOn + ' ' + '00:00').toISOString();
    }

    delete data.dueOn;
    delete data.dueTime;
    await props.putTask(task?.id, data);
    setLoading(false);
    onClose();
  };

  return (
    <Form
      initialValues={{
        dueOn: task?.scheduledOn ? moment(task?.scheduledOn) : moment().add('days', 5),
        dueTime: task?.scheduledOn ? moment(task?.scheduledOn).format('HH:mm') : '',
      }}
      validationSchema={Yup.object().shape({
        dueOn: Yup.date().typeError('Due date is required!').required('Due date is required!'),
      })}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {(props) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.submitForm();
              return false;
            }}
            style={{ height: '100%' }}
            noValidate
          >
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
              height="100%"
            >
              <Box width="100%">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Form.Field.Datepicker
                      fullWidth
                      variant="inline"
                      name="dueOn"
                      label="Due date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Form.Field.Timepicker
                      fullWidth
                      variant="outlined"
                      name="dueTime"
                      label="Due time"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mt={2} display="flex" justifyContent="flex-end" width="100%">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </Button>
                <Spacer x={1} y={1} />
                <Button
                  variant="outlined"
                  color="secondary"
                  loading={loading}
                  disabled={loading}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    </Form>
  );
}

export default TaskForm;
