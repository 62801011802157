import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
  Icon,
  Button,
  ButtonBase,
  Stack,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  useStyles,
  ExternalLinkWrapper,
  ProgressText,
  ProgressBar,
  AccordionMainText,
  IncompleteSection,
  AccordionSubText,
} from './OutreachAccordionStyle';
import { palette } from '../../../../../config/theme';

const OutReachOnboardingAccordion = ({ children }) => {
  const outer = React.Children.map(children, (child) =>
    child.type.displayName === 'Outer' ? child : null,
  );

  return <div>{outer}</div>;
};

const Outer = ({ title, isCompleted, icon, progress, ...props }) => {
  const classes = useStyles();

  return (
    <Accordion
      className={classes.outerAccordionContainer}
      expanded={props.expanded} // Control expanded state here
      onChange={props.onToggleExpand}
      id={props.id}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} className={classes.outerAccordionSummary}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center" gap="20px">
            <div style={{ width: '60px' }}>
              <ProgressBar>
                <ProgressText>{`${progress}%`}</ProgressText>
              </ProgressBar>
            </div>
            <CircularProgress
              variant="determinate"
              value={progress}
              color="success"
              size={54}
              className={classes.accordionProgress}
            />
            <Box>
              <AccordionMainText>{title}</AccordionMainText>
            </Box>
          </Box>
          <Box mr={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {isCompleted ? (
                <Typography
                  variant="body1"
                  className={`${classes.outerAccordionInfoTextComplete} ${classes.outerAccordionInfoText}`}
                >
                  All steps completed
                </Typography>
              ) : (
                <Typography variant="body1" className={classes.outerAccordionInfoText}>
                  {props.completeStep} / {props.steps} Completed
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.outerAccordionDetails}>
        <Box px={'24px'}>{props.children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};
Outer.displayName = 'Outer';
OutReachOnboardingAccordion.Outer = Outer;

const Inner = ({ title, isCompleted, index, onClick, ...props }) => {
  const classes = useStyles();
  const { icon } = props.accordionInner;

  return (
    <Accordion
      square={true}
      className={`${classes.innerAccordionContainer} ${isCompleted ? classes.completedStep : ''}`}
      expanded={props.expand}
      elevation={0}
      onChange={props.toggleExpand}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />} className={classes.innerAccordionSummary} style={{ padding: `${props.expand ? '0 20px 0px 45px' : '0px 0px 0px 5px'}` }}>
        {isCompleted ? (
          <Typography
            variant="body1"
            className={`${classes.outerAccordionInfoTextComplete} ${classes.outerAccordionInfoText}`}
          >
            <CheckCircleIcon />
          </Typography>
        ) : (
          <IncompleteSection />
        )}

        <Box m={'0 16px'} flex={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <AccordionSubText>{title}</AccordionSubText>
          </Stack>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.innerAccordionDetails}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};
Inner.displayName = 'Inner';
OutReachOnboardingAccordion.Inner = Inner;

const ExternalLink = ({
  title,
  isCompleted,
  index,
  onClick,
  accordionInner,
  markStepComplete,
  ...props
}) => {
  const { icon, action_source } = accordionInner;

  const handleClick = () => {
    window.open(action_source, '_blank');
    markStepComplete(accordionInner);
  };

  return (
    <ExternalLinkWrapper>
      <Icon color={'secondary'} style={isCompleted ? { color: palette.success.primary } : {}}>
        {icon}
      </Icon>

      <Box flex={1} ml={2}>
        <ButtonBase onClick={handleClick}>
          <Typography>{title}</Typography>
        </ButtonBase>
      </Box>
      <Button
        onClick={handleClick}
        variant="contained"
        style={
          isCompleted
            ? { background: palette.success.primary, textTransform: 'initial' }
            : { textTransform: 'initial' }
        }
        color={'secondary'}
      >
        {isCompleted ? 'Learning Complete' : 'Mark as complete'}
      </Button>
    </ExternalLinkWrapper>
  );
};
Inner.displayName = 'ExternalLink';
OutReachOnboardingAccordion.ExternalLink = ExternalLink;

export default OutReachOnboardingAccordion;
