import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import images from 'src/config/images';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { Button, Spacer } from 'src/components/shared';
import { taskRouteMatch } from 'src/config';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLinkedInStatus } from 'src/modules/auth/api/authApis';

const DisIcon = styled.img`
  width: 48px;
`;

const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: '#ffffff';
`;

const Desc = styled(Typography)`
  color: '#ffffff';
`;

const ConnectButton = styled(Button)`
  color: #ffffff;
  border-color: #ffffff !important;
`;

const WarningWrapper = styled.div`
  background-color: #e65100;
  color: #ffffff;
  width: -webkit-fill-available;
  height: 84px;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
  z-index: 999;
  justify-content: space-between;
`;

function Warning({ user, integrations, onboarding }) {
  const history = useHistory();
  const [openedError, setOpenedError] = useState();
  const tenant = useSelector((state) => state.app.tenant);
  const userData = useSelector((state) => state?.auth?.user);
  const [isLinkedInDisconnected, setIsLinkedInDisconnected] = useState(false);

  useEffect(() => {
    const bullhornIntegration = integrations?.find(
      (o) => o?.name?.toLowerCase() === 'bullhorn' && o.status === 'active',
    );
    const jobadderIntegration = integrations?.find(
      (o) => o?.name?.toLowerCase() === 'jobadder' && o.status === 'active',
    );
    const vincereIntegration = integrations?.find(
      (o) => o?.name?.toLowerCase() === 'vincere' && o.status === 'active',
    );
    const salesforceIntegration = integrations?.find(
      (o) => o?.name?.toLowerCase() === 'salesforce' && o.status === 'active',
    );
    const hubspotIntegration = integrations?.find(
      (o) => o?.name?.toLowerCase() === 'hubspot' && o.status === 'active',
    );
    const activeIntegration =
      bullhornIntegration ||
      jobadderIntegration ||
      vincereIntegration ||
      salesforceIntegration ||
      hubspotIntegration;
    setOpenedError((prevProps) => {
      return {
        ...prevProps,
        activeIntegration: activeIntegration,
        crmError:
          activeIntegration?.errorMessage ||
          activeIntegration?.statusMessage?.toLowerCase()?.includes('disconnected') ||
          activeIntegration?.statusMessage?.toLowerCase()?.includes('token invalid') ||
          activeIntegration?.serviceStatus?.toLowerCase()?.includes('down') ||
          activeIntegration?.serviceStatus?.toLowerCase()?.includes('error') ||
          activeIntegration?.serviceStatus?.toLowerCase()?.includes('slow'),
      };
    });
  }, [integrations]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getLinkedInStatus(userData.linkedinSessionId);
        if (res?.sessionDetails?.status === 'disconnected') setIsLinkedInDisconnected(true);
      } catch (e) {}
    };
    if (userData.linkedinSessionId && tenant?.featureAccess && tenant?.featureAccess?.linkedinAutomation) fetchData();
    else setIsLinkedInDisconnected(false);
  }, [tenant, userData]);

  const { nlProvider: provider, nlAccessToken: accessToken, nlSyncState } = user ?? {};
  const isSynced = !(nlSyncState === 'stopped' || nlSyncState === 'invalid');
  const type = ['eas', 'ews', 'graph', 'hotmail', 'exchange', 'office365', 'outlook']?.includes(
    provider,
  )
    ? 'Office 365'
    : provider === 'gmail'
    ? 'Gmail'
    : !!provider
    ? 'Others'
    : null;

  const wasConnected = ['Gmail', 'Office 365', 'Others']?.find((interList) => {
    const isActive = type === interList && accessToken && isSynced;
    const reConnect =
      type === interList && (!accessToken || nlSyncState !== 'running') && !isActive;
    return reConnect;
  });

  return (
    <Stack id="warning-element">
      {(!user?.nlAccessToken || user?.nlSyncState === 'not running' || !user?.nlEmail) &&
      !onboarding?.overview?.mailbox &&
      wasConnected ? (
        <React.Fragment>
          <WarningWrapper>
            <Box display="flex" alignItems="center">
              <DisIcon src={images.app.emailDisconnect} />
              <Box ml={2}>
                <Title>Inbox disconnected.</Title>
                <Desc>Please reconnect to start sending emails</Desc>
              </Box>
            </Box>
            <ConnectButton
              variant="outlined"
              onClick={() => {
                history.push('/profile/outreachSettings');
              }}
            >
              Click here
            </ConnectButton>
          </WarningWrapper>
          <Spacer y={3} />
        </React.Fragment>
      ) : null}
      {isLinkedInDisconnected ? (
        <React.Fragment>
          <WarningWrapper>
            <Box display="flex" alignItems="center">
              <DisIcon
                style={{ filter: 'brightness(0) invert(1)' }}
                src={images.tasks.taskLinkedinIcon}
              />
              <Box ml={2}>
                <Title>Linkedin disconnected.</Title>
                <Desc>Please reconnect to start linkedin automation</Desc>
              </Box>
            </Box>
            <ConnectButton
              variant="outlined"
              onClick={() => {
                history.push('/profile/outreachSettings?selectedTab=linkedIn');
              }}
            >
              Click here
            </ConnectButton>
          </WarningWrapper>
          <Spacer y={3} />
        </React.Fragment>
      ) : null}
      {openedError?.crmError && openedError?.activeIntegration?.name && (
        <React.Fragment>
          <Alert
            severity="warning"
            onClose={() =>
              setOpenedError((prevProps) => {
                return { ...prevProps, crmError: false };
              })
            }
            sx={{ '& .MuiAlert-icon ': { color: '#5F2B01' } }}
          >
            <Stack>
              <Typography fontSize={'15px'} fontWeight={500}>
                Temporary {openedError?.activeIntegration?.name} API disruptions may affect data
                interactions. Please note this is beyond SuperReach's control. Apologies for any
                inconvenience.
              </Typography>
              <Spacer y={1} />
              <Button
                onClick={() =>
                  window.open(
                    'https://help.superreach.com/en/articles/8311888-bullhorn-api-refresh-needed',
                    '_blank',
                  )
                }
                sx={{ padding: 0, width: 'fit-content' }}
                color="secondary"
                variant="text"
              >
                <Typography fontSize={'15px'} fontWeight={500}>
                  Please read this article for more details.
                </Typography>
              </Button>
            </Stack>
          </Alert>
          <Spacer y={3} />
        </React.Fragment>
      )}
    </Stack>
  );
}

export default Warning;
