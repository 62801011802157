import React, { useEffect, useRef, useState } from 'react';
import api from 'src/api';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import { Box, ButtonBase, Grid, Stack, Typography } from '@mui/material';
import CompetitionProgress from './CompetitionProgress';
import WinningPrize from './WinningPrize';
import TopPerformers from './TopPerformers';
import ProgressGraph from '../../shared/ProgressGraph';
import CompetitionHeader from '../LMSHeader/CompetitionHeader';
import { ArrowBack } from '@mui/icons-material';

const ReportScreen = (props) => {
  const ref = useRef();
  const [report, setReport] = useState({});
  const [competition, setCompetition] = useState({});
  const history = useHistory();

  const getReports = async () => {
    let id = props?.match?.params?.id;
    if (id) {
      try {
        const resp = await api(`lms/competition/report/${id}`, null, 'GET');
        const res = await api(`lms/competition/${id}`, null, 'GET');
        setCompetition(res.competition);
        if (resp.report.length) setReport(resp.report);
      } catch (err) {}
    }
  };

  useEffect(() => {
    getReports();
    ref.current = setInterval(() => {
      getReports();
    }, 120000);
    return () => {
      clearInterval(ref.current);
    };
  }, []);
  const classes = useStyles();
  return (
    <Box>
      <ButtonBase
        onClick={() => {
          // history.goBack();
          history.push('/lms/competition');
        }}
      >
        <Stack direction="row" gap={1} alignItems="center" mb={2}>
          <ArrowBack /> <Typography variant="body2">Back to Competitions</Typography>
        </Stack>
      </ButtonBase>
      <CompetitionHeader />
      <Grid container mb={4}>
        <Grid item xs={7} className={classes.headingContainer}>
          <Typography className="leagueHeading">
            {competition?.name || ''} - Progress report
          </Typography>
        </Grid>
        {/* <Grid item xs={5}>
          <CompetitionProgress competition={competition} />
        </Grid> */}
      </Grid>
      <Grid container mb={3}>
        <Grid item xs={12} className={classes.tableContainer}>
          <ProgressGraph data={report} competition={competition} reportData={report} />
        </Grid>
        <Grid item xs={12}>
          <TopPerformers report={report} competition={competition} type={'row'} hideMedal={true} />
        </Grid>
        <Grid item xs={5}>
          {competition?.prize ? <WinningPrize competition={competition} /> : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportScreen;
