import React from 'react';
import { useEffect } from 'react';
import { memo } from 'react';
import { Dropdown, Spacer } from 'src/components/shared';
import { getUsers } from 'src/modules/auth/api/authApis';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';
import ActivitiesFilter from './ActivitiesFilter';

const team = {
  key: 'team',
  title: 'All Team',
  type: 'dropdown',
  remote: true,
  optLabel: 'name',
  optValue: 'id',
  cancellable: false,
  searchable: true,
  isPrimary: true,
  checkbox: true,
  multiple: true,
  remoteMethod: async (value) => {
    return fetchTeamsLookup(value);
  },
};

function FilterItems({ appliedFilters, ...props }) {
  const [activityFilter, setActivityFilter] = React.useState(props.typeActiveFilters || []);

  const fetchUsers = async (searchTerm) => {
    try {
      const res = await getUsers();
      return res.users;
    } catch (error) {
      return [];
    }
  };

  const selected =
    props.typeActiveFilters && props.typeActiveFilters.length ? props.typeActiveFilters[0] : {};


  return (
    <>
      <Dropdown
        title="Activities"
        selected={selected}
        options={[]}
        onBlur={() => {
          props.fetchContactNote(props.contact.id, {
            type_eq: activityFilter?.filter(({ value }) => !!value),
          });
        }}
        onChange={(val) => {}}
        onClear={() => {}}
        optLabel="label"
        optValue="id"
        cancellable={false}
        customRenderMenu={() => {
          return <ActivitiesFilter values={activityFilter} onChange={setActivityFilter} />;
        }}
        value={activityFilter}
        multiple={true}
        sx={{
          '& .MuiPaper-root': {
            width: 'auto',
            maxWidth: 'auto',
            overflow: 'hidden',
          },
        }}
      />
      <Spacer x={1} />
      <Dropdown
        selected={
          appliedFilters?.teams && appliedFilters?.teams.length ? appliedFilters?.teams[0] : {}
        }
        onChange={(value) => {
          props.handleTeamFilter('teams', value);
        }}
        fullWidth={true}
        value={appliedFilters?.teams || []}
        {...team}
        cancellable={false}
        searchable={true}
        isPrimary={true}
        checkbox={true}
        multiple={true}
        apply={true}
      />
    </>
  );
}

export default memo(FilterItems);
