import { Dialog, Stack } from '@mui/material';
import { ModalContainer, ModalContent, ModalHeader } from './style';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../../../components/shared';
import { palette } from '../../../../config/theme';

const IcpModal = ({
  isErrorButton,
  secondButtonTitle = '',
  onCancel = () => {},
  onSubmit = () => {},
  content = '',
  open = false,
}) => {
  return (
    <>
      <Dialog open={open} onClose={() => onCancel()}>
        <ModalContainer>
          <ModalHeader>
            <CloseIcon sx={{ color: '#0000008A' }} onClick={() => onCancel()} />
          </ModalHeader>
          <ModalContent>{content}</ModalContent>
          <Stack
            direction="row"
            sx={{ padding: '0px 24px 24px 24px' }}
            alignItems={'center'}
            justifyContent={'flex-end'}
            gap={'20px'}
          >
            <Button
              sx={{ fontSize: palette.fontSize.large, fontWeight: palette.fontWeight.medium }}
              color={'secondary'}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant={'contained'}
              color={isErrorButton ? 'error' : 'secondary'}
              onClick={() => onSubmit()}
              sx={{
                fontSize: palette.fontSize.large,
                fontWeight: palette.fontWeight.medium,
                padding: '8px 22px',
              }}
            >
              {secondButtonTitle}
            </Button>
          </Stack>
        </ModalContainer>
      </Dialog>
    </>
  );
};
export default IcpModal;
