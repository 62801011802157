import React, { useState } from 'react';
import { Box } from '@mui/material';
import CreateICPModal from '../../container/createICP/CreateICPView';
import ICPCard from './ICPCard';
import ICPMoreDetailsModal from './ICPMoreDetailsModal';
import { fieldList } from './utils';

const ICPGrid = ({
  setShowICPForm,
  showICPForm,
  saveICP,
  icpsData,
  getICPById,
  updateICP,
  deleteICP,
  ...props
}) => {
  const [isDetailModal, setIsDetailModal] = useState({ isOpen: false, moreDetails: {} });

  const transformedData = icpsData?.data?.map((icp) => {
    const data = {
      profileType: icp.profileType === 'organisation' ? 'Customer' : 'Candidate',
      name: icp.name,
      status: icp.status,
      id: icp?.id,
    };

    fieldList.forEach((field) => {
      const fieldKey = field.key;

      let fieldValue;
      if (fieldKey === 'jobTitle') {
        fieldValue = icp[fieldKey];
      } else if (fieldKey === 'contactLocation') {
        fieldValue = icp[fieldKey].label;
      } else {
        fieldValue = icp[fieldKey]?.value;
      }

      if (fieldValue) {
        data[fieldKey] = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
      } else {
        data[fieldKey] = null;
      }
    });

    return data;
  });

  return (
    <>
      <Box sx={{ paddingTop: '24px' }}>
        <Box display={'flex'} gap={'12px'} flexWrap={'wrap'}>
          {transformedData?.map((item, index) => (
            <Box display={'flex'} gap={'12px'} flexWrap={'wrap'} key={index}>
              <ICPCard
                data={item}
                setIsDetailModal={setIsDetailModal}
                setShowICPForm={setShowICPForm}
                updateICP={updateICP}
                deleteICP={deleteICP}
                icpsData={icpsData?.data}
                {...props}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <CreateICPModal
        showICPForm={showICPForm}
        setShowICPForm={setShowICPForm}
        saveICP={saveICP}
        getICPById={getICPById}
        updateICP={updateICP}
        {...props}
      />
      <ICPMoreDetailsModal
        isDetailModal={isDetailModal}
        handleClose={() => setIsDetailModal({ isOpen: false, moreDetails: {} })}
      />
    </>
  );
};
export default ICPGrid;
