import Login from '../container/login';
import Register from '../container/register';
import Setup from '../container/setup';
import RegisterVerification from '../container/register/RegisterVerification';
import RegisterSetPassword from '../container/register/RegisterSetPassword';
import ForgotPassword from '../container/forgotPassword';
import ResetPassword from '../container/resetPassword';
import MyProfile from '../container/myProfile';
import GoogleAuthCallBack from '../container/GoogleAuthCallBack';
import createBrandVoice from '../container/createBrandVoice';
import updateBrandVoice from '../container/updateBrandVoice';

export default [
  {
    title: 'Login',
    component: Login,
    url: '/auth/login',
    exact: true,
    auth: false,
  },
  {
    title: 'Forgot Password',
    component: ForgotPassword,
    url: '/auth/forgot-password',
    exact: true,
    auth: false,
  },
  {
    title: 'Setup',
    component: Setup,
    url: '/auth/setup/password',
    exact: true,
    auth: false,
  },
  {
    title: 'Reset password',
    component: ResetPassword,
    url: '/auth/reset-password',
    exact: true,
    auth: false,
  },
  {
    title: 'My Profile',
    component: MyProfile,
    url: '/admin/user/:id/:tab',
    exact: true,
    auth: true,
  },
  {
    title: 'My Profile',
    component: MyProfile,
    url: '/profile',
    exact: true,
    auth: false,
  },
  {
    title: 'My Profile',
    component: MyProfile,
    url: '/profile/:tab',
    exact: true,
    auth: false,
  },
  {
    title: 'Register',
    component: Register,
    url: '/auth/signup',
    exact: true,
    auth: false,
  },
  {
    title: 'Register',
    component: RegisterVerification,
    url: '/auth/verification',
    exact: true,
    auth: false,
  },
  {
    title: 'Register Set Password',
    component: RegisterSetPassword,
    url: '/auth/onboarding/password',
    exact: true,
    auth: false,
  },
  {
    title: 'Google Auth Callback',
    component: GoogleAuthCallBack,
    url: '/auth/callback',
    exact: true,
    auth: false,
  },
  {
    title: 'Tone of voice',
    component: createBrandVoice,
    url: '/voice/create',
    exact: true,
    auth: false,
  },
  {
    title: 'My Profile',
    component: updateBrandVoice,
    url: '/voice/update/:id',
    exact: true,
    auth: false,
  },
];
