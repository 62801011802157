import { Box, Grid } from '@mui/material';
import React from 'react';
import { Form } from 'src/components/shared';
import { fetchTemplatesLookup } from 'src/modules/sequence/api/sequenceApi';
import { EmailContent, EmailSubject, PreviewContent } from './style';

function LinkedIn({
  event: { id, eventType, templates, contactPreviewObj, description, subject, content, name },
  formProps,
  onlyViewable,
}) {
  return (
    <>
      {onlyViewable ? (
        <Grid key={`sequence-preview=email`} item xs={12}>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px 4px 0px 0px',
              '& .tox-tinymce': {
                borderRadius: '0 !important',
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
              },
            }}
          >
            <Box sx={{ padding: '10px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <EmailSubject>{contactPreviewObj?.name || name || '##Template name##'}</EmailSubject>
            </Box>
            {/*{eventType === 'linkedinMail' ? (*/}
            {/*  <Box sx={{ padding: '10px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>*/}
            {/*    <EmailSubject>*/}
            {/*      {contactPreviewObj?.subject || subject || '##Template subject##'}*/}
            {/*    </EmailSubject>*/}
            {/*  </Box>*/}
            {/*) : null}*/}
            <PreviewContent>
              <EmailContent
                component="div"
                dangerouslySetInnerHTML={{
                  __html:
                    contactPreviewObj?.description ||
                    contactPreviewObj?.content ||
                    description ||
                    content,
                }}
              />
            </PreviewContent>
          </Box>
        </Grid>
      ) : (
        <Box
          sx={{
            borderRight: '1px solid rgba(0,0,0,0.12)',
            borderLeft: '1px solid rgba(0,0,0,0.12)',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            // marginLeft: '-1px',
            // width: 'calc(100% + 1px)',
            '& .tox-tinymce': {
              borderRadius: '0 !important',
              borderLeft: 'none',
              borderRight: 'none',
              // borderTop: 'none',
            },
          }}
        >
          <Form.Field.AutoComplete
            multiple={false}
            fullWidth
            options={[templates] || []}
            name={`${id}.template`}
            optLabel="name"
            optValue="id"
            placeholder="Template"
            remoteMethod={(val) => {
              return fetchTemplatesLookup(val, 'linkedin');
            }}
            size="small"
            sx={{
              marginTop: '-8px',
              marginBottom: '-17px',
            }}
            InputProps={{ sx: { padding: '6px 12px' } }}
            onChange={(val) => {
              formProps.setFieldValue(`${id}.subject`, val.subject);
              formProps.setFieldValue(`${id}.content`, val.content);
            }}
          />

          {eventType === 'linkedinMail' && (
            <Form.Field.Input
              fullWidth
              name={`${id}.subject`}
              placeholder="Subject"
              size="small"
              input
              showPicker={true}
              emojiPicker={true}
              InputProps={{
                sx: {
                  padding: '6px 12px',
                },
              }}
              sx={{
                marginBottom: '-9px',
              }}
            />
          )}

          <Form.Field.TextEditor
            name={`${id}.content`}
            showFontStyle={false}
            showFontEditor={false}
            showAlignProps={false}
            showListProps={false}
            showAttachments={false}
            toolbarLocation="bottom"
            sx={{
              borderColor: '#cccccc',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
          />
        </Box>
      )}
    </>
  );
}

export default LinkedIn;
