import styled from 'styled-components';

export const TextWrapper = styled('div')(({ selected, isDefault }) => ({
  color: isDefault ? 'rgba(0, 0, 0, 0.6)' : selected ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)',
  fontFamily: 'Inter, serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
}));

export const SubTextWrapper = styled('div')(({ selected, isDefault }) => ({
  color: isDefault ? 'rgba(0, 0, 0, 0.6)' : selected ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)',
  fontFamily: 'Inter, serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}));

export const AmountTextWrapper = styled('div')(({ selected, isDefault }) => ({
  color: isDefault
    ? 'rgba(0, 0, 0, 0.87)'
    : selected
    ? 'rgba(0, 0, 0, 0.87)'
    : 'rgba(0, 0, 0, 0.38)',
  fontFamily: 'Inter, serif',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  height: '42px',
}));

export const ContentWrapper = styled('div')(({ selected, isDefault }) => ({
  color: isDefault ? 'rgba(0, 0, 0, 0.6)' : selected ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)',
  fontFamily: 'Inter, serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}));

export const MainWrapper = styled('div')(({ selected, isDefault }) => ({
  background:
    'linear-gradient(white, white) padding-box, linear-gradient(to right, rgba(255, 212, 128, 1), rgba(242, 64, 164, 1), rgba(19, 222, 161, 1)) border-box',
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  borderRadius: '16px',
  padding: '24px',
  border: selected ? '4px solid transparent' : '1px solid transparent',
  width: '100%',
  position: 'relative',
  transition: 'background 0.3s ease', // Smooth transition on background change
  '& :hover': {
    courser: 'pointer',
  },
}));

export const SaveBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-16px',
  right: '35%',
  background: 'linear-gradient(95deg, #FFB82C 6.93%, #E23F9A 51.08%, #00B47E 95.23%)',
  color: 'white',
  borderRadius: '12px',
  padding: '4px 12px',
}));
