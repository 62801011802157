import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import darkTheme from 'src/config/darkTheme';
import ButtonBase from '@mui/material/ButtonBase';
import { List } from '@mui/material';

export const ChildMenu = styled(List)`
  margin-left: ${(props) => (props.collapsed ? '6px' : '48px')};
  & .MuiListItemButton-root {
    height: 40px;
    color: ${darkTheme.palette.text.secondary};
    & svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ToggleButton = styled(ButtonBase)`
  background: #ffffff4d;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const Logo = styled.img`
  width: 140px;
`;

export const CollapsedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  & .collapseButton {
    display: none;
  }
  &:hover .logo {
    display: none;
  }
  &:hover .collapseButton {
    display: block;
  }
`;

export const CollapsedLogo = styled.img`
  width: 34px;
  height: 34px;
`;

export const MenuItem = styled(ListItem)`
  padding: 0px !important;
  margin: 4px 0px;
  & .MuiListItemIcon-root {
    min-width: 48px;
    padding-left: 4px;
    & svg {
      width: 24px;
      height: 24px;
      color: #ffffff;
    }
  }
  &.active {
    background-color: #ffd47e1f;
    border-right: 2px solid ${darkTheme.palette.secondary.main};
    & .MuiListItemIcon-root svg,
    & .MuiTypography-root {
      color: ${darkTheme.palette.secondary.main};
      font-weight: 500;
    }
  }
`;

export const ChildMenuItem = styled(ListItem)`
  padding: 0px !important;
  margin: 4px 0px;
  & .MuiListItemIcon-root {
    min-width: 48px;
    padding-left: 4px;
    & svg {
      width: 24px;
      height: 24px;
      color: #ffffff;
    }
  }
  &.active {
    /* background-color: #ffd47e1f;
    border-right: 2px solid ${darkTheme.palette.secondary.main}; */
    & .MuiListItemIcon-root svg,
    & .MuiTypography-root {
      color: ${darkTheme.palette.secondary.main};
      font-weight: 500;
    }
  }
`;
