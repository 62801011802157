import { themeColors } from '../../config';
import { makeStyles } from '@mui/styles';
import previewImage from '../../../../img/Graphic.png';

const useStyles = makeStyles(({ palette }) => ({
  aiLearning_div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // background: 'rgba(8, 32, 53, 1)',
    height: '100%',
    // padding: '64px',
  },
  aiLearning_iframe: {
    width: '480px',
    height: '270px',
  },
  aiLearning_h1: {
    color: ' rgba(241, 229, 121, 1)',
    fontSize: '36px',
    fontWeight: '700',
    lineHeight: '44px',
    letterSpacing: '0px',
  },
  aiLearning_h3: {
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '27px',
    letterSpacing: '0px',
    color: 'rgba(242, 242, 242, 1)',
    textAlign: 'center',
  },
  aiLearning_span: {
    color: 'rgba(153, 153, 153, 1)',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '50px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '19px',
    letterSpacing: '0px',
  },
  sessionPreview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    // height: 'fit-content',
    // height: '100%',
    background:
      'linear-gradient(104.75deg, #000000 -5.01%, #132B4E 134.12%),linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    borderRadius: '10px',
    '&:focus-visible ': {
      outline: 'none !important',
    },
  },
  imgContainer: {
    // backgroundImage: `url(${previewImage})`,
    // backgroundPosition: '-62% 73%',
    // backgroundSize: '680px 537px',
  },
  gradient: {
    borderRadius: '10px',
    backgroundImage: `background: linear-gradient(104.75deg, #000000 -5.01%, #132B4E 134.12%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    `,
  },
  sessionPreviewImage: {
    padding: '90px',
  },
  categoryHeader: {
    color: '#F1E579',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    textTransform: 'capitalize',
  },
  categoryTitle: {
    fontSize: '26px',
    fontWeight: 400,
    color: '#EDEDED',
    lineHeight: '45px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textDecoration: 'none',
    width: '90%',
  },
  sessionPreviewText: {
    paddingTop: '90px',
    paddingLeft: '20px',
    paddingRight: '90px',
  },
  sessionPreviewButton: {
    color: '#7F7F7F',
    background: 'transparent',
    border: '1px solid #7F7F7F',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: '20px',
    marginRight: '10px',
  },
  sessionPreviewIntro: {
    color: '#EDEDED',
    fontWeight: '400',
    fontSize: '26px',
    marginBottom: '20px',
  },
  sessionPreviewDescription: {
    color: '#D2D2D2',
    fontWeight: 300,
    fontSize: '14px',
  },
  close: {
    color: 'rgba(255, 255, 255, 0.54)',
    display: 'flex',
    justifyContent: 'right',
    width: '4%',
    paddingTop: '2%',
    paddingRight: '1%',
  },
  button: {
    height: '37px',
    background: '#FBF197',
    opacity: '0.9',
    borderRadius: '5px',
    color: '#7F7F7F',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: '20px',
    marginRight: '10px',
    color: '#00000',
    '&:hover': {
      color: 'white',
    },
  },
  actionButton: {
    width: '195.33px',
    height: '47px',
    background: '#CF0175',
    borderRadius: '3px',
    color: '#FFFFFF',
    fontWeight: '700',
    fontSize: '16px',
    padding: '14px',
    textTransform: 'initial',
  },
  actionButtonContainer: {
    marginTop: '25%',
    marginLeft: '115%',
  },
  cardImage: {
    borderRadius: '6px',
  },
  tagButton: {
    color: '#7F7F7F',
    background: 'transparent',
    border: '1px solid #7F7F7F',
    fontWeight: '400',
    fontSize: '12px',
    marginLeft: '10px',
    marginBottom: '10px',
    textTransform: 'none',
    height: '28px',
  },
  Container: {
    background:
      'linear-gradient(104.75deg, #000000 -5.01%, #132B4E 134.12%),linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    marginTop:'0px',
    padding: '46px 90px 0px 0px',
    height: '100%',
    display: 'flex',
    borderRadius: '10px',
    overflow: 'none',
    // justifyContent: 'space-between',
    '&:focus-visible ': {
      outline: 'none !important',
    },
    '& .MuiGrid-item': {
      padding: '0px',
    },
  },
  sessionPreviewText: {
    paddingTop: '90px',
    paddingLeft: '20px',
    paddingRight: '90px',
  },
  header: {
    color: '#F1E579',
    marginBottom: '20px',
  },
  normalFont: {
    fontWeight: 300,
    fontSize: '14px',
  },
  title: {
    color: '#EDEDED',
    fontWeight: '400',
    fontSize: '26px',
    marginBottom: '20px',
  },
  description: {
    color: '#D2D2D2',
    width: '100%',
    lineHeight: '21px',
    fontWeight: 300,
    fontSize: '14px',
  },
  sessionPreviewImage: {
    padding: '90px',
  },
}));
export default useStyles;
