import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { LinearProgress, Stack, Box, Typography, Grid } from '@mui/material';
import { Spacer } from 'src/components/shared';
import { fetchDailySendingLimit } from 'src/modules/app/api/appApis';
import { Tabs, FilterBar, Pagination } from 'src/components/App';
import List from '../../components/outbox/List';
import OutboxBulkActions from '../../components/outbox/OutboxBulkActions';
import Message from '../../components/outbox/Message';
import Profile from 'src/modules/contacts/components/Contact/Profile';

import { outboxTabs } from '../../config';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';
import { fetchSequencesLookup } from 'src/modules/sequence/api/sequenceApi';
import Empty from '../../components/Inbox/Empty';

import Skeleton from 'src/modules/contacts/components/Contact/Skeleton';
import { isArray, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { fetchSequenceEnrollment } from 'src/modules/contacts/actions/contactActions';

function OutboxView({ outbox, user, fetchOutboxTasks, taskBulkUpdate, loading, ...props }) {
  const { paging, filters, data } = outbox;
  const [currentTab, setCurrentTab] = useState('inbox');
  const [limit, setLimit] = useState(0);
  const [used, setUsed] = useState(0);
  const [lastFetched, setLastFetched] = useState(new Date());
  const [rowsSelected, setRowsSelected] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [bulkAction, setBulkAction] = useState('');

  const [editTask, setEditTask] = useState({});

  const isAdmin = user?.isAdmin || false;
  const history = useHistory();
  const dispatch = useDispatch();

  const allFilters = [
    {
      key: 'scheduledAt',
      title: 'Scheduled for',
      type: 'relativeDateRange',
      custom: true,
      options: [
        { id: 'today', name: 'Scheduled for today' },
        { id: 'tomorrow', name: 'Scheduled for tomorrow' },
        { id: 'next7days', name: 'Scheduled for next 7 days' },
        { id: 'next10days', name: 'Scheduled for next 10 days' },
      ],
      isPrimary: true,
    },
    ...(isAdmin
      ? [
          {
            key: 'user',
            title: 'User',
            type: 'dropdown',
            remote: true,
            optLabel: 'name',
            optValue: 'id',
            cancellable: false,
            searchable: true,
            isPrimary: true,
            remoteMethod: async (value) => {
              return fetchUsersLookup(value);
            },
          },
        ]
      : []),
    {
      key: 'sequence',
      title: 'Sequence',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      searchable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchSequencesLookup(value);
      },
    },
  ];

  useEffect(() => {
    if (!filters?.user) {
      fetchOutboxTasksData(paging, {
        ...filters,
        user: {
          ...user,
          name: `${user.fname} ${user.lname}`,
        },
      });
    } else {
      fetchOutboxTasksData(paging, filters);
    }

    fetchDailySendingLimit(filters?.user?.id)
      .then((res) => {
        setLimit(res.Limit);
        setUsed(res.used);
      })
      .catch((error) => {
        console.error('Error: ', error);
      });

    // fetchOutboxTasksData(
    //   {
    //     ...outbox.paging,
    //     pageNo: 0,
    //   },
    //   outbox.filters,
    //   outbox.sort,
    // );
  }, [filters?.user]);

  useEffect(() => {
    if (outbox.data && outbox.data.length) {
      handleClick(outbox.data[0]);
    }
  }, [outbox.data]);

  useEffect(() => {
    props.contact?.id && dispatch(fetchSequenceEnrollment(props.contact?.id));
  }, [props.contact]);

  const email = useMemo(() => {
    let emailType = 'work';
    if (editTask?.sequence && editTask?.sequence?.id) {
      emailType = editTask?.sequence?.audienceEmailType === 'work' ? 'work' : 'personal';
    }
    if (
      props.contact &&
      props.contact?.id &&
      props.contact?.emails &&
      props.contact?.emails.length
    ) {
      return props.contact?.emails.filter((email) => email.type == emailType)[0]?.email || '';
    }
    return '';
  }, [props.contact, editTask]);

  const fetchOutboxTasksData = (paging, filters, sort) => {
    setLastFetched(new Date());
    fetchOutboxTasks(user.id, paging, filters, sort);
  };

  const handleSearch = (value) => {
    const key = '_search';
    if (value && value !== '') {
      fetchOutboxTasksData(
        outbox.paging,
        {
          ...outbox.filters,
          [key]: value,
        },
        outbox.sort,
      );
    } else {
      delete outbox.filters[key];
      fetchOutboxTasksData(outbox.paging, outbox.filters, outbox.sort);
    }
  };

  const onFilterChange = (key, value) => {
    if (isArray(value) && !value.length) {
      return false;
    }
    const prev = JSON.stringify(outbox.filters[key]);
    if (value && typeof value !== 'undefined' && prev != JSON.stringify(value)) {
      fetchOutboxTasksData(
        outbox.paging,
        {
          ...outbox.filters,
          [key]: value,
        },
        outbox.sort,
      );
    } else {
      delete outbox.filters[key];
      fetchOutboxTasksData(outbox.paging, outbox.filters, outbox.soprt);
    }
  };

  const refetch = () => {
    fetchOutboxTasksData(outbox.paging, outbox.filters, outbox.sort);
  };

  const progress = useMemo(() => {
    if (limit > 0 && used > 0) {
      return (100 * used) / limit;
    }
    return 0;
  }, [limit, used]);

  const handleClick = (task) => {
    setEditTask(task);
    props.fetchContact(task.contact.id);
    props.fetchSequenceEnrollment(task.contact.id);
  };

  const handlePageChange = ({ page, perPage }) => {
    fetchOutboxTasksData(
      {
        ...outbox.paging,
        pageNo: page,
        perPage,
      },
      outbox.filters,
      outbox.sort,
    );
  };

  const handleBulkAction = (action, tasks) => {
    setBulkAction(action);
    setSelectedTasks(tasks);
  };

  const onClose = () => {
    setBulkAction('');
    setSelectedTasks([]);
  };

  if (!outbox.data.length && !loading && isEmpty(outbox.filters))
    return (
      <Empty
        title="No scheduled emails, yet"
        description="Create sequence today to get your schedule emails!"
        buttonTitle="Create sequence"
        onClick={() => {
          history.push('/sequences');
        }}
      />
    );

  return (
    <>
      <Tabs
        tabs={outboxTabs}
        value={currentTab}
        onChange={() => {}}
        justifyContent="space-between"
        customRender={() => (
          <Stack direction="row" gap={1} alignItems="center">
            <Typography color="textSecondary" variant="body2">
              Daily sending limit :
            </Typography>
            <Typography color="secondary" variant="body2">
              {used} / {limit}
            </Typography>
            <Box width={200}>
              <LinearProgress variant="determinate" color="secondary" value={progress} />
            </Box>

            <Pagination
              count={outbox.paging.count}
              page={outbox.paging.pageNo}
              onChange={handlePageChange}
              perPage={outbox.paging.perPage}
            />
          </Stack>
        )}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FilterBar
            showSearch={false}
            filters={allFilters}
            appliedFilters={{ ...outbox.filters, status: { id: 'completed' } }}
            onChange={onFilterChange}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <List
            data={outbox.data}
            loading={loading}
            onSearch={handleSearch}
            filters={JSON.parse(JSON.stringify(outbox.filters))}
            search={outbox.filters?._search || ''}
            messageKey="latestReplyByDate"
            refetch={refetch}
            handleClick={handleClick}
            handleBulkAction={handleBulkAction}
            type="outbox"
            selected={editTask?.id || undefined}
            {...props}
          />
        </Grid>
        <Grid item md={8} lg={8} xl={9}>
          <div
            style={{
              overflow: 'auto',
              maxHeight: 'calc(100vh - 180px)',
            }}
          >
            {editTask && editTask?.id && editTask?.id !== '' && (
              <>
                {props.contactLoading ? (
                  <Box width="50%">
                    <Skeleton showButtons={false} />
                  </Box>
                ) : (
                  <Profile
                    contact={props.contact}
                    showEdit={false}
                    showAddress={false}
                    showEmail={true}
                    email={email}
                  />
                )}
                <Spacer x={5} y={5} />
                <Message
                  task={editTask}
                  user={user}
                  handleClick={handleClick}
                  setOutbox={props.setOutbox}
                  outbox={outbox}
                />
              </>
            )}{' '}
          </div>
        </Grid>
      </Grid>

      <OutboxBulkActions
        bulkAction={bulkAction}
        onClose={onClose}
        selectedTasks={selectedTasks}
        taskBulkUpdate={taskBulkUpdate}
      />
    </>
  );
}

export default OutboxView;
