import React from 'react';
import { Stack, Box } from '@mui/material';
// import { FilterBar } from 'src/components/App';
import FilterBar from 'src/components/lms/Filterbar';
import { Spacer } from 'src/components/shared';

import { useLmsReportsProvider } from '../../utils/reportsStore';
import { fetchUsersLookup } from 'src/modules/users/api/usersApi';

import Cards from './Cards';
import Chart from './Chart';
import Users from './Users';
import { fetchTeamsLookup } from 'src/modules/admin/api/adminApi';

function index() {
  const { filters, setFilters, loading } = useLmsReportsProvider();

  const filtersArr = [
    //
    {
      key: 'team',
      title: 'Team',
      type: 'dropdown',
      remote: true,
      optLabel: 'name',
      optValue: 'id',
      cancellable: true,
      isPrimary: true,
      remoteMethod: async (value) => {
        return fetchTeamsLookup(value);
      },
    },
    {
      key: 'createdAt',
      title: 'Filter By Date',
      custom: true,
      type: 'relativeDateRange',
      customOptions: {
        maxDate: new Date(),
      },
      options: [
        { id: 'today', name: 'Today' },
        { id: 'yesterday', name: 'Yesterday' },
        { id: 'thisWeek', name: 'This week' },
        { id: 'lastWeek', name: 'Last week' },
        { id: 'thisMonth', name: 'This month' },
        { id: 'lastMonth', name: 'Last month' },
        { id: 'last7days', name: 'Last 7 days' },
        { id: 'last14days', name: 'Last 14 days' },
        { id: 'last30Days', name: 'Last 30 days' },
        { id: 'last3Month', name: 'Last 3 months' },
      ],
      isPrimary: true,
    },
  ];

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <FilterBar filters={filtersArr} value={filters} onChange={handleFilterChange} />
      </Stack>
      <Spacer x={2} y={2} />
      <Cards />
      <Spacer x={2} y={2} />
      <Chart />
      <Box mt={2}>
        <Users loading={loading?.summary || false} />
      </Box>
    </>
  );
}

export default index;
