import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';
import SequenceView from './SequenceView';
import SequenceSkeleton from '../../components/Sequence/SequenceSkeleton';
import { getSequenceFields } from 'src/modules/app/actions/appSelector';

import {
  fetchSequence,
  fetchSequenceEvents,
  postSequenceEvent,
  putSequenceEvent,
  deleteSequenceEvent,
  handleEventsReorder,
  putSequence,
  fetchSequenceContacts,
  sequenceBulkAction,
  cloneSequence,
  enrollContactsToSequence,
  fetchSequenceStats,
  clearSeqData,
  setSequence,
  fetchSequenceContactsResolve,
} from '../../actions/sequenceActions';
import { fetchIntegrations, saveTemplates } from 'src/modules/admin/actions/adminActions';
import {
  fetchSeqDashboardsReport,
  toggleAddContactForm,
  toggleAddSequenceForm,
} from 'src/modules/app/actions/appActions';
import { contactBulkAction, deleteContact } from '../../../contacts/actions/contactActions';

export const SequenceContainer = (props) => {
  const [showOnboarding, setShowOnboarding] = React.useState(false);

  useEffect(() => {
    return () => {
      props.clearSeqData();
    };
  }, []);

  useEffect(() => {
    const seqId = props.match?.params?.id || 0;
    if (seqId && seqId !== '') {
      props.fetchSequence(seqId);
      props.fetchSequenceEvents(seqId);
      props.fetchSequenceStats(seqId);
      props.fetchIntegrations();
    }
  }, []);

  useEffect(() => {
    if (props.location && props.location?.search && props.location?.search == '?onboarding=true') {
      setShowOnboarding(true);
    }
  }, [props]);

  return (
    <>
      <PageTitle title={`Sequence - ${props.sequence.name}`} />
      {props.loading && !showOnboarding ? <SequenceSkeleton /> : <SequenceView {...props} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  integrations: state.admin.admin.integrations,
  loading: state.sequence.loading.sequence,
  loadingEvents: state.sequence.loading.sequenceEvents,
  sequence: state.sequence.sequence,
  sequenceEvents: state.sequence.sequenceEvents,
  sequenceAccessTypeList: state.app.globals.sequenceAccessTypeList,
  sequenceEmailTypeList: state.app.globals.sequenceEmailTypeList,
  contactsLoading: state.sequence.loading.sequencesContacts,
  sequencesContacts: state.sequence.sequencesContacts,
  fields: getSequenceFields(state),
  sequenceStatsLoading: state.sequence.loading.sequenceStats,
  enrollmentsStats: state.sequence.enrollmentsStats,
  sequencesStats: state.sequence.sequenceStats,
  user: state.auth.user,
  globalConfig: state.app.globals,
});

const mapDispatchToProps = (dispatch) => ({
  clearSeqData: () => dispatch(clearSeqData()),
  fetchIntegrations: () => dispatch(fetchIntegrations()),
  fetchSequence: (id) => dispatch(fetchSequence(id)),
  setSequence: () => dispatch(setSequence({})),
  fetchSequenceStats: (sequenceId) => dispatch(fetchSequenceStats(sequenceId)),
  fetchSequenceEvents: (id) => dispatch(fetchSequenceEvents(id)),
  toggleAddSequenceForm: () => dispatch(toggleAddSequenceForm()),
  fetchSeqDashboardsReport: (query) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSeqDashboardsReport(query, resolve, reject));
    });
  },
  fetchSequenceContacts: (paging, filters, seqId, sort) =>
    dispatch(fetchSequenceContacts(paging, filters, seqId, true, sort)),
  cloneSequence: (sequenceId, sequenceName) => {
    return new Promise((resolve, reject) => {
      dispatch(cloneSequence(sequenceId, sequenceName, resolve, reject));
    });
  },
  putSequence: (sequenceId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequence(sequenceId, payload, resolve, reject));
    });
  },
  fetchSequenceContactsResolve: (seqId, search) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchSequenceContactsResolve(seqId, search, resolve, reject));
    });
  },
  postSequenceEvent: (sequenceId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postSequenceEvent(sequenceId, payload, resolve, reject));
    });
  },
  putSequenceEvent: (sequenceId, stepId, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(putSequenceEvent(sequenceId, stepId, payload, resolve, reject));
    });
  },
  deleteSequenceEvent: (sequenceId, stepId, hideToaster) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteSequenceEvent(sequenceId, stepId, resolve, reject, hideToaster));
    });
  },
  sequenceBulkAction: (action, sequences, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(sequenceBulkAction(action, sequences, payload, resolve, reject));
    });
  },
  enrollContactsToSequence: (sequenceId, contacts) => {
    return new Promise((resolve, reject) => {
      dispatch(enrollContactsToSequence(sequenceId, contacts, resolve, reject));
    });
  },
  handleEventsReorder: (sequenceId, stepId, eventOrder) =>
    dispatch(handleEventsReorder(sequenceId, stepId, eventOrder)),
  saveTemplates: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(saveTemplates(data, null, resolve, reject));
    });
  },
  deleteContact: (contactId) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteContact(contactId, resolve, reject, true));
    });
  },
  contactBulkAction: (action, contacts, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(contactBulkAction(action, contacts, payload, resolve, reject));
    });
  },
  toggleAddContactForm: (contact) => dispatch(toggleAddContactForm(contact)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SequenceContainer);
