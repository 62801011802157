export const SET_CONTACTS_LOADING = 'SET_CONTACTS_LOADING';
export const TOGGLE_CONTACT_PROFILE = 'TOGGLE_CONTACT_PROFILE';
export const CLEAR_CONTACT_DATA = 'CLEAR_CONTACT_DATA';

export const FETCH_CONTACT_TABS = 'FETCH_CONTACT_TABS';
export const SET_CONTACT_TABS = 'SET_CONTACT_TABS';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_CONTACTS_LISTS = 'FETCH_CONTACTS_LISTS';
export const CLEAR_CONTACTS_FILTERS = 'CLEAR_CONTACTS_FILTERS';
export const SET_CONTACTS = 'SET_CONTACTS';
export const POST_CONTACT = 'POST_CONTACT';
export const PUT_CONTACT = 'PUT_CONTACT';
export const PATCH_CONTACT = 'PATCH_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

export const SET_CONTACT_OPTIONS_FILTERS = 'SET_CONTACT_OPTIONS_FILTERS';
export const FETCH_CONTACT_OPTIONS_FILTERS = 'FETCH_CONTACT_OPTIONS_FILTERS';

export const FETCH_CONTACT = 'FETCH_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACT_CRM_CONNECTION = 'SET_CONTACT_CRM_CONNECTION';
export const SET_FETCH_CONTACT_CRM_CONNECTION = 'SET_FETCH_CONTACT_CRM_CONNECTION';

export const UPDATE_CONTACT_SOCIAL_MEDIA = 'UPDATE_CONTACT_SOCIAL_MEDIA';
export const POST_CONTACT_DETAIL = 'POST_CONTACT_DETAIL';
export const PUT_CONTACT_DETAIL = 'PUT_CONTACT_DETAIL';
export const UPDATE_ENROLLMENT_STATUS = 'UPDATE_ENROLLMENT_STATUS';
export const DELETE_CONTACT_DETAIL = 'DELETE_CONTACT_DETAIL';

export const ADD_CONTACTS = 'ADD_CONTACTS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';

export const FETCH_FILTER_JOB_OPTIONS = 'FETCH_FILTER_JOB_OPTIONS';
export const FETCH_FILTER_EMP_OPTIONS = 'FETCH_FILTER_EMP_OPTIONS';
export const FETCH_FILTER_LOCATION_OPTIONS = 'FETCH_FILTER_LOCATION_OPTIONS';
export const FETCH_SEQUENCE_OPTIONS = 'FETCH_SEQUENCE_OPTIONS';

export const FETCH_CONTACT_TASK = 'FETCH_CONTACT_TASK';
export const FETCH_CONTACT_NOTE = 'FETCH_CONTACT_NOTE';
export const FETCH_CONTACT_NOTES = 'FETCH_CONTACT_NOTES';
export const FETCH_CONTACT_TASKS = 'FETCH_CONTACT_TASKS';
export const FETCH_SEQUENCE_ENROLL = 'FETCH_SEQUENCE_ENROLL';
export const FETCH_SEQUENCE_ENROLLMENTS = 'FETCH_SEQUENCE_ENROLLMENTS';

export const SET_CONTACT_TASK = 'SET_CONTACT_TASK';
export const SET_CONTACT_NOTE = 'SET_CONTACT_NOTE';
export const SET_CONTACT_NOTES = 'SET_CONTACT_NOTES';
export const SET_CONTACT_TASKS = 'SET_CONTACT_TASKS';
export const POST_CONTACT_NOTE = 'POST_CONTACT_NOTE';
export const SET_SEQUENCE_ENROLLMENT = 'SET_SEQUENCE_ENROLLMENT';
export const SET_SEQUENCE_ENROLLMENTS = 'SET_SEQUENCE_ENROLLMENTS';

export const CONTACT_BULK_ACTION = 'CONTACT_BULK_ACTION';
export const CHECK_ENROLLMENT_AND_FETCH_ERROR = 'CHECK_ENROLLMENT_AND_FETCH_ERROR';

export const ADD_CONTACT_TO_SEQUENCE = 'ADD_CONTACT_TO_SEQUENCE';
export const SEND_CONTACT_EMAIL = 'SEND_CONTACT_EMAIL';
export const PUT_CONTACT_EMAIL = 'PUT_CONTACT_EMAIL';

export const FETCH_BULLHORN_MATCHES = 'FETCH_BULLHORN_MATCHES';
export const SET_BULLHORN_MATCHES = 'SET_BULLHORN_MATCHES';
export const SYNC_BULLHORN_CONTACT = 'SYNC_BULLHORN_CONTACT';
export const SYNC_BULLHORN_NEW_CONTACT = 'SYNC_BULLHORN_NEW_CONTACT';

export const FETCH_VINCERE_MATCHES = 'FETCH_VINCERE_MATCHES';
export const SET_VINCERE_MATCHES = 'SET_VINCERE_MATCHES';
export const SYNC_VINCERE_CONTACT = 'SYNC_VINCERE_CONTACT';
export const SYNC_VINCERE_NEW_CONTACT = 'SYNC_VINCERE_NEW_CONTACT';

export const FETCH_SALESFORCE_MATCHES = 'FETCH_SALESFORCE_MATCHES';
export const SET_SALESFORCE_MATCHES = 'SET_SALESFORCE_MATCHES';
export const SYNC_SALESFORCE_CONTACT = 'SYNC_SALESFORCE_CONTACT';
export const SYNC_SALESFORCE_NEW_CONTACT = 'SYNC_SALESFORCE_NEW_CONTACT';

export const FETCH_HUBSPOT_MATCHES = 'FETCH_HUBSPOT_MATCHES';
export const SET_HUBSPOT_MATCHES = 'SET_HUBSPOT_MATCHES';
export const SYNC_HUBSPOT_CONTACT = 'SYNC_HUBSPOT_CONTACT';
export const SYNC_HUBSPOT_NEW_CONTACT = 'SYNC_HUBSPOT_NEW_CONTACT';

export const FETCH_JOBADDER_MATCHES = 'FETCH_JOBADDER_MATCHES';
export const SET_JOBADDER_MATCHES = 'SET_JOBADDER_MATCHES';
export const SYNC_JOBADDER_CONTACT = 'SYNC_JOBADDER_CONTACT';
export const SYNC_JOBADDER_NEW_CONTACT = 'SYNC_JOBADDER_NEW_CONTACT';

export const SELECTED_CONTACT_IDS = 'SELECTED_CONTACT_IDS';
