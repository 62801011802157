import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Button } from 'src/components/shared';

const StyledContentHeader = styled(Typography)`
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const StyledContentDescription = styled(Typography)`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
`;

const StyledSubContentDescription = styled(Typography)`
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledExperienceButton = styled(Button)`
  flex: 1 0 0;
  border-radius: 7px;
`;

export const ContentHeader = ({ children }) => {
  return (
    <StyledContentHeader sx={(theme) => ({ color: theme.palette.text.black })}>
      {children}
    </StyledContentHeader>
  );
};

export const ContentDescription = ({ children }) => {
  return (
    <StyledContentDescription sx={(theme) => ({ color: theme.palette.text.black })}>
      {children}
    </StyledContentDescription>
  );
};

export const SubContentDescription = ({ sx = {}, children }) => {
  return (
    <StyledSubContentDescription sx={{ ...sx, color: '#000000DE' }}>
      {children}
    </StyledSubContentDescription>
  );
};

export const ExperienceButton = ({ children, blackButton, ...props }) => {
  return (
    <StyledExperienceButton
      sx={{
        background: blackButton ? `#EDF4FB` : '#F9F9F9',
        color: '#000000DE',
        '&:hover': {
          boxShadow: 'none !important',
          background: `#EDF4FB !important`,
          color: `#000000DE`,
          border: `1px solid #1976D2 !important`,
        },
        height: '48px',
        border: `1px solid ${blackButton ? '#1976D2' : '#F9F9F9'}`,
      }}
      {...props}
    >
      {children}
    </StyledExperienceButton>
  );
};
