import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EmailIcon from "@mui/icons-material/Email";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import SmsIcon from "@mui/icons-material/Sms";
import images from "../../../config/images";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";

export const getPercentage = (value, total, isString = false) => {
  let progress = 0;
  if (parseInt(value) > 0 && parseInt(total) > 0) {
    progress = Math.floor((100 * value) / total);
  }
  if (isString) {
    return `${progress}%`;
  } else {
    return progress;
  }
};

export const handleEventsSorting = (events, event, eventOrder, increment = true) => {
  events = events.sort(function (a, b) {
    return a.eventOrder - b.eventOrder;
  });

  let sortedEvents = [
    ...events.filter((item) => item.eventOrder < eventOrder),
    {
      ...event,
      eventOrder,
    },
    ...events
      .filter((item) => item.eventOrder >= eventOrder && item.id !== event.id)
      .map((item) => ({ ...item, eventOrder: increment ? item.eventOrder + 1 : item.eventOrder })),
  ];
  sortedEvents = Object.assign({}, ...sortedEvents.map((event) => ({ [event.id]: event })));
  return sortedEvents;
};

export const getFilterLabel = (text = '', data) => {
  let newLabel = {};
  switch (text) {
    case 'assignees': {
      newLabel = {
        type: 'assignees',
        title: 'All Assignees',
        isSingle: true,
        showName: true,
      };
      break;
    }
    case 'created_by': {
      newLabel = {
        type: 'created_by',
        title: 'Created by',
      };
      break;
    }
    case 'type': {
      newLabel = {
        type: 'type',
        title: 'Type',
      };
      break;
    }

    case 'access_level': {
      newLabel = {
        type: 'access_level',
        title: 'Access level',
      };
      break;
    }
    case 'in_progress': {
      newLabel = {
        type: 'in_progress',
        title: 'In Progress',
      };
      break;
    }
    default: {
      newLabel = {};
      break;
    }
  }

  return newLabel;
};

export const parseSequenceFilterData = (data, tab) => {
  let filterListData = Object.keys(data);
  filterListData = filterListData
    .filter((e) => e !== 'created_date')
    .map((k) => ({
      name: k,
      data: data[k],
      optionLabel: 'name',
      optionValue: 'id',
      ...getFilterLabel(k, data),
    }));

  filterListData.push({
    name: 'createDate',
    control: 'dateRangePicker',
    isSingle: true,
    title: 'Created date',
    type: 'createDate',
    from: 'createdFrom',
    to: 'createdTo',
  });

  return filterListData;
};

export const stepTypes = [
  {
    title: 'Wait time',
    desc: 'Add a wait time between steps.',
    icon: <WatchLaterIcon style={{ fill: '#78909C', width: 32, height: 32 }} />,
    type: 'wait',
  },
  {
    title: 'Email',
    desc: 'Send an automated email.',
    icon: <EmailIcon style={{ fill: '#42A5F5', width: 32, height: 32 }} />,
    type: 'email',
  },
  {
    title: 'Select email template',
    desc: 'Choose email at the time of enrolling.',
    icon: <ContactMailIcon style={{ fill: '#FFA726', width: 32, height: 32 }} />,
    type: 'selectEmailTemplate',
    isAction: true,
  },
  {
    title: 'LinkedIn',
    desc: 'Create a linkedIn task',
    icon: <LinkedInIcon style={{ fill: '#1282B2', width: 32, height: 32 }} />,
    type: 'linkedin',
  },
  {
    title: 'Call back',
    desc: 'Add a call step.',
    icon: <PhoneCallbackIcon style={{ fill: '#66BB6A', width: 32, height: 32 }} />,
    type: 'call',
  },
  {
    title: 'SMS',
    desc: 'Add an SMS step.',
    icon: <SmsIcon style={{ fill: '#66BABC', width: 32, height: 32 }} />,
    type: 'sms',
  },
  {
    title: 'To do',
    desc: 'Add a to do step.',
    icon: <img src={images.sequence.AddTaskFilled} style={{ width: 32, height: 32 }} />,
    type: 'general',
  },
  {
    title: 'End the sequence',
    desc: 'End step of sequence',
    icon: <LockIcon style={{ fill: '#EF5350', width: 32, height: 32 }} />,
    type: 'endsquence',
    isAction: true,
  },
];

export const filterCardKeys = ['messaged', 'uniqueopens', 'uniqueclicks', 'uniquereplies', 'interested'];
