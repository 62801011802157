import React from 'react';
import { Box, Stack } from '@mui/material';
import images from 'src/config/images';
import { ContentDescription, ContentHeader } from './styles';
import { BottomButton } from './utils';

const GetStarted = ({ userJourney, setUserJourney, handleUpdateUserJourney, loading }) => {
  return (
    <>
      <Stack gap={'40px'}>
        <ContentHeader>Now lets get cracking</ContentHeader>
        <Box sx={{ textAlign: 'center' }}>
          <img src={images.journey.crackingImg} width={'100%'}/>
        </Box>
      </Stack>
      <BottomButton
        nextButtonTitle={'Create sequence'}
        onNext={() => {
          handleUpdateUserJourney(userJourney, 'completed');
        }}
      />
    </>
  );
};
export default GetStarted;
