import React from 'react';
import LMSCard from 'src/components/lms/LMSCard';
import Slider from 'src/components/lms/Slider';
import Course from 'src/components/lms/Course';
import useMediaQuery from 'src/components/hooks/useMediaQuery';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

function HomeBannerSection({ sectionData, title }) {
  const { isMobile } = useMediaQuery();

  if (!sectionData.length) return null;

  const sliderProps = {
    slidesToShow: 4,
    ...(isMobile && {
      centerMode: true,
      centerPadding: '30px',
      arrows: true,
      dots: true,
      fade: true,
    }),
    padding: 16,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <LMSCard title={title} icon={<></>}>
        <Slider {...sliderProps}>
          {sectionData.map((course, index) => (
            <Course course={course} key={`sales-home-${course}`} />
          ))}
        </Slider>
      </LMSCard>
    </>
  );
  return <div>HomeBannerSection</div>;
}

export default HomeBannerSection;
