import React, { useMemo, useState, useRef, useEffect, Fragment } from 'react';
import { Box, Typography, Alert, Tabs, Tab, Backdrop, Card, Stack } from '@mui/material';
import { Spacer, Form, Button } from 'src/components/shared';
import { Accordion, Empty } from 'src/components/App';
import { SubmitButtonWrapper } from './styles';
import { Email, SelectTemplate, Call, LinkedIn, General } from './Input';
import ContactDetails from 'src/modules/contacts/components/ContactDetails';
import { getTaskTypeIcon, getTaskTypeText } from 'src/modules/tasks/utils/tasksUtils';
import SMS from './Input/SMS';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ActivityItem from 'src/modules/contacts/components/ContactActivities/ActivityItem';
import SequenceItem from 'src/modules/contacts/components/Contact/sequence';
import { connect, useDispatch } from 'react-redux';
import { getContactFields } from 'src/modules/app/actions/appSelector';
import Attributes from 'src/modules/contacts/components/Contact/attributes';
import {
  checkEnrolmentAndFetchErrors, fetchSequenceEnrollment,
  postContactDetails,
  putEnrollment,
} from 'src/modules/contacts/actions/contactActions';

function EnrolmentPersonalizeForm({
  contact,
  sequence,
  steps,
  handleSubmit,
  action,
  sequenceEnrollment,
  contactNote,
  fields,
  personaliseEnrollment,
  enrolments,
  ...props
}) {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedEmailState, setSelectedEmailState] = useState(enrolments?.selectedEmailId);
  const [currentTab, setCurrentTab] = useState(0);

  const initialValues = useMemo(() => {
    const values = {};
    if (steps && Object.keys(steps) && Object.keys(steps)) {
      Object.keys(steps).map((key) => {
        const task = steps[key];
        switch (true) {
          case task.taskType === 'email':
            values[key] = {
              templateId: task?.template?.id,
              subject: task?.template?.subject,
              content: task?.template?.content,
              contentOriginal: task?.template?.contentOriginal
                ? task?.template?.contentOriginal
                : task?.template?.content,
              files: task?.template?.files,
              attachments: task?.template?.attachments || [],
              // sender: task.sender,
              taskType: task?.taskType,
            };
            break;

          case task.taskType === 'selectEmailTemplate':
            values[key] = {
              templateId: task?.template || {},
              subject: task?.template?.subject || '',
              content: task?.template?.content || '',
              contentOriginal: task?.template?.contentOriginal
                ? task?.template?.contentOriginal
                : task?.template?.content,
              files: task?.template?.files || [],
              attachments: task?.template?.attachments || [],
              // sender: task.sender,
              taskType: task?.taskType,
            };
            break;

          case task.taskType === 'general':
          case task.taskType === 'call':
          case task.taskType === 'sms':
            values[key] = {
              description: task?.description,
              content: task?.description,
              taskType: task?.taskType,
            };
            break;

          case task?.taskType == 'linkedinMessage' ||
            task?.taskType == 'linkedinConnection' ||
            task?.taskType == 'linkedinViewProfile' ||
            task?.taskType == 'linkedinMail':
            values[key] = {
              templateId: task?.template?.id,
              subject: task?.template?.subject,
              content: task?.template?.content,
              files: task?.template?.files,
              attachments: task?.template?.attachments || [],
              taskType: task?.taskType,
            };
            break;

          default:
            break;
        }
      });
      return values;
    }
  }, [steps]);

  useEffect(() => {
    if (contact?.emails && contact?.emails.length && !enrolments?.selectedEmailId) {
      switch (true) {
        case sequence?.audienceEmailType === 'personal':
          let selected = contact.emails.filter(
            (item) => item.type === 'personal' && item.isPrimary === true,
          );
          if (selected && selected.length) {
            setSelectedEmailState(selected[0].id);
          }
          break;

        case sequence?.audienceEmailType === 'work':
          let selectedWork = contact.emails.filter(
            (item) => item.type === 'work' && item.isPrimary === true,
          );
          if (selectedWork && selectedWork.length) {
            setSelectedEmailState(selectedWork[0].id);
          }
          break;
      }
    } else if (enrolments?.selectedEmailId) setSelectedEmailState(enrolments?.selectedEmailId);
  }, [contact?.emails || [], sequence, enrolments?.selectedEmailId]);

  const onSubmit = async (data, resume = true) => {
    let formData = JSON.parse(JSON.stringify(data));
    const selectedEmail = formData.selectedEmail;
    delete formData.selectedEmail;
    const values = { selectedEmail };
    if (Object.keys(formData) && Object.keys(formData).length) {
      Object.keys(formData).map((key) => {
        const filesArr =
          formData[key]?.attachments && formData[key]?.attachments.length
            ? formData[key]?.attachments.filter((item) => item.id).map((item) => item.id)
            : [];
        if (formData[key]?.taskType === 'selectEmailTemplate' && !formData[key]?.templateId?.id)
          return null;
        values[key] = {
          ...formData[key],
          attachments: filesArr,
          files: filesArr,
        };
      });
    }

    setLoading(true);
    await handleSubmit(values, resume);
    setLoading(false);
  };

  return (
    <Form
      initialValues={{
        selectedEmail: selectedEmailState,
        ...initialValues,
      }}
      enableReinitialize={true}
      onSubmit={(values) => onSubmit(values, true)}
      innerRef={formRef}
    >
      {({ values, ...formProps }) => {
        return (
          <Fragment>
            <Box px={2} mt={2}>
              {formProps?.errors?.selectedEmail && formProps?.errors?.selectedEmail !== '' && (
                <>
                  <Alert severity="error" sx={{ padding: '4px 12px' }}>
                    {formProps?.errors?.selectedEmail}
                  </Alert>
                  <Spacer x={2} y={2} />
                </>
              )}
              <Typography color="textSecondary">Confirm details for your outreach</Typography>
              <Box mt={1}>
                <ContactDetails
                  contact={contact}
                  showSelection={true}
                  onChange={() => dispatch(checkEnrolmentAndFetchErrors())}
                  selected={values.selectedEmail}
                  type={'dropdown'}
                  onSelectionChange={(value) => {
                    // formProps.setFieldValue('selectedEmail', value);
                    setSelectedEmailState(value);
                  }}
                  {...props}
                />
              </Box>
            </Box>

            <Tabs
              value={currentTab}
              onChange={(e, val) => {
                setCurrentTab(val);
              }}
              sx={{
                '.Mui-selected': {
                  color: '#1976d2',
                },
                '.MuiTabs-indicator': {
                  backgroundColor: '#1976d2',
                },
              }}
              variant="fullWidth"
            >
              <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="Personalise" />
              <Tab sx={{ textTransform: 'initial', fontSize: '15px' }} label="Other Info" />
            </Tabs>

            {currentTab === 0 && steps && Object.keys(steps).length ? (
              <>
                {Object.keys(steps)
                  .reduce((acc, currentValue) => {
                    const step = steps[currentValue];
                    step.taskId = currentValue;
                    if ('taskOrderInSequence' in step) {
                      const insertIndex = acc.findIndex(
                        (item) => item.taskOrderInSequence > step.taskOrderInSequence,
                      );
                      insertIndex === -1 ? acc.push(step) : acc.splice(insertIndex, 0, step);
                    } else {
                      acc.push(step);
                    }
                    return acc;
                  }, [])
                  .map((task, index) => {
                    return (
                      <Accordion
                        key={`sequence-personalize-step-${index}`}
                        title={getTaskTypeText(task?.taskType)}
                        icon={getTaskTypeIcon(task?.taskType)}
                        expanded={index === 0 ? true : false}
                        rotateIcon={false}
                      >
                        <Box px={2}>
                          {task?.taskType == 'email' ? (
                            <Email
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                          {task?.taskType == 'general' ? (
                            <General
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                          {task?.taskType == 'selectEmailTemplate' ? (
                            <SelectTemplate
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                          {task?.taskType == 'call' ? (
                            <Call
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                          {task?.taskType == 'linkedinMessage' ||
                          task?.taskType == 'linkedinConnection' ||
                          task?.taskType == 'linkedinViewProfile' ||
                          task?.taskType == 'linkedinMail' ? (
                            <LinkedIn
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                          {task?.taskType === 'sms' ? (
                            <SMS
                              contact={contact}
                              task={task}
                              taskId={task?.taskId}
                              formRef={formRef}
                            />
                          ) : null}
                        </Box>
                      </Accordion>
                    );
                  })}
              </>
            ) : null}

            {currentTab === 1 && (
              <Box>
                <Accordion title="Sequence History" expanded={false}>
                  <Box sx={{ padding: '16px 12px' }}>
                    {sequenceEnrollment?.length ? (
                      <>
                        {sequenceEnrollment.map((sequence, index) => (
                          <SequenceItem
                            sequence={sequence}
                            putEnrollment={props.putEnrollment}
                            key={`sequence-item-${sequence.id}-${index}`}
                          />
                        ))}
                      </>
                    ) : (
                      <Empty title="No sequences found" />
                    )}
                  </Box>
                </Accordion>
                <Accordion title="Activity" expanded={false}>
                  {contactNote?.length > 0 ? (
                    contactNote.map((note, index) => (
                      <ActivityItem data={note} key={`contact-activity-${index}`} />
                    ))
                  ) : (
                    <Empty title="No activities found!" />
                  )}
                </Accordion>
                <Accordion title="Attributes" expanded={false}>
                  <Box sx={{ padding: 3 }} display={'flex'} gap={2} flexDirection={'column'}>
                    <Attributes contact={contact} fields={fields} />
                  </Box>
                </Accordion>
              </Box>
            )}

            <Spacer x={10} y={10} />

            <SubmitButtonWrapper>
              {action === 'edit' && (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    loading={loading}
                    color="secondary"
                    sx={{ flex: 1.3 }}
                    onClick={() => {
                      onSubmit(values, false);
                    }}
                  >
                    Save Edits
                  </Button>
                  <Spacer x={2} y={2} />
                </>
              )}

              <Button
                fullWidth
                variant="contained"
                loading={loading}
                color="secondary"
                sx={{ flex: 1.7 }}
                onClick={() => {
                  formProps.submitForm();
                }}
              >
                {action === 'edit'
                  ? 'Save and resume Outreach'
                  : !personaliseEnrollment
                  ? 'Enrol'
                  : `Save edits`}
                &nbsp; {(action === 'edit' || personaliseEnrollment) && <ArrowForwardRoundedIcon />}
              </Button>
            </SubmitButtonWrapper>
          </Fragment>
        );
      }}
    </Form>
  );
}

const mapStateToProps = (state) => ({
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  contactNote: state.contacts.contactNote,
  fields: getContactFields(state),
});

const mapDispatchToProps = (dispatch) => ({
  putEnrollment: (id, enrollment) => dispatch(putEnrollment(id, enrollment)),
  postContactDetails: (contactId, type, payload) => {
    return new Promise((resolve, reject) => {
      dispatch(postContactDetails(contactId, type, payload, resolve, reject));
    });
  },
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrolmentPersonalizeForm);
